import styled, { css } from "styled-components";

import Button from "../../../../../../../../components/atoms/Button";

export const Container = styled.div`
  gap: 48px;
  width: 100%;
  display: flex;
  min-height: 400px;
  padding: 0 60px 48px;
  flex-direction: column;

  @media (max-width: 1160px) {
    padding: 0 0 48px;
  }
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Questions = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 0 40px;
  flex-direction: column;

  @media (max-width: 740px) {
    padding: 0;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
`;

type CompanyQuestionProps = {
  maxWidth: string;
};

export const CompanyQuestion = styled.div<CompanyQuestionProps>`
  ${({ maxWidth }) => css`
    gap: 32px;
    width: 100%;
    display: flex;
    max-width: ${maxWidth};
  `}
`;

export const SliderAndNumber = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

type ButtonsProps = {
  isVisible: boolean;
};

export const Clickable = styled(Button)<ButtonsProps>`
  ${({ isVisible }) => css`
    width: 126px;
    align-items: center;
    justify-content: center;
    display: ${isVisible ? "flex" : "none"};
  `}
`;

type BoxProps = {
  marginTop?: string;
  borderColor: string;
};

export const Box = styled.div<BoxProps>`
  ${({ marginTop, borderColor }) => css`
    gap: 40px;
    display: flex;
    padding: 40px 40px;
    border-radius: 12px;
    flex-direction: column;
    border: 2px solid ${borderColor};
    margin-top: ${marginTop ? marginTop : 0};
  `}
`;

export const ProductionBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

type SeparatorLineProps = {
  borderColor: string;
};

export const SeparatorLine = styled.span<SeparatorLineProps>`
  ${({ borderColor }) => css`
    content: "";
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${borderColor};
  `}
`;

export const QuestionsProduction = styled.div`
  width: 100%;
  gap: 12px 8px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  justify-content: space-between;

  @media (max-width: 1250px) {
    gap: 20px;
  }

  @media (max-width: 580px) {
    padding: 0;
  }
`;

export const DropdownQuestion = styled.div`
  display: flex;
  min-width: 240px;
`;

export const AddButton = styled.div`
  width: 100%;
  display: flex;
`;

export const RemoveIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
