// import { useEffect, useState } from "react";

import * as S from "./styles";
// import { Reports } from "../../services";
// import { emptyReport } from "../../utils";
// import Button from "../../components/atoms/Button";
import Page from "../../components/molecules/Page";
import { Auth, Snackbar, Theme } from "../../hooks";
import H3 from "../../components/atoms/Typography/H3";
import InputText from "../../components/molecules/InputText";
// import { IReport, TClassification } from "../../types/report";
// import OptionsList from "../../components/molecules/OptionsList";
import TitleDescription from "../../components/molecules/TitleDescription";

const { REACT_APP_REPORT_BASE_URL } = process.env;

const Report: React.FC = () => {
  // const [report, setReport] = useState<IReport>(emptyReport);
  // const [initialReport, setInitialReport] = useState<IReport>(emptyReport);

  const { user } = Auth.useAuth();
  const { newSuccess } = Snackbar.useSnackbar();
  const { /*primaryColor,*/ tertiaryColor, textColor /*, backgroundColor*/ } =
    Theme.useTheme();

  // useEffect(() => {
  //   const run = async () => {
  //     const reportOptions = await Reports.getReportOptions(
  //       user.entity,
  //       user.username || ""
  //     );

  //     setReport(reportOptions);
  //     setInitialReport(reportOptions);
  //   };

  //   run();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const classification =
  //   report?.classification === "negative" ? "negativo" : "neutro";

  // const titleOptions = [
  //   `Somos carbono ${classification}! Compensamos ${report.carbonCompensate} tCO2 até agora. E não vamos parar por aqui.`,
  //   `Daqui para o Futuro: Nossa marca agora é Carbono ${classification}!`,
  // ];

  // const descriptionOptions: { [key in TClassification]: string[] } = {
  //   neutral: [
  //     "Somos carbono neutro, ou seja, compensamos tudo aquilo que emitimos. Neutralizando nossa operação de modo que nossas atividades não impactam negativamente o planeta.",
  //     `Pensando no nosso passado, no presente e no futuro, decidimos compensar nossas emissões de CO2. Até agora compensamos ${report.carbonCompensate} tCO2 e não vamos parar por aqui!`,
  //   ],
  //   negative: [
  //     "Somos carbono negativo, ou seja, compensamos tudo aquilo que emitimos e ainda mais! Neutralizamos mais do que as emissões de nossas atividades para ajudar a salvar o planeta.",
  //     `Pensando no nosso passado, no presente e no futuro, decidimos compensar mais do que nossas emissões de CO2. Até agora compensamos ${report.carbonCompensate} tCO2 e não vamos parar por aqui!`,
  //   ],
  //   friendly: [
  //     "Somos carbono negativo, ou seja, compensamos tudo aquilo que emitimos e ainda mais! Neutralizamos mais do que as emissões de nossas atividades para ajudar a salvar o planeta.",
  //     `Pensando no nosso passado, no presente e no futuro, decidimos compensar mais do que nossas emissões de CO2. Até agora compensamos ${report.carbonCompensate} tCO2 e não vamos parar por aqui!`,
  //   ],
  // };

  const setIsCopied = () => {
    newSuccess("URL Copiada!");
  };

  // const onSaveChanges = async () => {
  //   if (report && changedReport) {
  //     const newReport = await Reports.saveReportChanges(report);
  //     setReport(newReport);
  //     setInitialReport(newReport);
  //   }
  // };

  // const changedReport =
  //   JSON.stringify(report) !== JSON.stringify(initialReport);

  return (
    <Page pageIndex={5} mobileSection="statement">
      <S.Content>
        <TitleDescription
          title="Report"
          description="Comunique a seus clientes seus esforços na luta por um mundo mais sustentável. Habilite seu Report e comece a divulgar!"
        />

        <S.Report>
          {!user.currentCalculation || user.currentCalculation.length === 0 ? (
            <H3 color={textColor}>
              Você ainda não possui efetuou nenhuma neutralização. Sua página de
              report será disponibilizada após a primeira compensação e
              atualizada automaticamente, conforme demais neutralizações
            </H3>
          ) : (
            <>
              <InputText
                wrap={true}
                disabled={true}
                copyIcon={true}
                label="URL Pública"
                onChange={() => null}
                value={user.username || ""}
                backgroundColor={tertiaryColor}
                setIsCopied={() => setIsCopied()}
                baseURL={REACT_APP_REPORT_BASE_URL}
              />

              {/* <OptionsList
            label="Título"
            direction="column"
            labelWeight="bold"
            options={titleOptions}
            setSelectedItem={(val) =>
              setReport((curr) => ({
                ...(curr || {}),
                title: titleOptions[val],
              }))
            }
            selectedItem={titleOptions.indexOf(report.title) || 0}
          />

          <OptionsList
            label="Descrição"
            direction="column"
            labelWeight="bold"
            options={descriptionOptions[report.classification]}
            selectedItem={
              descriptionOptions[report.classification].indexOf(
                report?.description || ""
              ) || 0
            }
            setSelectedItem={(val) =>
              setReport((curr) => ({
                ...(curr || {}),
                description: descriptionOptions[report.classification][val],
              }))
            }
          /> */}
            </>
          )}
        </S.Report>

        {/* <Button
          variant="solid"
          textColor={backgroundColor}
          onClick={() => onSaveChanges()}
          disabled={changedReport ? false : true}
          backgroundColor={changedReport ? primaryColor : `${primaryColor}40`}
        >
          Salvar alterações
        </Button> */}
      </S.Content>
    </Page>
  );
};

export default Report;
