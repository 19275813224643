import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const EventsIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.43333 11.85L1 24.3333L13.4833 19.9117M3.33333 2.16667H3.345M24.3333 8H24.345M16.1667 1H16.1783M24.3333 22H24.345M24.3333 1L21.72 1.875C20.9761 2.12279 20.3414 2.62125 19.9243 3.28518C19.5072 3.94911 19.3337 4.73729 19.4333 5.515C19.55 6.51833 18.7683 7.41667 17.7417 7.41667H17.2983C16.295 7.41667 15.4317 8.11667 15.245 9.09667L15 10.3333M24.3333 13.8333L23.3767 13.4483C22.3733 13.0517 21.2533 13.6817 21.0667 14.7433C20.9383 15.56 20.2267 16.1667 19.3983 16.1667H18.5M11.5 1L11.885 1.95667C12.2817 2.96 11.6517 4.08 10.59 4.26667C9.77333 4.38333 9.16667 5.10667 9.16667 5.935V6.83333"
        stroke={color || primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5001 13.8333C13.7518 16.085 14.8018 18.6983 13.8334 19.6667C12.8651 20.635 10.2518 19.585 8.0001 17.3333C5.74844 15.0817 4.69844 12.4683 5.66677 11.5C6.6351 10.5317 9.24843 11.5817 11.5001 13.8333Z"
        stroke={color || primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EventsIcon;
