/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import {
  states,
  statesCities,
  statesCitiesCalcs,
} from "../../constants/location";
import { Client } from "../../services";
import { IAddress, IUser } from "../../types";
import Page from "../../components/molecules/Page";
import { TStates } from "../../types/adminCalculators";
import { maskCEP, maskCPFOrCNPJ } from "../../utils/numbers";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import InputText from "../../components/molecules/InputText";
import AddImages from "../../components/organisms/AddImages";
import OptionsList from "../../components/molecules/OptionsList";
import DropdownMenu from "../../components/organisms/DropdownMenu";
import TitleDescription from "../../components/molecules/TitleDescription";

const validateProfile = (data: IUser): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.mail || data.mail.length === 0)
    errors.mail = [...(errors.mail || []), "E-mail é obrigatório"];

  if (
    data.mail &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.mail)
  )
    errors.mail = [...(errors.mail || []), "Verifique o e-mail"];

  if (!data.username || data.username.length === 0)
    errors.username = [...(errors.username || []), "Username é obrigatório"];

  if (data.cpfOrCnpj === "cpf" && !data.cpf)
    errors.cpf = [...(errors.cpf || []), "CPF obrigatório"];

  if (data.cpfOrCnpj === "cpf" && data.cpf && data.cpf.length !== 11)
    errors.cpf = [...(errors.cpf || []), "CPF incompleto"];

  if (data.cpfOrCnpj === "cpf") {
    if (!data.address?.street)
      errors.street = [...(errors.street || []), "Endereço obrigatório"];

    if (!data.address?.number)
      errors.number = [...(errors.number || []), "Número obrigatório"];

    if (!data.address?.postalCode)
      errors.postalCode = [...(errors.postalCode || []), "CEP obrigatório"];

    if (!data.address?.district)
      errors.district = [...(errors.district || []), "Bairro obrigatório"];

    if (!data.address?.city?.name)
      errors.city = [...(errors.city || []), "Cidade obrigatória"];
  }

  if (data.cpfOrCnpj === "cnpj" && !data.cnpj)
    errors.cnpj = [...(errors.cnpj || []), "CNPJ obrigatório"];

  if (data.cpfOrCnpj === "cnpj" && data.cnpj && data.cnpj.length !== 14)
    errors.cnpj = [...(errors.cnpj || []), "CNPJ incompleto"];

  return errors;
};

const Profile: React.FC = () => {
  const { user, setUserHandler, token } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { textColor, primaryColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  const triggerButton = useRef<HTMLDivElement>(null);

  const [isFixed, setIsFixed] = useState(false);
  const [search, setSearch] = useState<string>();
  const [filtered, setFiltered] = useState<string[]>();
  const [settings, setSettings] = useState<IUser>(user);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [changedSettings, setChangedSettings] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition =
        triggerButton.current?.getBoundingClientRect().bottom || 0;

      setIsFixed(scrollPosition <= triggerPosition - 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(user) !== JSON.stringify(settings)) {
      setChangedSettings(true);

      return;
    }

    setChangedSettings(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const checkUsernameAvailability = async () => {
    const isUsernameRegistered = await Client.checkFieldAvailability(
      "username",
      settings.username || "",
      user.entity
    );

    return isUsernameRegistered;
  };

  const onSaveChanges = async () => {
    try {
      showLoading();

      if (changedSettings) {
        if (user.username !== settings.username) {
          const isUsernameRegistered = await checkUsernameAvailability();

          if (isUsernameRegistered) {
            setErrors((curr) => ({
              ...curr,
              username: ["Username inválido"],
            }));

            newError(
              "Esse username já existe. Utilize-o para login ou escolha outra opção."
            );

            return;
          }
        }

        setErrors({});

        const currErrors = validateProfile(settings);

        if (currErrors && Object.keys(currErrors).length > 0) {
          setErrors(currErrors);

          return;
        }

        const payload: IUser = {
          ...settings,
          username: settings.username?.trim(),
          mail: settings.mail?.toLowerCase().trim(),
          cpf: settings.cpfOrCnpj === "cpf" ? settings.cpf : undefined,
          cnpj: settings.cpfOrCnpj === "cnpj" ? settings.cnpj : undefined,
        };

        const newSettings = await Client.updateClient(payload, token);

        setUserHandler(newSettings);
        setSettings(newSettings);
        setChangedSettings(false);
        setIsFixed(false);

        newSuccess("Dados atualizados com sucesso!");
      }
    } catch (error: any) {
      newError(error.toString());
      setSettings(user);
    } finally {
      hideLoading();
    }
  };

  const onChangeHandler = (key: keyof IUser, value: any) => {
    setSettings((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  const onChangeAddressHandler = (key: keyof IAddress, value: string) => {
    setSettings((curr) => {
      const address = { ...(curr.address || {}), [key]: value };

      return {
        ...curr,
        address: address,
      };
    });
  };

  const onChangeCityAddressHandler = (value: string) => {
    const cityCode = statesCities[settings.companyState as TStates].find(
      (item) => item.city === value
    );

    setSettings((curr) => {
      const city = {
        name: value,
        code: cityCode?.code,
        state: settings.companyState,
      };

      return {
        ...curr,
        address: { ...(curr.address || {}), city: city },
      };
    });
  };

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const cpfCnpjOpt = ["CNPJ", "CPF"];

  const cities = settings.companyState
    ? statesCities[settings.companyState as TStates].map((item) => item.city)
    : [];

  return (
    <Page pageIndex={10} mobileSection="profile">
      <S.Content>
        <TitleDescription
          title="Perfil"
          description="Ajuste suas definções de conta aqui."
        />

        <S.Profile>
          <S.TextInputs>
            <InputText
              key="company"
              labelColor={textColor}
              label="Nome da empresa"
              value={settings.company || ""}
              backgroundColor={tertiaryColor}
              onChange={(val) => onChangeHandler("company", val)}
            />

            <InputText
              key="username"
              label="Username"
              labelColor={textColor}
              backgroundColor={tertiaryColor}
              value={settings.username || ""}
              onChange={(val) => onChangeHandler("username", val)}
            />

            <S.CPFCNPJBox>
              <OptionsList
                direction="row"
                options={cpfCnpjOpt}
                labelWeight="bold"
                label="CNPJ ou CPF"
                setSelectedItem={(val) =>
                  onChangeHandler("cpfOrCnpj", cpfCnpjOpt[val].toLowerCase())
                }
                selectedItem={cpfCnpjOpt.indexOf(
                  settings.cpfOrCnpj.toUpperCase()
                )}
              />

              {settings.cpfOrCnpj === "cnpj" && (
                <InputText
                  label="CNPJ"
                  charLimit={18}
                  errors={errors.cnpj}
                  labelColor={textColor}
                  backgroundColor={tertiaryColor}
                  value={maskCPFOrCNPJ(settings.cnpj || "")}
                  onChange={(val) =>
                    onChangeHandler("cnpj", val.replace(/[^0-9]/g, ""))
                  }
                />
              )}

              {settings.cpfOrCnpj === "cpf" && (
                <InputText
                  label="CPF"
                  charLimit={14}
                  errors={errors.cpf}
                  labelColor={textColor}
                  backgroundColor={tertiaryColor}
                  value={maskCPFOrCNPJ(settings.cpf || "")}
                  onChange={(val) =>
                    onChangeHandler("cpf", val.replace(/[^0-9]/g, ""))
                  }
                />
              )}
            </S.CPFCNPJBox>

            <InputText
              key="mail"
              label="E-mail"
              errors={errors.mail}
              labelColor={textColor}
              value={settings.mail || ""}
              backgroundColor={tertiaryColor}
              onChange={(val) => onChangeHandler("mail", val)}
            />

            <S.LocationBox>
              <DropdownMenu
                options={states}
                labelWeight="bold"
                labelColor={textColor}
                borderColor={textColor}
                bgColor={tertiaryColor}
                label="Estado da empresa"
                placeholder="Selecione o estado"
                selected={settings.companyState}
                onSelect={(val) => onChangeHandler("companyState", val)}
              />

              <DropdownMenu
                infoIcon={true}
                labelWeight="bold"
                labelColor={textColor}
                borderColor={textColor}
                bgColor={tertiaryColor}
                label="Cidade da empresa"
                placeholder="Selecione a cidade"
                selected={settings.companyCity}
                onSelect={(val) => onChangeHandler("companyCity", val)}
                options={statesCitiesCalcs[settings.companyState as TStates]}
                info="Caso não encontre sua cidade, selecione aquela mais próxima"
              />
            </S.LocationBox>

            {settings.cpfOrCnpj === "cpf" && (
              <S.Address>
                <InputText
                  key="street"
                  label="Endereço"
                  labelWeight="bold"
                  labelColor={textColor}
                  errors={errors.street}
                  backgroundColor={tertiaryColor}
                  value={settings.address?.street || ""}
                  onChange={(val) => onChangeAddressHandler("street", val)}
                />

                <S.AddressBox>
                  <InputText
                    label="Número"
                    key="addressNumber"
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.number}
                    backgroundColor={tertiaryColor}
                    value={settings.address?.number || ""}
                    onChange={(val) => onChangeAddressHandler("number", val)}
                  />

                  <InputText
                    label="Complemento"
                    labelWeight="bold"
                    labelColor={textColor}
                    key="addressComplement"
                    backgroundColor={tertiaryColor}
                    value={settings.address?.additionalInformation || ""}
                    onChange={(val) =>
                      onChangeAddressHandler("additionalInformation", val)
                    }
                  />
                </S.AddressBox>

                <S.AddressBox>
                  <InputText
                    label="Bairro"
                    key="district"
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.district}
                    backgroundColor={tertiaryColor}
                    value={settings.address?.district || ""}
                    onChange={(val) => onChangeAddressHandler("district", val)}
                  />

                  <InputText
                    label="CEP"
                    key="postalCode"
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.postalCode}
                    backgroundColor={tertiaryColor}
                    value={maskCEP(settings.address?.postalCode || "")}
                    onChange={(val) =>
                      onChangeAddressHandler(
                        "postalCode",
                        val.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </S.AddressBox>

                <S.CityBox>
                  <DropdownMenu
                    label="Cidade"
                    infoIcon={true}
                    search={search}
                    options={cities}
                    useSearch={true}
                    labelWeight="bold"
                    errors={errors.city}
                    labelColor={textColor}
                    borderColor={textColor}
                    bgColor={tertiaryColor}
                    filteredOptions={filtered}
                    placeholder="Selecione a cidade"
                    onSearch={(val) => onSearch(val, cities)}
                    onClearSearch={() => setFiltered(undefined)}
                    selected={settings.address?.city?.name || ""}
                    onSelect={(val) => onChangeCityAddressHandler(val)}
                    info="Essa cidade será utilizada nas emissões da notas fiscais"
                  />
                </S.CityBox>
              </S.Address>
            )}
          </S.TextInputs>

          <S.LogoBox ref={triggerButton}>
            <AddImages
              label="Logo"
              showPreview={true}
              flexDirection="column"
              image={settings.logo || ""}
              onChange={(val) => onChangeHandler("logo", val)}
            />
          </S.LogoBox>
        </S.Profile>

        <S.SaveButton
          variant="solid"
          fontWeight="600"
          textColor={backgroundColor}
          disabled={!changedSettings}
          hasChanged={changedSettings}
          onClick={() => onSaveChanges()}
          fixed={isFixed && changedSettings}
          backgroundColor={changedSettings ? primaryColor : `${primaryColor}40`}
        >
          Salvar alterações
        </S.SaveButton>
      </S.Content>
    </Page>
  );
};

export default Profile;
