import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const ImageIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M416 80H96C69.4903 80 48 101.49 48 128V384C48 410.51 69.4903 432 96 432H416C442.51 432 464 410.51 464 384V128C464 101.49 442.51 80 416 80Z"
        stroke={color || primaryColor}
        strokeWidth="32"
        strokeLinejoin="round"
      />
      <path
        d="M336 208C353.673 208 368 193.673 368 176C368 158.327 353.673 144 336 144C318.327 144 304 158.327 304 176C304 193.673 318.327 208 336 208Z"
        stroke={color || primaryColor}
        strokeWidth="32"
        strokeMiterlimit="10"
      />
      <path
        d="M304 335.79L213.34 245.3C207.571 239.532 199.817 236.185 191.663 235.944C183.509 235.702 175.57 238.584 169.47 244L48 352M224 432L347.34 308.66C352.981 303.008 360.531 299.669 368.507 299.299C376.484 298.929 384.31 301.554 390.45 306.66L464 368"
        stroke={color || primaryColor}
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageIcon;
