import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Order } from "../../services";
import { IOrder } from "../../types/order";
import Page from "../../components/molecules/Page";
import { Auth, Snackbar, Theme } from "../../hooks";
import Skeleton from "../../components/atoms/Skeleton";
import { billingStatus, recurrence } from "../../utils/order";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import TitleDescription from "../../components/molecules/TitleDescription";

const Statements: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [statements, setStatements] = useState<IOrder[]>();
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { textColor, tertiaryColor } = Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const getStatementstData = async () => {
      try {
        const statementsData = await Order.getStatements(user.entity, token);

        setStatements(statementsData);
      } catch (error) {
        newError("Houve um erro ao obter suas compras");
      } finally {
        setLoading(false);
      }
    };

    getStatementstData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ascendingOrder) {
      setStatements((curr) => {
        if (!curr) return;

        const sortedStatements = curr.sort((a, b) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);

          return aDate.getTime() - bDate.getTime();
        });

        return sortedStatements;
      });

      return;
    }

    setStatements((curr) => {
      if (!curr) return;

      const sortedStatements = curr.sort((a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);

        return bDate.getTime() - aDate.getTime();
      });

      return sortedStatements;
    });
  }, [ascendingOrder]);

  const dateTimeWidth = "20%";
  const statusWidth = "20%";
  const typeWidth = "20%";
  const tCo2Width = "20%";
  const costWidth = "20%";

  return (
    <Page pageIndex={3} mobileSection="statement">
      <S.Content>
        <TitleDescription
          title="Extrato"
          description="Com o extrato você analisa informações de cada uma de suas compensações."
        />
      </S.Content>

      <S.Statements>
        <S.Header borderColor={tertiaryColor}>
          <S.TitleCustomDate
            fontWeight="bold"
            color={textColor}
            width={dateTimeWidth}
          >
            Data e hora
            <S.SortIcon
              isAscending={ascendingOrder}
              onClick={() => setAscendingOrder((curr) => !curr)}
            >
              <AvailableIcons option="arrow" color={textColor} />
            </S.SortIcon>
          </S.TitleCustomDate>

          <S.TitleCustom
            fontWeight="bold"
            color={textColor}
            width={statusWidth}
          >
            Status
          </S.TitleCustom>

          <S.TitleCustom fontWeight="bold" color={textColor} width={typeWidth}>
            Tipo
          </S.TitleCustom>

          <S.TitleCustom width={tCo2Width} color={textColor} fontWeight="bold">
            tCO<sub>2</sub>
          </S.TitleCustom>

          <S.TitleCustom width={costWidth} color={textColor} fontWeight="bold">
            Custo total
          </S.TitleCustom>
        </S.Header>

        {loading ? (
          <Skeleton
            direction="column"
            numberSkeletons={6}
            skeletonWidth="100%"
            skeletonHeight="40px"
          />
        ) : (
          <S.StatementList>
            {(statements || []).map((item, index) => (
              <S.Statement
                key={`${item.id}#${index}`}
                onClick={() => navigate(`/extrato/${item.id}`)}
              >
                <S.Icon>
                  <AvailableIcons option="statement" color={textColor} />
                </S.Icon>

                <S.TitleCustom width="18%" color={textColor}>
                  {new Date(item.createdAt).toLocaleString("pt-BR")}
                </S.TitleCustom>

                <S.TitleCustom width={statusWidth} color={textColor}>
                  {billingStatus(item.status)}
                </S.TitleCustom>

                <S.TitleCustom width={tCo2Width} color={textColor}>
                  {recurrence(item.recurrence)}
                </S.TitleCustom>

                <S.TitleCustom width={tCo2Width} color={textColor}>
                  {item.credits.toLocaleString("pt-BR", {
                    minimumFractionDigits: 0,
                  })}
                </S.TitleCustom>

                <S.TitleCustom width={costWidth} color={textColor}>
                  R${" "}
                  {item.totalValue.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                  })}
                </S.TitleCustom>
              </S.Statement>
            ))}
          </S.StatementList>
        )}

        {!loading && (
          <S.StatementsCards>
            {(statements || []).map((item, index) => (
              <S.StatementCard
                key={`${item.id}#${index}`}
                backgroundColor={tertiaryColor}
                onClick={() => navigate(`/extrato/${item.id}`)}
              >
                <S.Icon>
                  <AvailableIcons option="statement" color={textColor} />
                </S.Icon>

                <S.StatementCardInfo>
                  <S.StatementCardText color={textColor}>
                    <span>Data e hora: </span>

                    {new Date(item.createdAt).toLocaleString("pt-BR")}
                  </S.StatementCardText>

                  <S.StatementCardText color={textColor}>
                    <span>Status: </span>

                    {billingStatus(item.status)}
                  </S.StatementCardText>

                  <S.StatementCardText color={textColor}>
                    <span>Tipo: </span>

                    {recurrence(item.recurrence)}
                  </S.StatementCardText>

                  <S.StatementCardText color={textColor}>
                    <span>
                      tCO<sub>2</sub>:{" "}
                    </span>

                    {item.credits.toLocaleString("pt-BR", {
                      minimumFractionDigits: 0,
                    })}
                  </S.StatementCardText>

                  <S.StatementCardText color={textColor}>
                    <span>Custo total: </span>
                    R$
                    {item.totalValue.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </S.StatementCardText>
                </S.StatementCardInfo>
              </S.StatementCard>
            ))}
          </S.StatementsCards>
        )}
      </S.Statements>
    </Page>
  );
};

export default Statements;
