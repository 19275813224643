import { useState } from "react";

import * as S from "./styles";
import InputText from "../InputText";
import Button from "../../atoms/Button";
import { Client } from "../../../services";
import H1 from "../../atoms/Typography/H1";
import H4 from "../../atoms/Typography/H4";
import { Auth, Loading, Snackbar, Theme } from "../../../hooks";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const BeneficiaryModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { user, setUserHandler, token } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { textColor, backgroundColor, tertiaryColor } = Theme.useTheme();

  const [company, setCompany] = useState<string>(user.company || "");

  const onSave = async () => {
    try {
      showLoading();

      const updatedUser = await Client.updateClient(
        {
          ...user,
          company: company,
        },
        token
      );

      setUserHandler(updatedUser);
      setCompany(updatedUser.company || "");

      newSuccess("Nome do beneficiário atualizado com sucesso");
    } catch (error) {
      newError("Houve um erro ao atualizar o nome do beneficiário.");
    } finally {
      hideLoading();
      onClose();
    }
  };

  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <H1 color={textColor} fontWeight="bold">
          Alterar nome
        </H1>

        <H4 color={textColor}>
          Essa mudança alterará o nome definido no momento do cadastro e será
          aplicada a todas as compras. É possível mudar o nome, também, na aba
          Configurações
        </H4>

        <InputText
          value={company}
          backgroundColor={tertiaryColor}
          onChange={(val) => setCompany(val)}
        />

        <S.Buttons>
          <Button
            variant="outline"
            onClick={onClose}
            textColor={textColor}
            borderColor={textColor}
          >
            Cancelar
          </Button>

          <Button
            variant="solid"
            onClick={() => onSave()}
            textColor={backgroundColor}
            backgroundColor={textColor}
          >
            Salvar
          </Button>
        </S.Buttons>
      </S.Modal>
    </S.Content>
  );
};

export default BeneficiaryModal;
