import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../hooks";
import Page from "../../components/molecules/Page";
import H2 from "../../components/atoms/Typography/H2";
import H3 from "../../components/atoms/Typography/H3";
import H5 from "../../components/atoms/Typography/H5";
import { ICalculatorOptions } from "../../types/adminCalculators";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import TitleDescription from "../../components/molecules/TitleDescription";

const calculators: ICalculatorOptions[] = [
  {
    icon: "facilities",
    route: "administrativa",
    label: "Atividade administrativas",
    description:
      "Ideal para calcular emissões ligadas a Facilities, energia elétrica, delocamento dos funcionários, entre outras atividade administrativas",
  },
  {
    icon: "events",
    route: "eventos",
    label: "Eventos",
    description:
      "Feita para calcular as emissões de seu evento, considerando energia, lixo gerado, deslocamento de fornecedores e muito mais",
  },
  {
    icon: "agro",
    route: "agro",
    label: "Agropecuária",
    description:
      "Essa calculadora é perfeita para calcular emissões de propriedades rurais com lavoura ou pecuária",
  },
  {
    icon: "logistic",
    route: "logistica",
    label: "Logística",
    description:
      "Essa opção é indicada para calcular as emissões de trasportadoras",
  },
];

const Calculator: React.FC = () => {
  const navigate = useNavigate();

  const { textColor, tertiaryColor } = Theme.useTheme();

  return (
    <Page pageIndex={1} mobileSection="calculator">
      <S.Content>
        <TitleDescription
          title="Calculadora"
          description="Calcule sua pegada de carbono com nossas calculadoras, seguindos as diretrizes do Programa Brasileiro GHG Protocol (PBGHG). A Calculadora Detalhada exige maior detalhamento no preenchimento, enquanto a Calculadora Simplificada assume algumas premissas e facilita para você!"
        />
      </S.Content>

      <S.Calculators>
        <H3 color={textColor} fontWeight="bold">
          Selecione a calculadora ideal para estimar suas emissões:
        </H3>

        <S.Options>
          {calculators.map((item, index) => (
            <S.Calculator
              borderColor={tertiaryColor}
              key={`${item.route}#${index}`}
              onClick={() => navigate(`/calculadora/${item.route}`)}
            >
              <S.Details>
                <S.Icon size="48px">
                  <AvailableIcons option={item.icon} color={textColor} />
                </S.Icon>

                <S.Texts>
                  <H2 color={textColor} fontWeight="bold">
                    {item.label}
                  </H2>

                  <H5 color={textColor}>{item.description}</H5>
                </S.Texts>
              </S.Details>

              <S.Icon size="24px">
                <AvailableIcons option="chevron" color={textColor} />
              </S.Icon>
            </S.Calculator>
          ))}
        </S.Options>
      </S.Calculators>
    </Page>
  );
};

export default Calculator;
