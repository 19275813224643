import { Navigate, Route, Routes } from "react-router-dom";

import Report from "../../pages/Report";
import Profile from "../../pages/Profile";
import Projects from "../../pages/Projects";
import Dashboard from "../../pages/Dashboard";
import Statements from "../../pages/Statements";
import Calculator from "../../pages/Calculator";
import BuyCredits from "../../pages/BuyCredits";
import PaymentCards from "../../pages/PaymentCards";
import Project from "../../pages/Projects/components/Project";
import CurrentCalculation from "../../pages/CurrentCalculation";
import CertifcatesAndSeal from "../../pages/CertificatesAndSeal";
import MaterialsAndContent from "../../pages/MaterialsAndContent";
import Statement from "../../pages/Statements/components/Statement";
import Calculadora from "../../pages/Calculator/components/Calculadora";
import CalculadoraAgro from "../../pages/Calculator/components/CalculadoraAgro";
import CalculadoraEventos from "../../pages/Calculator/components/CalculadoraEventos";
import CalculadoraLogistica from "../../pages/Calculator/components/CalculadoraLogistica";

const PrivateRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/report" element={<Report />} />
        <Route path="/perfil" element={<Profile />} />
        <Route path="/projetos" element={<Projects />} />
        <Route path="/comprar" element={<BuyCredits />} />
        <Route path="/extrato" element={<Statements />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/cartoes" element={<PaymentCards />} />
        <Route path="/calculadora" element={<Calculator />} />
        <Route path="/materiais" element={<MaterialsAndContent />} />
        <Route path="/certificados" element={<CertifcatesAndSeal />} />
        <Route path="/calculo-atual" element={<CurrentCalculation />} />

        <Route path="/projetos/:id" element={<Project />} />
        <Route path="/extrato/:id" element={<Statement />} />

        <Route path="/calculadora/administrativa" element={<Calculadora />} />
        <Route path="/calculadora/eventos" element={<CalculadoraEventos />} />
        <Route path="/calculadora/agro" element={<CalculadoraAgro />} />
        <Route
          path="/calculadora/logistica"
          element={<CalculadoraLogistica />}
        />

        <Route path="*" element={<Navigate to="/projetos" />} />
      </Routes>
    </>
  );
};

export default PrivateRoutes;
