import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const Soon = styled.div`
  display: flex;
  margin-top: 100px;
  align-self: center;
  align-items: center;
  flex-direction: column;
`;

export const Icon = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
