import { ReactElement } from "react";

import { TBrand } from "../../../types";
import EloIcon from "../../icons/EloIcon";
import VisaIcon from "../../icons/VisaIcon";
import DiscoverIcon from "../../icons/DiscoverIcon";
import HipercardIcon from "../../icons/HipercardIcon";
import MastercardIcon from "../../icons/MastercardIcon";
import DinersClubIcon from "../../icons/DinersClubIcon";
import AmericanExpressIcon from "../../icons/AmericanExpressIcon";

interface IProps {
  option: TBrand;
}

const BrandIcons: React.FC<IProps> = ({ option }) => {
  const icons: { [key in TBrand]: ReactElement } = {
    elo: <EloIcon />,
    visa: <VisaIcon />,
    discover: <DiscoverIcon />,
    hipercard: <HipercardIcon />,
    dinersClub: <DinersClubIcon />,
    mastercard: <MastercardIcon />,
    americanExpress: <AmericanExpressIcon />,
  };

  return icons[option] || null;
};

export default BrandIcons;
