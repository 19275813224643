import axios from "axios";

import { TCalculators } from "../../types";
import { logoutUserIfTokenInvalid } from "../../utils";
import { ICalculatorForm } from "../../types/adminCalculators";
import { IAgroCalculatorForm } from "../../types/agroCalculator";
import { IEventCalculatorForm } from "../../types/eventCalculator";
import { ILogisticCalculatorForm } from "../../types/logisticCalculator";

const { REACT_APP_CALCULATOR_API_BASE_URL } = process.env;

const sendInformation = async (formData: ICalculatorForm, token: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_API_BASE_URL}/client/calculator`,
      {
        formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.result;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

const sendEventInformation = async (
  formData: IEventCalculatorForm,
  token: string
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_API_BASE_URL}/client/event-calculator`,
      {
        formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.result;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

const sendAgroInformation = async (
  formData: IAgroCalculatorForm,
  token: string
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_API_BASE_URL}/client/agro-calculator`,
      {
        formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.result;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

const sendLogisticInformation = async (
  formData: ILogisticCalculatorForm,
  token: string
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CALCULATOR_API_BASE_URL}/client/logistic-calculator`,
      {
        formData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.result;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao calcular suas emissões");
  }
};

const getCalculation = async (
  calculatorType: TCalculators,
  calculationId: string,
  token: string
) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CALCULATOR_API_BASE_URL}/calculation/${calculatorType}/${calculationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.calculation;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter seu cálculo");
  }
};

const getCalculationResult = async (
  calculatorType: TCalculators,
  calculationId: string,
  token: string
) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CALCULATOR_API_BASE_URL}/calculation-result/${calculatorType}/${calculationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.result;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter o resumo de seu último cálculo");
  }
};

export default {
  getCalculation,
  sendInformation,
  sendAgroInformation,
  getCalculationResult,
  sendEventInformation,
  sendLogisticInformation,
};
