import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";
import { LOCAL_STORAGE_CREDITS_KEY } from "../../../constants";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CancelBuyModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { textColor } = Theme.useTheme();

  const navigate = useNavigate();

  const onCancelBuy = () => {
    localStorage.removeItem(LOCAL_STORAGE_CREDITS_KEY);

    navigate("/calculadora");
  };

  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <H4 color={textColor}>Deseja mesmo cancelar a compra?</H4>

        <S.Buttons>
          <Button
            variant="solid"
            onClick={onClose}
            textColor="#fafafa"
            backgroundColor="#4A8415"
          >
            Continuar compra
          </Button>

          <Button
            variant="solid"
            textColor="#fafafa"
            backgroundColor="#ff0000"
            onClick={() => onCancelBuy()}
          >
            Cancelar compra
          </Button>
        </S.Buttons>
      </S.Modal>
    </S.Content>
  );
};

export default CancelBuyModal;
