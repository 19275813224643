import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../../hooks";
import Calculator from "./components/Calculator";
import Button from "../../../../components/atoms/Button";
import Page from "../../../../components/molecules/Page";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

const CalculadoraEventos: React.FC = () => {
  const { textColor, tertiaryColor } = Theme.useTheme();

  const navigate = useNavigate();

  return (
    <Page pageIndex={1} mobileSection="calculator">
      <S.Content>
        <S.Header>
          <Button
            size="small"
            variant="backButton"
            backgroundColor={tertiaryColor}
            onClick={() => navigate("/calculadora")}
          >
            <AvailableIcons option="chevron" color={textColor} />
          </Button>
        </S.Header>

        <Calculator />
      </S.Content>
    </Page>
  );
};

export default CalculadoraEventos;
