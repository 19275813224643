import * as S from "./styles";
import {
  IInputValues,
  IEventCalculatorForm,
} from "../../../../../../../../types/eventCalculator";
import {
  energySourcesObj,
  transportUnitsObj,
} from "../../../../../../../../constants/calculators";
import { Theme } from "../../../../../../../../hooks";
import H2 from "../../../../../../../../components/atoms/Typography/H2";
import { TUnits } from "../../../../../../../../types/adminCalculators";
import AddInputs from "../../../../../../../../components/organisms/AddInputs";
import OptionsList from "../../../../../../../../components/molecules/OptionsList";
import DropdownMenu from "../../../../../../../../components/organisms/DropdownMenu";
import InputSliceAndNumber from "../../../../../../../../components/organisms/InputSliceAndNumber";

interface IProps {
  question: number;
  columnOptions: boolean;
  booleanOptions: string[];
  onAheadClick: () => void;
  suppliers: IInputValues[];
  transportOptions: string[];
  createSupplier: () => void;
  onPreviousClick: () => void;
  onCalculateClick: () => void;
  formFields: IEventCalculatorForm;
  errors: { [key: string]: string[] };
  removeSuppliers: (index: number) => void;
  addSuppliers: (index: number, key: keyof IInputValues, value: string) => void;
  onChangeForm: (
    key: keyof IEventCalculatorForm,
    value: string | number | boolean
  ) => void;
}

const Questions: React.FC<IProps> = ({
  errors,
  question,
  suppliers,
  formFields,
  addSuppliers,
  onAheadClick,
  onChangeForm,
  columnOptions,
  booleanOptions,
  createSupplier,
  removeSuppliers,
  onPreviousClick,
  transportOptions,
  onCalculateClick,
}) => {
  const { textColor, primaryColor, backgroundColor } = Theme.useTheme();

  const content = () => {
    switch (question) {
      case 1:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Equipe do evento e público
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  type="number"
                  minValue={1}
                  maxValue={500}
                  value={formFields.employees}
                  onChange={(val) => onChangeForm("employees", val)}
                  label="Quantas pessoas trabalharão em seu evento?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="number"
                  minValue={1}
                  maxValue={10000}
                  value={formFields.maxCapacity}
                  label="Qual a lotação máxima do evento?"
                  onChange={(val) => onChangeForm("maxCapacity", val)}
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="number"
                  minValue={1}
                  maxValue={10000}
                  value={formFields.public}
                  onChange={(val) => onChangeForm("public", val)}
                  label="Quantas pessoas são estimadas no evento?"
                />
              </S.Question>
            </S.Questions>
          </S.BoxContent>
        );

      case 2:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Eletricidade
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  selectedItem={formFields.considerEnergy ? 0 : 1}
                  setSelectedItem={(val) =>
                    onChangeForm("considerEnergy", booleanOptions[val])
                  }
                  label="Seu evento arca com a despesa de energia elétrica?"
                />
              </S.Question>

              {formFields.considerEnergy && (
                <>
                  <S.Question>
                    <OptionsList
                      options={Object.values(energySourcesObj)}
                      direction={columnOptions ? "column" : "row"}
                      selectedItem={Object.keys(energySourcesObj).findIndex(
                        (item) => item === formFields.energySource
                      )}
                      setSelectedItem={(val) =>
                        onChangeForm("energySource", val)
                      }
                      label="Qual(s) a(s) fonte(s) de energia do evento?"
                    />
                  </S.Question>

                  {(formFields.energySource === "city-energy" ||
                    formFields.energySource === "both") && (
                    <S.Question>
                      <InputSliceAndNumber
                        option2="R$"
                        option1="KWh"
                        maxValue={20000}
                        unitSwitcher={true}
                        errors={errors.energyExpenses}
                        isOn={formFields.energyUnit === "R$"}
                        type={formFields.energyUnit || "KWh"}
                        value={formFields.energyExpenses || "0,00"}
                        setIsOn={(val) => onChangeForm("energyUnit", val)}
                        onChange={(val) => onChangeForm("energyExpenses", val)}
                        label="Qual o gasto com energia elétrica da rede da cidade?"
                      />
                    </S.Question>
                  )}

                  {(formFields.energySource === "generator" ||
                    formFields.energySource === "both") && (
                    <S.Question>
                      <InputSliceAndNumber
                        option2="R$"
                        option1="L"
                        maxValue={20000}
                        unitSwitcher={true}
                        errors={errors.generatorFuelExpenses}
                        isOn={formFields.generatorFuelUnit === "R$"}
                        value={formFields.generatorFuelExpenses || "0,00"}
                        label="Qual o gasto com combustível para o gerador?"
                        setIsOn={(val) =>
                          onChangeForm("generatorFuelUnit", val)
                        }
                        onChange={(val) =>
                          onChangeForm("generatorFuelExpenses", val)
                        }
                        type={
                          transportOptions[
                            Object.values(transportUnitsObj).indexOf(
                              formFields.generatorFuelUnit || "l"
                            )
                          ] as TUnits
                        }
                      />
                    </S.Question>
                  )}
                </>
              )}
            </S.Questions>
          </S.BoxContent>
        );

      case 3:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Gastos com deslocamento
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  maxValue={20000}
                  unitSelector={true}
                  units={transportOptions}
                  value={formFields.internalTransportExpenses || "0,00"}
                  selectUnit={(val) =>
                    onChangeForm("internalTransportUnit", val)
                  }
                  onChange={(val) =>
                    onChangeForm("internalTransportExpenses", val)
                  }
                  selectedUnit={Object.values(transportUnitsObj).indexOf(
                    formFields.internalTransportUnit || "R$"
                  )}
                  type={
                    transportOptions[
                      Object.values(transportUnitsObj).indexOf(
                        formFields.internalTransportUnit || "R$"
                      )
                    ] as TUnits
                  }
                  label="Qual o gasto com combustível para transporte de infraestrutura, materias, entre outros? - Uso interno"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  maxValue={200000}
                  unitSelector={true}
                  units={transportOptions}
                  value={formFields.employeesTransportExpenses || "0,00"}
                  selectUnit={(val) =>
                    onChangeForm("employeesTransportUnit", val)
                  }
                  onChange={(val) =>
                    onChangeForm("employeesTransportExpenses", val)
                  }
                  selectedUnit={Object.values(transportUnitsObj).indexOf(
                    formFields.employeesTransportUnit || "R$"
                  )}
                  type={
                    transportOptions[
                      Object.values(transportUnitsObj).indexOf(
                        formFields.employeesTransportUnit || "R$"
                      )
                    ] as TUnits
                  }
                  label="Qual o gasto total com combustível para deslocamento casa-trabalho dos colaboradores em veículos próprios ou alugados, táxis, transporte por aplicativo, etc? Informe dados de ida e volta."
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  maxValue={200000}
                  unitSelector={true}
                  units={transportOptions}
                  selectUnit={(val) =>
                    onChangeForm("employeesPublicTransportUnit", val)
                  }
                  onChange={(val) =>
                    onChangeForm("employeesPublicTransportExpenses", val)
                  }
                  selectedUnit={Object.values(transportUnitsObj).indexOf(
                    formFields.employeesPublicTransportUnit || "R$"
                  )}
                  value={formFields.employeesPublicTransportExpenses || "0,00"}
                  type={
                    transportOptions[
                      Object.values(transportUnitsObj).indexOf(
                        formFields.employeesPublicTransportUnit || "R$"
                      )
                    ] as TUnits
                  }
                  label="Qual o gasto total com combustível para deslocamento casa-trabalho dos colaboradores em transporte público em geral (ônibus, metrô, etc.)? Informe dados de ida e volta."
                />
              </S.Question>

              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  selectedItem={formFields.clientsEmissions ? 0 : 1}
                  setSelectedItem={(val) =>
                    onChangeForm("clientsEmissions", val)
                  }
                  label="Deseja considerar as emissões geradas pelo deslocamento dos clientes até seu evento?"
                />
              </S.Question>

              {formFields.clientsEmissions && (
                <>
                  <S.Question>
                    <InputSliceAndNumber
                      type="Km"
                      maxValue={20000}
                      value={formFields.clientsTransportExpenses || "0,00"}
                      onChange={(val) =>
                        onChangeForm("clientsTransportExpenses", val)
                      }
                      label="Quantos quilômetros, em média, seus clientes percorrerão para o evento? Informe dados de ida e volta."
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.clientsPublicTransportation}
                      value={formFields.clientsPublicTransportation || "0"}
                      onChange={(val) =>
                        onChangeForm("clientsPublicTransportation", val)
                      }
                      label="Qual o percentual do público esperado utilizará transporte público para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.clientsSelfOrAppVehicles}
                      value={formFields.clientsSelfOrAppVehicles || "0"}
                      onChange={(val) =>
                        onChangeForm("clientsSelfOrAppVehicles", val)
                      }
                      label="Qual o percentual do público esperado utilizará veículos próprios ou transporte por aplicativo para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      errors={errors.clientsWalking}
                      value={formFields.clientsWalking || "0"}
                      onChange={(val) => onChangeForm("clientsWalking", val)}
                      label="Qual o percentual do público irá à pé até o evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      value={formFields.publicShortFlights || "0"}
                      onChange={(val) =>
                        onChangeForm("publicShortFlights", val)
                      }
                      label="Qual o percentual do público esperado utilizará voos curtos (até 3 horas de voo) para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      value={formFields.publicMediumFlights || "0"}
                      onChange={(val) =>
                        onChangeForm("publicMediumFlights", val)
                      }
                      label="Qual o percentual do público esperado utilizará voos médios (até 6 horas de voo) para chegar até a região do evento?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      type="%"
                      maxValue={100}
                      value={formFields.publicLongFlights || "0"}
                      onChange={(val) => onChangeForm("publicLongFlights", val)}
                      label="Qual o percentual do público esperado utilizará voos longos (mais de 6 horas de voo) para chegar até a região do evento?"
                    />
                  </S.Question>
                </>
              )}

              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  selectedItem={formFields.suppliersEmissions ? 0 : 1}
                  setSelectedItem={(val) =>
                    onChangeForm("suppliersEmissions", val)
                  }
                  label="Deseja considerar as emissões de seus fornecedores geradas pelo deslocamento dos materiais/serviços até seu evento?"
                />
              </S.Question>

              {formFields.suppliersEmissions && (
                <S.Question>
                  <AddInputs
                    values={suppliers}
                    addValues={() => createSupplier()}
                    changeValues={(index, key, value) =>
                      addSuppliers(index, key, value)
                    }
                    buttonLabel="Adicionar Fornecedores"
                    removeValues={(index) => removeSuppliers(index)}
                  />
                </S.Question>
              )}
            </S.Questions>
          </S.BoxContent>
        );

      case 4:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Outros
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  option2="R$"
                  option1="Kg"
                  maxValue={1000}
                  unitSwitcher={true}
                  type={formFields.cookingGasUnit || "Kg"}
                  isOn={formFields.cookingGasUnit === "R$"}
                  value={formFields.cookingGasExpenses || "0,00"}
                  setIsOn={(val) => onChangeForm("cookingGasUnit", val)}
                  onChange={(val) => onChangeForm("cookingGasExpenses", val)}
                  label="Qual o gasto com gás de cozinha (GLP), para preparação de alimentos?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.organicWaste || "0,00"}
                  onChange={(val) => onChangeForm("organicWaste", val)}
                  label="Quantos Kg de lixo Orgânico foram gerados pelo evento?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.recyclableTrash || "0,00"}
                  onChange={(val) => onChangeForm("recyclableTrash", val)}
                  label="Quantos Kg de lixo Reciclável foram gerados pelo evento?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="horas"
                  maxValue={60}
                  errors={errors.eventDuration}
                  value={formFields.eventDuration || "0"}
                  onChange={(val) => onChangeForm("eventDuration", val)}
                  label="Quantas horas terá seu evento? Se seu evento tiver mais de um dia, some as horas."
                />
              </S.Question>

              <S.CompanyQuestion maxWidth="360px">
                <DropdownMenu
                  labelWeight="bold"
                  labelColor={textColor}
                  label="Segmento do evento"
                  selectorType="eventVertical"
                  errors={errors.eventVertical}
                  placeholder="Selecione o segmento"
                  selected={formFields.eventVertical}
                  onSelect={(val) => onChangeForm("eventVertical", val)}
                />
              </S.CompanyQuestion>
            </S.Questions>
          </S.BoxContent>
        );

      default:
        return;
    }
  };

  return (
    <S.Container>
      {content()}

      <S.Buttons>
        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question !== 4}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onAheadClick()}
        >
          Próximo
        </S.Clickable>

        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question === 4}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onCalculateClick()}
        >
          Calcular
        </S.Clickable>

        <S.Clickable
          variant="outline"
          fontWeight="bold"
          textColor={primaryColor}
          isVisible={question > 1}
          borderColor={primaryColor}
          backgroundColor={backgroundColor}
          onClick={() => onPreviousClick()}
        >
          Anterior
        </S.Clickable>
      </S.Buttons>
    </S.Container>
  );
};

export default Questions;
