import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Calculators } from "../../services";
import { ICalculationSummary } from "../../types";
import Button from "../../components/atoms/Button";
import Page from "../../components/molecules/Page";
import H3 from "../../components/atoms/Typography/H3";
import H2 from "../../components/atoms/Typography/H2";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import { ICalculatorForm } from "../../types/adminCalculators";
import { IAgroCalculatorForm } from "../../types/agroCalculator";
import { IEventCalculatorForm } from "../../types/eventCalculator";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import { ILogisticCalculatorForm } from "../../types/logisticCalculator";
import { getCalculator, getClassification } from "../../utils/calculators";
import TitleDescription from "../../components/molecules/TitleDescription";
import { default as Calculadora } from "../Calculator/components/Calculadora/components/Calculator";
import { default as CalculadoraAgro } from "../Calculator/components/CalculadoraAgro/components/Calculator";
import { default as CalculadoraEventos } from "../Calculator/components/CalculadoraEventos/components/Calculator";
import { default as CalculadoraLogistica } from "../Calculator/components/CalculadoraLogistica/components/Calculator";
import Skeleton from "../../components/atoms/Skeleton";

const CurrentCalculation: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [currCalculation, setCurrCalculation] = useState();
  const [editCalculation, setEditCalculation] = useState<boolean>(false);
  const [currCalculationSummary, setCurrCalculationSummary] =
    useState<ICalculationSummary>();

  const navigate = useNavigate();

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { textColor, backgroundColor, primaryColor, tertiaryColor } =
    Theme.useTheme();

  useEffect(() => {
    const calcResult = async () => {
      try {
        const result = await Calculators.getCalculationResult(
          user.currentCalculationType || "admin",
          user.currentCalculation || "",
          token
        );

        setCurrCalculationSummary(result);
      } catch (error) {
        newError("Houve um erro ao obter o resumo de seu último cálculo");
      } finally {
        setLoading(false);
      }
    };

    calcResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCalculation = async () => {
    try {
      showLoading();

      setEditCalculation(true);

      if (!user.currentCalculation || user.currentCalculation.length === 0) {
        setEditCalculation(false);
        return;
      }

      const calculationData = await Calculators.getCalculation(
        user.currentCalculationType || "admin",
        user.currentCalculation || "",
        token
      );

      setCurrCalculation(calculationData);
    } catch (error) {
      newError("Houve um erro ao obter os dados de seu cálculo");
      setEditCalculation(false);
    } finally {
      hideLoading();
    }
  };

  const calculatorType = getCalculator(user.currentCalculationType || "admin");

  const calculatorContent = (() => {
    switch (user.currentCalculationType) {
      case "event":
        return (
          <CalculadoraEventos
            filledForm={currCalculation as unknown as IEventCalculatorForm}
          />
        );

      case "agro":
        return (
          <CalculadoraAgro
            step="facilities"
            filledForm={currCalculation as unknown as IAgroCalculatorForm}
          />
        );

      case "logistic":
        return (
          <CalculadoraLogistica
            filledForm={currCalculation as unknown as ILogisticCalculatorForm}
          />
        );

      default:
        return (
          <Calculadora
            filledForm={currCalculation as unknown as ICalculatorForm}
          />
        );
    }
  })();

  return (
    <Page pageIndex={1} mobileSection="calculator">
      <S.Content>
        <TitleDescription
          title="Cálculo atual"
          description="Aqui, você encontra o formulário de cálculo que gerou sua última compra. Com isso, fica possível alterar a classificação de sua neutralização e ajustar suas despesas."
        />
      </S.Content>

      {loading && (
        <Skeleton
          direction="row"
          numberSkeletons={4}
          skeletonWidth="100%"
          skeletonHeight="100px"
        />
      )}

      {!loading &&
        (!user.currentCalculation || user.currentCalculation.length === 0) && (
          <S.NoCalculationBox>
            <S.Icon>
              <AvailableIcons option="calculator" color={primaryColor} />
            </S.Icon>

            <H3 color={textColor}>
              Você ainda não possui nenhum cálculo que tenha gerado uma
              compensação. Clique abaixo para calcular e descobrir sua pegada de
              carbono!
            </H3>

            <Button
              variant="solid"
              fontWeight="600"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => navigate("/calculadora")}
            >
              Calcular
            </Button>
          </S.NoCalculationBox>
        )}

      {!loading &&
        user.currentCalculation &&
        user.currentCalculation.length > 0 && (
          <S.CalculationBox>
            <S.SummaryBox>
              <S.Card
                borderColor={tertiaryColor}
                backgroundColor={`${tertiaryColor}40`}
              >
                <H2 color={textColor} fontWeight="600">
                  Calculadora
                </H2>

                <H3 color={textColor}>{calculatorType}</H3>
              </S.Card>

              <S.Card
                borderColor={tertiaryColor}
                backgroundColor={`${tertiaryColor}40`}
              >
                <H2 color={textColor} fontWeight="600">
                  Resultado cálculo
                </H2>

                <H3 color={textColor}>
                  {currCalculationSummary?.totalEmissions.toLocaleString(
                    "pt-BR",
                    { maximumFractionDigits: 0 }
                  )}
                </H3>
              </S.Card>

              <S.Card
                borderColor={tertiaryColor}
                backgroundColor={`${tertiaryColor}40`}
              >
                <H2 color={textColor} fontWeight="600">
                  Classificação
                </H2>

                <H3 color={textColor}>
                  {getClassification(
                    currCalculationSummary?.classification || "neutral"
                  )}
                </H3>
              </S.Card>

              <S.Card
                borderColor={tertiaryColor}
                backgroundColor={`${tertiaryColor}40`}
              >
                <H2 color={textColor} fontWeight="600">
                  Data
                </H2>

                <H3 color={textColor}>{currCalculationSummary?.createdAt}</H3>
              </S.Card>
            </S.SummaryBox>

            {editCalculation && currCalculation && (
              <S.Calculator>{calculatorContent}</S.Calculator>
            )}

            <S.Buttons>
              {!editCalculation && (
                <Button
                  variant="solid"
                  fontWeight="600"
                  textColor={backgroundColor}
                  backgroundColor={primaryColor}
                  onClick={() => getCalculation()}
                >
                  Editar cálculo
                </Button>
              )}

              {editCalculation && (
                <Button
                  fontWeight="600"
                  variant="outline"
                  textColor={primaryColor}
                  borderColor={primaryColor}
                  onClick={() => setEditCalculation(false)}
                >
                  Descartar alterações
                </Button>
              )}

              <Button
                fontWeight="600"
                variant="outline"
                textColor={primaryColor}
                borderColor={primaryColor}
                onClick={() => navigate("/calculadora")}
              >
                Novo cálculo
              </Button>
            </S.Buttons>
          </S.CalculationBox>
        )}
    </Page>
  );
};

export default CurrentCalculation;
