import styled, { css } from "styled-components";

type CardProps = {
  backgroundColor: string;
};

export const Card = styled.div<CardProps>`
  ${({ backgroundColor }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 20px;
    border-radius: 12px;
    align-items: center;
    justify-content: space-between;
    background-color: ${backgroundColor}80;
  `}
`;

export const Texts = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Details = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const Icons = styled.div`
  gap: 12px;
  display: flex;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
