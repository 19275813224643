import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const EditIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="593"
      height="593"
      viewBox="0 0 593 593"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M461.708 0.76944C454.761 0.649503 447.862 1.9279 441.42 4.52859C434.977 7.12927 429.124 10.9991 424.208 15.9079L401.131 39.7002L553.277 192.585L576.354 168.816C581.332 163.845 585.281 157.942 587.975 151.444C590.669 144.946 592.056 137.981 592.056 130.946C592.056 123.912 590.669 116.947 587.975 110.449C585.281 103.951 581.332 98.0475 576.354 93.0771L499.923 16.6233C489.766 6.51745 476.036 0.821342 461.708 0.76944ZM375.169 60.631L339.1 95.2464L497.754 253.9L534.538 220L375.169 60.631ZM316 120.493L66.5384 367.808C63.7041 369.259 61.21 371.295 59.221 373.781C57.2319 376.267 55.7932 379.147 55 382.231L1.59997 563.269C0.394275 567.18 0.277753 571.345 1.26295 575.316C2.24814 579.288 4.29774 582.916 7.19117 585.809C10.0846 588.702 13.7123 590.752 17.6839 591.737C21.6554 592.722 25.8205 592.606 29.7307 591.4L210.746 538.023C214.855 537.41 218.714 535.671 221.895 532.999C225.076 530.327 227.455 526.826 228.769 522.885L474.677 279.123L440.8 245.246L188.385 498.377L86.7077 527.916L65.0615 506.293L96.0769 400.3L346.323 150.769L316 120.493ZM370.808 176.016L117.7 429.862L154.462 437.777L159.515 471.7L413.362 218.546L370.808 176.016Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default EditIcon;
