import axios, { AxiosError } from "axios";

import { logoutUserIfTokenInvalid } from "../../utils";
import { ICreateClientResponse, IUser } from "../../types";

const { REACT_APP_CLIENT_API_URL } = process.env;

const create = async (clientData: IUser): Promise<ICreateClientResponse> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CLIENT_API_URL}/client`,
      clientData
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    switch (errorCode) {
      case "BODY001":
        throw new Error(
          "Verifique se todos os campos obrigatórios estão preenchidos"
        );
      case "BODY002":
        throw new Error(
          "O email informado já foi utilizado em outro cadastro, por favor informe um email diferente"
        );
      case "BODY003":
        throw new Error(
          "O username escolhido já está em uso, por favor tente outra opção"
        );
      case "BODY007":
        throw new Error("O username é inválido");
      default:
        throw new Error(
          "Houve um erro ao processar seu cadastro, por favor tente novamente ou entre em contato"
        );
    }
  }
};

const updateClient = async (
  clientData: IUser,
  token: string
): Promise<IUser> => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_CLIENT_API_URL}/client`,
      clientData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    switch (errorCode) {
      case "BODY001":
        throw new Error(
          "Verifique se todos os campos obrigatórios estão preenchidos"
        );
      case "BODY002":
        throw new Error(
          "O email informado já foi utilizado em outro cadastro, por favor informe um email diferente"
        );
      case "BODY003":
        throw new Error(
          "O username escolhido já está em uso, por favor tente outra opção"
        );
      case "BODY007":
        throw new Error("O username é inválido");
      default:
        throw new Error(
          "Houve um erro ao processar seu cadastro, por favor tente novamente ou entre em contato"
        );
    }
  }
};

const checkFieldAvailability = async (
  field: string,
  value: string,
  entity: string
): Promise<boolean> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CLIENT_API_URL}/check/${field}/${value}/${entity}`
    );

    return data.isRegistered;
  } catch (error) {
    throw new Error(`Error on when checking if ${field} is available`);
  }
};

const removeClient = async (token: string): Promise<string> => {
  try {
    const { data } = await axios.delete(`${REACT_APP_CLIENT_API_URL}/client`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.id;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Error on removeClient");
  }
};

const sendEmailToResetPassword = async (user: string, entity: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CLIENT_API_URL}/reset-password`,
      { user, entity }
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    throw new Error(errorCode || "Error on reset password");
  }
};

const resetPassword = async (token: string, password: string) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_CLIENT_API_URL}/reset-password/${token}`,
      { password }
    );

    return data;
  } catch (error) {
    const errorCode = error instanceof AxiosError && error.response?.data?.code;

    throw new Error(errorCode || "Error on reset password");
  }
};

export default {
  create,
  updateClient,
  removeClient,
  resetPassword,
  checkFieldAvailability,
  sendEmailToResetPassword,
};
