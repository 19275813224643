import * as XLSX from "xlsx";
import * as Papa from "papaparse";
import React, { useRef } from "react";

import * as S from "./styles";
import {
  ICsv,
  normalizeCsvData,
  normalizeExcelData,
} from "../../../utils/sheets";
import P from "../../atoms/Typography/P";
import H4 from "../../atoms/Typography/H4";
import { Snackbar, Theme } from "../../../hooks";
import AvailableIcons from "../../atoms/AvailableIcons";
import { ILogisticRoutes } from "../../../types/logisticCalculator";

interface IProps {
  label?: string;
  errors?: string[];
  documentName?: string;
  onChange: (data: ILogisticRoutes[], docName: string) => void;
}

const AddDocument: React.FC<IProps> = ({
  label,
  errors,
  onChange,
  documentName,
}) => {
  const { newError } = Snackbar.useSnackbar();
  const { textColor, tertiaryColor } = Theme.useTheme();

  const input = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result as string;

        if (file.name.endsWith(".csv")) {
          Papa.parse(result, {
            complete: (parsedData) => {
              const normData = normalizeCsvData(parsedData.data as ICsv[]);
              onChange(normData, file.name);
            },
            header: true,
          });
        } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
          const workbook = XLSX.read(result, { type: "binary" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const normData = normalizeExcelData(excelData as string[][]);

          if (!normData) {
            newError("Arquivo não está no formato esperado");

            return;
          }

          onChange(normData, file.name);
        } else {
          newError("Formato de arquivo não suportado. Use CSV ou Excel.");
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  return (
    <S.Container>
      {label && (
        <H4 color={textColor} fontWeight="bold">
          {label}
        </H4>
      )}

      <S.DocumentBox>
        <S.ButtonUploadImage
          borderColor={textColor}
          backgroundColor={tertiaryColor}
        >
          <S.PlusIcon borderColor={textColor}>
            <AvailableIcons option="plus" />
          </S.PlusIcon>
          Adicionar Documento (.csv ou .xlsx)
          <input
            type="file"
            ref={input}
            accept=".csv, .xls, .xlsx"
            onChange={handleFileChange}
          />
        </S.ButtonUploadImage>

        {documentName && (
          <S.UploadedDocument>
            <S.Icon>
              <AvailableIcons option="document" />
            </S.Icon>

            <P color={textColor}>{documentName}</P>
          </S.UploadedDocument>
        )}
      </S.DocumentBox>

      {errors && errors.length ? (
        <S.Error color="#FF4D4F">Erro: {errors.join(", ")}</S.Error>
      ) : null}
    </S.Container>
  );
};

export default AddDocument;
