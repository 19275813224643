import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  button {
    max-width: 200px;
  }
`;

export const Report = styled.div`
  gap: 32px;
  display: flex;
  margin: 40px 0;
  flex-direction: column;
`;

export const URL = styled.div`
  gap: 16px;
  display: flex;
  align-items: end;
`;

export const CopyIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
