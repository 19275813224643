import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const AnalyticsIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8601 3.65835V16.1584C15.8601 17.55 17.0001 18.3334 18.2501 18.3334C19.3901 18.3334 20.6401 17.675 20.6401 16.1584V3.75002C20.6401 2.46669 19.5001 1.66669 18.2501 1.66669C17.0001 1.66669 15.8601 2.55002 15.8601 3.65835ZM9.61011 10V16.1584C9.61011 17.5584 10.7701 18.3334 12.0001 18.3334C13.1401 18.3334 14.3901 17.675 14.3901 16.1584V10.0917C14.3901 8.80835 13.2501 8.00835 12.0001 8.00835C10.7501 8.00835 9.61011 8.89169 9.61011 10ZM5.75011 14.3584C7.07011 14.3584 8.14011 15.25 8.14011 16.3417C8.14011 16.8699 7.88831 17.3765 7.44009 17.75C6.99188 18.1235 6.38398 18.3334 5.75011 18.3334C5.11624 18.3334 4.50833 18.1235 4.06012 17.75C3.61191 17.3765 3.36011 16.8699 3.36011 16.3417C3.36011 15.25 4.43011 14.3584 5.75011 14.3584Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default AnalyticsIcon;
