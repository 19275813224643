import styled, { css } from "styled-components";

import Button from "../../components/atoms/Button";

export const Content = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: fit-content;
  transform: rotate(180deg);
  justify-content: flex-start;
`;

export const CertificateAndSeal = styled.div`
  gap: 20px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

type FolderProps = {
  backgroundColor: string;
};

export const Folder = styled(Button)<FolderProps>`
  ${({ backgroundColor }) => css`
    width: 260px;
    display: flex;
    cursor: pointer;
    padding: 16px 24px;
    justify-content: flex-start;
    background-color: ${backgroundColor}80;

    :hover {
      background-color: ${backgroundColor};
    }
  `}
`;

export const Icon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
