import axios from "axios";

import { IManagerPref } from "../../types/manager";

const { REACT_APP_CLIENT_MANAGER_API_URL } = process.env;

const getManagerPreferences = async (entity: string): Promise<IManagerPref> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CLIENT_MANAGER_API_URL}/client-pref/${entity}`
    );

    return data.preferences;
  } catch (error) {
    throw new Error("Houve um erro ao obter as preferência da plataforma");
  }
};

export default {
  getManagerPreferences,
};
