import * as S from "./styles";
import { Theme } from "../../../hooks";
import Slicer from "../../atoms/Slicer";
import H4 from "../../atoms/Typography/H4";
import SwitchUnit from "../../molecules/SwitchUnit";
import AvailableIcons from "../../atoms/AvailableIcons";
import UnitSelector from "../../molecules/UnitsSelector";
import { TUnits } from "../../../types/adminCalculators";
import InputNumberBase from "../../atoms/InputNumberBase";

type InputSliceAndNumberProps = {
  id?: string;
  gap?: string;
  type: TUnits;
  label: string;
  info?: string;
  value: string;
  isOn?: boolean;
  width?: string;
  slicer?: boolean;
  units?: string[];
  option1?: string;
  option2?: string;
  maxValue: number;
  errors?: string[];
  minValue?: number;
  infoIcon?: boolean;
  labelColor?: string;
  selectedUnit?: number;
  unitSwitcher?: boolean;
  unitSelector?: boolean;
  setIsOn?: (isOn: boolean) => void;
  onChange: (value: string) => void;
  selectUnit?: (index: number) => void;
};

const InputSliceAndNumber: React.FC<InputSliceAndNumberProps> = ({
  id,
  isOn,
  info,
  type,
  label,
  value,
  errors,
  option1,
  option2,
  setIsOn,
  infoIcon,
  maxValue,
  minValue,
  onChange,
  labelColor,
  selectUnit,
  gap = "24px",
  unitSelector,
  slicer = true,
  units = ["R$"],
  width = "140px",
  selectedUnit = 0,
  unitSwitcher = false,
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.Container gap={gap}>
      <S.LabelBox>
        <H4 color={labelColor || textColor}>{label}</H4>

        {infoIcon && (
          <S.InfoIcon>
            <AvailableIcons option="infoOne" color={textColor} />

            <S.InfoBox>{info}</S.InfoBox>
          </S.InfoIcon>
        )}
      </S.LabelBox>

      <S.Inputs>
        {slicer && (
          <Slicer
            type={type}
            value={value}
            minValue={minValue}
            maxValue={maxValue}
            onChange={(val) => onChange(val)}
          />
        )}

        <S.InputValueAndUnit>
          <InputNumberBase
            id={id}
            type={type}
            width={width}
            value={value}
            border={true}
            onChange={onChange}
          />

          {unitSwitcher && (
            <SwitchUnit
              setIsOn={setIsOn}
              color={textColor}
              isOn={isOn || false}
              option1={option1 || ""}
              option2={option2 || ""}
            />
          )}

          {unitSelector && (
            <UnitSelector
              units={units}
              selected={selectedUnit}
              onSelect={(val) => selectUnit && selectUnit(val)}
            />
          )}

          {errors && errors.length && errors[0] !== "" ? (
            <S.ErrorMessage width={width} color="#FF4D4F">
              Erros: {errors.join(", ")}
            </S.ErrorMessage>
          ) : null}
        </S.InputValueAndUnit>
      </S.Inputs>
    </S.Container>
  );
};

export default InputSliceAndNumber;
