import { TMonths } from "../types/dates";

export const normalizeDateToLocale = (date: string) => {
  const parsedDate = new Date(date).toLocaleString("pt-BR");

  const [normalizedDate, time] = parsedDate.split(" ");

  const normalizedTime = time.substring(0, 5);

  return `${normalizedDate} às ${normalizedTime}`;
};

export const maskHourAndMinute = (value: string) => {
  if (!value) return "";

  const onlyNumbers = value.replace(/[^0-9]/g, "");

  if (onlyNumbers.length <= 2) return onlyNumbers;

  const firstPart = onlyNumbers.substring(0, 2);
  const secondPart = onlyNumbers.substring(2);

  return `${firstPart}:${secondPart}`;
};

export const maskDate = (value: string) => {
  if (!value) return "";

  const normValue = value.replace(/[^0-9]/g, "");

  if (normValue.length > 8) {
    const day = normValue.substring(0, 2);
    const month = normValue.substring(2, 4);
    const year = normValue.substring(4, 8);

    return `${day}/${month}/${year}`;
  }

  const day = normValue.substring(0, 2);
  const month = normValue.substring(2, 4);
  const year = normValue.substring(4);

  if (!month) return `${day}`;

  if (!year) return `${day}/${month}`;

  return `${day}/${month}/${year}`;
};

export const getMonthLabel = (month: TMonths): string => {
  switch (month) {
    case 0:
      return "Janeiro";
    case 1:
      return "Fevereiro";
    case 2:
      return "Março";
    case 3:
      return "Abril";
    case 4:
      return "Maio";
    case 5:
      return "Junho";
    case 6:
      return "Julho";
    case 7:
      return "Agosto";
    case 8:
      return "Setembro";
    case 9:
      return "Outubro";
    case 10:
      return "Novembro";
    case 11:
      return "Dezembro";
  }
};
