import * as S from "./styles";
import { Theme } from "../../../hooks";
import H3 from "../../atoms/Typography/H3";
import AgroIcon from "../../icons/AgroIcon";
import FacilitiesIcon from "../../icons/FacilitiesIcon";

interface IProps {
  formStep: "crops" | "facilities";
}

const AgroCalculatorStep: React.FC<IProps> = ({ formStep }) => {
  const { primaryColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.IconAndLabel>
        <S.Icon>
          <AgroIcon color={primaryColor} />
        </S.Icon>

        {formStep === "crops" && (
          <H3 color={primaryColor} fontWeight="bold">
            Culturas
          </H3>
        )}
      </S.IconAndLabel>

      <S.Separators>
        {[1, 2, 3, 4, 5].map((item) => (
          <S.Separator
            key={item}
            color={formStep === "facilities" ? primaryColor : tertiaryColor}
          />
        ))}
      </S.Separators>

      <S.IconAndLabel>
        <S.Icon>
          <FacilitiesIcon
            color={formStep === "facilities" ? primaryColor : tertiaryColor}
          />
        </S.Icon>

        {formStep === "facilities" && (
          <H3 color={primaryColor} fontWeight="bold">
            Propriedade
          </H3>
        )}
      </S.IconAndLabel>
    </S.Container>
  );
};

export default AgroCalculatorStep;
