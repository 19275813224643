export const materialsAndContents = [
  {
    type: "Template",
    classification: "Divulgação",
    label: "Template para divulgação interna",
    link: "",
  },
  {
    type: "Template",
    classification: "Divulgação",
    label: "Template para redes socias",
    link: "",
  },
  {
    type: "Apresentação",
    classification: "Treinamento",
    label: "Créditos de carbono: tudo o que você precisa saber",
    link: "",
  },
  {
    type: "Documento",
    classification: "Leitura",
    label: "Perguntas frequentes - FAQ",
    link: "",
  },
  {
    type: "Documento",
    classification: "Leitura",
    label: "Glossário",
    link: "",
  },
  {
    type: "Documento",
    classification: "Leitura",
    label: "Manual de marca",
    link: "",
  },
  {
    type: "Documento",
    classification: "Leitura",
    label: "Projetos geradores de crédito de carbono",
    link: "",
  },
];
