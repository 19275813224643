import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const GlobeIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9167 14.4917C14.7001 13.825 14.0751 13.3334 13.3334 13.3334H12.5001V10.8334C12.5001 10.6123 12.4123 10.4004 12.256 10.2441C12.0997 10.0878 11.8878 10 11.6667 10H6.66675V8.33335H8.33341C8.55443 8.33335 8.76639 8.24556 8.92267 8.08928C9.07895 7.933 9.16675 7.72103 9.16675 7.50002V5.83335H10.8334C11.2754 5.83335 11.6994 5.65776 12.0119 5.3452C12.3245 5.03264 12.5001 4.60871 12.5001 4.16669V3.82502C13.4971 4.22699 14.382 4.86458 15.0788 5.68322C15.7756 6.50185 16.2637 7.47714 16.5012 8.52561C16.7387 9.57408 16.7187 10.6645 16.4427 11.7035C16.1668 12.7425 15.6432 13.6992 14.9167 14.4917ZM9.16675 16.6084C5.87508 16.2 3.33341 13.4 3.33341 10C3.33341 9.48335 3.40008 8.98335 3.50841 8.50835L7.50008 12.5V13.3334C7.50008 13.7754 7.67568 14.1993 7.98824 14.5119C8.3008 14.8244 8.72472 15 9.16675 15M10.0001 1.66669C8.90573 1.66669 7.8221 1.88224 6.81105 2.30102C5.80001 2.71981 4.88135 3.33364 4.10752 4.10746C2.54472 5.67027 1.66675 7.78988 1.66675 10C1.66675 12.2102 2.54472 14.3298 4.10752 15.8926C4.88135 16.6664 5.80001 17.2802 6.81105 17.699C7.8221 18.1178 8.90573 18.3334 10.0001 18.3334C12.2102 18.3334 14.3298 17.4554 15.8926 15.8926C17.4554 14.3298 18.3334 12.2102 18.3334 10C18.3334 8.90567 18.1179 7.82204 17.6991 6.81099C17.2803 5.79994 16.6665 4.88129 15.8926 4.10746C15.1188 3.33364 14.2002 2.71981 13.1891 2.30102C12.1781 1.88224 11.0944 1.66669 10.0001 1.66669Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default GlobeIcon;
