import styled, { css } from "styled-components";

import H4 from "../../../../components/atoms/Typography/H4";

export const BillingInfo = styled.div`
  top: 20vh;
  width: 100%;
  display: flex;
  padding: 8px 16px;
  border-radius: 12px;
  flex-direction: column;

  @media (max-width: 820px) {
    padding: 8px 0;
  }
`;

export const Content = styled.div`
  gap: 32px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

export const Summary = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const IconAndDetail = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

type IconAndTextProps = {
  backgroundColor: string;
};

export const IconAndText = styled.div<IconAndTextProps>`
  ${({ backgroundColor }) => css`
    gap: 8px;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-color: ${backgroundColor};
  `}
`;

export const Icon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const QRCode = styled.div`
  display: flex;
  align-items: center;
`;

type InvoiceProps = {
  textColor: string;
  backgroundColor: string;
};

export const Invoice = styled.a<InvoiceProps>`
  ${({ textColor, backgroundColor }) => css`
    width: 300px;
    display: flex;
    margin: 20px 0;
    font-size: 1.4rem;
    padding: 12px 36px;
    text-align: center;
    border-radius: 20px;
    align-items: center;
    color: ${textColor};
    text-decoration: none;
    justify-content: center;
    background-color: ${backgroundColor};
  `}
`;

export const CopyCode = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
`;

export const Description = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Statement = styled(H4)`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const Card = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
`;

export const CancelSignatureMessage = styled.div`
  display: flex;
  margin-top: 100px;
  align-items: center;
`;
