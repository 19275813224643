import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const AgroIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4271 23.6935L13.4687 29M11.4271 23.6935L7.17361 22.7226M11.4271 23.6935L14.0278 20.4194M2.79861 1.38387L3.625 3.50645M5.10764 17.3935L9.36111 18.3871L11.9861 15.1129M3.06597 12.0871L7.31944 13.0581L9.92014 9.80645M1 6.78065L5.25347 7.75161L7.87847 4.47742M21.1007 22.3387L19.7396 27.4194M21.1007 22.3387L18.3542 19.5387M21.1007 22.3387L24.9167 21.0742M26.7882 1L26.2535 3.03226M19.7153 14.4581L22.4375 17.2581L26.2778 15.971M21.0764 9.37742L23.7986 12.1774L27.6389 10.8903M22.4375 4.29677L25.1597 7.09677L29 5.80968"
        stroke={color || primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AgroIcon;
