import styled, { css } from "styled-components";

type SkeletonProps = {
  direction: string;
};

export const Skeleton = styled.div<SkeletonProps>`
  ${({ direction }) => css`
    gap: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    flex-direction: ${direction};
  `}
`;

type SkeletonBoxProps = {
  index: number;
  width: string;
  height: string;
};

export const SkeletonBox = styled.div<SkeletonBoxProps>`
  ${({ index, height, width }) => css`
    opacity: 0.4;
    display: flex;
    width: ${width};
    height: ${height};
    border-radius: 18px;
    align-items: center;
    justify-content: space-between;
    background-blend-mode: multiply;
    background: linear-gradient(
      275deg,
      rgba(250, 250, 250, 0.39) -160%,
      rgba(113, 114, 111, 0.6) 120%
    );
    animation: opacity 1s infinite;
    animation-delay: ${index * 0.2}s;

    @keyframes opacity {
      0% {
        opacity: 0.4;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.4;
      }
    }
  `}
`;
