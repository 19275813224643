import styled, { css } from "styled-components";

type ColorProps = {
  color: string;
};

export const Information = styled.div<ColorProps>`
  ${({ color }) => css`
    width: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    padding: 20px 100px 60px;
    background-color: ${color};

    @media (max-width: 770px) {
      padding: 20px 80px 60px;
    }

    @media (max-width: 770px) {
      padding: 20px 40px 60px;
    }
  `}
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type LogoProps = {
  logo: string;
};

export const Logo = styled.div<LogoProps>`
  ${({ logo }) => css`
    width: 160px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${logo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 770px) {
      width: 128px;
      height: 64px;
    }
  `}
`;

export const CreateAccount = styled.div`
  width: 140px;
  height: 52px;
  display: flex;

  @media (max-width: 770px) {
    width: 112px;
    height: 40px;

    button {
      font-size: 1rem;
    }
  }
`;

export const Content = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 60px;
  max-width: 700px;
  margin-top: 60px;
  align-self: center;
  border-radius: 20px;
  flex-direction: column;
  background-color: #fafafa;

  @media (max-width: 770px) {
    margin-top: 40px;
    padding: 40px 32px;
  }
`;

export const LoginForm = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;

  .forgotPassword {
    padding: 0 16px;
    margin-top: 8px;
    cursor: pointer;
    user-select: none;
    text-align: center;

    small {
      color: #fafafa80;
      font-weight: 400;
    }
  }
`;

type ForgotPasswordProps = {
  underlineColor: string;
};

export const ForgotPassword = styled.div<ForgotPasswordProps>`
  ${({ underlineColor }) => css`
    width: 100%;
    display: flex;
    cursor: pointer;
    user-select: none;
    padding-left: 12px;

    :hover {
      text-decoration: underline;
      text-decoration-color: ${underlineColor};
    }
  `}
`;
