import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const PersonIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10.7855C12.7625 10.7855 15 8.56922 15 5.83293C15 3.09664 12.7625 0.880371 10 0.880371C7.2375 0.880371 5 3.09664 5 5.83293C5 8.56922 7.2375 10.7855 10 10.7855ZM10 13.2618C6.6625 13.2618 0 14.9209 0 18.2143V19.4525C0 20.1335 0.5625 20.6906 1.25 20.6906H18.75C19.4375 20.6906 20 20.1335 20 19.4525V18.2143C20 14.9209 13.3375 13.2618 10 13.2618Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default PersonIcon;
