import styled, { css } from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: start;
  position: relative;
  flex-direction: column;
`;

export const Calculators = styled.div`
  display: flex;
  margin-top: 60px;
  position: relative;
  flex-direction: column;
`;

export const Options = styled.div`
  display: flex;
  margin-top: 12px;
  position: relative;
  flex-direction: column;
`;

type BorderProps = {
  borderColor: string;
};

export const Calculator = styled.div<BorderProps>`
  ${({ borderColor }) => css`
    gap: 60px;
    width: 100%;
    display: flex;
    cursor: pointer;
    text-align: left;
    position: relative;
    padding: 32px 12px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${borderColor};

    @media (max-width: 500px) {
      gap: 32px;
    }
  `}
`;

export const Details = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1240px) {
    gap: 28px;
  }

  @media (max-width: 500px) {
    transform: 16px;
  }
`;

type IconProps = {
  size: string;
};

export const Icon = styled.div<IconProps>`
  ${({ size }) => css`
    right: 0;
    display: flex;
    flex-shrink: 0;
    width: ${size};
    height: ${size};
    position: relative;
    align-items: start;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 500px) {
      transform: scale(0.8);
    }
  `}
`;

export const Texts = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;

  h2 {
    width: 25%;
    flex-shrink: 0;
    text-align: left;
  }

  h5 {
    text-align: justify;
  }

  @media (max-width: 1400px) {
    gap: 16px;
    flex-direction: column;

    h2 {
      width: 100%;
    }
  }
`;
