import * as S from "./styles";
import { Theme } from "../../../hooks";
import H4 from "../../atoms/Typography/H4";
import H5 from "../../atoms/Typography/H5";
import AvailableIcons from "../../atoms/AvailableIcons";
import { cropsObj } from "../../../constants/calculators";
import { IHerd, TCrop } from "../../../types/agroCalculator";

interface IProps {
  crop: string;
  herds?: IHerd[];
  onEdit: () => void;
  harvestDate: string;
  plantingDate: string;
  onRemove: () => void;
}

const CropCard: React.FC<IProps> = ({
  crop,
  herds,
  onEdit,
  onRemove,
  harvestDate,
  plantingDate,
}) => {
  const { tertiaryColor, textColor } = Theme.useTheme();

  const animals =
    crop === "livestock" &&
    herds?.reduce((acc, curr) => {
      if (acc === "") {
        acc += curr.type;

        return acc;
      }

      acc += `, ${curr.type}`;

      return acc;
    }, "");

  return (
    <S.Card backgroundColor={tertiaryColor}>
      <S.Texts>
        <H4 color={textColor} fontWeight="600">
          {cropsObj[crop as TCrop].toUpperCase()}
        </H4>

        {crop === "livestock" ? (
          <S.Details>
            <H5 color={textColor}>Número de rebanhos: {herds?.length}</H5>

            <H5 color={textColor}>Animais: {animals}</H5>
          </S.Details>
        ) : (
          <S.Details>
            <H5 color={textColor}>Data de plantio: {plantingDate}</H5>

            <H5 color={textColor}>Data de colheita: {harvestDate}</H5>
          </S.Details>
        )}
      </S.Texts>

      <S.Icons>
        <S.Icon onClick={onEdit}>
          <AvailableIcons option="edit" color={textColor} />
        </S.Icon>

        <S.Icon onClick={onRemove}>
          <AvailableIcons option="close" color="#ff0000" />
        </S.Icon>
      </S.Icons>
    </S.Card>
  );
};

export default CropCard;
