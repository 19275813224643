export const seals: { label: string; link: string; type: string }[] = [
  {
    label: "Manual do selo",
    link: "manual-cabono.pdf",
    type: "PDF",
  },
  { label: "Selo circular padrão", link: "selo-circular.png", type: "PNG" },
  {
    label: "Selo circular c/ label Ibioma",
    link: "selo-circular-nome.png",
    type: "PNG",
  },
  { label: "Selo horizontal", link: "selo-horizontal.png", type: "PNG" },
  {
    label: "Selo circular negativo branco",
    link: "selo-circular-negativo-branco.png",
    type: "PNG",
  },
  {
    label: "Selo circular negativo branco c/ label Ibioma",
    link: "selo-circular-nome-negativo-branco.png",
    type: "PNG",
  },
  {
    label: "Selo horizontal negativo branco",
    link: "selo-horizontal-negativo-branco.png",
    type: "PNG",
  },
  {
    label: "Selo circular padrão negativo preto",
    link: "selo-circular-negativo-preto.png",
    type: "PNG",
  },
  {
    label: "Selo circular negativo preto c/ label Ibioma",
    link: "selo-circular-nome-negativo-preto.png",
    type: "PNG",
  },
  {
    label: "Selo horizontal negativo preto",
    link: "selo-horizontal-negativo-preto.png",
    type: "PNG",
  },
];
