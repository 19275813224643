import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const SealIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="418"
      height="376"
      viewBox="0 0 418 376"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M229.833 375.5L271.5 354.667L313.166 375.5V229.667H229.833M313.166 125.5V83.8333L271.5 104.667L229.833 83.8333V125.5L188.166 146.333L229.833 167.167V208.833L271.5 188L313.166 208.833V167.167L354.833 146.333M375.666 0.5H42.3332C31.2825 0.5 20.6844 4.88987 12.8704 12.7039C5.05637 20.5179 0.666504 31.116 0.666504 42.1667V250.5C0.666504 261.551 5.05637 272.149 12.8704 279.963C20.6844 287.777 31.2825 292.167 42.3332 292.167H188.166V250.5H42.3332V42.1667H375.666V250.5H354.833V292.167H375.666C386.717 292.167 397.315 287.777 405.129 279.963C412.943 272.149 417.333 261.551 417.333 250.5V42.1667C417.333 31.116 412.943 20.5179 405.129 12.7039C397.315 4.88987 386.717 0.5 375.666 0.5ZM188.166 104.667H63.1665V63H188.166M146.5 167.167H63.1665V125.5H146.5M188.166 229.667H63.1665V188H188.166V229.667Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default SealIcon;
