/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import * as S from "./styles";
import Seal from "./components/Seal";
import { Certificate } from "../../services";
import { getMonthLabel } from "../../utils/dates";
import Page from "../../components/molecules/Page";
import Button from "../../components/atoms/Button";
import { Auth, Snackbar, Theme } from "../../hooks";
import { TClassification } from "../../types/report";
import Certificates from "./components/Certificates";
import H5 from "../../components/atoms/Typography/H5";
import Skeleton from "../../components/atoms/Skeleton";
import { ICertificatesContent } from "../../types/certificates";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import TitleDescription from "../../components/molecules/TitleDescription";

const CertificatesAndSeal: React.FC = () => {
  const [year, setYear] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [yearFolders, setYearFolders] = useState<number[]>();
  const [type, setType] = useState<"certificate" | "seal">();
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);
  const [verraCertificates, setVerraCertificates] = useState<number[]>();
  const [classification, setClassification] = useState<TClassification>();
  const [certificates, setCertificates] = useState<ICertificatesContent>();

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { textColor, tertiaryColor } = Theme.useTheme();

  useEffect(() => {
    const run = async () => {
      try {
        if (user.asaasId) {
          const certificateData = await Certificate.getCertificates(
            user.entity,
            token
          );

          const certificatesValues = certificateData.certificatesData.reduce(
            (acc, curr) => {
              const currYear = curr.year;
              const currMonthIndex = curr.month;
              const currMonth = getMonthLabel(curr.month);

              if (acc.folders.includes(currYear)) {
                if (curr.certificateLinks.length > 1) {
                  const newData = curr.certificateLinks.map((item) => ({
                    url: item.url,
                    month: currMonth,
                    label: item.label,
                    monthIndex: currMonthIndex,
                  }));

                  acc.content = {
                    ...acc.content,
                    [currYear]: [...acc.content[currYear], ...newData],
                  };

                  return acc;
                }

                acc.content = {
                  ...acc.content,
                  [currYear]: [
                    ...acc.content[currYear],
                    {
                      month: currMonth,
                      monthIndex: currMonthIndex,
                      url: curr.certificateLinks[0].url,
                      label: curr.certificateLinks[0].label,
                    },
                  ],
                };

                return acc;
              }

              acc.folders = [...acc.folders, currYear];

              if (curr.certificateLinks.length > 1) {
                const newData = curr.certificateLinks.map((item) => ({
                  url: item.url,
                  month: currMonth,
                  label: item.label,
                  monthIndex: currMonthIndex,
                }));

                acc.content = {
                  ...acc.content,
                  [currYear]: [...newData],
                };

                return acc;
              }

              acc.content = {
                ...acc.content,
                [currYear]: [
                  {
                    month: currMonth,
                    monthIndex: currMonthIndex,
                    url: curr.certificateLinks[0].url,
                    label: curr.certificateLinks[0].label,
                  },
                ],
              };

              return acc;
            },
            {
              folders: [] as number[],
              content: {} as ICertificatesContent,
            }
          );

          setClassification(certificateData.classification);
          setYearFolders(certificatesValues.folders);
          setCertificates(certificatesValues.content);
          setVerraCertificates(certificateData.verraCertificates);
        }
      } catch (error) {
        newError("Houve um erro ao importar os certificados");
      } finally {
        setLoading(false);
      }
    };

    run();
  }, []);

  useEffect(() => {
    if (!year || !certificates) return;

    if (ascendingOrder) {
      setCertificates((curr) => {
        if (!curr) return;

        const sortedCertificates = curr[year].sort(
          (a, b) => a.monthIndex - b.monthIndex
        );

        return {
          ...certificates,
          [year]: sortedCertificates,
        };
      });

      return;
    }

    setCertificates((curr) => {
      if (!curr) return;

      const sortedCertificates = curr[year].sort(
        (a, b) => b.monthIndex - a.monthIndex
      );

      return {
        ...certificates,
        [year]: sortedCertificates,
      };
    });
  }, [ascendingOrder]);

  const typeContent = (() => {
    switch (type) {
      case "seal":
        return <Seal classification={classification || "neutral"} />;

      case "certificate":
        return (
          <Certificates
            year={year}
            folders={yearFolders}
            certificates={certificates}
            ascendingOrder={ascendingOrder}
            setYear={(val) => setYear(val)}
            verraCertificates={year && verraCertificates && year}
            setAscendingOrder={() => setAscendingOrder((curr) => !curr)}
          />
        );

      default:
        return <></>;
    }
  })();

  const pageTexts: { title: string; description: string } = (() => {
    if (!type)
      return {
        title: "Certificados e selos",
        description:
          "Nesta seção, disponibilizamos todos os certificados que emitimos para afirmar o impacto gerado por suas ações. Além disso, temos os selos disponíveis para seu perfil (friendly, neutro ou negativo)",
      };

    if (type === "seal")
      return {
        title: "Selos",
        description:
          "Veja os selos disponíveis e o manual que auxilia na aplicação desses selos.",
      };

    return {
      title: "Certificados",
      description:
        "Veja os certificados gerados após as suas iniciativas de compensação do carbono emitido.",
    };
  })();

  const onBackHandler = () => {
    if (type && type === "certificate") {
      if (year) {
        setYear(undefined);

        return;
      }

      setType(undefined);
    }

    setType(undefined);
  };

  return (
    <Page pageIndex={8} mobileSection="content">
      <S.Content>
        {type && (
          <S.Header>
            <Button
              size="small"
              variant="backButton"
              backgroundColor={tertiaryColor}
              onClick={() => onBackHandler()}
            >
              <AvailableIcons option="chevron" color={textColor} />
            </Button>
          </S.Header>
        )}

        <TitleDescription
          title={pageTexts.title}
          description={pageTexts.description}
        />
      </S.Content>

      {loading && (
        <Skeleton
          direction="column"
          numberSkeletons={2}
          skeletonWidth="300px"
          skeletonHeight="60px"
        />
      )}

      {!type && !loading ? (
        <S.CertificateAndSeal>
          {!user.asaasId && !certificates ? (
            <H5 color={textColor}>
              Você ainda não possui certificados ou selos disponíveis. Para
              isso, neutralize suas emissões!
            </H5>
          ) : (
            <>
              <S.Folder
                variant="icon"
                fontWeight="bold"
                textColor={textColor}
                backgroundColor={tertiaryColor}
                onClick={() => setType("certificate")}
              >
                <S.Icon>
                  <AvailableIcons option="folder" color={textColor} />
                </S.Icon>
                Certificados
              </S.Folder>

              <S.Folder
                variant="icon"
                fontWeight="bold"
                textColor={textColor}
                onClick={() => setType("seal")}
                backgroundColor={tertiaryColor}
              >
                <S.Icon>
                  <AvailableIcons option="folder" color={textColor} />
                </S.Icon>
                Selos
              </S.Folder>
            </>
          )}
        </S.CertificateAndSeal>
      ) : (
        <>{typeContent}</>
      )}
    </Page>
  );
};

export default CertificatesAndSeal;
