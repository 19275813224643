const VisaIcon: React.FC = () => {
  return (
    <svg
      width="256"
      height="83"
      viewBox="0 0 256 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M132.397 26.428C132.251 37.944 142.66 44.37 150.501 48.191C158.557 52.111 161.263 54.625 161.231 58.131C161.171 63.496 154.805 65.864 148.848 65.956C138.455 66.117 132.412 63.15 127.608 60.906L123.864 78.425C128.684 80.646 137.609 82.583 146.864 82.668C168.589 82.668 182.802 71.944 182.879 55.317C182.964 34.215 153.691 33.047 153.891 23.615C153.96 20.755 156.689 17.703 162.669 16.927C165.629 16.535 173.8 16.235 183.064 20.501L186.7 3.55097C181.718 1.73697 175.315 -3.05176e-05 167.343 -3.05176e-05C146.895 -3.05176e-05 132.513 10.87 132.397 26.428ZM221.638 1.45997C217.671 1.45997 214.328 3.77397 212.836 7.32497L181.803 81.423H203.512L207.832 69.484H234.36L236.866 81.423H256L239.303 1.45997H221.638ZM224.675 23.061L230.94 53.088H213.782L224.675 23.061ZM106.076 1.46097L88.964 81.422H109.651L126.755 1.45897H106.076M75.473 1.45897L53.941 55.886L45.231 9.60897C44.209 4.44297 40.173 1.45997 35.691 1.45997H0.493L0 3.78197C7.226 5.34997 15.436 7.87897 20.41 10.585C23.454 12.238 24.322 13.683 25.322 17.611L41.819 81.423H63.68L97.196 1.45997L75.473 1.45897Z"
        fill="url(#paint0_linear_549_8)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_549_8"
          x1="11769.3"
          y1="248.5"
          x2="12008.6"
          y2="-8241.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222357" />
          <stop offset="1" stopColor="#254AA5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VisaIcon;
