import * as S from "./styles";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";

const { REACT_APP_IBIOMA_LOGO } = process.env;

interface IProps {
  setLogout: (val: boolean) => void;
}

const MobileHeader: React.FC<IProps> = ({ setLogout }) => {
  const { textColor, tertiaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Header backgroundColor={tertiaryColor}>
      <S.Logo logo={REACT_APP_IBIOMA_LOGO || ""} />

      <Button
        variant="solid"
        textColor={textColor}
        onClick={() => setLogout(true)}
        backgroundColor={backgroundColor}
      >
        sair
      </Button>
    </S.Header>
  );
};

export default MobileHeader;
