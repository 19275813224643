import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import { IProject } from "../../../../types/projects";
import Button from "../../../../components/atoms/Button";
import Page from "../../../../components/molecules/Page";
import { Auth, Snackbar, Theme } from "../../../../hooks";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H3 from "../../../../components/atoms/Typography/H3";
import Skeleton from "../../../../components/atoms/Skeleton";
import MoneyIcon from "../../../../components/icons/MoneyIcon";
import { Project as ProjectService } from "../../../../services";
import StorageIcon from "../../../../components/icons/StorageIcon";
import CertifiedIcon from "../../../../components/icons/CertifiedIcon";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

const { REACT_APP_ODS_BASE_URL } = process.env;

const Project: React.FC = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<IProject>();

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { textColor, tertiaryColor } = Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const projectData = await ProjectService.getProject(
          user.entity,
          id || "",
          token
        );

        setProject(projectData);
      } catch (error) {
        newError("Houve um erro ao obter as informações do projeto");
      } finally {
        setLoading(false);
      }
    };

    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Page pageIndex={0} mobileSection="projects">
      <S.Container>
        <S.Header>
          <Button
            size="small"
            variant="backButton"
            backgroundColor={tertiaryColor}
            onClick={() => navigate("/projetos")}
          >
            <AvailableIcons option="chevron" color={textColor} />
          </Button>
        </S.Header>

        {loading ? (
          <Skeleton
            direction="column"
            numberSkeletons={10}
            skeletonWidth="100%"
            skeletonHeight="40px"
          />
        ) : (
          <>
            <H1 color={textColor} fontWeight="bold">
              {project?.name}
            </H1>

            <S.Details>
              <S.Detail backgroundColor={tertiaryColor}>
                <S.DetailIcon>
                  <MoneyIcon color={textColor} />
                </S.DetailIcon>

                <P color={textColor}>
                  R$ {project?.details.pricing.toFixed(2).replace(".", ",")}
                </P>
              </S.Detail>

              <S.Detail backgroundColor={tertiaryColor}>
                <S.DetailIcon>
                  <StorageIcon color={textColor} />
                </S.DetailIcon>

                <P color={textColor}>{project?.details.storage} storage</P>
              </S.Detail>

              <S.Detail backgroundColor={tertiaryColor}>
                <S.DetailIcon>
                  <CertifiedIcon color={textColor} />
                </S.DetailIcon>

                <P color={textColor}>{project?.details.certifier}</P>
              </S.Detail>
            </S.Details>

            <S.Description>
              {project?.description
                .sort((a, b) => a.index - b.index)
                .map((item, index) => (
                  <P color={textColor} key={`${item}#${index}`}>
                    {item.value}
                  </P>
                ))}
            </S.Description>

            <S.Summary borderColor={tertiaryColor}>
              <H3 color={textColor} fontWeight="bold">
                Resumo do impacto do projeto
              </H3>

              <P color={textColor}>{project?.impactSummary.summary}</P>

              <S.Topics>
                {project?.impactSummary.topics
                  .sort((a, b) => a.index - b.index)
                  .map((item, index) => (
                    <S.Topic key={`${item.title}#${index}`}>
                      <P color={textColor} fontWeight="bold">
                        {item.title}
                      </P>

                      <P color={textColor}>{item.value}</P>

                      <S.TopicImage
                        image={
                          item.image ||
                          "https://ibioma-report-images.s3.amazonaws.com/floresta-topo.png"
                        }
                      />
                    </S.Topic>
                  ))}
              </S.Topics>
              <P color={textColor} fontWeight="bold"></P>
            </S.Summary>

            <S.ODS>
              <H3 color={textColor} fontWeight="bold">
                Objetivos de Desenvolvimento Sustentável da ONU
              </H3>

              <P color={textColor}>
                Estes são os ODS da ONU que o projeto apoia.
              </P>

              <S.ODSImages>
                {project?.ods.map((item) => (
                  <S.ODSImage
                    key={item}
                    size="cover"
                    ods={`${REACT_APP_ODS_BASE_URL}/ods-${item}.svg`}
                  />
                ))}

                <S.ODSImage
                  size="contain"
                  ods={`${REACT_APP_ODS_BASE_URL}/ods-logo.jpg`}
                />
              </S.ODSImages>
            </S.ODS>
          </>
        )}
      </S.Container>
    </Page>
  );
};

export default Project;
