import * as S from "./styles";

interface IProps {
  skeletonWidth: string;
  skeletonHeight: string;
  numberSkeletons: number;
  direction: "column" | "row";
}

const Skeleton: React.FC<IProps> = ({
  direction,
  skeletonWidth,
  skeletonHeight,
  numberSkeletons,
}) => {
  const skeletons = Array(numberSkeletons).fill(null);

  return (
    <S.Skeleton direction={direction}>
      {skeletons.map((_, index) => (
        <S.SkeletonBox
          index={index + 1}
          width={skeletonWidth}
          height={skeletonHeight}
          key={`skeleton#${index}`}
        />
      ))}
    </S.Skeleton>
  );
};

export default Skeleton;
