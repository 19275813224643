import styled from "styled-components";

export const Loading = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Image = styled.img`
  width: 800px;
  height: 240px;

  @media (max-width: 640px) {
    transform: scale(0.9);
  }

  @media (max-width: 560px) {
    transform: scale(0.7);
  }

  @media (max-width: 450px) {
    transform: scale(0.4);
  }

  @media (max-width: 360px) {
    transform: scale(0.3);
  }
`;
