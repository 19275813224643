import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import { Order } from "../../../../services";
import { IOrder } from "../../../../types/order";
import { billingStatus } from "../../../../utils/order";
import Button from "../../../../components/atoms/Button";
import Page from "../../../../components/molecules/Page";
import { Auth, Snackbar, Theme } from "../../../../hooks";
import P from "../../../../components/atoms/Typography/P";
import H1 from "../../../../components/atoms/Typography/H1";
import H4 from "../../../../components/atoms/Typography/H4";
import H5 from "../../../../components/atoms/Typography/H5";
import Skeleton from "../../../../components/atoms/Skeleton";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import PaymentDetails from "../../../../components/organisms/PaymentDetails";

const Statement: React.FC = () => {
  const { id } = useParams();

  const [statement, setStatement] = useState<IOrder>();
  const [loading, setLoading] = useState<boolean>(true);
  const [biggerTextarea, setBiggerTextarea] = useState(
    window.innerWidth <= 540
  );

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { textColor, tertiaryColor } = Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setBiggerTextarea(window.innerWidth <= 540);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [biggerTextarea]);

  useEffect(() => {
    const getStatementData = async () => {
      try {
        const statementData = await Order.getStatement(
          user.entity,
          id || "",
          token
        );

        setStatement(statementData);
      } catch (error) {
        newError("Houve um erro ao obter os detalhes do pedido");
      } finally {
        setLoading(false);
      }
    };

    getStatementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const nameWidth = "40%";
  const tCo2Width = "20%";
  const pricingWidth = "20%";
  const totalPricingWidth = "20%";

  return (
    <Page pageIndex={3} mobileSection="statement">
      <S.Container>
        <S.Header>
          <Button
            size="small"
            variant="backButton"
            backgroundColor={tertiaryColor}
            onClick={() => navigate("/extrato")}
          >
            <AvailableIcons option="chevron" color={textColor} />
          </Button>
        </S.Header>

        {loading ? (
          <Skeleton
            direction="column"
            numberSkeletons={5}
            skeletonWidth="100%"
            skeletonHeight="40px"
          />
        ) : (
          <>
            <S.Title>
              <H1 color={textColor} fontWeight="bold">
                Extrato - ID #{statement?.id}
              </H1>

              <H5 color={textColor}>
                Última atualização:{" "}
                {new Date(statement?.createdAt || "").toLocaleString("pt-BR")}
              </H5>
            </S.Title>

            <PaymentDetails
              card={statement?.creditCard}
              biggerTextarea={biggerTextarea}
              invoiceUrl={statement?.invoiceUrl}
              billingValue={statement?.billingValue || 0}
              payment={statement?.paymentMethod || "PIX"}
              paymentStatus={statement?.status || "PENDING"}
              orderAsaasId={statement?.orderAsaasId || "pay_empty"}
              paymentRecurrence={statement?.recurrence || "single"}
              copyIdentification={statement?.identificationField || ""}
            />

            <S.Details>
              <H4 color={textColor} fontWeight="bold">
                Detalhes do pedido
              </H4>

              <S.Detail>
                <S.HeaderDetail>
                  <S.TitleCustom
                    fontWeight="bold"
                    color={textColor}
                    width={nameWidth}
                  >
                    Projeto
                  </S.TitleCustom>

                  <S.TitleCustom
                    fontWeight="bold"
                    color={textColor}
                    width={tCo2Width}
                  >
                    tCO<sub>2</sub>
                  </S.TitleCustom>

                  <S.TitleCustom
                    color={textColor}
                    fontWeight="bold"
                    width={pricingWidth}
                  >
                    Preço un.
                  </S.TitleCustom>

                  <S.TitleCustom
                    color={textColor}
                    fontWeight="bold"
                    width={totalPricingWidth}
                  >
                    Preço
                  </S.TitleCustom>
                </S.HeaderDetail>

                <S.ProjectsList borderColor={tertiaryColor}>
                  {(statement?.projects || []).map((item, index) => (
                    <S.Project key={`${item.projectId}#${index}`}>
                      <S.ValueCustom width={nameWidth} color={textColor}>
                        {item.name}
                      </S.ValueCustom>

                      <S.ValueCustom width={tCo2Width} color={textColor}>
                        {item.quantity.toLocaleString("pt-BR", {
                          minimumFractionDigits: 0,
                        })}
                      </S.ValueCustom>

                      <S.ValueCustom width={pricingWidth} color={textColor}>
                        R${" "}
                        {item.pricing.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                        })}
                      </S.ValueCustom>

                      <S.ValueCustom
                        width={totalPricingWidth}
                        color={textColor}
                      >
                        R${" "}
                        {(item.pricing * item.quantity).toLocaleString(
                          "pt-BR",
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </S.ValueCustom>
                    </S.Project>
                  ))}
                </S.ProjectsList>

                <S.Total>
                  <S.TotalValue
                    color={textColor}
                    fontWeight="bold"
                    width={nameWidth + tCo2Width + pricingWidth}
                  >
                    Total
                  </S.TotalValue>

                  <S.TotalValue width={pricingWidth} color={textColor}>
                    R${" "}
                    {statement?.totalValue.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </S.TotalValue>
                </S.Total>

                {!user.simplesNacional && (
                  <>
                    <S.Total>
                      <S.TotalValue
                        color={textColor}
                        width={nameWidth + tCo2Width + pricingWidth}
                      >
                        Impostos retidos na fonte
                      </S.TotalValue>

                      <S.TotalValue width={pricingWidth} color={textColor}>
                        - R${" "}
                        {(
                          (statement?.totalValue || 0) -
                          (statement?.billingValue || 0)
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                        })}
                      </S.TotalValue>
                    </S.Total>

                    <S.Total>
                      <S.TotalValue
                        color={textColor}
                        fontWeight="bold"
                        width={nameWidth + tCo2Width + pricingWidth}
                      >
                        Valor da cobrança
                      </S.TotalValue>

                      <S.TotalValue width={pricingWidth} color={textColor}>
                        R${" "}
                        {statement?.billingValue.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                        })}
                      </S.TotalValue>
                    </S.Total>
                  </>
                )}
              </S.Detail>
            </S.Details>
          </>
        )}

        {billingStatus(statement?.status || "PENDING") === "Confirmado" &&
          statement?.recurrence === "signature" && (
            <S.CancelSignatureMessage>
              <P color={`${textColor}80`}>
                Caso deseje cancelar sua assinatura, entre em contato com
                contato@ibioma.com.br e informe o ID dessa compra
              </P>
            </S.CancelSignatureMessage>
          )}
      </S.Container>
    </Page>
  );
};

export default Statement;
