import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import {
  TBilling,
  ICreditCard,
  TRecurrence,
  TBillingStatus,
  ICreditCardHolderInfo,
} from "../../../../types/order";
import { Theme } from "../../../../hooks";
import { billingStatus } from "../../../../utils/order";
import P from "../../../../components/atoms/Typography/P";
import H3 from "../../../../components/atoms/Typography/H3";
import Title from "../../../../components/atoms/Typography/Title";
import PaymentDetails from "../../../../components/organisms/PaymentDetails";

interface IProps {
  payment: TBilling;
  totalValue: number;
  invoiceUrl?: string;
  orderAsaasId: string;
  biggerTextarea: boolean;
  copyIdentification?: string;
  paymentStatus: TBillingStatus;
  paymentRecurrence: TRecurrence;
  card?: {
    creditCard?: ICreditCard;
    creditCardToken?: string;
    creditCardHolderInfo?: ICreditCardHolderInfo;
  };
}

const BillingInfo: React.FC<IProps> = ({
  card,
  payment,
  totalValue,
  invoiceUrl,
  orderAsaasId,
  paymentStatus,
  biggerTextarea,
  paymentRecurrence,
  copyIdentification,
}) => {
  const navigate = useNavigate();

  const { textColor } = Theme.useTheme();

  return (
    <S.BillingInfo>
      <Title color={textColor}>Dados do pagamento</Title>

      <S.Content>
        <S.Description>
          <H3 color={textColor}>
            A atualização do status do pagamento pode levar alguns minutos para
            atualizar. Você poderá verificar a atualização na aba Extrato.
          </H3>

          <S.Statement color={textColor} onClick={() => navigate("/extrato")}>
            Ir para Extrato
          </S.Statement>
        </S.Description>

        <PaymentDetails
          card={card}
          invoiceUrl={invoiceUrl}
          payment={payment || "PIX"}
          orderAsaasId={orderAsaasId}
          billingValue={totalValue || 0}
          biggerTextarea={biggerTextarea}
          paymentRecurrence={paymentRecurrence}
          copyIdentification={copyIdentification}
          paymentStatus={paymentStatus || "PENDING"}
        />

        {billingStatus(paymentStatus) === "Confirmado" &&
          paymentRecurrence === "signature" && (
            <S.CancelSignatureMessage>
              <P color={`${textColor}80`}>
                Caso deseje cancelar sua assinatura, entre em contato com
                contato@ibioma.com.br e informe o ID dessa compra
              </P>
            </S.CancelSignatureMessage>
          )}
      </S.Content>
    </S.BillingInfo>
  );
};

export default BillingInfo;
