import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const GoogleIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.17143 11.02C3.86941 7.70649 6.47423 4.92102 9.69476 2.97494C12.9153 1.02885 16.6246 -0.00116714 20.4082 9.92462e-07C25.9082 9.92462e-07 30.5286 1.982 34.0612 5.21L28.2102 10.946C26.0939 8.964 23.4041 7.954 20.4082 7.954C15.0918 7.954 10.5918 11.474 8.9898 16.2C8.58163 17.4 8.34898 18.68 8.34898 20C8.34898 21.32 8.58163 22.6 8.9898 23.8C10.5939 28.528 15.0918 32.046 20.4082 32.046C23.1531 32.046 25.4898 31.336 27.3184 30.136C28.3784 29.452 29.286 28.5644 29.9861 27.527C30.6863 26.4895 31.1645 25.3237 31.3918 24.1H20.4082V16.364H39.6286C39.8694 17.672 40 19.036 40 20.454C40 26.546 37.7755 31.674 33.9143 35.154C30.5388 38.21 25.9184 40 20.4082 40C17.7278 40.001 15.0735 39.4844 12.597 38.4797C10.1205 37.475 7.87031 36.0019 5.97502 34.1445C4.07973 32.2871 2.57652 30.0819 1.5513 27.6549C0.526069 25.2279 -0.00107115 22.6267 1.63411e-06 20C1.63411e-06 16.772 0.787757 13.72 2.17143 11.02Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default GoogleIcon;
