import styled, { css } from "styled-components";
import CreditCardsCard from "../../components/molecules/CreditCardsCard";

export const Content = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const PaymentCards = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  margin-top: 32px;
`;

export const Cards = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardBox = styled.div`
  gap: 32px;
  width: 500px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    gap: 20px;
    width: 100%;
  }
`;

export const CreditCard = styled(CreditCardsCard)`
  width: 400px;
`;

type RemoveProps = {
  backgroundColor: string;
};

export const RemoveIcon = styled.div<RemoveProps>`
  ${({ backgroundColor }) => css`
    width: 40px;
    height: 36px;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};

    svg {
      width: 70%;
      height: 70%;
    }

    :hover {
      background-color: #ff000080;
    }
  `}
`;

export const AddCardButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
