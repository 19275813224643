import { TUnits } from "../types/adminCalculators";
import { IOrderDetails } from "../types/order";

export const normalizeSlicerNumber = (value: string | number, type: TUnits) => {
  if (type === "%" || type === "number" || type === "horas") {
    const normalizedNumber = (
      typeof value === "string" ? +value : value
    ).toLocaleString("pt", {
      minimumFractionDigits: 0,
    });

    return normalizedNumber;
  }

  const normalizedNumber = (
    typeof value === "string" ? +value : value
  ).toLocaleString("pt", {
    minimumFractionDigits: 2,
  });

  return normalizedNumber;
};

export const denormalizeNumber = (value: string, type: TUnits) => {
  if (
    type === "L" ||
    type === "Km" ||
    type === "Kg" ||
    type === "mL" ||
    type === "m²" ||
    type === "KWh" ||
    type === "un." ||
    type === "horas" ||
    type === "number" ||
    type === "hectare" ||
    type === "Kg/hectare" ||
    type === "ton/hectare" ||
    type === "m³/hectare"
  ) {
    const denormalizedNumber = +value.replaceAll(".", "").replace(",", ".");

    return denormalizedNumber;
  }

  if (type === "R$") {
    const denormalizedNumber = +value.replaceAll(".", "").replace(",", ".");

    return denormalizedNumber;
  }

  const denormalizedNumber = +value.replace(".", "");

  return denormalizedNumber;
};

export const normalizeNumber = (value: number, type: TUnits) => {
  const length = value.toString().length;

  if (type === "%" || type === "number" || type === "horas") {
    const normalizedNumber = value.toLocaleString("pt", {
      minimumFractionDigits: 0,
    });

    return normalizedNumber;
  }

  const normalizedNumber = (() => {
    if (value < 10) {
      return `0,0${value}`;
    } else if (value < 100) {
      return `0,${value}`;
    } else {
      const integer = +value.toString().slice(0, -2);
      const number = `${integer.toLocaleString("pt", {
        minimumFractionDigits: 0,
      })}`;
      const decimals = `${value.toString().substring(length - 2, length)}`;

      return `${number},${decimals}`;
    }
  })();

  return normalizedNumber;
};

export const maskPhoneNumber = (value: string) => {
  if (!value) return "";

  const ddd = value.substring(0, 2);
  const phone = value.substring(2);

  const firstPart = phone.substring(0, 5);
  const secondPart = phone.substring(5);

  if (!phone.length) return `(${value}`;

  if (!secondPart.length) return `(${ddd}) ${phone}`;

  return `(${ddd}) ${firstPart}-${secondPart}`;
};

export const maskCEP = (value: string) => {
  const firstPart = value.substring(0, 5);
  const secondPart = value.substring(5);

  if (!secondPart) return firstPart;

  return `${firstPart}-${secondPart}`;
};

export const maskCPFOrCNPJ = (value: string) => {
  if (!value) return "";

  if (value.length <= 11) {
    const firstPart = value.substring(0, 3);
    const secondPart = value.substring(3, 6);
    const thirdPart = value.substring(6, 9);
    const finalPart = value.substring(9);

    if (!secondPart) return `${firstPart}`;

    if (!thirdPart) return `${firstPart}.${secondPart}`;

    if (!finalPart) return `${firstPart}.${secondPart}.${thirdPart}`;

    return `${firstPart}.${secondPart}.${thirdPart}-${finalPart}`;
  }

  const firstPart = value.substring(0, 2);
  const secondPart = value.substring(2, 5);
  const thirdPart = value.substring(5, 8);
  const forthPart = value.substring(8, 12);
  const finalPart = value.substring(12);

  if (!secondPart) return `${firstPart}`;

  if (!thirdPart) return `${firstPart}.${secondPart}`;

  if (!forthPart) return `${firstPart}.${secondPart}.${thirdPart}`;

  if (!finalPart) return `${firstPart}.${secondPart}.${thirdPart}/${forthPart}`;

  return `${firstPart}.${secondPart}.${thirdPart}/${forthPart}-${finalPart}`;
};

export const volumeSelectedProjects = (
  projects: IOrderDetails[] | undefined
) => {
  if (!projects) return [""];

  const totalVolume =
    projects.reduce((acc, curr) => {
      acc += curr.quantity;

      return acc;
    }, 0) || 1;

  const newVolume = projects.map(
    (item) => `${((item.quantity / totalVolume) * 100).toFixed(1)} %`
  );

  return newVolume;
};
