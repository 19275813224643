import * as S from "./styles";
import { Theme } from "../../../hooks";
import H3 from "../../atoms/Typography/H3";
import PersonIcon from "../../icons/PersonIcon";
import BuildingIcon from "../../icons/BuildingIcon";
import CredentialsIcon from "../../icons/CredentialsIcon";

interface IProps {
  formStep: number;
}

const RegisterProgress: React.FC<IProps> = ({ formStep }) => {
  const { primaryColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.IconAndLabel>
        <S.Icon color={primaryColor}>
          <CredentialsIcon color={primaryColor} />
        </S.Icon>

        {formStep === 0 && (
          <H3 color={primaryColor} fontWeight="bold">
            Credenciais
          </H3>
        )}
      </S.IconAndLabel>

      <S.Separators>
        {[1, 2, 3, 4, 5].map((item) => (
          <S.Separator
            key={item}
            color={formStep >= 1 ? primaryColor : tertiaryColor}
          />
        ))}
      </S.Separators>

      <S.IconAndLabel>
        <S.Icon color={formStep >= 1 ? primaryColor : tertiaryColor}>
          <BuildingIcon color={formStep >= 1 ? primaryColor : tertiaryColor} />
        </S.Icon>

        {formStep === 1 && (
          <H3 color={primaryColor} fontWeight="bold">
            Informações da empresa
          </H3>
        )}
      </S.IconAndLabel>

      <S.Separators>
        {[1, 2, 3, 4, 5].map((item) => (
          <S.Separator
            key={item}
            color={formStep >= 2 ? primaryColor : tertiaryColor}
          />
        ))}
      </S.Separators>

      <S.IconAndLabel>
        <S.Icon color={formStep >= 2 ? primaryColor : tertiaryColor}>
          <PersonIcon color={formStep >= 2 ? primaryColor : tertiaryColor} />
        </S.Icon>

        {formStep === 2 && (
          <H3 color={primaryColor} fontWeight="bold">
            Informações do contato
          </H3>
        )}
      </S.IconAndLabel>
    </S.Container>
  );
};

export default RegisterProgress;
