import {
  TProduction,
  TGeneratorFuel,
  TAirConditioning,
} from "../types/adminCalculators";
import {
  TAge,
  TSex,
  THerd,
  TCrop,
  TCycle,
  TBiome,
  THandling,
  TDryMatter,
  TWaterRegime,
  TFormerLandUse,
  TClayInTheSoil,
  TSoilPreparation,
  TCaneFarmingSystem,
  TSoilTexturalClass,
  TCurrentFarmingSystem,
  TLivestockFarmingSystem,
  TFarmingSystemAdoptionTime,
} from "../types/agroCalculator";
import { TEnergy } from "../types/eventCalculator";
import { TFuel, TRoutesMethod, TVehicle } from "../types/logisticCalculator";

export const CONTACT_PHONE_NUMBER = "5511917128585"; //wpp Ibioma

export const verticals = [
  "Construção",
  "Finanças e seguros",
  "Indústria",
  "Mineração e energia",
  "ONG",
  "Serviços e consultorias",
  "Tecnologia",
  "Turismo",
  "Varejo",
  "Outros",
];

export const eventVerticals = [
  "Convenção",
  "Congresso",
  "Feira",
  "Festa",
  "Festival",
  "Show",
];

export const airConditioningTypes: TAirConditioning[] = [
  "none",
  "central",
  "split",
  "multi-split",
];

export const airConditioningTypesObj: { [key in TAirConditioning]: string } = {
  none: "Não temos",
  central: "Central",
  split: "Split",
  "multi-split": "Multi-split",
};

export const energySources: TEnergy[] = ["city-energy", "generator", "both"];

export const energySourcesObj: { [key in TEnergy]: string } = {
  "city-energy": "Rede elétrica da cidade",
  generator: "Gerador",
  both: "Rede elétrica e Gerador",
};

export const generatorFuel: TGeneratorFuel[] = [
  "etanol hidratado",
  "biodiesel (b100)",
  "oleo diesel (comercial)",
  "gasolina automotiva (comercial)",
  "gas liquefeito de petroleo (glp)",
];

export const generatorFuelObj: { [key in TGeneratorFuel]: string } = {
  "etanol hidratado": "Etanol",
  "biodiesel (b100)": "Biodiesel",
  "oleo diesel (comercial)": "Diesel",
  "gasolina automotiva (comercial)": "Gasolina",
  "gas liquefeito de petroleo (glp)": "Gás (GLP)",
};

export const transportUnitsObj = {
  R$: "R$",
  Km: "Km",
  L: "l",
};

export const soilTexturalObj: { [key in TSoilTexturalClass]: string } = {
  average: "Médio",
  clayish: "Argiloso",
  sandy: "Arenoso",
};

export const clayInTheSoilObj: { [key in TClayInTheSoil]: string } = {
  "< 60%": "Menor que 60%",
  "> 60%": "Maior que 60%",
};

export const formerLandUseObj: TFormerLandUse[] = [
  "Cultivo convencional",
  "Floresta nativa",
  "Pastagem degradada",
  "Pastagem/pastagem melhorada",
  "Plantio direto",
  "",
];

export const currentFarmingSystemObj: TCurrentFarmingSystem[] = [
  "Cultivo convencional",
  "Integração lavoura-pecuária",
  "Plantio direto",
];

export const caneCurrentFarmingSystemObj: TCaneFarmingSystem[] = [
  "Cana-de-açúcar com queima",
  "Cana-de-açúcar sem queima",
];

export const livestockCurrentFarmingSystemObj: TLivestockFarmingSystem[] = [
  "Integração lavoura-pecuária",
  "Pastagem degradada",
  "Pastagem melhorada com insumos",
  "Pastagem melhorada sem insumos",
  "Pastagem/pastagem melhorada",
];

export const farmingAdoptionTime: {
  [key in TFarmingSystemAdoptionTime]: string;
} = {
  "<= 20 anos": "Menor ou igual a 20 anos",
  "> 20 anos": "Mais que 20 anos",
};

export const waterRegime: TWaterRegime[] = [
  "A lavoura não está em terras baixas",
  "Continuamente inundado",
  "Inundação intermitente com aeração única",
  "Inundação intermitente com aerações múltiplas",
  "Várzea seca",
  "Várzea úmida",
  "Águas profundas entre 50 e 100 cm",
  "Águas profundas maiores que 100 cm",
];

export const soilPrepation: TSoilPreparation[] = [
  "Preparo antecipado (Primavera)",
  "Preparo convencional (Outono/Inverno)",
];

export const cycle: TCycle[] = ["12 meses", "18 meses"];

export const cropsObj: { [key in TCrop]: string } = {
  bean: "Feijão",
  cane: "Cana-de-açúcar",
  coffee: "Café",
  corn: "Milho",
  cotton: "Algodão",
  livestock: "Pecuária",
  rice: "Arroz",
  soy: "Soja",
  wheat: "Trigo",
};

export const dryMatter: TDryMatter[] = [
  "Sem aplicação",
  "De 1.000 a 2.000",
  "De 2.000 a 4.000",
  "De 4.000 a 8.000",
  "Mais que 15.000",
];

export const biome: TBiome[] = [
  "Amazônia - Ombrófila e estacional",
  "Amazônia - Savana e campina",
  "Caatinga",
  "Cerrado - Norte",
  "Cerrado",
  "Mata atlântica - Estacional",
  "Mata atlântica - Mista",
  "Mata atlântica - Ombrófila",
  "Pampas",
  "Pantanal",
];

export const herd: THerd[] = [
  "Asinino",
  "Bovino de corte",
  "Bovino de leite",
  "Bubalino",
  "Caprino",
  "Codorna",
  "Equino",
  "Frango/pinto",
  "Galinha de corte",
  "Galo",
  "Muare",
  "Ovino",
  "Poedeira",
  "Suíno",
];

export const herdAge: { [key in TAge | string]: string } = {
  "0-6 months": "0-6 meses",
  "6-12 months": "6-12 meses",
  "12-24 months": "12-24 meses",
  "+24 months": "+24 meses",
  "": "",
};

export const herdHandling: THandling[] = [
  "Animais em pastagem",
  "Armazenamento sólido",
  "Biodigestor",
  "Dry lot",
  "Esterqueira",
  "Lagoa anaeróbia",
  "Outros",
  "Poços abaixo do confinamento",
  "Queimado como custível",
];

export const herdSex: { [key in TSex]: string } = {
  male: "Macho",
  female: "Fêmea",
};

export const vehicles: TVehicle[] = [
  "Utilitário",
  "Carro",
  "Motocicleta",
  "Van",
  "Caminhão",
];

export const vehiclesFuel: { [key in TFuel]: string } = {
  "etanol hidratado": "Etanol",
  "biodiesel (b100)": "Biodiesel",
  "oleo diesel (comercial)": "Diesel",
  "gasolina automotiva (comercial)": "Gasolina",
};

export const productionType: TProduction[] = [
  "Bebidas",
  "Têxtil",
  "Garrafas térmicas",
];

export const productionProducts: { [key in TProduction | ""]: string[] } = {
  "": [""],
  Têxtil: ["Algodão", "Seda", "Lã", "Nylon", "Poliéster", "Linho"],
  Bebidas: [
    "Lata",
    "Garrafa de plástico",
    "Garrafa de vidro",
    "Caixa cartonada",
  ],
  "Garrafas térmicas": ["Garrafa de alumínio"],
};

export const routesMethodObj: { [key in TRoutesMethod]: string } = {
  manually: "Manualmente",
  upload: "Carregar planilha",
};
