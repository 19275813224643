import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const EngineIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5215 10.99L17.5114 11.01C17.5518 10.68 17.5923 10.34 17.5923 10C17.5923 9.66 17.562 9.34 17.5215 9.01L17.5316 9.03L20 7.11L17.5417 2.89L14.6383 4.05L14.6485 4.06C14.1224 3.66 13.5458 3.32 12.9186 3.06H12.9287L12.4633 0H7.53667L7.09155 3.07H7.10167C6.47446 3.33 5.89782 3.67 5.37178 4.07L5.38189 4.06L2.46839 2.89L0 7.11L2.46839 9.03L2.4785 9.01C2.43804 9.34 2.40769 9.66 2.40769 10C2.40769 10.34 2.43804 10.68 2.48862 11.01L2.4785 10.99L0.354072 12.64L0.0202329 12.9L2.4785 17.1L5.39201 15.95L5.37178 15.91C5.90794 16.32 6.48457 16.66 7.1219 16.92H7.09155L7.54679 20H12.4532C12.4532 20 12.4836 19.82 12.5139 19.58L12.8983 16.93H12.8882C13.5154 16.67 14.1022 16.33 14.6383 15.92L14.6181 15.96L17.5316 17.11L19.9899 12.91C19.9899 12.91 19.8483 12.79 19.656 12.65L17.5215 10.99ZM9.99494 13.5C8.04249 13.5 6.45422 11.93 6.45422 10C6.45422 8.07 8.04249 6.5 9.99494 6.5C11.9474 6.5 13.5357 8.07 13.5357 10C13.5357 11.93 11.9474 13.5 9.99494 13.5Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default EngineIcon;
