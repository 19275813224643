import * as S from "./styles";
import { Theme } from "../../../hooks";

interface IProps {
  units: string[];
  selected: number;
  onSelect: (index: number) => void;
}

const UnitSelector: React.FC<IProps> = ({ units, onSelect, selected }) => {
  const { primaryColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.Container borderColor={tertiaryColor}>
      {units.map((unit, index) => (
        <S.Unit
          key={unit}
          backgroundColor={primaryColor}
          onClick={() => onSelect(index)}
          isSelected={index === selected}
        >
          {unit}
        </S.Unit>
      ))}
    </S.Container>
  );
};

export default UnitSelector;
