import * as S from "./styles";
import {
  vehicles,
  vehiclesFuel,
  transportUnitsObj,
  routesMethodObj,
} from "../../../../../../../../constants/calculators";
import {
  TFuel,
  ILogistic,
  ILogisticRoutes,
  ILogisticCalculatorForm,
} from "../../../../../../../../types/logisticCalculator";
import { LogisticErrors } from "../..";
import { Theme } from "../../../../../../../../hooks";
import Button from "../../../../../../../../components/atoms/Button";
import { TUnits } from "../../../../../../../../types/adminCalculators";
import H2 from "../../../../../../../../components/atoms/Typography/H2";
import H4 from "../../../../../../../../components/atoms/Typography/H4";
import OptionsList from "../../../../../../../../components/molecules/OptionsList";
import AddDocument from "../../../../../../../../components/organisms/AddDocument";
import DropdownMenu from "../../../../../../../../components/organisms/DropdownMenu";
import AvailableIcons from "../../../../../../../../components/atoms/AvailableIcons";
import InputSliceAndNumber from "../../../../../../../../components/organisms/InputSliceAndNumber";

interface IProps {
  office: boolean;
  question: number;
  pickups: boolean;
  warehouse: boolean;
  documentName?: string;
  setOffice: () => void;
  columnOptions: boolean;
  setPickups: () => void;
  onAddPickup: () => void;
  setWarehouse: () => void;
  onAheadClick: () => void;
  booleanOptions: string[];
  onAddDelivery: () => void;
  pickupsData?: ILogistic[];
  transportOptions: string[];
  airConditioningType: number;
  onPreviousClick: () => void;
  columnRoutesOptions: boolean;
  deliveriesData?: ILogistic[];
  onCalculateClick: () => void;
  airConditioningTypes: string[];
  pickupsErrors?: LogisticErrors[];
  deliveriesErrors?: LogisticErrors[];
  formFields: ILogisticCalculatorForm;
  onRemovePickup: (val: number) => void;
  onRemoveDelivery: (val: number) => void;
  setAirConditioningType: (index: number) => void;
  onAddRoutes: (data: ILogisticRoutes[], doc: string) => void;
  onChangeForm: (
    key: keyof ILogisticCalculatorForm,
    value: string | number | boolean
  ) => void;
  onChangePickupForm: (
    key: keyof ILogistic,
    index: number,
    value: string | number | boolean
  ) => void;
  onChangeDeliveryForm: (
    key: keyof ILogistic,
    index: number,
    value: string | number | boolean
  ) => void;
}

const Questions: React.FC<IProps> = ({
  office,
  pickups,
  question,
  warehouse,
  setOffice,
  setPickups,
  formFields,
  onAddPickup,
  pickupsData,
  onAddRoutes,
  documentName,
  onAheadClick,
  setWarehouse,
  onChangeForm,
  onAddDelivery,
  columnOptions,
  pickupsErrors,
  deliveriesData,
  booleanOptions,
  onRemovePickup,
  onPreviousClick,
  onRemoveDelivery,
  deliveriesErrors,
  transportOptions,
  onCalculateClick,
  onChangePickupForm,
  airConditioningType,
  columnRoutesOptions,
  airConditioningTypes,
  onChangeDeliveryForm,
  setAirConditioningType,
}) => {
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  const content = () => {
    switch (question) {
      case 1:
        return (
          <>
            <S.BoxContent borderColor={primaryColor} key="pickups">
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Rotas
                </H2>
              </S.QuestionTitle>

              <S.Question>
                <OptionsList
                  options={Object.values(routesMethodObj)}
                  setSelectedItem={(val) =>
                    onChangeForm(
                      "routesMethod",
                      Object.keys(routesMethodObj)[val]
                    )
                  }
                  direction={columnRoutesOptions ? "column" : "row"}
                  selectedItem={Object.keys(routesMethodObj).indexOf(
                    formFields.routesMethod
                  )}
                  label="Como deseja inserir suas rotas logísticas: carregando planilha
                  (com template Ibioma) ou manualmente, via formulário da calculadora?"
                />
              </S.Question>
            </S.BoxContent>

            {formFields.routesMethod === "upload" && (
              <S.BoxContent borderColor={primaryColor} key="manuallyRoutes">
                <S.QuestionTitle>
                  <H2 color={textColor} fontWeight="bold">
                    Planilha
                  </H2>

                  <H4 color={textColor}>
                    Para subir a planilha com as rotas, verifique se ela está no
                    padrão esperado. O template padrão está nesta{" "}
                    <S.RefPage
                      target="_blank"
                      color={textColor}
                      rel="noreferrer noopener"
                      href="https://ibioma.com.br/template-rotas"
                    >
                      página de referência
                    </S.RefPage>
                  </H4>
                </S.QuestionTitle>

                <S.Questions>
                  <AddDocument
                    documentName={documentName}
                    onChange={(doc, docName) => onAddRoutes(doc, docName)}
                  />
                </S.Questions>
              </S.BoxContent>
            )}

            {formFields.routesMethod === "manually" && (
              <>
                <S.BoxContent borderColor={primaryColor} key="pickups">
                  <S.QuestionTitle>
                    <H2 color={textColor} fontWeight="bold">
                      Coletas
                    </H2>

                    <H4 color={textColor}>
                      Consideramos como coletas os segmentos do percurso em que
                      a transportadora realiza a retirada das mercadorias do
                      local de origem e as armazena em um CD, com o objetivo de,
                      posteriormente, efetuar sua entrega ao destino final.
                    </H4>
                  </S.QuestionTitle>

                  <S.Question>
                    <OptionsList
                      options={booleanOptions}
                      selectedItem={pickups ? 0 : 1}
                      setSelectedItem={() => setPickups()}
                      label="Realiza a coleta das mercadorias a serem entregues?"
                    />
                  </S.Question>

                  {pickups &&
                    pickupsData &&
                    pickupsData.map((item, index) => (
                      <>
                        {index > 0 && <S.SeparatorLine />}

                        <S.LogisticBox key={`pickups#${item}#${index}`}>
                          <S.QuestionsDeliveries>
                            <S.Question width="220px">
                              <DropdownMenu
                                options={vehicles}
                                labelWeight="regular"
                                labelColor={textColor}
                                label="Tipo do veículo"
                                selected={item.vehicleType || ""}
                                placeholder="Selecione o veículo"
                                errors={[
                                  (pickupsErrors &&
                                    pickupsErrors[index]?.vehicleType) ||
                                    "",
                                ]}
                                onSelect={(val) =>
                                  onChangePickupForm("vehicleType", index, val)
                                }
                              />
                            </S.Question>

                            <S.Question width="260px">
                              <DropdownMenu
                                labelWeight="regular"
                                labelColor={textColor}
                                label="Combustível utilizado"
                                options={Object.values(vehiclesFuel)}
                                placeholder="Selecione o combustível"
                                selected={
                                  vehiclesFuel[item.fuelType as TFuel] || ""
                                }
                                errors={[
                                  (pickupsErrors &&
                                    pickupsErrors[index]?.fuelType) ||
                                    "",
                                ]}
                                onSelect={(val) =>
                                  onChangePickupForm("fuelType", index, val)
                                }
                              />
                            </S.Question>

                            {/* <S.Question>
                              <InputSliceAndNumber
                                type="Kg"
                                gap="10px"
                                slicer={false}
                                maxValue={20000}
                                label="Carga carregada"
                                value={item.load || "0,00"}
                                errors={[
                                  (pickupsErrors &&
                                    pickupsErrors[index]?.load) ||
                                    "",
                                ]}
                                onChange={(val) =>
                                  onChangePickupForm("load", index, val)
                                }
                              />
                            </S.Question> */}

                            <S.Question>
                              <InputSliceAndNumber
                                gap="10px"
                                slicer={false}
                                maxValue={20000}
                                unitSelector={true}
                                units={transportOptions}
                                value={item.fuelExpenses || "0,00"}
                                selectedUnit={Object.values(
                                  transportUnitsObj
                                ).indexOf(item.expensesUnit)}
                                errors={[
                                  (pickupsErrors &&
                                    pickupsErrors[index]?.fuelExpenses) ||
                                    "",
                                ]}
                                selectUnit={(val) =>
                                  onChangePickupForm("expensesUnit", index, val)
                                }
                                type={
                                  transportOptions[
                                    Object.values(transportUnitsObj).indexOf(
                                      item.expensesUnit
                                    )
                                  ] as TUnits
                                }
                                onChange={(val) =>
                                  onChangePickupForm("fuelExpenses", index, val)
                                }
                                label={
                                  transportOptions[
                                    Object.values(transportUnitsObj).indexOf(
                                      item.expensesUnit
                                    )
                                  ] === "R$" ||
                                  transportOptions[
                                    Object.values(transportUnitsObj).indexOf(
                                      item.expensesUnit
                                    )
                                  ] === "L"
                                    ? "Gasto combustível"
                                    : "Km percorrido"
                                }
                              />
                            </S.Question>
                          </S.QuestionsDeliveries>

                          <S.RemoveIcon onClick={() => onRemovePickup(index)}>
                            <AvailableIcons option="close" color="#ff0000" />
                          </S.RemoveIcon>
                        </S.LogisticBox>
                      </>
                    ))}

                  {pickups && (
                    <S.AddButton onClick={() => onAddPickup()}>
                      <Button
                        variant="dashed"
                        textColor={primaryColor}
                        borderColor={primaryColor}
                      >
                        Adicionar coleta
                      </Button>
                    </S.AddButton>
                  )}
                </S.BoxContent>

                <S.BoxContent borderColor={primaryColor} key="deliveries">
                  <S.QuestionTitle>
                    <H2 color={textColor} fontWeight="bold">
                      Entregas
                    </H2>
                  </S.QuestionTitle>

                  {deliveriesData &&
                    deliveriesData.map((item, index) => (
                      <>
                        {index > 0 && <S.SeparatorLine />}

                        <S.LogisticBox key={`deliveries#${item}#${index}`}>
                          <S.QuestionsDeliveries>
                            <S.Question width="220px">
                              <DropdownMenu
                                options={vehicles}
                                labelWeight="regular"
                                labelColor={textColor}
                                label="Tipo do veículo"
                                selected={item.vehicleType || ""}
                                placeholder="Selecione o veículo"
                                errors={[
                                  (deliveriesErrors &&
                                    deliveriesErrors[index]?.vehicleType) ||
                                    "",
                                ]}
                                onSelect={(val) =>
                                  onChangeDeliveryForm(
                                    "vehicleType",
                                    index,
                                    val
                                  )
                                }
                              />
                            </S.Question>

                            <S.Question width="260px">
                              <DropdownMenu
                                labelWeight="regular"
                                labelColor={textColor}
                                label="Combustível utilizado"
                                options={Object.values(vehiclesFuel)}
                                placeholder="Selecione o combustível"
                                selected={
                                  vehiclesFuel[item.fuelType as TFuel] || ""
                                }
                                errors={[
                                  (deliveriesErrors &&
                                    deliveriesErrors[index]?.fuelType) ||
                                    "",
                                ]}
                                onSelect={(val) =>
                                  onChangeDeliveryForm("fuelType", index, val)
                                }
                              />
                            </S.Question>

                            {/* <S.Question>
                              <InputSliceAndNumber
                                type="Kg"
                                gap="10px"
                                slicer={false}
                                maxValue={20000}
                                errors={[
                                  (deliveriesErrors &&
                                    deliveriesErrors[index]?.load) ||
                                    "",
                                ]}
                                label="Carga carregada"
                                value={item.load || "0,00"}
                                onChange={(val) =>
                                  onChangeDeliveryForm("load", index, val)
                                }
                              />
                            </S.Question> */}

                            <S.Question>
                              <InputSliceAndNumber
                                gap="10px"
                                slicer={false}
                                maxValue={20000}
                                unitSelector={true}
                                units={transportOptions}
                                value={item.fuelExpenses || "0,00"}
                                selectedUnit={Object.values(
                                  transportUnitsObj
                                ).indexOf(item.expensesUnit)}
                                errors={[
                                  (deliveriesErrors &&
                                    deliveriesErrors[index]?.fuelExpenses) ||
                                    "",
                                ]}
                                selectUnit={(val) =>
                                  onChangeDeliveryForm(
                                    "expensesUnit",
                                    index,
                                    val
                                  )
                                }
                                type={
                                  transportOptions[
                                    Object.values(transportUnitsObj).indexOf(
                                      item.expensesUnit
                                    )
                                  ] as TUnits
                                }
                                onChange={(val) =>
                                  onChangeDeliveryForm(
                                    "fuelExpenses",
                                    index,
                                    val
                                  )
                                }
                                label={
                                  transportOptions[
                                    Object.values(transportUnitsObj).indexOf(
                                      item.expensesUnit
                                    )
                                  ] === "R$" ||
                                  transportOptions[
                                    Object.values(transportUnitsObj).indexOf(
                                      item.expensesUnit
                                    )
                                  ] === "L"
                                    ? "Gasto combustível"
                                    : "Km percorrido"
                                }
                              />
                            </S.Question>
                          </S.QuestionsDeliveries>

                          <S.RemoveIcon onClick={() => onRemoveDelivery(index)}>
                            <AvailableIcons option="close" color="#ff0000" />
                          </S.RemoveIcon>
                        </S.LogisticBox>
                      </>
                    ))}

                  <S.AddButton onClick={() => onAddDelivery()}>
                    <Button
                      variant="dashed"
                      textColor={primaryColor}
                      borderColor={primaryColor}
                    >
                      Adicionar entrega
                    </Button>
                  </S.AddButton>
                </S.BoxContent>
              </>
            )}
          </>
        );

      case 2:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Viagens aéreas à negócio (considere ida e volta como 2 voos)
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  type="number"
                  maxValue={100}
                  value={formFields.numberOfShortFlights || "0"}
                  onChange={(val) => onChangeForm("numberOfShortFlights", val)}
                  label="Número de voos curtos (até 3 horas de voo) - média mensal"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="number"
                  maxValue={100}
                  value={formFields.numberOfMediumFlights || "0"}
                  onChange={(val) => onChangeForm("numberOfMediumFlights", val)}
                  label="Número de voos médios (até 6 horas de voo) - média mensal"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="number"
                  maxValue={100}
                  value={formFields.numberOfLongFlights || "0"}
                  onChange={(val) => onChangeForm("numberOfLongFlights", val)}
                  label="Número de voos longos (mais de 6 horas de voo) - média mensal"
                />
              </S.Question>
            </S.Questions>
          </S.BoxContent>
        );

      case 3:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Facilities - Sede Administrativa
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  selectedItem={office ? 0 : 1}
                  setSelectedItem={() => setOffice()}
                  label="Possui sede administrativa da empresa?"
                />
              </S.Question>

              {office && (
                <>
                  <S.Question>
                    <InputSliceAndNumber
                      type="number"
                      minValue={1}
                      maxValue={5000}
                      value={formFields.officeEmployees || "1"}
                      onChange={(val) => onChangeForm("officeEmployees", val)}
                      label="Quantas pessoas trabalham na sede de sua empresa?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      option2="R$"
                      option1="KWh"
                      maxValue={20000}
                      unitSwitcher={true}
                      type={formFields.officeEnergyUnit || "KWh"}
                      isOn={formFields.officeEnergyUnit === "R$"}
                      value={formFields.officeEnergyExpenses || "0,00"}
                      setIsOn={(val) => onChangeForm("officeEnergyUnit", val)}
                      onChange={(val) =>
                        onChangeForm("officeEnergyExpenses", val)
                      }
                      label="Qual o gasto médio mensal com energia elétrica em sua sede?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      option2="R$"
                      option1="Kg"
                      maxValue={1000}
                      unitSwitcher={true}
                      type={formFields.officeCookingGasUnit || "Kg"}
                      isOn={formFields.officeCookingGasUnit === "R$"}
                      value={formFields.officeCookingGasExpenses || "0,00"}
                      label="Qual o gasto médio mensal com gás de cozinha?"
                      setIsOn={(val) =>
                        onChangeForm("officeCookingGasUnit", val)
                      }
                      onChange={(val) =>
                        onChangeForm("officeCookingGasExpenses", val)
                      }
                    />
                  </S.Question>

                  <S.Question>
                    <OptionsList
                      options={airConditioningTypes}
                      selectedItem={airConditioningType}
                      label="Qual o tipo do seu Ar-condicionado?"
                      direction={columnOptions ? "column" : "row"}
                      setSelectedItem={(val) => setAirConditioningType(val)}
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      option1="Kg"
                      option2="un."
                      maxValue={50}
                      unitSwitcher={true}
                      type={formFields.airConditioningUnit || "Kg"}
                      isOn={formFields.airConditioningUnit === "un."}
                      value={formFields.airConditioningRefills || "0,00"}
                      setIsOn={(val) =>
                        onChangeForm("airConditioningUnit", val)
                      }
                      onChange={(val) =>
                        onChangeForm("airConditioningRefills", val)
                      }
                      label="Quantidade de recargas de Ar-condicionado no último mês"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      maxValue={20000}
                      unitSelector={true}
                      units={transportOptions}
                      selectedUnit={Object.values(transportUnitsObj).indexOf(
                        formFields.officeTransportationUnit || "R$"
                      )}
                      selectUnit={(val) =>
                        onChangeForm("officeTransportationUnit", val)
                      }
                      type={
                        transportOptions[
                          Object.values(transportUnitsObj).indexOf(
                            formFields.officeTransportationUnit || "R$"
                          )
                        ] as TUnits
                      }
                      value={formFields.officeTransportationExpenses || "0,00"}
                      onChange={(val) =>
                        onChangeForm("officeTransportationExpenses", val)
                      }
                      label="Qual é a média mensal de gastos com combustíveis para o deslocamento de colaboradores em veículos próprios, táxis, transportes por aplicativo etc.? (Indique a unidade de medida entre R$, Km ou L)"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      maxValue={20000}
                      unitSelector={true}
                      units={transportOptions}
                      selectedUnit={Object.values(transportUnitsObj).indexOf(
                        formFields.officePublicTransportationUnit || "R$"
                      )}
                      selectUnit={(val) =>
                        onChangeForm("officePublicTransportationUnit", val)
                      }
                      type={
                        transportOptions[
                          Object.values(transportUnitsObj).indexOf(
                            formFields.officePublicTransportationUnit || "R$"
                          )
                        ] as TUnits
                      }
                      value={
                        formFields.officePublicTransportationExpenses || "0,00"
                      }
                      onChange={(val) =>
                        onChangeForm("officePublicTransportationExpenses", val)
                      }
                      label="Qual é a média mensal de gastos com combustíveis para o deslocamento de colaboradores em transportes públicos? (Indique a unidade de medida entre R$, Km ou L)"
                    />
                  </S.Question>
                </>
              )}
            </S.Questions>
          </S.BoxContent>
        );

      case 4:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Facilities - Armazém / Depósito / CD
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  selectedItem={warehouse ? 0 : 1}
                  setSelectedItem={() => setWarehouse()}
                  label="Possui armazém, depósito ou centro de distribuição (CD) da empresa?"
                />
              </S.Question>

              {warehouse && (
                <>
                  <S.Question>
                    <InputSliceAndNumber
                      type="number"
                      minValue={1}
                      maxValue={5000}
                      value={formFields.warehouseEmployees || "1"}
                      label="Quantas pessoas trabalham no CD de sua empresa?"
                      onChange={(val) =>
                        onChangeForm("warehouseEmployees", val)
                      }
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      option2="R$"
                      option1="KWh"
                      maxValue={20000}
                      unitSwitcher={true}
                      type={formFields.warehouseEnergyUnit || "KWh"}
                      isOn={formFields.warehouseEnergyUnit === "R$"}
                      value={formFields.warehouseEnergyExpenses || "0,00"}
                      setIsOn={(val) =>
                        onChangeForm("warehouseEnergyUnit", val)
                      }
                      onChange={(val) =>
                        onChangeForm("warehouseEnergyExpenses", val)
                      }
                      label="Qual o gasto médio mensal com energia elétrica em seu CD?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      option1="Kg"
                      option2="un."
                      maxValue={50}
                      unitSwitcher={true}
                      type={formFields.warehouseRefrigeratorUnit || "Kg"}
                      isOn={formFields.warehouseRefrigeratorUnit === "un."}
                      value={formFields.warehouseRefrigeratorRefills || "0,00"}
                      setIsOn={(val) =>
                        onChangeForm("warehouseRefrigeratorUnit", val)
                      }
                      onChange={(val) =>
                        onChangeForm("warehouseRefrigeratorRefills", val)
                      }
                      label="Qual o gasto com refrigeradores de cargas em seu CD no último mês?"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      maxValue={20000}
                      unitSelector={true}
                      units={transportOptions}
                      selectedUnit={Object.values(transportUnitsObj).indexOf(
                        formFields.warehouseTransportationUnit || "R$"
                      )}
                      selectUnit={(val) =>
                        onChangeForm("warehouseTransportationUnit", val)
                      }
                      type={
                        transportOptions[
                          Object.values(transportUnitsObj).indexOf(
                            formFields.warehouseTransportationUnit || "R$"
                          )
                        ] as TUnits
                      }
                      value={
                        formFields.warehouseTransportationExpenses || "0,00"
                      }
                      onChange={(val) =>
                        onChangeForm("warehouseTransportationExpenses", val)
                      }
                      label="Qual é a média mensal de gastos com combustíveis para o deslocamento de colaboradores do seu CD em veículos próprios, táxis, transportes por aplicativo etc.? (Indique a unidade de medida entre R$, Km ou L)"
                    />
                  </S.Question>

                  <S.Question>
                    <InputSliceAndNumber
                      maxValue={20000}
                      unitSelector={true}
                      units={transportOptions}
                      selectUnit={(val) =>
                        onChangeForm("warehousePublicTransportationUnit", val)
                      }
                      selectedUnit={Object.values(transportUnitsObj).indexOf(
                        formFields.warehousePublicTransportationUnit || "R$"
                      )}
                      type={
                        transportOptions[
                          Object.values(transportUnitsObj).indexOf(
                            formFields.warehousePublicTransportationUnit || "R$"
                          )
                        ] as TUnits
                      }
                      value={
                        formFields.warehousePublicTransportationExpenses ||
                        "0,00"
                      }
                      onChange={(val) =>
                        onChangeForm(
                          "warehousePublicTransportationExpenses",
                          val
                        )
                      }
                      label="Qual é a média mensal de gastos com combustíveis para o deslocamento de colaboradores do seu CD em transportes públicos? (Indique a unidade de medida entre R$, Km ou L)"
                    />
                  </S.Question>
                </>
              )}
            </S.Questions>
          </S.BoxContent>
        );

      case 5:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Emissões residuais
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.organicWaste || "0,00"}
                  onChange={(val) => onChangeForm("organicWaste", val)}
                  label="Quantos Kg de lixo Orgânico foram gerados pela empresa no último mês?"
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  type="Kg"
                  maxValue={20000}
                  value={formFields.recyclableTrash || "0,00"}
                  onChange={(val) => onChangeForm("recyclableTrash", val)}
                  label="Quantos Kg de lixo Reciclável foram gerados pela empresa no último mês? (Considere, também, as embalagens acrescentadas por sua empresa para entregar ao consumidor)"
                />
              </S.Question>
            </S.Questions>
          </S.BoxContent>
        );

      default:
        return;
    }
  };

  return (
    <S.Container>
      {content()}

      <S.Buttons>
        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question !== 5}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onAheadClick()}
        >
          Próximo
        </S.Clickable>

        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          isVisible={question === 5}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onCalculateClick()}
        >
          Calcular
        </S.Clickable>

        <S.Clickable
          variant="outline"
          fontWeight="bold"
          textColor={primaryColor}
          isVisible={question > 1}
          borderColor={primaryColor}
          onClick={() => onPreviousClick()}
          backgroundColor={backgroundColor}
        >
          Anterior
        </S.Clickable>
      </S.Buttons>
    </S.Container>
  );
};

export default Questions;
