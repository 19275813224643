import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const LogisticIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6 2H13.2C12.8287 2 12.4726 2.15804 12.2101 2.43934C11.9475 2.72064 11.8 3.10218 11.8 3.5V20C11.8 20.3978 11.9475 20.7794 12.2101 21.0607C12.4726 21.342 12.8287 21.5 13.2 21.5H28.6C28.9713 21.5 29.3274 21.342 29.59 21.0607C29.8525 20.7794 30 20.3978 30 20V3.5C30 3.10218 29.8525 2.72064 29.59 2.43934C29.3274 2.15804 28.9713 2 28.6 2ZM2 21.5H11.8V11H6.9L2 15.8465V21.5Z"
        stroke={color || primaryColor}
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M11.8 23C11.8 23.7957 11.505 24.5587 10.9798 25.1213C10.4547 25.6839 9.74256 26 8.99995 26C8.25734 26 7.54515 25.6839 7.02005 25.1213C6.49495 24.5587 6.19995 23.7957 6.19995 23M27.2 23C27.2 23.7957 26.905 24.5587 26.3798 25.1213C25.8547 25.6839 25.1426 26 24.4 26C23.6573 26 22.9452 25.6839 22.4201 25.1213C21.895 24.5587 21.6 23.7957 21.6 23"
        stroke={color || primaryColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogisticIcon;
