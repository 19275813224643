import styled from "styled-components";

type H5Props = {
  color: string;
  fontWeight?: string;
};

const H5 = styled.h5<H5Props>`
  font-size: 1.1rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};

  strong {
    font-weight: 600;
  }
`;

export default H5;
