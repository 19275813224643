import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CredentialsIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6 18.3324H16V16.7476H17.6V18.3324ZM12.8 18.3324H14.4V16.7476H12.8V18.3324ZM20.8 18.3324H19.2V16.7476H20.8V18.3324Z"
        fill={color || primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 10.4083V6.44628C4.8 5.71786 4.94485 4.99657 5.22627 4.32359C5.5077 3.65061 5.92019 3.03913 6.4402 2.52405C6.96021 2.00898 7.57755 1.6004 8.25697 1.32164C8.9364 1.04289 9.6646 0.899414 10.4 0.899414C11.1354 0.899414 11.8636 1.04289 12.543 1.32164C13.2225 1.6004 13.8398 2.00898 14.3598 2.52405C14.8798 3.03913 15.2923 3.65061 15.5737 4.32359C15.8552 4.99657 16 5.71786 16 6.44628V10.4083H18.4C19.0365 10.4083 19.647 10.6588 20.0971 11.1046C20.5471 11.5504 20.8 12.1551 20.8 12.7856V13.6572C21.7042 13.8391 22.5172 14.325 23.1011 15.0326C23.685 15.7403 24.004 16.6261 24.004 17.54C24.004 18.454 23.685 19.3398 23.1011 20.0474C22.5172 20.755 21.7042 21.241 20.8 21.4228V22.2945C20.8 22.925 20.5471 23.5296 20.0971 23.9754C19.647 24.4213 19.0365 24.6717 18.4 24.6717H2.4C1.76348 24.6717 1.15303 24.4213 0.702944 23.9754C0.252856 23.5296 0 22.925 0 22.2945L0 12.7856C0 12.1551 0.252856 11.5504 0.702944 11.1046C1.15303 10.6588 1.76348 10.4083 2.4 10.4083H4.8ZM6.4 6.44628C6.4 5.39548 6.82143 4.38772 7.57157 3.64469C8.32172 2.90166 9.33913 2.48423 10.4 2.48423C11.4609 2.48423 12.4783 2.90166 13.2284 3.64469C13.9786 4.38772 14.4 5.39548 14.4 6.44628V10.4083H6.4V6.44628ZM13.6 15.1628C12.9635 15.1628 12.353 15.4132 11.9029 15.8591C11.4529 16.3049 11.2 16.9095 11.2 17.54C11.2 18.1705 11.4529 18.7752 11.9029 19.221C12.353 19.6668 12.9635 19.9173 13.6 19.9173H20C20.6365 19.9173 21.247 19.6668 21.6971 19.221C22.1471 18.7752 22.4 18.1705 22.4 17.54C22.4 16.9095 22.1471 16.3049 21.6971 15.8591C21.247 15.4132 20.6365 15.1628 20 15.1628H13.6Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CredentialsIcon;
