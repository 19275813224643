import styled, { css } from "styled-components";

import P from "../../components/atoms/Typography/P";
import H4 from "../../components/atoms/Typography/H4";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MaterialsAndContent = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

type TitleCustomProps = {
  width: string;
};

export const TitleCustom = styled(H4)<TitleCustomProps>`
  ${({ width }) => css`
    width: ${width};
    padding-left: 20px;
  `}
`;

type HeaderProps = {
  borderColor: string;
};

export const Header = styled.div<HeaderProps>`
  ${({ borderColor }) => css`
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid ${borderColor};

    @media (max-width: 624px) {
      display: none;
    }
  `}
`;

export const MaterialsList = styled.div`
  gap: 24px;
  display: flex;
  margin-top: 16px;
  flex-direction: column;

  @media (max-width: 624px) {
    display: none;
  }
`;

export const Material = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const MaterialsCards = styled.div`
  display: none;

  @media (max-width: 624px) {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
`;

type MaterialCardProps = {
  backgroundColor: string;
};

export const MaterialCard = styled.a<MaterialCardProps>`
  ${({ backgroundColor }) => css`
    gap: 20px;
    display: flex;
    cursor: pointer;
    padding: 12px 24px;
    align-items: center;
    border-radius: 60px;
    text-decoration: none;
    background-color: ${backgroundColor};

    @media (max-width: 480px) {
      border-radius: 40px;
    }
  `}
`;

export const MaterialCardInfo = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const MaterialCardText = styled(P)`
  text-align: justify;

  span {
    font-weight: bold;
  }
`;
