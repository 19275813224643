import { useState } from "react";

import * as S from "./styles";
import InputText from "../InputText";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  label?: string;
  search?: string;
  bgColor?: string;
  options: string[];
  useSearch?: boolean;
  borderColor?: string;
  selectedIndex: number;
  onClearSearch?: () => void;
  filteredOptions?: string[];
  onSelect: (index: number) => void;
  onSearch?: (value: string) => void;
}

const Select: React.FC<IProps> = ({
  label,
  search,
  options,
  onSelect,
  onSearch,
  borderColor,
  selectedIndex,
  onClearSearch,
  filteredOptions,
  useSearch = false,
  bgColor = "transparent",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { textColor, tertiaryColor } = Theme.useTheme();

  const onClose = (val: boolean) => {
    useSearch && onSearch && onSearch("");
    useSearch && onClearSearch && onClearSearch();

    setIsOpen(val);
  };

  const onSelectHandler = (index: number) => {
    onSelect(index);

    setIsOpen(false);
  };

  const optionsFiltered =
    filteredOptions && filteredOptions.length > 0 ? filteredOptions : options;

  return (
    <S.Container
      isOpen={isOpen}
      listType={"text"}
      onClick={() => (!useSearch || (useSearch && !isOpen)) && onClose(!isOpen)}
    >
      {isOpen && <S.Backdrop onClick={() => onClose(false)} />}

      <S.Dropdown
        isOpen={isOpen}
        listType="text"
        textColor={textColor}
        closeBgColor={bgColor}
        openBgColor={tertiaryColor}
        closeBorderColor={tertiaryColor}
        openBorderColor={borderColor || tertiaryColor}
      >
        <S.Selected>
          <S.IconAndText>
            <P color={textColor} fontWeight="regular">
              {!options[selectedIndex] && label && !isOpen && label}

              {!options[selectedIndex] &&
                label &&
                isOpen &&
                !useSearch &&
                label}

              {options[selectedIndex] && !isOpen && options[selectedIndex]}

              {options[selectedIndex] &&
                isOpen &&
                !useSearch &&
                options[selectedIndex]}

              {isOpen && useSearch && (
                <InputText
                  border={false}
                  value={search || ""}
                  placeholder="Busque aqui"
                  onChange={(val) => onSearch && onSearch(val)}
                />
              )}
            </P>
          </S.IconAndText>

          <S.AheadIcon isOpen={isOpen}>
            <AvailableIcons option="chevron" color={textColor} />
          </S.AheadIcon>
        </S.Selected>

        {isOpen &&
          optionsFiltered.map((item, index) => {
            if (!useSearch && index !== selectedIndex) {
              return (
                <li onClick={() => onSelectHandler(index)} key={item}>
                  <S.IconAndText>
                    <P color={textColor} fontWeight="regular">
                      {item}
                    </P>
                  </S.IconAndText>
                </li>
              );
            } else if (useSearch) {
              return (
                <li onClick={() => onSelectHandler(index)} key={item}>
                  <S.IconAndText>
                    <P color={textColor} fontWeight="regular">
                      {item}
                    </P>
                  </S.IconAndText>
                </li>
              );
            }
          })}
      </S.Dropdown>
    </S.Container>
  );
};

export default Select;
