import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const StatementIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8145 14.1895L14.375 19.6387L11.748 17.002L12.627 16.123L14.375 17.8613L18.9355 13.3105L19.8145 14.1895ZM10 6.25H6.25V5H10V6.25ZM10 8.75H6.25V7.5H10V8.75ZM6.25 10H10V11.25H6.25V10ZM5 6.25H3.75V5H5V6.25ZM5 8.75H3.75V7.5H5V8.75ZM3.75 10H5V11.25H3.75V10ZM11.25 6.25V1.25H2.5V18.75H11.25V20H1.25V0H12.1387L17.5 5.36133V12.5L16.25 13.75V6.25H11.25ZM12.5 5H15.3613L12.5 2.13867V5Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default StatementIcon;
