import { AxiosError, AxiosResponse } from "axios";

import { IRegister } from "../types";
import { IReport } from "../types/report";
import { IOrderDetails } from "../types/order";
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY } from "../constants";

const emptyRegister: IRegister = {
  logo: "",
  mail: "",
  entity: "",
  company: "",
  username: "",
  password: "",
  companyCity: "",
  contactName: "",
  companyState: "",
  contactPhone: "",
  cpfOrCnpj: "cnpj",
  confirmPassword: "",
};

const emptyReport: IReport = {
  name: "",
  title: "",
  description: "",
  carbonCompensate: 0,
  classification: "neutral",
};

const emptyProjectOrder: IOrderDetails[] = [
  {
    label: "",
    image: "",
    pricing: 0,
    quantity: 0,
    storage: "",
    projectId: "",
    projectName: "",
    availableCredits: 0,
    shortDescription: "",
  },
];

const normalizeUsername = (value: string) => {
  const normalizedUsername = (value || "")
    .replace(/[^a-zA-Z0-9-]/g, "")
    .toLowerCase();

  return normalizedUsername;
};

const logoutUser = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);

  window.location.reload();
};

const logoutUserIfTokenInvalid = (error: unknown) => {
  if (error instanceof AxiosError) {
    const { data } = error.response as AxiosResponse<{
      code: string;
      message: string;
    }>;

    if (
      error.code === "ERR_NETWORK" ||
      (data &&
        data.code &&
        ["LGOT001", "LGOT002", "LGOT003", "LGOT004"].includes(data.code))
    )
      logoutUser();
  }
};

export {
  logoutUser,
  emptyReport,
  emptyRegister,
  emptyProjectOrder,
  normalizeUsername,
  logoutUserIfTokenInvalid,
};
