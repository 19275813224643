const DinersClubIcon: React.FC = () => {
  return (
    <svg
      width="169"
      height="134"
      viewBox="0 0 169 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.2851 133.86C135.731 134.037 168.999 104.2 168.999 67.9039C169 28.2149 135.731 0.78195 99.2851 0.79395H67.9201C31.0371 0.78095 0.680054 28.2209 0.680054 67.9029C0.680054 104.208 31.0371 134.036 67.9201 133.859H99.2851"
        fill="#006095"
      />
      <path
        d="M81.91 103.247V31.1749C96.426 36.7319 106.732 50.7579 106.757 67.2089C106.732 83.6619 96.427 97.6789 81.909 103.247M29.388 67.2089C29.42 50.7679 39.71 36.7499 54.218 31.1769V103.232C39.71 97.6639 29.42 83.6539 29.389 67.2099M68.066 6.29395C34.365 6.30395 7.05502 33.5659 7.04602 67.2089C7.05502 100.848 34.365 128.104 68.066 128.114C101.78 128.104 129.094 100.848 129.099 67.2089C129.094 33.5659 101.779 6.30495 68.066 6.29395Z"
        fill="white"
      />
    </svg>
  );
};

export default DinersClubIcon;
