import { useState } from "react";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import MoneyIcon from "../../icons/MoneyIcon";
import { IOrderDetails } from "../../../types/order";
import CertifiedIcon from "../../icons/CertifiedIcon";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  label?: string;
  selectedIndex: string[];
  options: IOrderDetails[];
  onSelect: (index: string) => void;
}

const SelectProject: React.FC<IProps> = ({
  label,
  options,
  onSelect,
  selectedIndex,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { primaryColor, textColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  return (
    <S.Container
      isOpen={isOpen}
      listType={"text"}
      backgroundColor={tertiaryColor}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen && <S.Backdrop onClick={() => setIsOpen(false)} />}

      <S.Dropdown
        isOpen={isOpen}
        listType="text"
        textColor={textColor}
        borderColor={textColor}
        backgroundColor={tertiaryColor}
      >
        <S.Selected>
          <S.IconAndText>
            <P color={textColor} fontWeight="regular">
              {label}
            </P>
          </S.IconAndText>

          <S.AheadIcon isOpen={isOpen}>
            <AvailableIcons option="chevron" color={textColor} />
          </S.AheadIcon>
        </S.Selected>

        {isOpen &&
          options.map((item) => {
            return (
              <li key={item.projectId}>
                <S.IconAndText>
                  <S.Icon
                    onClick={() => onSelect(item.projectId)}
                    color={
                      selectedIndex.includes(item.projectId)
                        ? primaryColor
                        : backgroundColor
                    }
                  >
                    <AvailableIcons option="check" color={backgroundColor} />
                  </S.Icon>

                  <S.Content>
                    <S.ProjectImage image={item.image} />

                    <S.Texts onClick={() => onSelect(item.projectId)}>
                      <P fontWeight="bold" color={textColor}>
                        {`${item.label} - ${item.projectName}`}
                      </P>

                      <S.TextsDetails>{item.shortDescription}</S.TextsDetails>

                      <S.TextsSummary>
                        <S.SummaryBox>
                          <S.SummaryIcon>
                            <CertifiedIcon color={textColor} />
                          </S.SummaryIcon>

                          <P color={textColor} fontWeight="bold">
                            {item.storage} storage
                          </P>
                        </S.SummaryBox>

                        <S.SummaryBox>
                          <S.SummaryIcon>
                            <MoneyIcon color={textColor} />
                          </S.SummaryIcon>

                          <S.Pricing color={textColor} fontWeight="bold">
                            R${" "}
                            {item.pricing.toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                            })}
                          </S.Pricing>
                        </S.SummaryBox>
                      </S.TextsSummary>
                    </S.Texts>

                    <S.SeeMore
                      target="_blank"
                      textColor={textColor}
                      rel="noreferrer noopener"
                      href={`/projetos/${item.projectId}`}
                    >
                      Ver detalhes
                    </S.SeeMore>
                  </S.Content>
                </S.IconAndText>
              </li>
            );
          })}
      </S.Dropdown>
    </S.Container>
  );
};

export default SelectProject;
