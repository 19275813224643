import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 20px;
  display: flex;
  text-align: justify;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: fit-content;
  transform: rotate(180deg);
  justify-content: flex-start;
`;

export const Details = styled.div`
  gap: 12px;
  display: flex;

  @media (max-width: 695px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 160px));
  }
`;

type DetailProps = {
  backgroundColor: string;
};

export const Detail = styled.div<DetailProps>`
  ${({ backgroundColor }) => css`
    gap: 8px;
    display: flex;
    text-align: left;
    padding: 8px 16px;
    width: fit-content;
    border-radius: 16px;
    align-items: center;
    background-color: ${backgroundColor};
  `}
`;

export const DetailIcon = styled.div`
  width: 28px;
  height: 28px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Description = styled.div`
  gap: 16px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

type SummaryProps = {
  borderColor: string;
};

export const Summary = styled.div<SummaryProps>`
  ${({ borderColor }) => css`
    gap: 20px;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    border-bottom: 1px solid ${borderColor};
  `}
`;

export const Topics = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Topic = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

type TopicImageProps = {
  image: string;
};

export const TopicImage = styled.div<TopicImageProps>`
  ${({ image }) => css`
    width: 260px;
    height: 160px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    background: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 860px) {
      align-self: center;
    }
  `}
`;

export const ODS = styled.div`
  gap: 16px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

export const ODSImages = styled.div`
  gap: 4px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 18fr));

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

type ODSIMageProps = {
  ods: string;
  size: string;
};

export const ODSImage = styled.div<ODSIMageProps>`
  ${({ ods, size }) => css`
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url(${ods});
    background-size: ${size};
    background-position: center;
    background-repeat: no-repeat;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 600px) {
      justify-self: center;
    }
  `}
`;
