import * as S from "./styles";
import { Theme } from "../../../hooks";

interface IProps {
  currentQuestion: number;
  numberOfQuestions: number;
}

const CalculatorProgressBar: React.FC<IProps> = ({
  currentQuestion,
  numberOfQuestions,
}) => {
  const { primaryColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.ProgressBar backgroundColor={tertiaryColor}>
      <S.Progress
        backgroundColor={primaryColor}
        progress={(100 * currentQuestion) / numberOfQuestions}
      />
    </S.ProgressBar>
  );
};

export default CalculatorProgressBar;
