import styled, { css } from "styled-components";

type ContainerProps = {
  backgroundColor: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ backgroundColor }) => css`
    top: 0;
    gap: 24px;
    z-index: 100;
    height: 100%;
    display: flex;
    position: fixed;
    min-height: 100vh;
    padding: 32px 40px;
    flex-direction: column;
    background-color: ${backgroundColor};

    @media (max-width: 1160px) {
      display: none;
    }
  `}
`;

export const Header = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    max-width: 100px;
  }
`;

type LogoProps = {
  logo: string;
};

export const Logo = styled.div<LogoProps>`
  ${({ logo }) => css`
    width: 200px;
    height: 60px;
    background-image: url(${logo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

export const Sections = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type SectionProps = {
  isSelected?: boolean;
};

export const Section = styled.div<SectionProps>`
  ${({ isSelected }) => css`
    gap: 12px;
    width: 100%;
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 8px 12px;
    align-items: center;
    border-radius: 12px;
    background-color: ${isSelected ? "#1818184D" : "transparent"};

    :hover {
      background-color: #1818181a;
    }
  `}
`;

type CalculatorSectionProps = {
  borderColor: string;
  backgroundColor: string;
};

export const CalculatorSections = styled(Sections)<CalculatorSectionProps>`
  ${({ backgroundColor, borderColor }) => css`
    top: 132px;
    left: 320px;
    width: 240px;
    padding: 8px 12px;
    position: absolute;
    border-radius: 12px;
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
  `}
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }
`;
