import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import P from "../../atoms/Typography/P";
import { TIcon, TSections } from "../../../types";
import AvailableIcons from "../../atoms/AvailableIcons";

const sections = {
  projects: "projetos",
  statement: [
    { icon: "statement", label: "Extrato", navigate: "extrato" },
    { icon: "analytics", label: "Dashboard", navigate: "dashboard" },
    { icon: "megaphone", label: "Report", navigate: "report" },
  ],
  calculator: [
    { icon: "calculator", label: "Calculadoras", navigate: "calculadora" },
    { icon: "calculator", label: "Cálculo atual", navigate: "calculo-atual" },
  ],
  content: [
    { icon: "folder", label: "Materiais e conteúdos", navigate: "materiais" },
    { icon: "seal", label: "Certificados e selos", navigate: "certificados" },
  ],
  profile: [
    { icon: "person", label: "Meu perfil", navigate: "perfil" },
    { icon: "card", label: "Meus cartões", navigate: "cartoes" },
  ],
};

interface IProps {
  selected: TSections;
  optionOpen: boolean;
  setOptionSelected: () => void;
  setSelected: (index: TSections) => void;
}

const MobileMenu: React.FC<IProps> = ({
  selected,
  optionOpen,
  setSelected,
  setOptionSelected,
}) => {
  const navigate = useNavigate();

  const { textColor, tertiaryColor } = Theme.useTheme();

  const onClickHandler = (section: TSections) => {
    setSelected(section);
    setOptionSelected();

    if (section === "projects") navigate("/projetos");
  };

  return (
    <S.Container backgroundColor={tertiaryColor}>
      {optionOpen && <S.Backdrop onClick={setOptionSelected} />}

      <S.Sections>
        <S.Section
          key="projects"
          isSelected={selected === "projects"}
          onClick={() => onClickHandler("projects")}
        >
          <S.Icon>
            <AvailableIcons option="globe" color={textColor} />
          </S.Icon>
        </S.Section>

        <S.Section
          key="statement"
          isSelected={selected === "statement"}
          onClick={() => onClickHandler("statement")}
        >
          <S.Icon>
            <AvailableIcons option="statement" color={textColor} />
          </S.Icon>

          {optionOpen && selected === "statement" && (
            <S.SectionOptions
              borderColor={textColor}
              backgroundColor={tertiaryColor}
            >
              {sections["statement"].map((item, index) => (
                <S.Option
                  key={`${item.label}#${index}`}
                  onClick={() => navigate(`/${item.navigate}`)}
                  isLast={index === sections["statement"].length - 1}
                >
                  <S.OptionIcon>
                    <AvailableIcons
                      color={textColor}
                      option={item.icon as TIcon}
                    />
                  </S.OptionIcon>

                  <P color={textColor}>{item.label}</P>
                </S.Option>
              ))}
            </S.SectionOptions>
          )}
        </S.Section>

        <S.Section
          key="calculator"
          isSelected={selected === "calculator"}
          onClick={() => onClickHandler("calculator")}
        >
          <S.Icon>
            <AvailableIcons option="calculator" color={textColor} />
          </S.Icon>

          {optionOpen && selected === "calculator" && (
            <S.SectionOptions
              borderColor={textColor}
              backgroundColor={tertiaryColor}
            >
              {sections["calculator"].map((item, index) => (
                <S.Option
                  key={`${item.label}#${index}`}
                  onClick={() => navigate(`/${item.navigate}`)}
                  isLast={index === sections["calculator"].length - 1}
                >
                  <S.OptionIcon>
                    <AvailableIcons
                      color={textColor}
                      option={item.icon as TIcon}
                    />
                  </S.OptionIcon>

                  <P color={textColor}>{item.label}</P>
                </S.Option>
              ))}
            </S.SectionOptions>
          )}
        </S.Section>

        <S.Section
          key="content"
          onClick={() => onClickHandler("content")}
          isSelected={selected === "content"}
        >
          <S.Icon>
            <AvailableIcons option="folder" color={textColor} />
          </S.Icon>

          {optionOpen && selected === "content" && (
            <S.SectionOptions
              borderColor={textColor}
              backgroundColor={tertiaryColor}
            >
              {sections["content"].map((item, index) => (
                <S.Option
                  key={`${item.label}#${index}`}
                  onClick={() => navigate(`/${item.navigate}`)}
                  isLast={index === sections["content"].length - 1}
                >
                  <S.OptionIcon>
                    <AvailableIcons
                      color={textColor}
                      option={item.icon as TIcon}
                    />
                  </S.OptionIcon>

                  <P color={textColor}>{item.label}</P>
                </S.Option>
              ))}
            </S.SectionOptions>
          )}
        </S.Section>

        <S.Section
          key="profile"
          isSelected={selected === "profile"}
          onClick={() => onClickHandler("profile")}
        >
          <S.Icon>
            <AvailableIcons option="person" color={textColor} />
          </S.Icon>

          {optionOpen && selected === "profile" && (
            <S.SectionOptions
              borderColor={textColor}
              backgroundColor={tertiaryColor}
            >
              {sections["profile"].map((item, index) => (
                <S.Option
                  key={`${item.label}#${index}`}
                  onClick={() => navigate(`/${item.navigate}`)}
                  isLast={index === sections["profile"].length - 1}
                >
                  <S.OptionIcon>
                    <AvailableIcons
                      color={textColor}
                      option={item.icon as TIcon}
                    />
                  </S.OptionIcon>

                  <P color={textColor}>{item.label}</P>
                </S.Option>
              ))}
            </S.SectionOptions>
          )}
        </S.Section>
      </S.Sections>
    </S.Container>
  );
};

export default MobileMenu;
