import styled from "styled-components";

type PageProps = {
  backgroundColor: string;
};

export const Page = styled.div<PageProps>`
  width: 100%;
  display: flex;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  padding-bottom: 120px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  @media (max-width: 1160px) {
    justify-content: center;
  }
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
  background-color: #00000000;
`;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
  margin-left: 340px;
  flex-direction: column;
  padding: 48px 48px 40px;

  @media (max-width: 1160px) {
    margin-left: 0;
    margin-top: 80px;
  }

  @media (max-width: 600px) {
    padding: 48px 32px 40px;
  }
`;
