import styled from "styled-components";

type H2Props = {
  color: string;
  fontWeight?: string;
};

const H2 = styled.h2<H2Props>`
  font-size: 1.6rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`;

export default H2;
