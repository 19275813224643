import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  max-width: 500px;
  align-self: center;
  justify-content: center;

  @media (max-width: 1080px) {
    max-width: 500px;
  }

  @media (max-width: 630px) {
    gap: 8px;
  }

  @media (max-width: 490px) {
    gap: 0;
  }

  @media (max-width: 450px) {
    transform: scale(0.85);
  }
`;

export const IconAndLabel = styled.div`
  gap: 8px;
  width: 116px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 630px) {
    transform: scale(0.8);
  }

  @media (max-width: 450px) {
    transform: scale(0.7);
  }
`;

type IconProps = {
  color: string;
};

export const Icon = styled.div<IconProps>`
  ${({ color }) => css`
    width: 80px;
    height: 80px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid ${color};

    svg {
      width: 50%;
      height: 50%;
    }
  `}
`;

export const Separators = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 32px;
  align-self: flex-start;

  @media (max-width: 630px) {
    gap: 4px;
    transform: scale(0.8);
  }
`;

type SeparatorProps = {
  color: string;
};

export const Separator = styled.div<SeparatorProps>`
  ${({ color }) => css`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${color};

    @media (max-width: 630px) {
      width: 8px;
      height: 8px;
    }
  `}
`;
