const EloIcon: React.FC = () => {
  return (
    <svg
      width="512"
      height="197"
      viewBox="0 0 512 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_550_2)">
        <path
          d="M79.817 43.344C85.7186 41.3781 91.8996 40.3805 98.12 40.39C126.038 40.39 149.347 60.22 154.686 86.578L194.262 78.505C185.179 33.711 145.594 4.25471e-05 98.12 4.25471e-05C87.5473 -0.0098119 77.0427 1.69221 67.014 5.04004L79.817 43.344Z"
          fill="#FFCC00"
        />
        <path
          d="M33.111 171.604L59.874 141.346C47.928 130.762 40.39 115.316 40.39 98.1071C40.39 80.9111 47.917 65.4721 59.856 54.9011L33.091 24.6401C12.802 42.6141 0 68.8691 0 98.1071C0 127.363 12.807 153.626 33.111 171.604Z"
          fill="#00A0DE"
        />
        <path
          d="M154.676 109.69C149.314 136.035 126.03 155.827 98.12 155.827C91.715 155.827 85.548 154.794 79.8 152.862L66.979 191.182C76.758 194.446 87.229 196.214 98.12 196.214C145.548 196.214 185.129 162.559 194.246 117.806L154.676 109.69Z"
          fill="#EC3413"
        />
        <path
          d="M228.87 142.622C227.573 140.522 225.81 137.162 224.75 134.69C218.483 120.14 218.183 105.083 223.476 90.6291C229.295 74.7771 240.411 62.6411 254.774 56.4621C272.831 48.6911 292.802 50.2231 310.108 60.4921C321.102 66.7991 328.896 76.5371 334.814 90.3051L335.363 91.6441L336.387 94.3041C336.552 94.7331 336.714 95.1501 336.876 95.5501L228.87 142.622ZM264.935 79.8191C252.112 85.3301 245.502 97.3591 246.86 111.463L301.18 88.0851C291.839 77.1061 279.681 73.4681 264.935 79.8191ZM328.949 144.723L307.953 130.685L307.923 130.716L306.798 129.958C303.558 135.218 298.499 139.478 292.118 142.245C279.976 147.525 268.724 146.168 260.644 139.081L259.901 140.211C259.893 140.198 259.891 140.188 259.877 140.188L246.097 160.805C249.525 163.187 253.196 165.198 257.049 166.805C272.272 173.128 287.847 172.835 303.191 166.162C314.29 161.352 322.998 154.018 328.949 144.722V144.723ZM374.627 26.0991V140.719L392.447 147.941L382.321 171.568L362.651 163.377C358.235 161.466 355.231 158.539 352.955 155.237C350.78 151.871 349.153 147.251 349.153 141.031V26.0991H374.627ZM420.792 111.519C420.802 101.759 425.112 93.0061 431.932 87.0571L413.649 66.6711C401.249 77.6311 393.439 93.6471 393.425 111.491C393.405 129.341 401.203 145.373 413.59 156.362L431.852 135.956C425.065 129.984 420.784 121.257 420.792 111.519ZM453.276 144.052C449.676 144.042 446.209 143.447 442.976 142.371L434.245 168.331C440.37 170.384 446.787 171.433 453.247 171.437C482.196 171.465 506.368 150.925 511.969 123.62L485.132 118.14C482.08 132.94 468.975 144.062 453.276 144.052ZM453.356 51.6631C446.904 51.6504 440.493 52.6824 434.371 54.7191L443.026 80.7031C446.35 79.6031 449.829 79.042 453.33 79.0411C469.066 79.0561 482.18 90.2441 485.16 105.086L512 99.6421C506.476 72.2971 482.328 51.6811 453.356 51.6631Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_550_2">
          <rect width="512" height="197" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EloIcon;
