// export const backgroundColors = ["#000000", "#181818", "#1d232e", "#090223"];
// export const backgroundLabelColors = [
//   "Preto",
//   "Cinza",
//   "Verde Escuro",
//   "Azul Escuro",
// ];

// export const primaryColors = [
//   "#DDFF66",
//   "#FF9960",
//   "#00FFB2",
//   "#A0D7FF",
//   "#FFA8BD",
//   "#FAFAFA",
//   "#A091FF",
//   "#FFC700",
// ];
// export const primaryLabelColors = [
//   "Verde",
//   "Laranja",
//   "Verde Claro",
//   "Azul Claro",
//   "Rosa",
//   "Branco",
//   "Lilás",
//   "Amarelo",
// ];

export const defaultPrimaryColor = "#226d30";

export const defaultSecondaryColor = "#add13f";

export const defaultTertiaryColor = "#dee2df";
