import axios from "axios";

import { IProject } from "../../types/projects";
import { IOrderDetails } from "../../types/order";
import { logoutUserIfTokenInvalid } from "../../utils";

const { REACT_APP_PROJECTS_API_URL } = process.env;

const getProjects = async (
  entity: string,
  token: string
): Promise<IProject[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/projects/${entity}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.projects;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os projetos disponíveis");
  }
};

const getProject = async (
  entity: string,
  id: string,
  token: string
): Promise<IProject> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/project/${entity}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.project;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os dados do projeto");
  }
};

const getProjectsOrder = async (
  entity: string,
  token: string
): Promise<IOrderDetails[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_PROJECTS_API_URL}/order/${entity}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.projects;
  } catch (error) {
    logoutUserIfTokenInvalid(error);
    throw new Error("Houve um erro ao obter os projetos disponíveis");
  }
};

export default {
  getProject,
  getProjects,
  getProjectsOrder,
};
