import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const DownloadIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4375 17.5C2.85734 17.5 2.30094 17.2695 1.8907 16.8593C1.48047 16.4491 1.25 15.8927 1.25 15.3125V12.1875C1.25 11.9389 1.34877 11.7004 1.52459 11.5246C1.7004 11.3488 1.93886 11.25 2.1875 11.25C2.43614 11.25 2.6746 11.3488 2.85041 11.5246C3.02623 11.7004 3.125 11.9389 3.125 12.1875V15.3125C3.125 15.485 3.265 15.625 3.4375 15.625H16.5625C16.6454 15.625 16.7249 15.5921 16.7835 15.5335C16.8421 15.4749 16.875 15.3954 16.875 15.3125V12.1875C16.875 11.9389 16.9738 11.7004 17.1496 11.5246C17.3254 11.3488 17.5639 11.25 17.8125 11.25C18.0611 11.25 18.2996 11.3488 18.4754 11.5246C18.6512 11.7004 18.75 11.9389 18.75 12.1875V15.3125C18.75 15.8927 18.5195 16.4491 18.1093 16.8593C17.6991 17.2695 17.1427 17.5 16.5625 17.5H3.4375Z"
        fill={color || primaryColor}
      />
      <path
        d="M9.06253 9.61125V2.5C9.06253 2.25136 9.1613 2.0129 9.33711 1.83709C9.51293 1.66127 9.75139 1.5625 10 1.5625C10.2487 1.5625 10.4871 1.66127 10.6629 1.83709C10.8388 2.0129 10.9375 2.25136 10.9375 2.5V9.61125L13.4 7.15C13.487 7.063 13.5903 6.99399 13.704 6.9469C13.8177 6.89982 13.9395 6.87558 14.0625 6.87558C14.1856 6.87558 14.3074 6.89982 14.4211 6.9469C14.5347 6.99399 14.638 7.063 14.725 7.15C14.812 7.237 14.881 7.34029 14.9281 7.45396C14.9752 7.56763 14.9994 7.68946 14.9994 7.8125C14.9994 7.93554 14.9752 8.05737 14.9281 8.17104C14.881 8.28471 14.812 8.388 14.725 8.475L10.6625 12.5375C10.5756 12.6246 10.4723 12.6937 10.3586 12.7408C10.2449 12.7879 10.1231 12.8122 10 12.8122C9.87696 12.8122 9.75511 12.7879 9.64143 12.7408C9.52775 12.6937 9.42448 12.6246 9.33753 12.5375L5.27503 8.475C5.18803 8.388 5.11901 8.28471 5.07193 8.17104C5.02484 8.05737 5.00061 7.93554 5.00061 7.8125C5.00061 7.68946 5.02484 7.56763 5.07193 7.45396C5.11901 7.34029 5.18803 7.237 5.27503 7.15C5.36203 7.063 5.46531 6.99399 5.57898 6.9469C5.69266 6.89982 5.81449 6.87558 5.93753 6.87558C6.06056 6.87558 6.1824 6.89982 6.29607 6.9469C6.40974 6.99399 6.51303 7.063 6.60003 7.15L9.06253 9.61125Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default DownloadIcon;
