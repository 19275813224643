import styled, { css } from "styled-components";

type HeaderProps = {
  backgroundColor: string;
};

export const Header = styled.div<HeaderProps>`
  ${({ backgroundColor }) => css`
    display: none;

    @media (max-width: 1160px) {
      gap: 40px;
      width: 90%;
      z-index: 110;
      display: flex;
      position: fixed;
      margin: 20px 100px;
      padding: 16px 40px;
      align-items: center;
      border-radius: 60px;
      justify-content: space-between;
      background-color: ${backgroundColor};

      button {
        max-width: 100px;
      }
    }
  `}
`;

type LogoProps = {
  logo: string;
};

export const Logo = styled.div<LogoProps>`
  ${({ logo }) => css`
    width: 180px;
    height: 48px;
    background-image: url(${logo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;
