import * as S from "./styles";
import {
  denormalizeNumber,
  normalizeSlicerNumber,
} from "../../../utils/numbers";
import { Theme } from "../../../hooks";
import { TUnits } from "../../../types/adminCalculators";

interface IProps {
  type: TUnits;
  value: string;
  maxValue: number;
  minValue?: number;
  onChange: (val: string) => void;
}

const Slicer: React.FC<IProps> = ({
  type,
  value,
  maxValue,
  onChange,
  minValue = 0,
}) => {
  const { primaryColor, tertiaryColor } = Theme.useTheme();

  const numberValue = denormalizeNumber(value, type);

  const barMaxValue = Math.max(maxValue, numberValue);

  const onChangeHandler = (val: number) => {
    const normalizeValue = normalizeSlicerNumber(val, type);

    onChange(normalizeValue);
  };

  return (
    <S.Container>
      <S.Bar
        type="range"
        min={minValue}
        max={barMaxValue}
        value={numberValue}
        primaryColor={primaryColor}
        tertiaryColor={tertiaryColor}
        percentage={(numberValue / barMaxValue) * 100}
        onChange={(e) => onChangeHandler(+e.target.value)}
      />
    </S.Container>
  );
};

export default Slicer;
