import styled from "styled-components";

export const HeaderAndProgress = styled.div`
  gap: 20px;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;

  @media (max-width: 640px) {
    gap: 12px;
    margin-bottom: 20px;
  }

  @media (max-width: 450px) {
    gap: 4px;
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
`;

export const FormBox = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LocationBox = styled.div`
  gap: 40px;
  width: 60%;
  display: flex;
  padding-left: 6px;

  @media (max-width: 1400px) {
    width: 80%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 660px) {
    gap: 28px;
    flex-direction: column;
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 60px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const Address = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const AddressBox = styled.div`
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 660px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const CityBox = styled.div`
  display: flex;
  max-width: 60%;
  padding-left: 6px;

  @media (max-width: 660px) {
    width: 100%;
    max-width: 100%;
  }
`;
