import styled, { css } from "styled-components";

type ContentProps = {
  isOpen: boolean;
};

export const Content = styled.div<ContentProps>`
  ${({ isOpen }) => css`
    position: relative;
    align-items: center;
    justify-content: center;
    display: ${isOpen ? "flex" : "none"};
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
  position: fixed;
  background-color: #00000080;
`;

export const Modal = styled.div`
  top: 40vh;
  gap: 32px;
  left: 40vw;
  z-index: 200;
  width: 300px;
  display: flex;
  padding: 20px;
  position: fixed;
  text-align: center;
  border-radius: 12px;
  flex-direction: column;
  background-color: #fafafa;

  @media (max-width: 1160px) {
    left: calc(50% - 150px);
    align-self: center;
  }
`;

export const Buttons = styled.div`
  gap: 20px;
  display: flex;
  justify-content: space-between;
`;
