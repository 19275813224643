import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import * as C from "../../../styles";
import {
  LOCAL_STORAGE_CREDITS_KEY,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../../../../../constants";
import {
  emptyCrop,
  emptyAgroCalculatorForm,
} from "../../../../../../utils/calculators";
import {
  ICrop,
  IHerd,
  IAgroCalculatorForm,
} from "../../../../../../types/agroCalculator";
import Questions from "./components/Questions";
import { maskDate } from "../../../../../../utils/dates";
import { Calculators } from "../../../../../../services";
import Button from "../../../../../../components/atoms/Button";
import P from "../../../../../../components/atoms/Typography/P";
import H1 from "../../../../../../components/atoms/Typography/H1";
import H2 from "../../../../../../components/atoms/Typography/H2";
import H3 from "../../../../../../components/atoms/Typography/H3";
import H5 from "../../../../../../components/atoms/Typography/H5";
import CropCard from "../../../../../../components/molecules/CropCard";
import { Snackbar, Loading, Auth, Theme } from "../../../../../../hooks";
import { transportUnitsObj } from "../../../../../../constants/calculators";
import { ICalculatorResult } from "../../../../../../types/adminCalculators";
import ExcludeModal from "../../../../../../components/molecules/ExcludeModal";
import EditCropModal from "../../../../../../components/molecules/EditCropModal";
import ChartAndIndicators from "../../../../../../components/organisms/ChartAndIndicators";
import AgroCalculatorStep from "../../../../../../components/molecules/AgroCalculatorStep";
import CalculatorProgressBar from "../../../../../../components/molecules/CalculatorProgressBar";

const validateCropForm = (data: ICrop): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.crop)
    errors.crop = [...(errors.crop || []), "Tipo de cultura é obrigatório"];

  if (!data.plantingDate && data.crop !== "livestock" && data.crop !== "cane")
    errors.plantingDate = [
      ...(errors.plantingDate || []),
      "Data de plantio é obrigatória",
    ];

  if (data.plantingDate) {
    if (data.plantingDate.replace(/[^0-9]/g, "").length !== 8) {
      errors.plantingDate = [
        ...(errors.plantingDate || []),
        "Verifique a data inserida",
      ];
    }

    const day = +data.plantingDate.substring(0, 2);
    const month = +data.plantingDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.plantingDate = [
        ...(errors.plantingDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  if (!data.harvestDate && data.crop !== "livestock")
    errors.harvestDate = [
      ...(errors.harvestDate || []),
      "Data de colheita é obrigatória",
    ];

  if (data.harvestDate) {
    if (data.harvestDate.replace(/[^0-9]/g, "").length !== 8) {
      errors.harvestDate = [
        ...(errors.harvestDate || []),
        "Verifique a data inserida",
      ];
    }

    const day = +data.harvestDate.substring(0, 2);
    const month = +data.harvestDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.harvestDate = [
        ...(errors.harvestDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  if (data.plantingDate && data.harvestDate) {
    const plantingDay = +data.plantingDate.substring(0, 2);
    const plantingMonth = +data.plantingDate.substring(2, 4);
    const plantingYear = +data.plantingDate.substring(4);

    const plantingDate = new Date(
      +plantingYear,
      +plantingMonth - 1,
      +plantingDay
    ).getTime();

    const harvestDay = +data.harvestDate.substring(0, 2);
    const harvestMonth = +data.harvestDate.substring(2, 4);
    const harvestYear = +data.harvestDate.substring(4);

    const harvestDate = new Date(
      +harvestYear,
      +harvestMonth - 1,
      +harvestDay
    ).getTime();

    if (harvestDate <= plantingDate) {
      errors.harvestDate = [
        ...(errors.harvestDate || []),
        "Data de colheita anterior ao plantio",
      ];
    }
  }

  if (!data.cycle && data.crop === "cane")
    errors.cycle = [...(errors.cycle || []), "Ciclo é obrigatório"];

  return errors;
};

const validateHerdForm = (data: IHerd): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.type)
    errors.type = [...(errors.type || []), "Tipo de rebanho é obrigatório"];

  if (!data.entryDate)
    errors.entryDate = [
      ...(errors.entryDate || []),
      "Data de entrada é obrigatória",
    ];

  if (data.entryDate) {
    const day = +data.entryDate.substring(0, 2);
    const month = +data.entryDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.entryDate = [
        ...(errors.entryDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  if (!data.departureDate)
    errors.departureDate = [
      ...(errors.departureDate || []),
      "Data de saída é obrigatória",
    ];

  if (data.departureDate) {
    const day = +data.departureDate.substring(0, 2);
    const month = +data.departureDate.substring(2, 4);

    if (day < 1 || day > 31 || month < 1 || month > 12) {
      errors.departureDate = [
        ...(errors.departureDate || []),
        "Verifique a data inserida",
      ];
    }
  }

  return errors;
};

interface IProps {
  step?: "crops" | "facilities";
  filledForm?: IAgroCalculatorForm;
}

const Calculator: React.FC<IProps> = ({
  step = "crops",
  filledForm = emptyAgroCalculatorForm,
}) => {
  const [calculatorForm, setCalculatorForm] =
    useState<IAgroCalculatorForm>(filledForm);
  const [herds, setHerds] = useState<IHerd[]>();
  const [currHerd, setCurrHerd] = useState<number>(0);
  const [editCrop, setEditCrop] = useState<number>(-1);
  const [aircraft, setAircraft] = useState<boolean>(true);
  const [currCrop, setCurrCrop] = useState<ICrop>(emptyCrop);
  const [excludeCrop, setExcludeCrop] = useState<boolean>(false);
  const [excludeHerd, setExcludeHerd] = useState<boolean>(false);
  const [solarEnergy, setSolarEnergy] = useState<boolean>(false);
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [editCropModal, setEditCropModal] = useState<boolean>(false);
  const [excludeCropIndex, setExcludeCropIndex] = useState<number>(0);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [formStep, setFormStep] = useState<"crops" | "facilities">(step);
  const [calculatorResult, setCalculatorResult] = useState<ICalculatorResult>();

  const questionsTop = useRef<HTMLDivElement>(null);

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  const navigate = useNavigate();

  const onChangeCropHandler = (
    key: keyof ICrop,
    value: string | number | boolean
  ) => {
    setCurrCrop((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  const onChangeHerdHandler = (
    key: keyof IHerd,
    index: number,
    value: string | number | boolean
  ) => {
    setHerds((curr) => {
      if (!curr && currHerd > 0) return curr;

      if (!curr && currHerd === 0) {
        return [
          {
            ...(curr || {}),
            [key]: value,
          },
        ];
      }

      if (curr) {
        const currentHerd = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            return {
              ...currentHerd,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onChangeHandler = (
    key: keyof IAgroCalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (key === "dieselUnit" || key === "aircraftUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "l",
        };
      }

      if (key === "energyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onBuyCredits = () => {
    localStorage.setItem(
      LOCAL_STORAGE_CREDITS_KEY,
      Math.ceil(calculatorResult?.totalEmissions || 0).toString()
    );

    localStorage.setItem(LOCAL_STORAGE_CALCULATOR_KEY, "agro");

    localStorage.setItem(
      LOCAL_STORAGE_CALCULATION_ID_KEY,
      calculatorResult?.id || ""
    );

    navigate("/comprar");
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setFormStep("crops");
    setCalculatorForm(emptyAgroCalculatorForm);
    setErrors({});
    setCurrCrop(emptyCrop);
    setAircraft(true);
    setSolarEnergy(false);
    setQuestionNumber(1);
  };

  const onAddCrop = () => {
    const currErrors = validateCropForm(currCrop);

    if (currErrors && Object.keys(currErrors).length) {
      setQuestionNumber(1);
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      questionsTop.current?.scrollIntoView({ behavior: "smooth" });
      return alert(
        "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
      );
    }

    setCalculatorForm((curr) => ({
      ...curr,
      crops: [
        ...(curr.crops || []),
        {
          ...currCrop,
          harvestDate: maskDate(currCrop.harvestDate || ""),
          plantingDate: maskDate(currCrop.plantingDate || ""),
          herd: herds?.map((item) => ({
            ...item,
            entryDate: maskDate(item.entryDate || ""),
            departureDate: maskDate(item.departureDate || ""),
          })),
        },
      ],
    }));

    setCurrCrop(emptyCrop);
    setErrors({});
    setHerds(undefined);
    setQuestionNumber(1);

    questionsTop.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onGoToFacilities = () => {
    const currErrors = validateCropForm(currCrop);

    if (currErrors && Object.keys(currErrors).length) {
      setQuestionNumber(1);
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      questionsTop.current?.scrollIntoView({ behavior: "smooth" });
      return alert(
        "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
      );
    }

    setCalculatorForm((curr) => ({
      ...curr,
      crops: [
        ...(curr.crops || []),
        {
          ...currCrop,
          harvestDate: maskDate(currCrop.harvestDate || ""),
          plantingDate: maskDate(currCrop.plantingDate || ""),
          herd: herds?.map((item) => ({
            ...item,
            entryDate: maskDate(item.entryDate || ""),
            departureDate: maskDate(item.departureDate || ""),
          })),
        },
      ],
    }));

    setFormStep("facilities");
    setErrors({});
    setHerds(undefined);
    setQuestionNumber(1);

    questionsTop.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onRemoveCrop = (index: number) => {
    setCalculatorForm((curr) => {
      const newData = [...(curr.crops || [])];

      newData.splice(index, 1);

      return { ...curr, crops: newData };
    });

    setExcludeCrop(false);
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const onBackCrop = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setFormStep("crops");
    setQuestionNumber(1);
  };

  const onEditCrop = (index: number) => {
    setCurrCrop((curr) =>
      calculatorForm.crops ? calculatorForm.crops[index] : curr
    );

    setCalculatorForm((curr) => {
      if (!curr) return curr;

      const newCrops = curr.crops?.filter((item, indexItem) => {
        if (indexItem !== index) return item;
      });

      return {
        ...curr,
        crops: newCrops,
      } as IAgroCalculatorForm;
    });

    setFormStep("crops");

    setEditCropModal(false);
  };

  const onExcludeCropCard = (index: number) => {
    setExcludeCropIndex(index);
    setExcludeCrop(true);
  };

  const onPreviousHerd = (index: number) => {
    setCurrHerd(index);
  };

  const onNextHerd = (index: number) => {
    setCurrHerd(index);
  };

  const onAddHerd = () => {
    const currErrors = validateHerdForm((herds && herds[currHerd]) || {});

    if (currErrors && Object.keys(currErrors).length) {
      setErrors(currErrors);
      window.scrollTo({ top: 0, behavior: "smooth" });
      questionsTop.current?.scrollIntoView({ behavior: "smooth" });
      return alert(
        "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
      );
    }
    setErrors({});

    setHerds((curr) => [...(curr || []), {}]);

    setCurrHerd(herds?.length || 1);
  };

  const onRemoveHerd = (index: number) => {
    setHerds((curr) => {
      if (!curr) return;

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludeHerd(false);

    if (currHerd === 0) return;

    setCurrHerd((curr) => curr - 1);
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const result = await Calculators.sendAgroInformation(
        {
          ...calculatorForm,
          userId: user.id || "",
          cpf: user.cpf || undefined,
          cnpj: user.cnpj || undefined,
          contactMail: user.mail || "",
          producerName: user.company || "",
          cpfOrCnpj: user.cpfOrCnpj || "cnpj",
          contactName: user.contactName || "",
          producerCity: user.companyCity || "",
          contactPhone: user.contactPhone || "",
          producerState: user.companyState || "",
          dieselUnit:
            calculatorForm.dieselExpenses && !calculatorForm.dieselUnit
              ? "l"
              : calculatorForm.dieselUnit,
          aircraftUnit:
            calculatorForm.aircraftExpenses && !calculatorForm.aircraftUnit
              ? "l"
              : calculatorForm.aircraftUnit,
          energyUnit:
            calculatorForm.energyExpenses && !calculatorForm.energyUnit
              ? "KWh"
              : calculatorForm.energyUnit,
        },
        token
      );

      setCalculatorResult(result);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const booleanOptions = ["Sim", "Não"];

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  return (
    <C.Container>
      <C.TitleAndProgress ref={questionsTop}>
        <C.TitleAndDescription>
          <H1 color={textColor} fontWeight="bold">
            Calculadora - Agro
          </H1>

          {!calculatorResult && (
            <H5 color={textColor}>
              Detalhe sua operação respondendo às perguntas abaixo,
              primeiramente sobre as culturas e, depois, sobre a propriedade, e
              descubra sua pegada mensal de carbono
            </H5>
          )}
        </C.TitleAndDescription>

        {!calculatorResult && <AgroCalculatorStep formStep={formStep} />}

        {!calculatorResult && (
          <CalculatorProgressBar
            currentQuestion={questionNumber}
            numberOfQuestions={formStep === "crops" ? 3 : 4}
          />
        )}
      </C.TitleAndProgress>

      <S.Content resultBox={!!calculatorResult}>
        <S.CropCards>
          {calculatorForm.crops && calculatorForm.crops.length > 0 && (
            <P color={textColor}>
              Caso queira editar uma cultura já adicionada, clique no lápis do
              card abaixo:
            </P>
          )}

          {!calculatorResult &&
            calculatorForm.crops &&
            calculatorForm.crops.length > 0 &&
            calculatorForm.crops.map((curr, index) => (
              <S.CropCardBox key={`${curr.crop}#${index}`}>
                <CropCard
                  herds={curr.herd}
                  crop={curr.crop || ""}
                  harvestDate={curr.harvestDate || ""}
                  plantingDate={curr.plantingDate || ""}
                  onRemove={() => onExcludeCropCard(index)}
                  onEdit={() => {
                    setEditCrop(index);
                    setEditCropModal(true);
                  }}
                />
              </S.CropCardBox>
            ))}
        </S.CropCards>

        {calculatorResult && (
          <C.ChartResult>
            <H2 color={textColor} fontWeight="600">
              A pegada mensal de carbono de sua propriedade é:
            </H2>

            <H2 color={textColor}>
              {calculatorResult.totalEmissions.toFixed(2).replace(".", ",")}{" "}
              toneladas de CO<sub>2</sub>e
            </H2>

            <H3 color={textColor}>
              Clique abaixo para compensar suas emissões
            </H3>

            <Button
              variant="solid"
              fontWeight="bold"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => onBuyCredits()}
            >
              Compre créditos
            </Button>
          </C.ChartResult>
        )}

        <C.CalculatorBox resultBox={!!calculatorResult}>
          {calculatorResult ? (
            <>
              <C.Charts>
                <C.ChartBox>
                  <ChartAndIndicators result={calculatorResult} />
                </C.ChartBox>
              </C.Charts>

              <C.Buttons>
                <Button
                  variant="solid"
                  fontWeight="bold"
                  textColor={backgroundColor}
                  backgroundColor={primaryColor}
                  onClick={() => onBuyCredits()}
                >
                  Compre créditos
                </Button>

                <Button
                  variant="outline"
                  fontWeight="bold"
                  textColor={primaryColor}
                  borderColor={primaryColor}
                  backgroundColor={backgroundColor}
                  onClick={() => onNewConsultHandler()}
                >
                  Nova consulta
                </Button>
              </C.Buttons>
            </>
          ) : (
            <Questions
              herds={herds}
              errors={errors}
              currHerd={currHerd}
              currCrop={currCrop}
              formStep={formStep}
              aircraft={aircraft}
              solarEnergy={solarEnergy}
              question={questionNumber}
              formFields={calculatorForm}
              onAddHerd={() => onAddHerd()}
              onAddCrop={() => onAddCrop()}
              onBackCrop={() => onBackCrop()}
              booleanOptions={booleanOptions}
              onAheadClick={() => onAheadClick()}
              onNextHerd={(val) => onNextHerd(val)}
              transportOptions={transportUnitsOptions}
              onRemoveHerd={() => setExcludeHerd(true)}
              onPreviousClick={() => onPreviousClick()}
              onChangeFormStep={() => onGoToFacilities()}
              onCalculateClick={() => onCalculateHandler()}
              onPreviousHerd={(val) => onPreviousHerd(val)}
              setAircraft={() => setAircraft((curr) => !curr)}
              setSolarEnergy={() => setSolarEnergy((curr) => !curr)}
              onChangeForm={(key, val) => onChangeHandler(key, val)}
              onChangeCropForm={(key, val) => onChangeCropHandler(key, val)}
              onChangeHerdForm={(key, index, val) =>
                onChangeHerdHandler(key, index, val)
              }
            />
          )}
        </C.CalculatorBox>
      </S.Content>

      {editCropModal && (
        <EditCropModal
          isOpen={editCropModal}
          onEdit={() => onEditCrop(editCrop)}
          onClose={() => setEditCropModal(false)}
        />
      )}

      {excludeHerd && (
        <ExcludeModal
          isOpen={excludeHerd}
          onClose={() => setExcludeHerd(false)}
          onExclude={() => onRemoveHerd(currHerd)}
        />
      )}

      {excludeCrop && (
        <ExcludeModal
          isOpen={excludeCrop}
          onClose={() => setExcludeCrop(false)}
          onExclude={() => onRemoveCrop(excludeCropIndex)}
        />
      )}
    </C.Container>
  );
};

export default Calculator;
