/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import { TBrand } from "../../../types";
import H4 from "../../atoms/Typography/H4";
import InputBase from "../../atoms/InputBase";
import BrandIcons from "../../atoms/BrandIcons";
import { Snackbar, Theme } from "../../../hooks";
import LoadingIcon from "../../icons/LoadingIcon";
import AvailableIcons from "../../atoms/AvailableIcons";
import HidePasswordIcon from "../../icons/HidePasswordIcon";

type InputTextProps = {
  id?: string;
  value: string;
  rows?: number;
  label?: string;
  wrap?: boolean;
  height?: string;
  limit?: boolean;
  border?: boolean;
  baseURL?: string;
  errors?: string[];
  copyIcon?: boolean;
  charLimit?: number;
  disabled?: boolean;
  brandIcon?: TBrand;
  inputWidth?: string;
  labelColor?: string;
  autofocus?: boolean;
  labelWeight?: string;
  placeholder?: string;
  borderRadius?: string;
  allLowerCase?: boolean;
  setIsCopied?: () => void;
  preventScroll?: boolean;
  backgroundColor?: string;
  fixedPlaceholder?: string;
  as?: "textarea" | "input";
  textAlign?: "center" | "start";
  onChange: (val: string) => void;
  status?: "success" | "error" | "loading" | "";
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const InputText: React.FC<InputTextProps> = ({
  id,
  value,
  label,
  limit,
  status,
  errors,
  height,
  copyIcon,
  disabled,
  rows = 5,
  onChange,
  autofocus,
  brandIcon,
  labelColor,
  setIsCopied,
  placeholder,
  wrap = false,
  borderRadius,
  as = "input",
  allLowerCase,
  preventScroll,
  border = true,
  backgroundColor,
  charLimit = 100,
  fixedPlaceholder,
  inputWidth = "100%",
  textAlign = "start",
  labelWeight = "bold",
  baseURL,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const input = useRef<HTMLInputElement>(null);

  const { textColor } = Theme.useTheme();
  const { newSuccess } = Snackbar.useSnackbar();

  const onFocusHandler = () => {
    if (window.navigator.userAgent.includes("Mobi")) {
      document.body.style.paddingBottom = "400px";
      document.body.style.backgroundColor = "#F9FAFB";

      if (!preventScroll) {
        setTimeout(() => {
          if (input.current)
            window.scrollTo({
              left: 0,
              top: input.current?.offsetTop - 100,
              behavior: "smooth",
            });
        }, 10);
      }
    }
  };

  const onBlurHandler = () => {
    if (window.navigator.userAgent.includes("Mobi"))
      document.body.style.paddingBottom = "0";
  };

  const onChangeHandler = (val: string) => {
    if (charLimit)
      return allLowerCase
        ? onChange(val.toLowerCase().substring(0, charLimit))
        : onChange(val.substring(0, charLimit));

    allLowerCase ? onChange(val.toLowerCase()) : onChange(val);
  };

  useEffect(() => {
    if (autofocus && input.current) input.current.focus();
  }, [autofocus]);

  const iconToRender = {
    loading: <LoadingIcon />,
    success: <AvailableIcons option="check" />,
    error: <AvailableIcons option="close" />,
  };

  const onCopyHandler = () => {
    navigator.clipboard.writeText(`${baseURL}${value}`);
    setIsCopied && setIsCopied();
    newSuccess("Texto copiado!");
  };

  return (
    <S.Container height={height} borderRadius={borderRadius}>
      {label || limit || brandIcon ? (
        <S.LabelAndLimit>
          {label ? (
            <H4 color={labelColor || textColor} fontWeight={labelWeight}>
              {label}
            </H4>
          ) : null}

          {limit ? (
            <S.RightBar>
              <S.CharLimit
                color={textColor}
              >{`${value.length}/${charLimit}`}</S.CharLimit>
            </S.RightBar>
          ) : null}

          {brandIcon && (
            <S.Brand>
              <BrandIcons option={brandIcon} />
            </S.Brand>
          )}
        </S.LabelAndLimit>
      ) : null}

      <S.InputAndIcon>
        <S.InputContainer
          wrap={wrap}
          border={border}
          width={inputWidth}
          backgroundColor={backgroundColor}
          hasIcon={props.type === "password"}
        >
          {baseURL && <S.BaseUrl color={`${textColor}bf`}>{baseURL}</S.BaseUrl>}

          {fixedPlaceholder && (
            <S.FixedPlaceholder color={textColor}>
              {fixedPlaceholder}
            </S.FixedPlaceholder>
          )}
          <InputBase
            id={id}
            as={as}
            rows={rows}
            ref={input}
            value={value}
            autoComplete="off"
            disabled={disabled}
            textColor={textColor}
            textAlign={textAlign}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            placeholder={placeholder}
            onChange={({ target }: any) => onChangeHandler(target.value)}
            {...props}
            type={
              props.type === "password" && isPasswordVisible
                ? "text"
                : props.type
            }
          />

          <S.Icons>
            {props.type === "password" &&
              (isPasswordVisible ? (
                <HidePasswordIcon onClick={() => setIsPasswordVisible(false)} />
              ) : (
                <S.PasswordIcon onClick={() => setIsPasswordVisible(true)} />
              ))}

            {!!status && iconToRender[status]}
          </S.Icons>
        </S.InputContainer>

        {copyIcon && (
          <S.CopyIcon onClick={() => onCopyHandler()}>
            <AvailableIcons option="copy" color={textColor} />
          </S.CopyIcon>
        )}
      </S.InputAndIcon>

      {errors && errors.length ? (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      ) : null}
    </S.Container>
  );
};

export default InputText;
