import styled from "styled-components";

type H1Props = {
  color: string;
  fontWeight?: string;
};

const Title = styled.h1<H1Props>`
  font-size: 3rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`;

export default Title;
