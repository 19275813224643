import styled, { css } from "styled-components";

import Button from "../../components/atoms/Button";

export const Content = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  button {
    max-width: 200px;
    margin-top: 20px;
  }
`;

export const Profile = styled.div`
  gap: 40px;
  display: flex;
  margin-top: 32px;
  margin-bottom: 80px;

  @media (max-width: 1240px) {
    flex-direction: column;
  }

  @media (max-width: 1160px) {
    margin-bottom: 40px;
  }

  @media (max-width: 740px) {
    width: 100%;
  }
`;

export const TextInputs = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 740px) {
    flex-direction: column;
  }
`;

export const LocationBox = styled.div`
  gap: 40px;
  width: 80%;
  display: flex;
  padding-left: 6px;

  @media (max-width: 1160px) {
    width: 100%;
  }

  @media (max-width: 740px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const CPFCNPJBox = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Address = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AddressBox = styled.div`
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 740px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const CityBox = styled.div`
  display: flex;
  max-width: 80%;
  padding-left: 6px;

  @media (max-width: 1160px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LogoBox = styled.div``;

type SaveButtonProps = {
  fixed: boolean;
  hasChanged: boolean;
};

export const SaveButton = styled(Button)<SaveButtonProps>`
  ${({ fixed, hasChanged }) => css`
    @media (max-width: 1160px) {
      z-index: 1000;
      align-self: center;
      justify-content: center;
      bottom: ${fixed && "100px"};
      display: ${hasChanged ? "flex" : "none"};
      position: ${fixed ? "fixed" : "relative"};
      box-shadow: ${fixed && "0 180px 100px 200px #00000040"};
    }
  `}
`;
