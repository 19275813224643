/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from "react";

import {
  LOCAL_STORAGE_USER_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_USERNAME_KEY,
} from "../../constants";
import { Loading, Snackbar } from "..";
import { IAuthContext, IUser } from "../../types";
import { Auth, Client, OAuth } from "../../services";

const emptyContext: IAuthContext = {
  user: {
    id: "",
    cpf: "",
    mail: "",
    cnpj: "",
    logo: "",
    company: "",
    companyCity: "",
    contactName: "",
    companyState: "",
    contactPhone: "",
    entity: "ibioma",
    primaryColor: "",
    cpfOrCnpj: "cnpj",
    onboarding: false,
    secondaryColor: "",
    backgroundColor: "",
    simplesNacional: true,
  },
  token: "",
  isLogged: false,
  login: () => {},
  logout: () => {},
  register: () => {},
  setUserHandler: () => {},
  setTokenHandler: () => {},
};

const Context = createContext(emptyContext);
const useAuth = () => useContext(Context);

const initialUserState: IUser = {
  ...emptyContext.user,
  ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || "{}"),
};

const initialTokenState: string =
  localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || "";

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState(initialUserState);
  const [token, setToken] = useState(initialTokenState);

  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();

  const setTokenHandler = (tokenValue: string) => {
    setToken(tokenValue);
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, tokenValue);
  };

  const setUserHandler = (userData: IUser) => {
    setUser((current) => {
      localStorage.setItem(
        LOCAL_STORAGE_USER_KEY,
        JSON.stringify({ ...current, ...userData })
      );

      return { ...current, ...userData };
    });
  };

  const login: IAuthContext["login"] = async (
    user,
    password,
    entity,
    oAuthProvider,
    oAuthToken
  ) => {
    try {
      showLoading();
      let client;

      if (user && password && !oAuthProvider)
        client = await Auth.login({ user, password, entity });

      const searchParams = localStorage.getItem("@searchParams");

      if (oAuthProvider && oAuthToken)
        client = await await OAuth.signWithOAuth(
          oAuthToken,
          oAuthProvider,
          undefined,
          searchParams ? JSON.parse(searchParams) : undefined
        );

      if (client) {
        localStorage.removeItem("@searchParams");

        setUserHandler(client.user);
        setTokenHandler(client.token);
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Houve um erro ao fazer o login, tente novamente";

      newError(errorMessage);
    } finally {
      hideLoading();
    }
  };

  const logout = () => {
    setUser(emptyContext.user);
    setToken("");

    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  };

  const register: IAuthContext["register"] = async (
    payload,
    oAuthProvider,
    oAuthToken
  ) => {
    try {
      showLoading();
      let client;

      const searchParams = localStorage.getItem("@searchParams");
      // const leadData = { ...payload };

      // if (searchParams) {
      //   leadData.origin = JSON.parse(searchParams);
      // }

      if (!oAuthProvider && payload) client = await Client.create(payload);

      if (oAuthProvider && oAuthToken) {
        const username = localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY) || "";
        if (!username) return;

        client = await OAuth.signWithOAuth(
          oAuthToken,
          oAuthProvider,
          username,
          searchParams ? JSON.parse(searchParams) : undefined
        );
      }

      if (client) {
        localStorage.removeItem("@searchParams");

        setUserHandler(client.user);
        setTokenHandler(client.token);
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Houve um erro, tente novamente";

      newError(errorMessage);
    } finally {
      localStorage.removeItem(LOCAL_STORAGE_USERNAME_KEY);
      hideLoading();
    }
  };

  const providerValue = {
    user,
    token,
    login,
    logout,
    register,
    setUserHandler,
    setTokenHandler,
    isLogged: !!token,
  };

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export default {
  useAuth,
  Provider,
};
