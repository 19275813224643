import styled, { css } from "styled-components";

type CardProps = {
  isSelected: boolean;
  borderColor: string;
  backgroundColor: string;
};

export const Card = styled.div<CardProps>`
  ${({ backgroundColor, isSelected, borderColor }) => css`
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 20px;
    cursor: pointer;
    border-radius: 12px;
    align-items: center;
    background-color: ${backgroundColor}80;
    border: ${isSelected && `1px solid ${borderColor}`};
  `}
`;

export const Texts = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;

  small {
    line-height: 1.2;
  }
`;

export const Brand = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
