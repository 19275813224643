import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const WarningIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="225"
      height="201"
      viewBox="0 0 225 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M221.8 164.09L134.35 12.2199C132.165 8.49917 129.045 5.41411 125.3 3.27053C121.555 1.12695 117.315 -0.000732422 113 -0.000732422C108.685 -0.000732422 104.445 1.12695 100.7 3.27053C96.955 5.41411 93.8353 8.49917 91.65 12.2199L4.19999 164.09C2.09734 167.689 0.989258 171.782 0.989258 175.95C0.989258 180.118 2.09734 184.211 4.19999 187.81C6.35729 191.553 9.47169 194.655 13.2236 196.797C16.9755 198.939 21.2298 200.045 25.55 200H200.45C204.767 200.041 209.017 198.934 212.765 196.792C216.513 194.65 219.624 191.55 221.78 187.81C223.886 184.213 224.997 180.121 225.001 175.953C225.004 171.785 223.9 167.691 221.8 164.09ZM105 79.9999C105 77.8782 105.843 75.8434 107.343 74.3431C108.843 72.8428 110.878 71.9999 113 71.9999C115.122 71.9999 117.157 72.8428 118.657 74.3431C120.157 75.8434 121 77.8782 121 79.9999V120C121 122.122 120.157 124.156 118.657 125.657C117.157 127.157 115.122 128 113 128C110.878 128 108.843 127.157 107.343 125.657C105.843 124.156 105 122.122 105 120V79.9999ZM113 168C110.627 168 108.307 167.296 106.333 165.978C104.36 164.659 102.822 162.785 101.913 160.592C101.005 158.399 100.768 155.987 101.231 153.659C101.694 151.331 102.836 149.193 104.515 147.515C106.193 145.836 108.331 144.694 110.659 144.23C112.987 143.767 115.399 144.005 117.592 144.913C119.785 145.822 121.659 147.36 122.978 149.333C124.296 151.306 125 153.627 125 156C125 159.183 123.736 162.235 121.485 164.485C119.235 166.736 116.183 168 113 168Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default WarningIcon;
