import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 0 20px;
  flex-direction: column;

  @media (max-width: 1160px) {
    padding: 0;
  }
`;

export const CardInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 740px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardHolderInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 740px) {
    display: flex;
    flex-direction: column;
  }
`;

type ButtonsProps = {
  direction: "column" | "row";
};

export const Buttons = styled.div<ButtonsProps>`
  ${({ direction }) => css`
    gap: 20px;
    display: flex;
    margin-top: 20px;
    flex-direction: ${direction};

    @media (max-width: 560px) {
      button {
        padding: 12px 6px;
      }
    }
  `}
`;

export const SaveBox = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

type CheckBoxProps = {
  color: string;
  isSelected: boolean;
  borderColor: string;
};

export const CheckBox = styled.div<CheckBoxProps>`
  ${({ color, isSelected, borderColor }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${borderColor};
    background-color: ${isSelected ? color : "transparent"};

    svg {
      width: 60%;
      height: 60%;
    }

    @media (max-width: 820px) {
      flex-shrink: 0;
    }
  `}
`;

type InputsProps = {
  backgroundColor: string;
};

export const ExpirationDate = styled.div`
  gap: 8px;
  display: flex;
  padding: 12px 0 0 12px;
  flex-direction: column;
`;

export const Inputs = styled.div<InputsProps>`
  ${({ backgroundColor }) => css`
    width: 100%;
    display: flex;
    max-width: 120px;
    align-items: center;
    border-radius: 12px;
    background-color: ${backgroundColor};
  `}
`;
