const MastercardIcon: React.FC = () => {
  return (
    <svg
      width="256"
      height="159"
      viewBox="0 0 256 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.2981 16.9028H162.448V141.154H93.2981V16.9028Z"
        fill="#FF5F00"
      />
      <path
        d="M97.689 79.029C97.689 53.784 109.543 31.392 127.763 16.903C114.373 6.366 97.47 0 79.03 0C35.343 0 0 35.343 0 79.029C0 122.714 35.343 158.058 79.029 158.058C97.469 158.058 114.372 151.692 127.763 141.154C109.543 126.885 97.689 104.274 97.689 79.029Z"
        fill="#EB001B"
      />
      <path
        d="M255.746 79.029C255.746 122.714 220.403 158.058 176.717 158.058C158.277 158.058 141.374 151.692 127.983 141.154C146.423 126.666 158.058 104.274 158.058 79.029C158.058 53.784 146.203 31.392 127.983 16.903C141.373 6.366 158.277 0 176.717 0C220.403 0 255.746 35.563 255.746 79.029Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default MastercardIcon;
