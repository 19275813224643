import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const StaticLoadingIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6C17.2612 4.4992 16.1168 3.23534 14.6965 2.35162C13.2762 1.46791 11.6368 0.999683 9.964 1C5.014 1 1 5.03 1 10M2 14C2.73879 15.5008 3.88318 16.7647 5.30348 17.6484C6.72378 18.5321 8.36322 19.0003 10.036 19C14.986 19 19 14.97 19 10M7 14H1V20M19 0V6H13"
        stroke={color || primaryColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default StaticLoadingIcon;
