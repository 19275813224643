import * as S from "./styles";
import { Theme } from "../../../../hooks";
import { seals } from "../../../../constants/seals";
import { TClassification } from "../../../../types/report";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

const { REACT_APP_SEALS_BUCKET } = process.env;

interface IProps {
  classification: TClassification;
}

const Seal: React.FC<IProps> = ({ classification }) => {
  const { textColor, tertiaryColor } = Theme.useTheme();

  const href = (link: string): string => {
    return `${REACT_APP_SEALS_BUCKET}/${classification}/${link}`;
  };

  return (
    <S.Content>
      <S.Seals>
        <S.HeaderSeal borderColor={tertiaryColor}>
          <S.TitleCustom width="80%" fontWeight="bold" color={textColor}>
            Nome do arquivo
          </S.TitleCustom>

          <S.TitleCustom width="20%" color={textColor} fontWeight="bold">
            Tipo
          </S.TitleCustom>
        </S.HeaderSeal>

        <S.SealsList>
          {seals.map((item, index) => (
            <S.Seal
              target="_blank"
              href={href(item.link)}
              rel="noferrer noopener"
              key={`${item.label}#${index}`}
            >
              <S.Icon>
                <AvailableIcons
                  color={textColor}
                  option={item.type === "PDF" ? "document" : "image"}
                />
              </S.Icon>

              <S.TitleCustom width="80%" color={textColor}>
                {item.label}
              </S.TitleCustom>

              <S.TitleCustom width="20%" color={textColor}>
                {item.type}
              </S.TitleCustom>
            </S.Seal>
          ))}
        </S.SealsList>
      </S.Seals>
    </S.Content>
  );
};

export default Seal;
