import * as S from "./styles";
import { Theme } from "../../hooks";
import Page from "../../components/molecules/Page";
import { materialsAndContents } from "../../constants/materials";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import TitleDescription from "../../components/molecules/TitleDescription";

const MaterialsAndContent: React.FC = () => {
  const { textColor, tertiaryColor } = Theme.useTheme();

  const titleWidth = "50%";
  const classificationWidth = "25%";
  const typeWidth = "25%";

  return (
    <Page pageIndex={7} mobileSection="content">
      <S.Content>
        <TitleDescription
          title="Materiais e conteúdos"
          description="Aqui disponibilizamos alguns materiais para que você possa se aprofundar no que estamos fazendo juntos. Também deixamos alguns templates tanto para divulgações e treinamentos internos, quanto para suas redes sociais."
        />
      </S.Content>

      <S.MaterialsAndContent>
        <S.Header borderColor={tertiaryColor}>
          <S.TitleCustom width={titleWidth} color={textColor} fontWeight="bold">
            Título
          </S.TitleCustom>

          <S.TitleCustom
            fontWeight="bold"
            color={textColor}
            width={classificationWidth}
          >
            Classificação
          </S.TitleCustom>

          <S.TitleCustom width={typeWidth} color={textColor} fontWeight="bold">
            Tipo
          </S.TitleCustom>
        </S.Header>

        <S.MaterialsList>
          {materialsAndContents.map((item, index) => (
            <S.Material
              target="_blank"
              href={item.link}
              rel="noferrer noopener"
              key={`${item.label}#${index}`}
            >
              <S.Icon>
                <AvailableIcons option="document" color={textColor} />
              </S.Icon>

              <S.TitleCustom width={titleWidth} color={textColor}>
                {item.label}
              </S.TitleCustom>

              <S.TitleCustom width={classificationWidth} color={textColor}>
                {item.classification}
              </S.TitleCustom>

              <S.TitleCustom width={typeWidth} color={textColor}>
                {item.type}
              </S.TitleCustom>
            </S.Material>
          ))}
        </S.MaterialsList>

        <S.MaterialsCards>
          {materialsAndContents.map((item, index) => (
            <S.MaterialCard
              target="_blank"
              href={item.link}
              rel="noferrer noopener"
              key={`${item.label}#${index}`}
              backgroundColor={tertiaryColor}
            >
              <S.Icon>
                <AvailableIcons option="document" color={textColor} />
              </S.Icon>

              <S.MaterialCardInfo>
                <S.MaterialCardText color={textColor}>
                  <span>Tipo: </span>

                  {item.type}
                </S.MaterialCardText>

                <S.MaterialCardText color={textColor}>
                  <span>Classificação: </span>

                  {item.classification}
                </S.MaterialCardText>

                <S.MaterialCardText color={textColor}>
                  <span>Título: </span>

                  {item.label}
                </S.MaterialCardText>
              </S.MaterialCardInfo>
            </S.MaterialCard>
          ))}
        </S.MaterialsCards>
      </S.MaterialsAndContent>
    </Page>
  );
};

export default MaterialsAndContent;
