import * as S from "./styles";
import {
  getSexByValue,
  getCropByValue,
  getHerdAgeByValue,
  getSoilTextureByValue,
  getFarmAdoptionByValue,
  getClayInTheSoilByValue,
} from "../../../../../../../../utils/calculators";
import {
  TCrop,
  ICrop,
  IHerd,
  IAgroCalculatorForm,
} from "../../../../../../../../types/agroCalculator";
import {
  herd,
  cycle,
  biome,
  herdSex,
  herdAge,
  cropsObj,
  dryMatter,
  waterRegime,
  herdHandling,
  soilPrepation,
  soilTexturalObj,
  clayInTheSoilObj,
  formerLandUseObj,
  transportUnitsObj,
  farmingAdoptionTime,
  currentFarmingSystemObj,
  caneCurrentFarmingSystemObj,
  livestockCurrentFarmingSystemObj,
} from "../../../../../../../../constants/calculators";
import { Theme } from "../../../../../../../../hooks";
import { maskDate } from "../../../../../../../../utils/dates";
import Button from "../../../../../../../../components/atoms/Button";
import H2 from "../../../../../../../../components/atoms/Typography/H2";
import { TUnits } from "../../../../../../../../types/adminCalculators";
import { normalizeSlicerNumber } from "../../../../../../../../utils/numbers";
import InputText from "../../../../../../../../components/molecules/InputText";
import OptionsList from "../../../../../../../../components/molecules/OptionsList";
import AvailableIcons from "../../../../../../../../components/atoms/AvailableIcons";
import DropdownMenu from "../../../../../../../../components/organisms/DropdownMenu";
import InputSliceAndNumber from "../../../../../../../../components/organisms/InputSliceAndNumber";

interface IProps {
  currCrop: ICrop;
  herds?: IHerd[];
  question: number;
  currHerd: number;
  aircraft: boolean;
  // roasting: boolean;
  solarEnergy: boolean;
  onAddCrop: () => void;
  onAddHerd: () => void;
  onBackCrop: () => void;
  setAircraft: () => void;
  // setRoasting: () => void;
  onRemoveHerd: () => void;
  booleanOptions: string[];
  onAheadClick: () => void;
  transportOptions: string[];
  setSolarEnergy: () => void;
  onPreviousClick: () => void;
  onCalculateClick: () => void;
  onChangeFormStep: () => void;
  formFields: IAgroCalculatorForm;
  formStep: "crops" | "facilities";
  onNextHerd: (val: number) => void;
  errors: { [key: string]: string[] };
  onPreviousHerd: (val: number) => void;
  onChangeCropForm: (
    key: keyof ICrop,
    value: string | number | boolean
  ) => void;
  onChangeHerdForm: (
    key: keyof IHerd,
    index: number,
    value: string | number | boolean
  ) => void;
  onChangeForm: (
    key: keyof IAgroCalculatorForm,
    value: string | number | boolean
  ) => void;
}

const Questions: React.FC<IProps> = ({
  herds,
  errors,
  currCrop,
  question,
  formStep,
  aircraft,
  currHerd,
  // roasting,
  onAddHerd,
  onAddCrop,
  formFields,
  // setRoasting,
  solarEnergy,
  onBackCrop,
  onNextHerd,
  setAircraft,
  onAheadClick,
  onChangeForm,
  onRemoveHerd,
  setSolarEnergy,
  booleanOptions,
  onPreviousHerd,
  onPreviousClick,
  onChangeFormStep,
  transportOptions,
  onChangeHerdForm,
  onChangeCropForm,
  onCalculateClick,
}) => {
  const { textColor, primaryColor, backgroundColor } = Theme.useTheme();

  const dates = (crop: TCrop) => {
    if (crop === "livestock") return;

    if (crop === "cane")
      return (
        <>
          <S.QuestionSmall>
            <DropdownMenu
              label="Ciclo"
              labelWeight="regular"
              labelColor={textColor}
              options={Object.values(cycle)}
              selected={currCrop.cycle || ""}
              placeholder="Selecione o ciclo"
              onSelect={(val) => onChangeCropForm("cycle", val)}
            />
          </S.QuestionSmall>

          <S.QuestionSmall>
            <InputText
              labelWeight="regular"
              labelColor={textColor}
              label="Data de colheita"
              errors={errors.harvestDate}
              value={maskDate(currCrop.harvestDate || "")}
              onChange={(val) =>
                onChangeCropForm(
                  "harvestDate",
                  (val || "").replace(/[^0-9]/g, "")
                )
              }
            />
          </S.QuestionSmall>
        </>
      );

    if (crop === "coffee")
      return (
        <>
          <S.QuestionSmall>
            <InputText
              labelWeight="regular"
              labelColor={textColor}
              label="Início da safra"
              errors={errors.plantingDate}
              value={maskDate(currCrop.plantingDate || "")}
              onChange={(val) =>
                onChangeCropForm(
                  "plantingDate",
                  (val || "").replace(/[^0-9]/g, "")
                )
              }
            />
          </S.QuestionSmall>

          <S.QuestionSmall>
            <InputText
              labelWeight="regular"
              labelColor={textColor}
              label="Data de colheita"
              errors={errors.harvestDate}
              value={maskDate(currCrop.harvestDate || "")}
              onChange={(val) =>
                onChangeCropForm(
                  "harvestDate",
                  (val || "").replace(/[^0-9]/g, "")
                )
              }
            />
          </S.QuestionSmall>
        </>
      );

    return (
      <>
        <S.QuestionSmall>
          <InputText
            labelWeight="regular"
            labelColor={textColor}
            label="Data de plantio"
            errors={errors.plantingDate}
            value={maskDate(currCrop.plantingDate || "")}
            onChange={(val) =>
              onChangeCropForm(
                "plantingDate",
                (val || "").replace(/[^0-9]/g, "")
              )
            }
          />
        </S.QuestionSmall>

        <S.QuestionSmall>
          <InputText
            labelWeight="regular"
            labelColor={textColor}
            label="Data de colheita"
            errors={errors.harvestDate}
            value={maskDate(currCrop.harvestDate || "")}
            onChange={(val) =>
              onChangeCropForm(
                "harvestDate",
                (val || "").replace(/[^0-9]/g, "")
              )
            }
          />
        </S.QuestionSmall>
      </>
    );
  };

  const farmingSystem = (crop: TCrop) => {
    if (crop === "livestock")
      return (
        <S.QuestionSmall>
          <DropdownMenu
            labelWeight="regular"
            labelColor={textColor}
            label="Sistema de cultivo atual"
            placeholder="Selecione o sistema"
            options={livestockCurrentFarmingSystemObj}
            selected={currCrop.livestockCurrentFarmingSystem || ""}
            onSelect={(val) =>
              onChangeCropForm("livestockCurrentFarmingSystem", val)
            }
          />
        </S.QuestionSmall>
      );

    if (crop === "cane")
      return (
        <S.QuestionSmall>
          <DropdownMenu
            labelWeight="regular"
            labelColor={textColor}
            label="Sistema de cultivo atual"
            placeholder="Selecione o sistema"
            options={caneCurrentFarmingSystemObj}
            selected={currCrop.caneCurrentFarmingSystem || ""}
            onSelect={(val) =>
              onChangeCropForm("caneCurrentFarmingSystem", val)
            }
          />
        </S.QuestionSmall>
      );

    return (
      <S.QuestionSmall>
        <DropdownMenu
          labelWeight="regular"
          labelColor={textColor}
          label="Sistema de cultivo atual"
          placeholder="Selecione o sistema"
          options={currentFarmingSystemObj}
          selected={currCrop.currentFarmingSystem || ""}
          onSelect={(val) => onChangeCropForm("currentFarmingSystem", val)}
        />
      </S.QuestionSmall>
    );
  };

  const cropsContent = () => {
    switch (question) {
      case 1:
        return (
          <>
            <S.BoxContent borderColor={primaryColor}>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Sistema de cultivo
                </H2>
              </S.QuestionTitle>

              <S.QuestionsGrid>
                <S.QuestionSmall>
                  <DropdownMenu
                    label="Cultura"
                    labelWeight="regular"
                    errors={errors.crop}
                    labelColor={textColor}
                    placeholder="Selecione a cultura"
                    options={Object.values(cropsObj)}
                    selected={(currCrop.crop && cropsObj[currCrop.crop]) || ""}
                    onSelect={(val) =>
                      onChangeCropForm("crop", getCropByValue(val))
                    }
                  />
                </S.QuestionSmall>

                {dates(currCrop.crop || "bean")}

                <S.QuestionSmall>
                  <DropdownMenu
                    labelWeight="regular"
                    labelColor={textColor}
                    label="Classe textural do solo"
                    placeholder="Selecione a classe"
                    options={Object.values(soilTexturalObj)}
                    selected={
                      (currCrop.soilTexturalClass &&
                        soilTexturalObj[currCrop.soilTexturalClass]) ||
                      ""
                    }
                    onSelect={(val) =>
                      onChangeCropForm(
                        "soilTexturalClass",
                        getSoilTextureByValue(val)
                      )
                    }
                  />
                </S.QuestionSmall>

                <S.QuestionSmall>
                  <DropdownMenu
                    labelWeight="regular"
                    labelColor={textColor}
                    label="Teor de argila no solo"
                    placeholder="Selecione o teor"
                    options={Object.values(clayInTheSoilObj)}
                    selected={
                      (currCrop.clayInTheSoil &&
                        clayInTheSoilObj[currCrop.clayInTheSoil]) ||
                      ""
                    }
                    onSelect={(val) =>
                      onChangeCropForm(
                        "clayInTheSoil",
                        getClayInTheSoilByValue(val)
                      )
                    }
                  />
                </S.QuestionSmall>

                <S.QuestionSmall>
                  <DropdownMenu
                    labelWeight="regular"
                    labelColor={textColor}
                    options={formerLandUseObj}
                    label="Uso anterior da terra"
                    placeholder="Selecione o uso"
                    selected={currCrop.formerLandUse || ""}
                    onSelect={(val) => onChangeCropForm("formerLandUse", val)}
                  />
                </S.QuestionSmall>

                {farmingSystem(currCrop.crop || "bean")}

                <S.QuestionSmall>
                  <DropdownMenu
                    labelWeight="regular"
                    labelColor={textColor}
                    placeholder="Selecione o tempo"
                    label="Tempo de adoção do sistema"
                    options={Object.values(farmingAdoptionTime)}
                    selected={
                      (currCrop.farmingAdoptionTime &&
                        farmingAdoptionTime[currCrop.farmingAdoptionTime]) ||
                      ""
                    }
                    onSelect={(val) =>
                      onChangeCropForm(
                        "farmingAdoptionTime",
                        getFarmAdoptionByValue(val)
                      )
                    }
                  />
                </S.QuestionSmall>

                {currCrop.crop === "rice" && (
                  <>
                    <S.QuestionSmall>
                      <DropdownMenu
                        labelWeight="regular"
                        labelColor={textColor}
                        label="Preparo de solo"
                        placeholder="Selecione o preparo"
                        options={Object.values(soilPrepation)}
                        selected={currCrop.soilPrepation || ""}
                        onSelect={(val) =>
                          onChangeCropForm("soilPrepation", val)
                        }
                      />
                    </S.QuestionSmall>

                    <S.QuestionSmall>
                      <DropdownMenu
                        labelWeight="regular"
                        label="Regime hídrico"
                        labelColor={textColor}
                        placeholder="Selecione o regime"
                        options={Object.values(waterRegime)}
                        selected={currCrop.waterRegime || ""}
                        onSelect={(val) => onChangeCropForm("waterRegime", val)}
                      />
                    </S.QuestionSmall>
                  </>
                )}
              </S.QuestionsGrid>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    minValue={1}
                    width="180px"
                    type="hectare"
                    maxValue={5000}
                    value={currCrop.acreage || "0,00"}
                    onChange={(val) => onChangeCropForm("acreage", val)}
                    label={
                      currCrop.crop === "livestock"
                        ? "Área de pastagem"
                        : "Área cultivada"
                    }
                  />
                </S.Question>

                {currCrop.crop !== "livestock" && (
                  <>
                    <S.Question>
                      <InputSliceAndNumber
                        minValue={1}
                        width="180px"
                        maxValue={10000}
                        type="ton/hectare"
                        label="Produtividade média"
                        value={currCrop.productivity || "0,00"}
                        onChange={(val) =>
                          onChangeCropForm("productivity", val)
                        }
                      />
                    </S.Question>

                    <S.Question>
                      <InputSliceAndNumber
                        minValue={1}
                        width="180px"
                        type="hectare"
                        maxValue={10000}
                        value={currCrop.cropResidueBurningArea || "0,00"}
                        label="Área de queima de resíduos da cultura"
                        onChange={(val) =>
                          onChangeCropForm("cropResidueBurningArea", val)
                        }
                      />
                    </S.Question>
                  </>
                )}

                <S.Question>
                  <InputSliceAndNumber
                    minValue={1}
                    width="180px"
                    type="hectare"
                    maxValue={10000}
                    label="Área de manejo de solos orgânicos"
                    value={currCrop.organicSoilManagementArea || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("organicSoilManagementArea", val)
                    }
                  />
                </S.Question>
              </S.Questions>
            </S.BoxContent>
          </>
        );

      case 2:
        return (
          <>
            <S.BoxContent borderColor={primaryColor}>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Adubação sintética
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    width="180px"
                    maxValue={1000}
                    type="Kg/hectare"
                    value={currCrop.syntheticNitrogenFertilizer || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("syntheticNitrogenFertilizer", val)
                    }
                    label="Adubação nitrogenada sintética (Exceto Ureia)"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    type="%"
                    width="180px"
                    maxValue={1000}
                    label="Teor de nitrogênio no adubo sintético"
                    onChange={(val) =>
                      onChangeCropForm(
                        "nitrogenContentInSyntheticFertilizer",
                        val
                      )
                    }
                    value={currCrop.nitrogenContentInSyntheticFertilizer || "0"}
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    label="Ureia"
                    width="180px"
                    maxValue={1000}
                    type="Kg/hectare"
                    value={currCrop.urea || "0,00"}
                    onChange={(val) => onChangeCropForm("urea", val)}
                  />
                </S.Question>
              </S.Questions>
            </S.BoxContent>

            <S.BoxContent borderColor={primaryColor}>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Correção e condicionamento de solo
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    width="180px"
                    maxValue={1000}
                    type="Kg/hectare"
                    label="Calcário calcítico"
                    value={currCrop.calciticLimestone || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("calciticLimestone", val)
                    }
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    width="180px"
                    maxValue={1000}
                    type="Kg/hectare"
                    value={currCrop.dolomiticLimestone || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("dolomiticLimestone", val)
                    }
                    label="Calcário dolomítico"
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    width="180px"
                    maxValue={1000}
                    type="Kg/hectare"
                    label="Gesso agrícola"
                    value={currCrop.agriculturalPlaster || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("agriculturalPlaster", val)
                    }
                  />
                </S.Question>
              </S.Questions>
            </S.BoxContent>
          </>
        );

      case 3:
        return (
          <>
            <S.BoxContent borderColor={primaryColor}>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Adubação orgânica
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                {currCrop.crop === "rice" ? (
                  <>
                    <S.QuestionSmall>
                      <DropdownMenu
                        labelWeight="regular"
                        options={dryMatter}
                        labelColor={textColor}
                        placeholder="Selecione a aplicação"
                        selected={currCrop.dryMatterApplication || ""}
                        label="Aplicação de matéria seca - em Kg/hectare"
                        onSelect={(val) =>
                          onChangeCropForm("dryMatterApplication", val)
                        }
                      />
                    </S.QuestionSmall>

                    <S.QuestionSmall>
                      <DropdownMenu
                        labelWeight="regular"
                        labelColor={textColor}
                        options={booleanOptions}
                        placeholder="Selecione a opção"
                        label="A matéria seca é fermentada?"
                        selected={currCrop.dryMatterIsFermented || ""}
                        onSelect={(val) =>
                          onChangeCropForm("dryMatterIsFermented", val)
                        }
                      />
                    </S.QuestionSmall>
                  </>
                ) : (
                  <>
                    {currCrop.crop === "cane" && (
                      <>
                        <S.Question>
                          <InputSliceAndNumber
                            width="180px"
                            maxValue={1000}
                            label="Vinhaça"
                            type="m³/hectare"
                            value={currCrop.vinasse || "0,00"}
                            onChange={(val) => onChangeCropForm("vinasse", val)}
                          />
                        </S.Question>

                        <S.Question>
                          <InputSliceAndNumber
                            width="180px"
                            maxValue={1000}
                            type="Kg/hectare"
                            label="Torta de filtro - Quantidade aplicada"
                            value={currCrop.filterPieQuantity || "0,00"}
                            onChange={(val) =>
                              onChangeCropForm("filterPieQuantity", val)
                            }
                          />
                        </S.Question>

                        <S.Question>
                          <InputSliceAndNumber
                            width="180px"
                            maxValue={1000}
                            type="hectare"
                            label="Torta de filtro - Área de aplicação"
                            value={currCrop.filterPieArea || "0,00"}
                            onChange={(val) =>
                              onChangeCropForm("filterPieArea", val)
                            }
                          />
                        </S.Question>
                      </>
                    )}

                    <S.Question>
                      <InputSliceAndNumber
                        width="180px"
                        maxValue={1000}
                        type="Kg/hectare"
                        label="Composto orgânico"
                        value={currCrop.organicCompost || "0,00"}
                        onChange={(val) =>
                          onChangeCropForm("organicCompost", val)
                        }
                      />
                    </S.Question>

                    <S.Question>
                      <InputSliceAndNumber
                        width="180px"
                        maxValue={1000}
                        type="Kg/hectare"
                        value={currCrop.manure || "0,00"}
                        label="Esterco bovino, equino, suíno ou ovino"
                        onChange={(val) => onChangeCropForm("manure", val)}
                      />
                    </S.Question>

                    <S.Question>
                      <InputSliceAndNumber
                        width="180px"
                        maxValue={1000}
                        type="Kg/hectare"
                        label="Esterco avícola"
                        value={currCrop.poultryManure || "0,00"}
                        onChange={(val) =>
                          onChangeCropForm("poultryManure", val)
                        }
                      />
                    </S.Question>

                    <S.Question>
                      <InputSliceAndNumber
                        width="180px"
                        label="Outros"
                        maxValue={1000}
                        type="Kg/hectare"
                        value={currCrop.otherOrganicFertilizers || "0,00"}
                        onChange={(val) =>
                          onChangeCropForm("otherOrganicFertilizers", val)
                        }
                      />
                    </S.Question>
                  </>
                )}
              </S.Questions>
            </S.BoxContent>

            <S.BoxContent borderColor={primaryColor}>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Adubação verde
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    width="180px"
                    maxValue={1000}
                    label="Leguminosa"
                    type="Kg/hectare"
                    value={currCrop.legumeFertilization || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("legumeFertilization", val)
                    }
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    width="180px"
                    label="Gramínea"
                    maxValue={1000}
                    type="Kg/hectare"
                    value={currCrop.grassFertilization || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("grassFertilization", val)
                    }
                  />
                </S.Question>

                <S.Question>
                  <InputSliceAndNumber
                    width="180px"
                    label="Outros"
                    maxValue={1000}
                    type="Kg/hectare"
                    value={currCrop.othersGreenFertilizer || "0,00"}
                    onChange={(val) =>
                      onChangeCropForm("othersGreenFertilizer", val)
                    }
                  />
                </S.Question>
              </S.Questions>
            </S.BoxContent>

            {currCrop.crop === "livestock" && (
              <S.BoxContent borderColor={primaryColor}>
                <S.QuestionTitleHerd>
                  <H2 color={textColor} fontWeight="bold">
                    Rebanho {currHerd + 1}
                  </H2>

                  <S.AddHerd onClick={() => onAddHerd()}>
                    Adicionar rebanho
                    <AvailableIcons option="plus" />
                  </S.AddHerd>
                </S.QuestionTitleHerd>

                <S.QuestionsBox>
                  {herds && herds.length > 1 && currHerd !== 0 && (
                    <S.BackHerd onClick={() => onPreviousHerd(currHerd - 1)}>
                      <AvailableIcons option="chevron" />
                    </S.BackHerd>
                  )}

                  <S.QuestionsHerd>
                    <S.QuestionsGrid>
                      <S.QuestionSmall>
                        <DropdownMenu
                          label="Animal"
                          options={herd}
                          errors={errors.type}
                          labelWeight="regular"
                          labelColor={textColor}
                          placeholder="Selecione o animal"
                          selected={(herds || [])[currHerd]?.type || ""}
                          onSelect={(val) =>
                            onChangeHerdForm("type", currHerd, val)
                          }
                        />
                      </S.QuestionSmall>

                      <S.QuestionSmall>
                        <DropdownMenu
                          label="Sexo"
                          labelWeight="regular"
                          labelColor={textColor}
                          placeholder="Selecione o sexo"
                          options={Object.values(herdSex)}
                          onSelect={(val) =>
                            onChangeHerdForm(
                              "sex",
                              currHerd,
                              getSexByValue(val)
                            )
                          }
                          selected={
                            ((herds || [])[currHerd]?.sex &&
                              herdSex[
                                (herds || [])[currHerd]?.sex || "male"
                              ]) ||
                            ""
                          }
                        />
                      </S.QuestionSmall>

                      <S.QuestionSmall>
                        <DropdownMenu
                          labelWeight="regular"
                          labelColor={textColor}
                          label="Idade do rebanho"
                          placeholder="Selecione a idade"
                          options={Object.values(herdAge)}
                          selected={
                            herdAge[(herds || [])[currHerd]?.age || ""] || ""
                          }
                          onSelect={(val) =>
                            onChangeHerdForm(
                              "age",
                              currHerd,
                              getHerdAgeByValue(val)
                            )
                          }
                        />
                      </S.QuestionSmall>

                      <S.QuestionSmall>
                        <InputText
                          labelWeight="regular"
                          labelColor={textColor}
                          label="Número de animais"
                          value={
                            normalizeSlicerNumber(
                              (herds || [])[currHerd]?.herdSize || 0,
                              "number"
                            ) || "0"
                          }
                          onChange={(val) =>
                            onChangeHerdForm(
                              "herdSize",
                              currHerd,
                              (val || "").replace(/[^0-9]/g, "")
                            )
                          }
                        />
                      </S.QuestionSmall>

                      <S.QuestionSmall>
                        <DropdownMenu
                          labelWeight="regular"
                          options={herdHandling}
                          labelColor={textColor}
                          label="Manejo de dejetos"
                          placeholder="Selecione o manejo"
                          selected={(herds || [])[currHerd]?.handling || ""}
                          onSelect={(val) =>
                            onChangeHerdForm("handling", currHerd, val)
                          }
                        />
                      </S.QuestionSmall>

                      <S.QuestionSmall>
                        <InputText
                          labelWeight="regular"
                          labelColor={textColor}
                          label="Data de entrada"
                          errors={errors.entryDate}
                          value={maskDate(
                            (herds || [])[currHerd]?.entryDate || ""
                          )}
                          onChange={(val) =>
                            onChangeHerdForm(
                              "entryDate",
                              currHerd,
                              (val || "").replace(/[^0-9]/g, "")
                            )
                          }
                        />
                      </S.QuestionSmall>

                      <S.QuestionSmall>
                        <InputText
                          labelWeight="regular"
                          label="Data de saída"
                          labelColor={textColor}
                          errors={errors.departureDate}
                          value={maskDate(
                            (herds || [])[currHerd]?.departureDate || ""
                          )}
                          onChange={(val) =>
                            onChangeHerdForm(
                              "departureDate",
                              currHerd,
                              (val || "").replace(/[^0-9]/g, "")
                            )
                          }
                        />
                      </S.QuestionSmall>
                    </S.QuestionsGrid>

                    {herds && herds?.length > 1 && (
                      <S.RemoveHerd>
                        <Button
                          variant="solid"
                          fontWeight="bold"
                          textColor="#fafafa"
                          backgroundColor="#ff4040"
                          onClick={() => onRemoveHerd()}
                        >
                          Remover rebanho {currHerd + 1}
                        </Button>
                      </S.RemoveHerd>
                    )}
                  </S.QuestionsHerd>

                  {herds &&
                    herds.length > 0 &&
                    herds.length - 1 !== currHerd && (
                      <S.NextHerd onClick={() => onNextHerd(currHerd + 1)}>
                        <AvailableIcons option="chevron" />
                      </S.NextHerd>
                    )}
                </S.QuestionsBox>
              </S.BoxContent>
            )}
          </>
        );

      default:
        return;
    }
  };

  const facilitiesContent = () => {
    switch (question) {
      case 1:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Combustíveis
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  option2="R$"
                  option1="L"
                  maxValue={20000}
                  unitSwitcher={true}
                  isOn={formFields.dieselUnit === "R$"}
                  value={formFields.dieselExpenses || "0,00"}
                  type={
                    transportOptions[
                      Object.values(transportUnitsObj).indexOf(
                        formFields.dieselUnit || "l"
                      )
                    ] as TUnits
                  }
                  setIsOn={(val) => onChangeForm("dieselUnit", val)}
                  onChange={(val) => onChangeForm("dieselExpenses", val)}
                  label="Qual o gasto total com Diesel em sua propriedade? 
                  Considere tratores, caminhões, geradores, operações mecanizadas, etc."
                />
              </S.Question>

              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  selectedItem={aircraft ? 0 : 1}
                  setSelectedItem={() => setAircraft()}
                  label="Possui aeronaves para pulverização?"
                />
              </S.Question>

              {aircraft && (
                <S.Question>
                  <InputSliceAndNumber
                    option2="R$"
                    option1="L"
                    maxValue={20000}
                    unitSwitcher={true}
                    isOn={formFields.aircraftUnit === "R$"}
                    value={formFields.aircraftExpenses || "0,00"}
                    setIsOn={(val) => onChangeForm("aircraftUnit", val)}
                    type={
                      transportOptions[
                        Object.values(transportUnitsObj).indexOf(
                          formFields.aircraftUnit || "l"
                        )
                      ] as TUnits
                    }
                    onChange={(val) => onChangeForm("aircraftExpenses", val)}
                    label="Qual o gasto mensal com combustíveis para a(s) aeronave(s)?"
                  />
                </S.Question>
              )}
            </S.Questions>
          </S.BoxContent>
        );

      case 2:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Energia
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <OptionsList
                  options={booleanOptions}
                  label="Utiliza energia solar?"
                  selectedItem={solarEnergy ? 0 : 1}
                  setSelectedItem={() => setSolarEnergy()}
                />
              </S.Question>

              {solarEnergy && (
                <S.Question>
                  <InputSliceAndNumber
                    type="%"
                    maxValue={100}
                    value={formFields.solarEnergyPerc || "0"}
                    label="Qual o percentual de sua energia é solar?"
                    onChange={(val) => onChangeForm("solarEnergyPerc", val)}
                  />
                </S.Question>
              )}

              <S.Question>
                <InputSliceAndNumber
                  option2="R$"
                  option1="KWh"
                  maxValue={20000}
                  unitSwitcher={true}
                  type={formFields.energyUnit || "KWh"}
                  isOn={formFields.energyUnit === "R$"}
                  label="Qual o gasto com energia elétrica?"
                  value={formFields.energyExpenses || "0,00"}
                  setIsOn={(val) => onChangeForm("energyUnit", val)}
                  onChange={(val) => onChangeForm("energyExpenses", val)}
                />
              </S.Question>
            </S.Questions>
          </S.BoxContent>
        );

      case 3:
        return (
          <S.BoxContent borderColor={primaryColor}>
            <S.QuestionTitle>
              <H2 color={textColor} fontWeight="bold">
                Meio ambiente
              </H2>
            </S.QuestionTitle>

            <S.Questions>
              <S.Question>
                <InputSliceAndNumber
                  width="180px"
                  maxValue={1000}
                  type="hectare"
                  infoIcon={true}
                  value={formFields.primaryPreservation || "0,00"}
                  onChange={(val) => onChangeForm("primaryPreservation", val)}
                  label="Área de preservação permanente/vegetação florestal primária"
                  info=" A floresta primária é uma floresta madura e antiga, que nunca sofreu perturbações significativas causadas pelo homem ou eventos naturais ao longo de um longo período. Essas florestas geralmente possuem uma alta diversidade de espécies vegetais e animais, incluindo espécies raras e ameaçadas."
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  width="180px"
                  maxValue={1000}
                  type="hectare"
                  infoIcon={true}
                  value={formFields.secondaryPreservation || "0,00"}
                  onChange={(val) => onChangeForm("secondaryPreservation", val)}
                  label="Área de preservação permanente/vegetação florestal secundária"
                  info="A floresta secundária é uma área que foi previamente desmatada, degradada ou perturbada de alguma forma e, em seguida, começou a se regenerar naturalmente ou foi plantada novamente pelo homem. Possuem menos diversidade de espécies em comparação com florestas primárias."
                />
              </S.Question>

              <S.Question>
                <InputSliceAndNumber
                  width="180px"
                  type="hectare"
                  maxValue={1000}
                  value={formFields.deforestation || "0,00"}
                  label="Área de desmatamento para o cultivo"
                  onChange={(val) => onChangeForm("deforestation", val)}
                />
              </S.Question>
            </S.Questions>
          </S.BoxContent>
        );

      case 4:
        return (
          <>
            <S.BoxContent borderColor={primaryColor}>
              <S.QuestionTitle>
                <H2 color={textColor} fontWeight="bold">
                  Outros
                </H2>
              </S.QuestionTitle>

              <S.Questions>
                <S.Question>
                  <InputSliceAndNumber
                    type="number"
                    maxValue={500}
                    value={formFields.employees || ""}
                    onChange={(val) => onChangeForm("employees", val)}
                    label="Quantas pessoas trabalham em sua propriedade?"
                  />
                </S.Question>

                <S.CompanyQuestion maxWidth="260px">
                  <DropdownMenu
                    label="Bioma"
                    options={biome}
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.producerBiome}
                    placeholder="Selecione o bioma"
                    selected={formFields.producerBiome || ""}
                    onSelect={(val) => onChangeForm("producerBiome", val)}
                  />
                </S.CompanyQuestion>
              </S.Questions>
            </S.BoxContent>
          </>
        );

      default:
        return;
    }
  };

  return (
    <S.Container>
      {formStep === "crops" ? cropsContent() : facilitiesContent()}

      <S.Buttons>
        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          textColor={backgroundColor}
          isVisible={
            (question !== 3 && formStep === "crops") ||
            (question !== 4 && formStep === "facilities")
          }
          backgroundColor={primaryColor}
          onClick={() => onAheadClick()}
        >
          Próximo
        </S.Clickable>

        {question === 3 && formStep === "crops" && (
          <S.CropButtons>
            <S.Clickable
              width="220px"
              variant="solid"
              fontWeight="bold"
              borderColor={primaryColor}
              textColor={backgroundColor}
              onClick={() => onAddCrop()}
              backgroundColor={primaryColor}
              isVisible={question === 3 && formStep === "crops"}
            >
              Adicionar outra Cultura
            </S.Clickable>

            <S.Clickable
              width="220px"
              variant="solid"
              fontWeight="bold"
              borderColor={primaryColor}
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => onChangeFormStep()}
              isVisible={question === 3 && formStep === "crops"}
            >
              Seguir para Propriedade
            </S.Clickable>
          </S.CropButtons>
        )}

        <S.Clickable
          variant="solid"
          fontWeight="bold"
          borderColor={primaryColor}
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => onCalculateClick()}
          isVisible={question === 4 && formStep === "facilities"}
        >
          Calcular
        </S.Clickable>

        <S.Clickable
          variant="outline"
          fontWeight="bold"
          isVisible={question > 1}
          textColor={primaryColor}
          borderColor={primaryColor}
          backgroundColor={backgroundColor}
          onClick={() => onPreviousClick()}
        >
          Anterior
        </S.Clickable>

        <S.Clickable
          width="200px"
          variant="outline"
          fontWeight="bold"
          textColor={primaryColor}
          borderColor={primaryColor}
          onClick={() => onBackCrop()}
          backgroundColor={backgroundColor}
          isVisible={question === 1 && formStep === "facilities"}
        >
          Voltar para Culturas
        </S.Clickable>
      </S.Buttons>
    </S.Container>
  );
};

export default Questions;
