import React, { ChangeEvent, useRef } from "react";

import * as S from "./styles";
import H4 from "../../atoms/Typography/H4";
import { Snackbar, Theme } from "../../../hooks";
import AvailableIcons from "../../atoms/AvailableIcons";

interface IProps {
  image: string;
  label?: string;
  errors?: string[];
  showPreview?: boolean;
  flexDirection?: string;
  onChange: (image: string) => void;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const AddImages: React.FC<IProps> = ({
  image,
  label,
  errors,
  onChange,
  showPreview,
  flexDirection = "row",
}) => {
  const { newError } = Snackbar.useSnackbar();
  const { textColor, tertiaryColor } = Theme.useTheme();

  const input = useRef<HTMLInputElement | null>(null);

  const onUploadHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = (files || [])[0];

    if (file.size > MAX_FILE_SIZE) {
      if (input.current) input.current.value = "";
      return newError("Arquivo muito grande: máx 5Mb");
    }

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64 = reader.result?.toString();

        if (base64) {
          onChange(base64);
        }

        if (input.current) input.current.value = "";
      };
    }
  };

  return (
    <S.Container>
      {label && (
        <H4 color={textColor} fontWeight="bold">
          {label}
        </H4>
      )}

      <S.BoxImages flexDirection={flexDirection}>
        <S.ButtonUploadImage
          borderColor={textColor}
          backgroundColor={tertiaryColor}
        >
          <S.PlusIcon borderColor={textColor}>
            <AvailableIcons option="plus" />
          </S.PlusIcon>
          Adicionar Imagem
          <input
            type="file"
            ref={input}
            onChange={onUploadHandler}
            accept="image/png, image/jpeg, image/jpg, image/webp"
          />
        </S.ButtonUploadImage>

        {showPreview && (
          <S.ImagePreview image={image} borderColor={textColor} />
        )}
      </S.BoxImages>

      {errors && errors.length ? (
        <S.Error color="#FF4D4F">Erro: {errors.join(", ")}</S.Error>
      ) : null}
    </S.Container>
  );
};

export default AddImages;
