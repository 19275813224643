import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";
import { IMenuSections } from "../../../types";
import SeparatorLine from "../../atoms/SeparatorLine";
import AvailableIcons from "../../atoms/AvailableIcons";

const { REACT_APP_IBIOMA_LOGO } = process.env;

const sections: IMenuSections[] = [
  { icon: "globe", label: "Projetos", navigate: "projetos" },
  { icon: "calculator", label: "Calcule emissões", navigate: "calculadora" },
  { icon: "", label: "", navigate: "" },
  { icon: "statement", label: "Extrato", navigate: "extrato" },
  { icon: "analytics", label: "Dashboard", navigate: "dashboard" },
  { icon: "megaphone", label: "Report", navigate: "report" },
  { icon: "", label: "", navigate: "" },
  { icon: "folder", label: "Materiais e conteúdos", navigate: "materiais" },
  { icon: "seal", label: "Certificados e selos", navigate: "certificados" },
  { icon: "", label: "", navigate: "" },
  { icon: "person", label: "Meu perfil", navigate: "perfil" },
  { icon: "card", label: "Meus cartões", navigate: "cartoes" },
];

interface IProps {
  calcSection: boolean;
  selectedIndex: number;
  setLogout: (val: boolean) => void;
  setSelected: (index: number) => void;
  setCalcSection: (val: boolean) => void;
}

const Menu: React.FC<IProps> = ({
  setLogout,
  calcSection,
  setSelected,
  selectedIndex,
  setCalcSection,
}) => {
  const navigate = useNavigate();

  const { textColor, tertiaryColor } = Theme.useTheme();

  const onClickHandler = (section: string, index: number) => {
    setSelected(index);

    if (section === "calculadora") {
      setCalcSection(!calcSection);

      return;
    }

    navigate(`/${section}`);
  };

  const onClickCalculatorHandler = (section: string) => {
    setCalcSection(false);
    navigate(`/${section}`);
  };

  return (
    <S.Container backgroundColor={tertiaryColor}>
      <S.Header>
        <S.Logo logo={REACT_APP_IBIOMA_LOGO || ""} />

        <Button
          variant="solid"
          textColor={textColor}
          backgroundColor="#18181810"
          onClick={() => setLogout(true)}
        >
          sair
        </Button>
      </S.Header>

      <S.Sections>
        {sections.map((section, index) => {
          if (section.icon === "") {
            return <SeparatorLine key={index} />;
          }

          return (
            <S.Section
              key={`${section.icon}#${index}`}
              isSelected={index === selectedIndex}
              onClick={() => onClickHandler(section.navigate, index)}
            >
              <S.Icon>
                <AvailableIcons option={section.icon} color={textColor} />
              </S.Icon>

              <H4 color={textColor}>{section.label}</H4>
            </S.Section>
          );
        })}

        {calcSection && (
          <S.CalculatorSections
            borderColor={textColor}
            backgroundColor={tertiaryColor}
          >
            <S.Section
              key="calculadoras"
              onClick={() => onClickCalculatorHandler("calculadora")}
            >
              <S.Icon>
                <AvailableIcons option="calculator" color={textColor} />
              </S.Icon>

              <H4 color={textColor}>Calculadoras</H4>
            </S.Section>

            <S.Section
              key="calculo-atual"
              onClick={() => onClickCalculatorHandler("calculo-atual")}
            >
              <S.Icon>
                <AvailableIcons option="calculator" color={textColor} />
              </S.Icon>

              <H4 color={textColor}>Cálculo atual</H4>
            </S.Section>
          </S.CalculatorSections>
        )}
      </S.Sections>
    </S.Container>
  );
};

export default Menu;
