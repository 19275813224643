import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import * as C from "../../../styles";
import {
  LOCAL_STORAGE_CREDITS_KEY,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../../../../../constants";
import {
  emptySupplier,
  emptyEventCalculatorForm,
} from "../../../../../../utils/calculators";
import {
  IEventCalculatorForm,
  IInputValues,
} from "../../../../../../types/eventCalculator";
import {
  energySources,
  transportUnitsObj,
} from "../../../../../../constants/calculators";
import {
  ICalculatorResult,
  TUnits,
} from "../../../../../../types/adminCalculators";
import Questions from "./components/Questions";
import { Calculators } from "../../../../../../services";
import Button from "../../../../../../components/atoms/Button";
import H1 from "../../../../../../components/atoms/Typography/H1";
import H2 from "../../../../../../components/atoms/Typography/H2";
import H3 from "../../../../../../components/atoms/Typography/H3";
import H5 from "../../../../../../components/atoms/Typography/H5";
import { Snackbar, Loading, Auth, Theme } from "../../../../../../hooks";
import ChartAndIndicators from "../../../../../../components/organisms/ChartAndIndicators";
import CalculatorProgressBar from "../../../../../../components/molecules/CalculatorProgressBar";

const validateForm = (
  data: IEventCalculatorForm
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.eventVertical)
    errors.eventVertical = [
      ...(errors.eventVertical || []),
      "Segmento do evento é obrigatório",
    ];

  if (!data.eventDuration || (data.eventDuration && +data.eventDuration === 0))
    errors.eventDuration = [
      ...(errors.eventDuration || []),
      "Duração do evento é obrigatório",
    ];

  if (
    data.considerEnergy &&
    (data.energySource === "city-energy" || data.energySource === "both") &&
    (!data.energyExpenses || +data.energyExpenses === 0)
  )
    errors.energyExpenses = [
      ...(errors.energyExpenses || []),
      "Energia é obrigatório. Caso queira desconsiderar, marque 'Não' acima",
    ];

  if (
    data.considerEnergy &&
    (data.energySource === "generator" || data.energySource === "both") &&
    (!data.generatorFuelExpenses || +data.generatorFuelExpenses === 0)
  )
    errors.generatorFuelExpenses = [
      ...(errors.generatorFuelExpenses || []),
      "Gasto com gerador é obrigatório. Caso queira desconsiderar, marque 'Não' acima",
    ];

  if (
    data.clientsEmissions &&
    (!data.clientsTransportExpenses || +data.clientsTransportExpenses === 0)
  )
    errors.clientsTransportExpenses = [
      ...(errors.clientsTransportExpenses || []),
      "Distância média dos clientes é obrigatório. Caso queira desconsiderar, marque 'Não' acima",
    ];

  if (
    data.clientsEmissions &&
    !data.clientsPublicTransportation &&
    !data.clientsSelfOrAppVehicles &&
    !data.clientsWalking &&
    !data.publicShortFlights &&
    !data.publicMediumFlights &&
    !data.publicLongFlights
  ) {
    errors.clientsPublicTransportation = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.clientsSelfOrAppVehicles = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.clientsWalking = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.publicShortFlights = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.publicMediumFlights = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];

    errors.publicLongFlights = [
      "Preencha os percentuais dos meios de transporte até somar 100%",
    ];
  }

  if (data.clientsEmissions) {
    let percentualValue = 0;
    percentualValue += data.clientsPublicTransportation
      ? +data.clientsPublicTransportation
      : 0;
    percentualValue += data.clientsSelfOrAppVehicles
      ? +data.clientsSelfOrAppVehicles
      : 0;
    percentualValue += data.clientsWalking ? +data.clientsWalking : 0;
    percentualValue += data.publicShortFlights ? +data.publicShortFlights : 0;
    percentualValue += data.publicMediumFlights ? +data.publicMediumFlights : 0;
    percentualValue += data.publicLongFlights ? +data.publicLongFlights : 0;

    if (percentualValue !== 100) {
      errors.clientsPublicTransportation = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.clientsSelfOrAppVehicles = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.clientsWalking = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.publicShortFlights = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.publicMediumFlights = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];

      errors.publicLongFlights = [
        "Preencha os percentuais dos meios de transporte até somar 100%",
      ];
    }
  }

  return errors;
};

interface IProps {
  filledForm?: IEventCalculatorForm;
}

const Calculator: React.FC<IProps> = ({
  filledForm = emptyEventCalculatorForm,
}) => {
  const [calculatorForm, setCalculatorForm] =
    useState<IEventCalculatorForm>(filledForm);
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [columnOptions, setColumnOptions] = useState(window.innerWidth <= 820);
  const [calculatorResult, setCalculatorResult] = useState<ICalculatorResult>();
  const [suppliers, setSuppliers] = useState<IInputValues[]>(
    filledForm.suppliers || [emptySupplier]
  );

  const questionsTop = useRef<HTMLDivElement>(null);

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, backgroundColor, textColor } = Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setColumnOptions(window.innerWidth <= 820);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [columnOptions]);

  const onChangeHandler = (
    key: keyof IEventCalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (key === "considerEnergy") {
        return {
          ...curr,
          [key]: value === "Sim" ? true : false,
        };
      }

      if (
        key === "internalTransportUnit" ||
        key === "employeesTransportUnit" ||
        key === "employeesPublicTransportUnit"
      ) {
        return {
          ...curr,
          [key]: Object.values(transportUnitsObj)[+value],
        };
      }

      if (key === "energySource") {
        return {
          ...curr,
          [key]: energySources[+value],
        };
      }

      if (key === "generatorFuelUnit") {
        return {
          ...curr,
          [key]: value ? ("R$" as TUnits) : ("l" as TUnits),
        };
      }

      if (key === "cookingGasUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "Kg",
        };
      }

      if (key === "energyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      if (key === "clientsEmissions" || key === "suppliersEmissions") {
        return {
          ...curr,
          [key]: value === 0 ? true : false,
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      const currErrors = validateForm(calculatorForm);

      if (currErrors && Object.keys(currErrors).length > 0) {
        setErrors(currErrors);

        if (
          currErrors.energyExpenses.length > 0 ||
          currErrors.generatorFuelExpenses.length > 0
        ) {
          setQuestionNumber(2);
        } else if (
          currErrors.clientsPublicTransportation.length > 0 ||
          currErrors.clientsSelfOrAppVehicles.length > 0 ||
          currErrors.clientsWalking.length > 0 ||
          currErrors.publicShortFlights.length > 0 ||
          currErrors.publicMediumFlights.length > 0 ||
          currErrors.publicLongFlights.length > 0
        ) {
          setQuestionNumber(3);
        } else if (
          currErrors.eventDuration.length > 0 ||
          currErrors.eventVertical.length > 0
        ) {
          setQuestionNumber(4);
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
        );
      }

      setErrors({});

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const result = await Calculators.sendEventInformation(
        {
          ...calculatorForm,
          userId: user.id || "",
          cpf: user.cpf || undefined,
          cnpj: user.cnpj || undefined,
          contactMail: user.mail || "",
          eventName: user.company || "",
          eventCity: user.companyCity || "",
          cpfOrCnpj: user.cpfOrCnpj || "cnpj",
          contactName: user.contactName || "",
          eventState: user.companyState || "",
          contactPhone: user.contactPhone || "",
          suppliers: calculatorForm.suppliersEmissions ? suppliers : undefined,
          energyUnit:
            calculatorForm.energyExpenses && !calculatorForm.energyUnit
              ? "KWh"
              : calculatorForm.energyUnit,
          cookingGasUnit:
            calculatorForm.cookingGasExpenses && !calculatorForm.cookingGasUnit
              ? "Kg"
              : calculatorForm.cookingGasUnit,
          generatorFuelUnit:
            calculatorForm.generatorFuelExpenses &&
            !calculatorForm.generatorFuelUnit
              ? "l"
              : calculatorForm.generatorFuelUnit,
          internalTransportUnit:
            calculatorForm.internalTransportExpenses &&
            !calculatorForm.internalTransportUnit
              ? "R$"
              : calculatorForm.internalTransportUnit,
          employeesTransportUnit:
            calculatorForm.employeesTransportExpenses &&
            !calculatorForm.employeesTransportUnit
              ? "R$"
              : calculatorForm.employeesTransportUnit,
          employeesPublicTransportUnit:
            calculatorForm.employeesPublicTransportExpenses &&
            !calculatorForm.employeesPublicTransportUnit
              ? "R$"
              : calculatorForm.employeesPublicTransportUnit,
          generatorFuelType: "oleo diesel (comercial)",
          energyExpenses:
            calculatorForm.considerEnergy &&
            (calculatorForm.energySource === "city-energy" ||
              calculatorForm.energySource === "both")
              ? calculatorForm.energyExpenses
              : undefined,
          generatorFuelExpenses:
            calculatorForm.considerEnergy &&
            (calculatorForm.energySource === "generator" ||
              calculatorForm.energySource === "both")
              ? calculatorForm.generatorFuelExpenses
              : undefined,
        },
        token
      );

      setCalculatorResult(result);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const onBuyCredits = () => {
    localStorage.setItem(
      LOCAL_STORAGE_CREDITS_KEY,
      Math.ceil(calculatorResult?.totalEmissions || 0).toString()
    );

    localStorage.setItem(LOCAL_STORAGE_CALCULATOR_KEY, "event");

    localStorage.setItem(
      LOCAL_STORAGE_CALCULATION_ID_KEY,
      calculatorResult?.id || ""
    );

    navigate("/comprar");
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setCalculatorForm(emptyEventCalculatorForm);
    setErrors({});
    setQuestionNumber(1);
  };

  const onCreateSupplier = () => {
    setSuppliers((curr) => [...curr, emptySupplier]);
  };

  const onAddSuppliers = (
    index: number,
    key: keyof IInputValues,
    value: string
  ) => {
    setSuppliers((curr) => {
      const newData = [...curr];
      newData[index] = { ...newData[index], [key]: value };

      return newData;
    });
  };

  const onRemoveSuppliers = (index: number) => {
    setSuppliers((curr) => {
      const newData = [...curr];
      newData.splice(index, 1);

      return newData;
    });
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const booleanOptions = ["Sim", "Não"];

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  return (
    <C.Container>
      <C.TitleAndProgress ref={questionsTop}>
        <C.TitleAndDescription>
          <H1 color={textColor} fontWeight="bold">
            Calculadora - Eventos
          </H1>

          {!calculatorResult && (
            <H5 color={textColor}>
              Detalhe sua operação respondendo às perguntas abaixo e descubra
              sua pegada mensal de carbono
            </H5>
          )}
        </C.TitleAndDescription>

        {!calculatorResult && (
          <CalculatorProgressBar
            numberOfQuestions={4}
            currentQuestion={questionNumber}
          />
        )}
      </C.TitleAndProgress>

      <C.Content resultBox={!!calculatorResult}>
        {calculatorResult && (
          <C.ChartResult>
            <H2 color={textColor} fontWeight="600">
              A pegada mensal de carbono de seu evento é:
            </H2>

            <H2 color={textColor}>
              {calculatorResult.totalEmissions.toFixed(2).replace(".", ",")}{" "}
              toneladas de CO<sub>2</sub>e
            </H2>

            <H3 color={textColor}>
              Clique abaixo para compensar suas emissões
            </H3>

            <Button
              variant="solid"
              fontWeight="bold"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => onBuyCredits()}
            >
              Compre créditos
            </Button>
          </C.ChartResult>
        )}

        <C.CalculatorBox resultBox={!!calculatorResult}>
          {calculatorResult ? (
            <>
              <C.Charts>
                <C.ChartBox>
                  <ChartAndIndicators result={calculatorResult} />
                </C.ChartBox>
              </C.Charts>

              <C.Buttons>
                <Button
                  variant="solid"
                  fontWeight="bold"
                  textColor={backgroundColor}
                  backgroundColor={primaryColor}
                  onClick={() => onBuyCredits()}
                >
                  Compre créditos
                </Button>

                <Button
                  variant="outline"
                  fontWeight="bold"
                  textColor={primaryColor}
                  borderColor={primaryColor}
                  backgroundColor={backgroundColor}
                  onClick={() => onNewConsultHandler()}
                >
                  Nova consulta
                </Button>
              </C.Buttons>
            </>
          ) : (
            <Questions
              errors={errors}
              suppliers={suppliers}
              question={questionNumber}
              formFields={calculatorForm}
              columnOptions={columnOptions}
              booleanOptions={booleanOptions}
              onAheadClick={() => onAheadClick()}
              transportOptions={transportUnitsOptions}
              onPreviousClick={() => onPreviousClick()}
              createSupplier={() => onCreateSupplier()}
              onCalculateClick={() => onCalculateHandler()}
              removeSuppliers={(index) => onRemoveSuppliers(index)}
              onChangeForm={(key, val) => onChangeHandler(key, val)}
              addSuppliers={(index, key, value) =>
                onAddSuppliers(index, key, value)
              }
            />
          )}
        </C.CalculatorBox>
      </C.Content>
    </C.Container>
  );
};

export default Calculator;
