export const LOCAL_STORAGE_USERNAME_KEY = "@username";
export const LOCAL_STORAGE_TOKEN_KEY = "@token";
export const LOCAL_STORAGE_USER_KEY = "@user";
export const LOCAL_STORAGE_CREDITS_KEY = "@credits";
export const LOCAL_STORAGE_CALCULATOR_KEY = "@calculator";
export const LOCAL_STORAGE_CALCULATION_ID_KEY = "@calculationId";
export const LOCAL_STORAGE_AUTHENTICATION_METHOD = "@authenticationMethod";

export const PAGE_VIEW_ACTION = "PAGE_VIEW";
export const BTN_CLICK_ACTION = "BTN_CLICK";
