import { Theme } from "../../../../hooks";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import Button from "../../../../components/atoms/Button";
import H2 from "../../../../components/atoms/Typography/H2";
import H4 from "../../../../components/atoms/Typography/H4";
import { defaultPrimaryColor } from "../../../../constants/colors";

import ibioma from "../../../../components/assets/ibioma_logo.png";

interface IProps {
  logo?: string;
  company: string;
  contactName: string;
}

const SuccessfulChange: React.FC<IProps> = ({ company, logo, contactName }) => {
  const navigate = useNavigate();

  const { primaryColor, textColor, tertiaryColor, backgroundColor } =
    Theme.useTheme();

  return (
    <S.Container color={tertiaryColor}>
      <S.Header>
        <S.Logo logo={ibioma} />

        <S.Login>
          <Button
            variant="solid"
            fontWeight="bold"
            onClick={() => navigate("/login")}
            textColor={backgroundColor || "#fafafa"}
            backgroundColor={primaryColor || defaultPrimaryColor}
          >
            Login
          </Button>
        </S.Login>
      </S.Header>

      <S.Content>
        <S.LogoCompany logo={logo || ""} />

        <S.Cta>
          <H2 color={primaryColor}>
            Olá, {contactName} | {company}!{" "}
          </H2>

          <H4 color={textColor}>Sua senha foi atualizada.</H4>
        </S.Cta>

        <Button
          variant="solid"
          fontWeight="600"
          textColor={backgroundColor}
          backgroundColor={primaryColor}
          onClick={() => navigate("/login")}
        >
          Acesse sua conta
        </Button>
      </S.Content>
    </S.Container>
  );
};

export default SuccessfulChange;
