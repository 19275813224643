import styled, { css } from "styled-components";

type ContainerProps = {
  backgroundColor: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ backgroundColor }) => css`
    display: none;

    @media (max-width: 1160px) {
      bottom: 0;
      gap: 24px;
      width: 100%;
      display: flex;
      z-index: 1000;
      position: fixed;
      overflow: visible;
      padding: 20px 40px;
      align-items: center;
      justify-content: center;
      border-radius: 20px 20px 0 0;
      backdrop-filter: blur(3.5px);
      -webkit-backdrop-filter: blur(3.5px);
      background-color: ${backgroundColor}99;
    }
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
  background-color: #00000000;
`;

export const Sections = styled.div`
  gap: 16px;
  width: 80%;
  display: flex;
  justify-content: space-around;
`;

type SectionProps = {
  isSelected: boolean;
};

export const Section = styled.div<SectionProps>`
  ${({ isSelected }) => css`
    gap: 12px;
    width: 100%;
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 8px 12px;
    position: relative;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    background-color: ${isSelected ? "#1818184D" : "transparent"};
  `}
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const OptionIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

type SectionOptionsProps = {
  borderColor: string;
  backgroundColor: string;
};

export const SectionOptions = styled.div<SectionOptionsProps>`
  ${({ borderColor, backgroundColor }) => css`
    gap: 12px;
    bottom: 48px;
    padding: 12px;
    display: flex;
    z-index: 1000;
    border-radius: 8px;
    position: absolute;
    flex-direction: column;
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
  `}
`;

type OptionProps = {
  isLast: boolean;
};

export const Option = styled.div<OptionProps>`
  ${({ isLast }) => css`
    gap: 8px;
    display: flex;
    align-items: center;
    padding-bottom: ${!isLast && "8px"};
    border-bottom: ${!isLast && "1px solid #000000"};
  `}
`;
