import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-right: 24px;

  @media (max-width: 840px) {
    padding-right: 0px;
  }
`;

type BarProps = {
  percentage: number;
  primaryColor: string;
  tertiaryColor: string;
};

export const Bar = styled.input<BarProps>`
  ${({ percentage, primaryColor, tertiaryColor }) => css`
    height: 2px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    -webkit-appearance: none;
    --webkitProgressPercent: ${percentage}%;

    &::-webkit-slider-thumb {
      width: 20px;
      height: 20px;
      margin-top: -8px;
      border-radius: 50%;
      -webkit-appearance: none;
      background: ${primaryColor};
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      margin-top: -8px;
      border-radius: 50%;
      -webkit-appearance: none;
      background: ${primaryColor};
    }

    &::-webkit-slider-runnable-track {
      height: 4px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      background-color: ${tertiaryColor};
    }

    &::-moz-range-track {
      height: 4px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      background-color: ${tertiaryColor};
    }

    &::-moz-range-progress {
      background-color: ${primaryColor};
    }

    &::-webkit-slider-runnable-track {
      background-image: linear-gradient(
        90deg,
        ${primaryColor} var(--webkitProgressPercent),
        ${tertiaryColor} var(--webkitProgressPercent)
      );
    }
  `}
`;
