import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import { Theme } from "../../../hooks";
import { defaultTertiaryColor } from "../../../constants/colors";

const { REACT_APP_IBIOMA_LOGO } = process.env;

interface IProps {
  bgColor?: string;
}

const RegisterPage: React.FC<PropsWithChildren<IProps>> = ({
  children,
  bgColor,
}) => {
  const navigate = useNavigate();

  const { backgroundColor, textColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.Page backgroundColor={bgColor || backgroundColor}>
      <S.Menu backgroundColor={tertiaryColor || defaultTertiaryColor}>
        <S.Logo logo={REACT_APP_IBIOMA_LOGO || ""} />

        <S.LoginButton
          variant="solid"
          fontWeight="500"
          textColor={textColor}
          backgroundColor={backgroundColor}
          onClick={() => navigate("/login")}
        >
          Login
        </S.LoginButton>
      </S.Menu>

      <S.Container>{children}</S.Container>
    </S.Page>
  );
};

export default RegisterPage;
