import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
`;

type ColorProps = {
  backgroundColor: string;
};

export const OptionBox = styled.div<ColorProps>`
  ${({ backgroundColor }) => css`
    width: 20px;
    height: 20px;
    display: flex;
    flex-shrink: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
  `}
`;

type OptionProps = {
  isSelected: boolean;
  backgroundColor: string;
};

export const OptionSelected = styled.div<OptionProps>`
  ${({ isSelected, backgroundColor }) => css`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${backgroundColor};
    display: ${isSelected ? "flex" : "none"};
  `}
`;
