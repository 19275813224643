import axios from "axios";

import {
  IOrder,
  ICardsData,
  IAsaasClient,
  IOrderPayload,
} from "../../types/order";
import { IUser } from "../../types";

const { REACT_APP_ORDER_API_BASE_URL } = process.env;

const getStatements = async (
  entity: string,
  token: string
): Promise<IOrder[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_ORDER_API_BASE_URL}/statements/${entity}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.orders;
  } catch (error) {
    throw new Error("Houve um erro ao obter os dados do extrato.");
  }
};

const getStatement = async (
  entity: string,
  statementId: string,
  token: string
): Promise<IOrder> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_ORDER_API_BASE_URL}/statements/${entity}/${statementId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.order;
  } catch (error) {
    throw new Error("Houve um erro ao obter os dados do extrato detalhado.");
  }
};

const createAsaasClient = async (
  clientData: IAsaasClient,
  entity: string,
  token: string
): Promise<IUser> => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_ORDER_API_BASE_URL}/create-asaas-client`,
      { ...clientData, entity },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.user;
  } catch (error) {
    throw new Error("Houve um erro ao criar o cliente no Asaas.");
  }
};

const getCreditCards = async (
  entity: string,
  token: string
): Promise<ICardsData[]> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_ORDER_API_BASE_URL}/cards/${entity}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.cards;
  } catch (error) {
    throw new Error("Houve um erro ao obter os cartões cadastrados.");
  }
};

const saveCreditCard = async (
  cardData: ICardsData,
  entity: string,
  token: string,
  asaasId: string
): Promise<ICardsData[]> => {
  try {
    const ipData = await axios.get("https://ipinfo.io?token=3b4ad5df6132ec");

    const { data } = await axios.post(
      `${REACT_APP_ORDER_API_BASE_URL}/cards`,
      { ...cardData, entity, asaasId, remoteIp: ipData.data.ip },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.cards;
  } catch (error) {
    throw new Error("Houve um erro ao salvar o cartão.");
  }
};

const removeCreditCard = async (
  cardId: string,
  entity: string,
  token: string
): Promise<ICardsData[]> => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_ORDER_API_BASE_URL}/cards/${entity}/${cardId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.cards;
  } catch (error) {
    throw new Error("Houve um erro ao remover o cartão.");
  }
};

const createOrder = async (
  orderData: IOrderPayload,
  saveCard: boolean,
  entity: string,
  token: string
): Promise<IOrder> => {
  try {
    const normOrderData = {
      ...orderData,
      entity: entity,
      saveCard: saveCard,
      remoteIp:
        orderData.billingType === "CREDIT_CARD"
          ? (await axios.get("https://ipinfo.io?token=3b4ad5df6132ec")).data.ip
          : undefined,
    };

    const { data } = await axios.post(
      `${REACT_APP_ORDER_API_BASE_URL}/payment`,
      normOrderData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.order;
  } catch (error) {
    throw new Error("Houve um erro ao criar o pagamento.");
  }
};

export default {
  createOrder,
  getStatement,
  getStatements,
  getCreditCards,
  saveCreditCard,
  removeCreditCard,
  createAsaasClient,
};
