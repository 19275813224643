import { ILogisticRoutes, TVehicle } from "../types/logisticCalculator";

export interface ICsv {
  Origem: string;
  Destino: string;
  Trechos: string;
  Veículo: TVehicle;
}

const FILE_FIELDS_NUMBER = 4;

export const normalizeExcelData = (
  data: string[][]
): ILogisticRoutes[] | null => {
  const [header, ...currentData] = data;

  if (header.length !== FILE_FIELDS_NUMBER) return null;

  const normData = currentData.reduce(
    (acc: ILogisticRoutes[], curr: string[]) => {
      if (Object.keys(curr).length !== FILE_FIELDS_NUMBER) return acc;

      const normRoute = {
        travels: +curr[3],
        departure: curr[0],
        destination: curr[1],
        vehicle: curr[2] as TVehicle,
      };

      acc.push(normRoute);

      return acc;
    },
    []
  );

  return normData;
};

export const normalizeCsvData = (data: ICsv[]): ILogisticRoutes[] => {
  const normData = data.reduce((acc: ILogisticRoutes[], curr: ICsv) => {
    if (Object.keys(curr).length !== FILE_FIELDS_NUMBER) return acc;

    const normRoute = {
      travels: +curr.Trechos,
      departure: curr.Origem,
      vehicle: curr["Veículo"],
      destination: curr.Destino,
    };

    acc.push(normRoute);

    return acc;
  }, []);

  return normData;
};
