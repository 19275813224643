import styled, { css } from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const Projects = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  margin-top: 40px;
  grid-template-columns: repeat(auto-fill, 360px);

  @media (max-width: 1400px) {
    grid-template-columns: repeat(auto-fill, 320px);
  }

  @media (max-width: 1112px) {
    grid-template-columns: repeat(auto-fill, 360px);
  }

  @media (max-width: 860px) {
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 320px);
  }
`;

type ProjectCardProps = {
  backgroundImage: string;
};

export const ProjectCard = styled.div<ProjectCardProps>`
  ${({ backgroundImage }) => css`
    gap: 32px;
    width: 360px;
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 40px 28px;
    border-radius: 32px;
    flex-direction: column;
    background: url(${backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h4 {
      text-align: justify;
    }

    @media (max-width: 1400px) {
      width: 320px;
    }

    @media (max-width: 1112px) {
      width: 360px;
    }

    @media (max-width: 860px) {
      width: 320px;
    }
  `}
`;

export const Details = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const IconAndText = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
