import styled, { css } from "styled-components";

import Button from "../../../../../../../../components/atoms/Button";
import { Box } from "../../../../../Calculadora/components/Calculator/components/Questions/styles";

export const Container = styled.div`
  gap: 48px;
  width: 100%;
  display: flex;
  min-height: 400px;
  padding: 0 60px 48px;
  flex-direction: column;

  @media (max-width: 1160px) {
    padding: 0 0 48px;
  }
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Questions = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 0 40px;
  flex-direction: column;

  @media (max-width: 580px) {
    padding: 0;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyQuestions = styled.div`
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
`;

type CompanyQuestionProps = {
  maxWidth: string;
};

export const CompanyQuestion = styled.div<CompanyQuestionProps>`
  ${({ maxWidth }) => css`
    gap: 32px;
    width: 100%;
    display: flex;
    max-width: ${maxWidth};
  `}
`;

export const SliderAndNumber = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

type ButtonsProps = {
  isVisible: boolean;
};

export const Clickable = styled(Button)<ButtonsProps>`
  ${({ isVisible }) => css`
    width: 126px;
    align-items: center;
    justify-content: center;
    display: ${isVisible ? "flex" : "none"};
  `}
`;

type BoxProps = {
  marginTop?: string;
};

export const BoxContent = styled(Box)<BoxProps>`
  ${({ marginTop }) => css`
    margin-top: ${marginTop ? marginTop : 0};
  `}
`;
