import styled, { css } from "styled-components";

type ContainerProps = {
  borderColor: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ borderColor }) => css`
    display: flex;
    border-radius: 8px;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${borderColor};
  `}
`;

type UnitProps = {
  isSelected: boolean;
  backgroundColor: string;
};

export const Unit = styled.div<UnitProps>`
  ${({ isSelected, backgroundColor }) => css`
    display: flex;
    cursor: pointer;
    padding: 8px 12px;
    user-select: none;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    color: ${isSelected ? "#f9fafb" : "#000000"};
    background-color: ${isSelected && backgroundColor};
  `}
`;
