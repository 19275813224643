import styled, { css } from "styled-components";

type ButtonProps = {
  width?: string;
  textColor?: string;
  disabled?: boolean;
  fontWeight?: string;
  borderColor?: string;
  backgroundColor?: string;
  size?: "medium" | "large" | "small";
  variant?: "default" | "solid" | "outline" | "icon" | "dashed" | "backButton";
};

const buttonModifier = {
  solid: (backgroundColor: string) => css`
    border-radius: 24px;
    background-color: ${backgroundColor};
  `,
  outline: (borderColor: string) => css`
    background: none;
    border-radius: 24px;
    border: 1px solid ${borderColor};
  `,
  dashed: (borderColor: string, backgroundColor: string) => css`
    border-radius: 24px;
    border: 1px dashed ${borderColor};
    background-color: ${backgroundColor};
  `,
  backButton: (backgroundColor: string) => css`
    width: 48px;
    height: 48px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};

    svg {
      width: 60%;
      height: 60%;
    }
  `,
  icon: (backgroundColor: string, size: string) => css`
    gap: 20px;
    display: flex;
    padding: 12px 16px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};

    svg {
      width: ${size == "small" ? "15px" : "24px"};
      height: ${size == "small" ? "15px" : "24px"};
    }
  `,
  small: () => css`
    padding: 8px 0px;
  `,
  medium: () => css`
    padding: 12px 0px;
  `,
  large: () => css`
    padding: 18px 0px;
  `,
};

const Button = styled.button<ButtonProps>`
  ${({
    width = "100%",
    size = "medium",
    disabled = false,
    variant = "default",
    fontWeight = "regular",
    textColor = "#000000",
    borderColor = "#fafafa",
    backgroundColor = ["solid", "icon"].includes(variant)
      ? "#fafafa0D"
      : "none",
  }) => {
    return css`
      width: ${width};
      font-size: 16px;
      color: ${textColor};
      font-weight: ${fontWeight};
      cursor: ${disabled ? "not-allowed" : "pointer"};

      ${variant === "solid" && buttonModifier[variant](backgroundColor)}
      ${variant === "outline" && buttonModifier[variant](borderColor)}
      ${variant === "dashed" &&
      buttonModifier[variant](borderColor, backgroundColor)}
      ${variant === "icon" && buttonModifier[variant](backgroundColor, size)}
      ${variant === "backButton" && buttonModifier[variant](backgroundColor)}
      ${!["default", "icon"].includes(variant) && buttonModifier[size]()}

      &:disabled, &[disabled] {
        color: ${textColor}80;
        border-color: ${borderColor}80;
        background-color: ${backgroundColor.length === 9
          ? backgroundColor
          : `${backgroundColor}80`};
        cursor: not-allowed;

        svg {
          opacity: 0.5;
        }
      }
    `;
  }}
`;

export default Button;
