import * as S from "./styles";
import H4 from "../Typography/H4";
import { Theme } from "../../../hooks";

interface IProps {
  label: string;
  isSelected: boolean;
  setSelected: () => void;
}

const Option: React.FC<IProps> = ({ label, isSelected, setSelected }) => {
  const { textColor, primaryColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.Container onClick={setSelected}>
      <S.OptionBox backgroundColor={tertiaryColor}>
        <S.OptionSelected
          isSelected={isSelected}
          backgroundColor={primaryColor}
        />
      </S.OptionBox>

      <H4 color={textColor}>{label}</H4>
    </S.Container>
  );
};

export default Option;
