import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  padding-left: 12px;
  flex-direction: column;
  justify-content: flex-start;
`;

type OptionsProps = {
  direction: string;
};

export const Options = styled.div<OptionsProps>`
  ${({ direction }) => css`
    gap: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: ${direction};
  `}
`;
