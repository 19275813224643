/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import {
  IOrder,
  TBilling,
  ICardsData,
  TRecurrence,
  ICreditCard,
  IOrderPayload,
  IOrderDetails,
  TBillingOption,
  ICreditCardHolderInfo,
} from "../../types/order";
import {
  LOCAL_STORAGE_CREDITS_KEY,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../constants";
import { TCalculators } from "../../types";
import { Order, Project } from "../../services";
import { emptyProjectOrder } from "../../utils";
import Button from "../../components/atoms/Button";
import CompleteBuy from "./components/CompleteBuy";
import Page from "../../components/molecules/Page";
import BillingInfo from "./components/BillingInfo";
// import { TClassification } from "../../types/report";
import H3 from "../../components/atoms/Typography/H3";
import H4 from "../../components/atoms/Typography/H4";
import H5 from "../../components/atoms/Typography/H5";
import { paymentMethod } from "../../constants/order";
import Title from "../../components/atoms/Typography/Title";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import InputText from "../../components/molecules/InputText";
import { volumeSelectedProjects } from "../../utils/numbers";
import OptionsList from "../../components/molecules/OptionsList";
import { creditCardBrand, emptyCardInfo } from "../../utils/order";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import CancelBuyModal from "../../components/molecules/CancelBuyModal";
import BuyProjectsMenu from "../../components/organisms/BuyProjectsMenu";
import BeneficiaryModal from "../../components/molecules/BeneficiaryModal";

const validateForm = (data: ICardsData): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.creditCard.number)
    errors.number = [
      ...(errors.number || []),
      "Número do cartão é obrigatório",
    ];

  if (!data.creditCard.ccv)
    errors.ccv = [...(errors.ccv || []), "CCV é obrigatório"];

  if (!data.creditCard.holderName)
    errors.holderName = [
      ...(errors.holderName || []),
      "Nome no cartão é obrigatório",
    ];

  if (!data.creditCard.expiryMonth)
    errors.expiryMonth = [...(errors.expiryMonth || []), "Mês"];

  if (!data.creditCard.expiryYear)
    errors.expiryYear = [...(errors.expiryYear || []), "Ano"];

  if (!data.creditCardHolderInfo.addressNumber)
    errors.addressNumber = [
      ...(errors.addressNumber || []),
      "Número do endereço é obrigatório",
    ];

  if (!data.creditCardHolderInfo.address)
    errors.address = [...(errors.address || []), "Endereço é obrigatório"];

  if (!data.creditCardHolderInfo.cpfCnpj)
    errors.cpfCnpj = [...(errors.cpfCnpj || []), "CPF/CNPJ é obrigatório"];

  if (!data.creditCardHolderInfo.email)
    errors.email = [...(errors.email || []), "E-mail do contato é obrigatório"];

  if (!data.creditCardHolderInfo.phone)
    errors.phone = [
      ...(errors.phone || []),
      "Telefone do contato é obrigatório",
    ];

  if (!data.creditCardHolderInfo.name)
    errors.name = [...(errors.name || []), "Nome do contato é obrigatório"];

  if (!data.creditCardHolderInfo.postalCode)
    errors.postalCode = [...(errors.postalCode || []), "CEP é obrigatório"];

  return errors;
};

const BuyCredits: React.FC = () => {
  const [billing, setBilling] = useState<IOrder>();
  const [credits, setCredits] = useState<number>();
  const [payment, setPayment] = useState<TBilling>();
  const [volume, setVolume] = useState<string[]>([""]);
  const [newCard, setNewCard] = useState<boolean>(false);
  const [plan, setPlan] = useState<TRecurrence>("annual");
  const [selected, setSelected] = useState<string[]>([""]);
  const [selectedCard, setSelectedCard] = useState<number>(-1);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [completeBuy, setCompleteBuy] = useState<boolean>(false);
  const [billingInfo, setBillingInfo] = useState<boolean>(false);
  const [saveNewCard, setSaveNewCard] = useState<boolean>(false);
  const [availableCards, setAvailableCards] = useState<ICardsData[]>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [beneficiaryModal, setBeneficiaryModal] = useState<boolean>(false);
  const [newCardData, setNewCardData] = useState<ICardsData>(emptyCardInfo);
  // const [classificationIndex, setClassificationIndex] = useState<number>(0);
  const [selectedProjects, setSelectedProjects] = useState<IOrderDetails[]>();
  const [biggerTextarea, setBiggerTextarea] = useState(
    window.innerWidth <= 540
  );
  // const [classification, setClassification] =
  //   useState<TClassification>("neutral");
  const [availableProjects, setAvalaibleProjects] =
    useState<IOrderDetails[]>(emptyProjectOrder);

  const buyCredits = useRef<HTMLDivElement>(null);

  const { user, token, setUserHandler } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, tertiaryColor, backgroundColor, textColor } =
    Theme.useTheme();

  useEffect(() => {
    const handleResize = () => {
      setBiggerTextarea(window.innerWidth <= 540);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [biggerTextarea]);

  useEffect(() => {
    const calculatedCredits = localStorage.getItem(LOCAL_STORAGE_CREDITS_KEY);

    calculatedCredits && setCredits(+calculatedCredits * 12);

    buyCredits.current?.scrollIntoView({ behavior: "smooth" });

    const getProjects = async () => {
      const projectsData = await Project.getProjectsOrder(user.entity, token);

      setAvalaibleProjects(projectsData);
    };

    getProjects();
  }, []);

  useEffect(() => {
    selectedIds();

    const newVolume = volumeSelectedProjects(selectedProjects);
    setVolume(newVolume);
  }, [selectedProjects]);

  useEffect(() => {
    const getCreditCards = async () => {
      try {
        const creditCards = await Order.getCreditCards(user.entity, token);

        setAvailableCards(creditCards);
      } catch (error) {
        newError("Houve um eror ao obter os cartões salvos.");
      }
    };

    getCreditCards();
  }, []);

  const onBackClick = () => {
    if (completeBuy && !billingInfo) {
      setNewCard(false);
      setPlan("annual");
      setSelectedCard(-1);
      setCompleteBuy(false);
      setSaveNewCard(false);
      setPayment(undefined);
      setNewCardData(emptyCardInfo);

      const calculatedCredits = localStorage.getItem(LOCAL_STORAGE_CREDITS_KEY);

      calculatedCredits && setCredits(+calculatedCredits * 12);

      return;
    }

    setCancelModal(true);
  };

  const selectedIds = () => {
    const newSelected = selectedProjects?.map((item) => item.projectId);

    setSelected(newSelected || [""]);
  };

  const onSelectProject = (id: string) => {
    if (plan !== "annual") {
      const currProject = availableProjects.find(
        (item) => item.projectId === id
      );

      if (selected.includes(id)) {
        setSelectedProjects((curr) => {
          const newProjects = curr?.filter((item) => item.projectId !== id);

          return newProjects;
        });

        return;
      }

      currProject &&
        setSelectedProjects((curr) => [...(curr || []), currProject]);

      return;
    } else {
      if (selected.includes(id)) {
        setSelectedProjects((curr) => {
          const newProjects = curr?.filter((item) => item.projectId !== id);

          return newProjects;
        });

        return;
      }

      const multiple = (credits || 12) / 12;

      if (multiple === selectedProjects?.length) {
        newError(
          "Máximo de projetos atingido. Remova, ao menos, um projeto, para adicionar outro"
        );

        return;
      }

      const currProject = availableProjects.find(
        (item) => item.projectId === id
      );

      currProject &&
        setSelectedProjects((curr) => [...(curr || []), currProject]);

      return;
    }
  };

  const onRemoveProject = (index: number) => {
    const currProject = selectedProjects && selectedProjects[index];

    setSelectedProjects((curr) => {
      const newProjects = curr?.filter((item) => item !== currProject);

      return newProjects;
    });

    setErrors((curr) => {
      if (curr[currProject?.projectId || ""]) {
        const newErrors = curr;

        delete newErrors[currProject?.projectId || ""];

        return newErrors;
      }

      return curr;
    });
  };

  const onSelectPlan = (index: number) => {
    setSelectedProjects((curr) => {
      if (!curr) return;

      const normSelectedProjects = curr.map((item) => ({
        ...item,
        quantity: 0,
      }));

      return normSelectedProjects;
    });

    const calculatedCredits = localStorage.getItem(LOCAL_STORAGE_CREDITS_KEY);

    if (planOpt[index] === "Pacote anual") {
      setPlan("annual");
      setPayment("CREDIT_CARD");
      setCredits(+(calculatedCredits || 1) * 12);

      return;
    }

    if (planOpt[index] === "Avulsa") {
      setPlan("single");
      setPayment(undefined);
      setCredits(+(calculatedCredits || 1));

      return;
    }

    setPlan("signature");
    setPayment(undefined);
    setCredits(+(calculatedCredits || 1));
  };

  // Ajustar para validr se a quantidade por projeto é multipla de 12
  const onChangeQuantity = (index: number, val: string) => {
    if (!selectedProjects) return;

    const newVal = +val.replace(/[^0-9]/g, "");

    setSelectedProjects((curr) => {
      return curr?.map((item, i) => {
        if (index === i) {
          if (newVal > item.availableCredits) {
            setErrors((currError) => ({
              ...currError,
              [item.projectId]: [
                `Quantidade máxima: ${item.availableCredits} créditos`,
              ],
            }));

            return {
              ...item,
              quantity: item.availableCredits,
            };
          }

          return {
            ...item,
            quantity: newVal,
          };
        }
        return item;
      });
    });
  };

  const onCompleteBuy = () => {
    if (plan === "annual") {
      selectedProjects?.map((item) => {
        if (item.quantity % 12 !== 0) {
          setErrors((currError) => ({
            ...currError,
            [item.projectId]: [
              `Deve-se escolher múltiplos de 12, para ter, no mínimo, 1 crédito do projeto por mês`,
            ],
          }));
        } else {
          setErrors({});
          setCompleteBuy(true);
          buyCredits.current?.scrollIntoView({ behavior: "smooth" });
        }
      });

      return;
    }

    setCompleteBuy(true);
    buyCredits.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onSelectPaymentMethod = (value: TBillingOption) => {
    if (!value) return;

    setPayment(
      Object.keys(paymentMethod).find(
        (key) => paymentMethod[key as TBilling] === value
      ) as TBilling
    );

    if (value === "Boleto" || value === "Pix") {
      setSelectedCard(-1);
    }
  };

  const onAddNewCard = () => {
    setSelectedCard(-1);
    setNewCard(true);
  };

  const onChangeCardInfo = (key: keyof ICreditCard, val: string) => {
    if (key === "number") {
      const currBrand = creditCardBrand(val);

      setNewCardData((curr) => ({
        ...curr,
        creditCard: {
          ...curr.creditCard,
          [key]: val,
          brand: currBrand,
        },
      }));

      return;
    }

    setNewCardData((curr) => ({
      ...curr,
      creditCard: {
        ...curr.creditCard,
        [key]: val,
      },
    }));
  };

  const onChangeCardHolderInfo = (
    key: keyof ICreditCardHolderInfo,
    val: string
  ) => {
    setNewCardData((curr) => ({
      ...curr,
      creditCardHolderInfo: {
        ...curr.creditCardHolderInfo,
        [key]: val,
      },
    }));
  };

  const onSelectCard = (val: number) => {
    setSelectedCard((curr) => {
      if (curr === val) return -1;

      return val;
    });
  };

  const onDiscardNewCard = () => {
    setErrors({});
    setNewCard(false);
    setSaveNewCard(false);
    setNewCardData(emptyCardInfo);
  };

  const onBuyClick = async () => {
    try {
      showLoading();

      if (payment === "CREDIT_CARD" && newCard) {
        const currErrors = validateForm(newCardData);

        if (currErrors && Object.keys(currErrors).length > 0) {
          setErrors(currErrors);
          newError("Verifique as informações do cartão");

          return;
        }
      }

      setErrors({});

      const updatedUser = (async () => {
        if (!user.asaasId) {
          const newUser = await Order.createAsaasClient(
            {
              email: user.mail,
              company: user.company,
              phone: user.contactPhone,
              externalReference: user.id,
              mobilePhone: user.contactPhone,
              address: newCardData.creditCardHolderInfo.address,
              postalCode: newCardData.creditCardHolderInfo.postalCode,
              addressNumber: newCardData.creditCardHolderInfo.addressNumber,
              complement: newCardData.creditCardHolderInfo.addressComplement,
              name: user.contactName || newCardData.creditCardHolderInfo.name,
              cpfCnpj: (
                user.cnpj ||
                user.cpf ||
                newCardData.creditCardHolderInfo.cpfCnpj
              ).replace(/[^0-9]/g, ""),
            },
            user.entity,
            token
          );

          return newUser;
        }

        return user;
      })();

      const calculator = localStorage.getItem(LOCAL_STORAGE_CALCULATOR_KEY);
      const calculationId = localStorage.getItem(
        LOCAL_STORAGE_CALCULATION_ID_KEY
      );

      // Atualizando user. Ao realizar um novo login, as informações novas já serão importadas
      if (JSON.stringify(user) !== JSON.stringify(updatedUser))
        setUserHandler({
          ...(await updatedUser),
          currentCalculation: calculationId || "",
          currentCalculationType: (calculator as TCalculators) || "admin",
        });

      // Primeira cobrança terá vencimento no dia da compra
      const date = new Date();
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1;
      const normDate = `${date.getFullYear()}-${month}-${date.getDate()}`;

      const creditCardData = (() => {
        if (selectedCard >= 0 && availableCards && availableCards.length > 0) {
          return availableCards[selectedCard];
        }

        if (newCard && selectedCard < 0) {
          return {
            creditCardToken: undefined,
            creditCard: newCardData.creditCard,
            creditCardHolderInfo: newCardData.creditCardHolderInfo,
          };
        }

        return null;
      })();

      const orderPayload: IOrderPayload = {
        plan: plan,
        dueDate: normDate,
        classification: "neutral",
        calculator: calculator || "",
        value: quantitySum?.value || 0,
        credits: quantitySum?.credits || 0,
        calculationId: calculationId || "",
        calculationType: calculator as TCalculators,
        customer: (await updatedUser).asaasId || "",
        simplesNacional: user.simplesNacional ? true : false,
        billingType: plan === "annual" ? "CREDIT_CARD" : payment || "PIX",
        projects: (selectedProjects || emptyProjectOrder).map((item) => ({
          pricing: item.pricing,
          name: item.projectName,
          quantity: item.quantity,
          projectId: item.projectId,
        })),
        creditCard:
          (selectedCard >= 0 || newCard) && creditCardData
            ? creditCardData.creditCard
            : undefined,
        creditCardToken:
          (selectedCard >= 0 || newCard) && creditCardData
            ? creditCardData.creditCardToken
            : undefined,
        creditCardHolderInfo:
          (selectedCard >= 0 || newCard) && creditCardData
            ? creditCardData.creditCardHolderInfo
            : undefined,
      };

      const orderResponse = await Order.createOrder(
        orderPayload,
        saveNewCard,
        user.entity,
        token
      );

      setBilling(orderResponse);

      setBillingInfo(true);
      setSaveNewCard(false);
      setNewCardData((curr) => ({
        ...curr,
        creditCard: {
          ...curr.creditCard,
          ccv: "***",
          number: `************${curr.creditCard.number.substring(12)}`,
        },
      }));

      // limpar local storage
      localStorage.removeItem(LOCAL_STORAGE_CREDITS_KEY);
      localStorage.removeItem(LOCAL_STORAGE_CALCULATOR_KEY);
      localStorage.removeItem(LOCAL_STORAGE_CALCULATION_ID_KEY);

      newSuccess("Ordem de compra criada com sucesso!");
    } catch (error) {
      newError("Houve um erro ao finalizar a compra");
    } finally {
      hideLoading();
    }
  };

  const projectWidth = "40%";
  const tCo2Width = "12%";
  const volumeMarginLeft = "8%";
  const volumeMarginLeftValue = "10%";
  const volumeWidth = "20%";
  const pricingWidth = "20%";

  const projects =
    selectedProjects && selectedProjects.length > 0
      ? selectedProjects.map((item) => item.projectName).join(", ")
      : null;

  const quantitySum = selectedProjects?.reduce(
    (acc, curr) => {
      acc.credits += curr.quantity;
      acc.value += curr.quantity * curr.pricing;

      return acc;
    },
    { credits: 0, value: 0 }
  );

  const enableCompleteBuy =
    quantitySum &&
    quantitySum.credits &&
    credits &&
    quantitySum.credits >= credits
      ? true
      : false;

  const calculatorType = localStorage.getItem(LOCAL_STORAGE_CALCULATOR_KEY);

  const planOpt =
    calculatorType === "event"
      ? ["Avulsa", "Pacote anual"]
      : ["Assinatura", "Pacote anual"];

  // const classificationOpt = ["Negativo", "Neutro"];

  // const onChangeClassification = (index: number) => {
  //   if (
  //     classificationOpt[index] === "Negativo" &&
  //     classification === "negative"
  //   )
  //     return;

  //   if (classificationOpt[index] === "Neutro" && classification === "neutral")
  //     return;

  //   if (classificationOpt[index] === "Negativo") {
  //     setClassificationIndex(index);
  //     setClassification("negative");
  //     setCredits((curr) => curr && 2 * curr);

  //     return;
  //   }

  //   setClassification("neutral");
  //   setClassificationIndex(index);
  //   setCredits((curr) => curr && curr / 2);
  // };

  // const textCredits = `Para ser ${
  //   classification === "negative" ? "negativo" : ""
  // }${
  //   classification === "neutral" ? "carbono neutro" : ""
  // }, você deve neutralizar ${Math.ceil(+(credits || 12))} créditos. Falta${
  //   Math.ceil(+(credits || 12)) - (quantitySum?.credits || 0) === 1 ? "" : "m"
  // } ${Math.max(
  //   Math.ceil(+(credits || 12)) - (quantitySum?.credits || 0),
  //   0
  // )} crédito${
  //   Math.ceil(+(credits || 12)) - (quantitySum?.credits || 0) === 1 ? "" : "s"
  // } para isso.`;

  const textCredits = `Para ser carbono neutro, você deve neutralizar ${Math.ceil(
    +(credits || 1)
  )} créditos. Falta${
    Math.ceil(+(credits || 1)) - (quantitySum?.credits || 0) === 1 ? "" : "m"
  } ${Math.max(
    Math.ceil(+(credits || 1)) - (quantitySum?.credits || 0),
    0
  )} crédito${
    Math.ceil(+(credits || 1)) - (quantitySum?.credits || 0) === 1 ? "" : "s"
  } para isso.`;

  return (
    <Page pageIndex={1} mobileSection="calculator">
      <S.Content ref={buyCredits}>
        {!billingInfo && (
          <S.Header>
            <Button
              size="small"
              variant="backButton"
              onClick={() => onBackClick()}
              backgroundColor={tertiaryColor}
            >
              <AvailableIcons option="chevron" color={textColor} />
            </Button>
          </S.Header>
        )}

        {!completeBuy && !billingInfo && (
          <S.BuyCredits>
            <Title color={textColor}>Portfólio</Title>

            <S.ProjectsSelector>
              <BuyProjectsMenu
                selected={selected}
                labelWeight="regular"
                labelColor={textColor}
                label="Selecione projetos"
                options={availableProjects}
                onSelect={(val) => onSelectProject(val)}
                placeholder={
                  projects ? projects : "Selecione os projetos de interesse"
                }
              />
            </S.ProjectsSelector>

            <S.Plan>
              <OptionsList
                options={planOpt}
                direction="column"
                label="Selecione o plano desejado:"
                selectedItem={plan === "annual" ? 1 : 0}
                setSelectedItem={(val) => onSelectPlan(val)}
              />

              <S.PlanDescription>
                {plan === "annual" && (
                  <H5 color={textColor}>
                    O pacote anual é equivalente ao valor do seu cálculo,
                    multiplicado por 12. Com isso, você garante a neutralização
                    de suas emissões durante um ano, preocupando-se, apenas, em
                    atualizar o cálculo feito, periodicamente. Se ao atualizar o
                    cálculo o número de créditos necessários para a
                    neutralização mudar, atualizaremos, apenas, as parcelas
                    subsequentes. O valor referente aos 12 meses será reservado
                    no limite de seu cartão de crédito, mas será realizada uma
                    cobrança por mês.
                  </H5>
                )}

                {plan === "signature" && (
                  <H5 color={textColor}>
                    A assinatura garantirá que, todo mês, você neutralizará suas
                    emissões. Iremos gerar uma cobrança por mês, com base no
                    último cálculo realizado. Solicitaremos a atualização desse
                    cálculo mensalmente, para garantir que todas as emissões
                    serão compensadas.
                  </H5>
                )}

                {plan === "single" && (
                  <H5 color={textColor}>
                    Deseja fazer a neutralização recorrente (assinatura - todo
                    mês neutralizaremos a mesma quantidade para você) ou
                    adquirir o pacote anual, que considera 12 vezes a quantidade
                    de créditos de carbono calculada anteriormente?
                  </H5>
                )}

                <H4 color={textColor} fontWeight="bold">
                  {textCredits}
                </H4>
              </S.PlanDescription>
            </S.Plan>

            {/* <S.Classification>
              <H3 color={textColor} fontWeight="600">
                {textCredits}
              </H3>

              <OptionsList
                direction="column"
                options={classificationOpt}
                selectedItem={classificationIndex}
                setSelectedItem={(val) => onChangeClassification(val)}
                label="Selecione o tipo de classificação de neutralização:"
              />
            </S.Classification> */}

            <S.Projects>
              <S.HeaderProjects>
                <S.TitleCustom
                  fontWeight="bold"
                  color={textColor}
                  width={projectWidth}
                >
                  Projeto
                </S.TitleCustom>

                <S.TitleCustom
                  width={tCo2Width}
                  color={textColor}
                  fontWeight="bold"
                >
                  tCO<sub>2</sub>
                </S.TitleCustom>

                <S.TitleCustom
                  fontWeight="bold"
                  color={textColor}
                  width={volumeWidth}
                  marginLeft={volumeMarginLeft}
                >
                  % Volume
                </S.TitleCustom>

                <S.TitleCustom
                  color={textColor}
                  fontWeight="bold"
                  width={pricingWidth}
                >
                  Preço
                </S.TitleCustom>
              </S.HeaderProjects>

              <S.ProjectsList
                borderColor={tertiaryColor}
                bothBorders={
                  selectedProjects && selectedProjects.length > 0 ? true : false
                }
              >
                {selectedProjects && selectedProjects.length > 0 ? (
                  (selectedProjects || []).map((item, index) => (
                    <S.Project key={`${item.projectId}#${index}`}>
                      <S.TitleCustom width={projectWidth} color={textColor}>
                        {item.projectName}
                      </S.TitleCustom>

                      <S.QuantityBox width={tCo2Width}>
                        <InputText
                          border={false}
                          textAlign="center"
                          labelColor={textColor}
                          errors={errors[item.projectId]}
                          backgroundColor={tertiaryColor}
                          onChange={(val) => onChangeQuantity(index, val)}
                          value={item.quantity.toLocaleString("pt-BR", {
                            minimumFractionDigits: 0,
                          })}
                        />
                      </S.QuantityBox>

                      <S.TitleCustom
                        color={textColor}
                        width={volumeWidth}
                        marginLeft={volumeMarginLeftValue}
                      >
                        {volume[index]}
                      </S.TitleCustom>

                      <S.TitleCustom width={pricingWidth} color={textColor}>
                        R${" "}
                        {item.pricing.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                        })}
                      </S.TitleCustom>

                      <S.RemoveIcon onClick={() => onRemoveProject(index)}>
                        <AvailableIcons option="close" color="#ff0000" />
                      </S.RemoveIcon>
                    </S.Project>
                  ))
                ) : (
                  <H3 color={`${textColor}50`}>Nenhum projeto selecionado</H3>
                )}
              </S.ProjectsList>

              {selectedProjects && selectedProjects.length > 0 && (
                <S.Total>
                  <S.TotalValue
                    color={textColor}
                    fontWeight="bold"
                    width={
                      projectWidth + tCo2Width + volumeMarginLeft + volumeWidth
                    }
                  >
                    Total a pagar
                  </S.TotalValue>

                  <S.TotalValue width={pricingWidth} color={textColor}>
                    R${" "}
                    {selectedProjects
                      ?.reduce((acc, curr) => {
                        acc += curr.quantity * curr.pricing;

                        return acc;
                      }, 0)
                      .toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                  </S.TotalValue>
                </S.Total>
              )}
            </S.Projects>

            <Button
              variant="solid"
              fontWeight="600"
              textColor={backgroundColor}
              disabled={!enableCompleteBuy}
              backgroundColor={primaryColor}
              onClick={() => onCompleteBuy()}
            >
              Finalizar
            </Button>
          </S.BuyCredits>
        )}

        {completeBuy && !billingInfo && (
          <CompleteBuy
            plan={plan}
            errors={errors}
            addNewCard={newCard}
            newCardInfo={newCardData}
            saveNewCard={saveNewCard}
            selectedCard={selectedCard}
            availableCards={availableCards}
            onBuyHandler={() => onBuyClick()}
            onAddNewCard={() => onAddNewCard()}
            totalValue={quantitySum?.value || 0}
            onDiscardNewCard={() => onDiscardNewCard()}
            setSelectedCard={(val) => onSelectCard(val)}
            onBeneficiaryClick={() => setBeneficiaryModal(true)}
            onSaveNewCard={() => setSaveNewCard((curr) => !curr)}
            onSelectPayment={(val) => onSelectPaymentMethod(val)}
            selectedProjects={selectedProjects || emptyProjectOrder}
            onChangeCardInfo={(key, val) => onChangeCardInfo(key, val)}
            onChangeCardHolderInfo={(key, val) =>
              onChangeCardHolderInfo(key, val)
            }
            selectedPayment={
              plan === "annual"
                ? 0
                : Object.keys(paymentMethod).findIndex((key) => key === payment)
            }
          />
        )}

        {completeBuy && billingInfo && (
          <BillingInfo
            paymentRecurrence={plan}
            payment={payment || "PIX"}
            biggerTextarea={biggerTextarea}
            invoiceUrl={billing?.invoiceUrl}
            totalValue={billing?.billingValue || 0}
            paymentStatus={billing?.status || "PENDING"}
            orderAsaasId={billing?.orderAsaasId || "pay_empty"}
            copyIdentification={billing ? billing.identificationField : ""}
            card={
              payment === "CREDIT_CARD" && newCard
                ? newCardData
                : availableCards && availableCards[selectedCard]
            }
          />
        )}

        {cancelModal && (
          <CancelBuyModal
            isOpen={cancelModal}
            onClose={() => setCancelModal(false)}
          />
        )}

        {beneficiaryModal && (
          <BeneficiaryModal
            isOpen={beneficiaryModal}
            onClose={() => setBeneficiaryModal(false)}
          />
        )}
      </S.Content>
    </Page>
  );
};

export default BuyCredits;
