const ConstructingIcon: React.FC = () => {
  return (
    <svg
      width="1020"
      height="1477"
      viewBox="0 0 1020 1477"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M602 105.263C422 147.368 380 277.263 338.4 386.105L376.2 400L395.2 351.579C404.8 355.158 414.8 357.895 422 357.895C642 357.895 702 0 702 0C682 42.1053 542 47.3684 442 68.4211C342 89.4737 302 178.947 302 221.053C302 263.158 337 300 337 300C402 105.263 602 105.263 602 105.263Z"
        fill="black"
      />
      <path
        d="M954.444 550C913.401 474.96 849.824 411.767 770.918 367.581C692.012 323.395 600.932 299.984 508 300C233 300 10 501.5 10 750M65.5556 950C106.599 1025.04 170.176 1088.23 249.082 1132.42C327.988 1176.6 419.068 1200.02 512 1200C787 1200 1010 998.5 1010 750M343.333 950H10V1250M1010 250V550H676.667"
        stroke="black"
        strokeWidth="20"
      />
      <path
        d="M782.882 1278.54L734.618 1392.09H561.076L609.34 1278.54H782.882Z"
        fill="black"
      />
      <path
        d="M784.382 1278.54L736.118 1392.09H673.688L721.951 1278.54H782.854H784.382Z"
        fill="#181818"
        fillOpacity="0.8"
      />
      <path
        d="M628.944 1115.21C600.583 1130.94 550.132 1134.34 511.875 1115.69L487.222 1163.19C486.722 1162.39 486.306 1161.61 485.847 1160.82C485.743 1162.9 485.597 1164.95 485.597 1167.05C485.597 1221.41 520.854 1265.48 564.34 1265.48C607.826 1265.48 643.076 1221.41 643.076 1167.05C643.076 1147.53 638.472 1129.4 630.639 1114.09C630.056 1114.44 629.542 1114.88 628.938 1115.22L628.944 1115.21Z"
        fill="#FAFAFA"
      />
      <path
        d="M434.479 1386.16C432.823 1386.16 431.223 1385.57 429.967 1384.49C428.711 1383.41 427.882 1381.91 427.632 1380.28C427.347 1378.4 420.875 1334.22 445.549 1305.45C458.91 1289.89 478.549 1282 503.938 1282C505.309 1282 506.65 1282.41 507.792 1283.17C529.757 1297.81 547.201 1306.9 566.16 1306.94H566.437C568.279 1306.94 570.046 1307.67 571.35 1308.97C572.654 1310.27 573.387 1312.04 573.389 1313.88C573.391 1315.72 572.661 1317.49 571.36 1318.79C570.059 1320.1 568.293 1320.83 566.451 1320.83H566.417C566.329 1320.83 566.241 1320.83 566.153 1320.83H565.569L565.257 1320.83C543.736 1320.53 524.868 1311.08 501.875 1295.91C481.764 1296.3 466.389 1302.53 456.139 1314.44C435.611 1338.31 441.292 1377.78 441.354 1378.18C441.503 1379.17 441.437 1380.18 441.16 1381.14C440.882 1382.1 440.401 1382.99 439.748 1383.75C439.095 1384.51 438.287 1385.11 437.377 1385.53C436.468 1385.95 435.479 1386.16 434.479 1386.16Z"
        fill="black"
      />
      <path
        d="M553.083 1360.91C552.041 1360.91 551.012 1360.68 550.073 1360.23C549.134 1359.78 548.309 1359.12 547.66 1358.31L497.208 1295.31C496.638 1294.6 496.213 1293.78 495.959 1292.91C495.704 1292.03 495.624 1291.12 495.724 1290.21C495.824 1289.3 496.102 1288.43 496.541 1287.63C496.981 1286.83 497.573 1286.13 498.285 1285.56C498.995 1284.98 499.812 1284.56 500.689 1284.3C501.565 1284.04 502.483 1283.96 503.391 1284.06C504.298 1284.16 505.177 1284.44 505.976 1284.88C506.776 1285.32 507.48 1285.92 508.049 1286.63L558.493 1349.62C559.313 1350.64 559.828 1351.88 559.978 1353.18C560.128 1354.48 559.908 1355.79 559.342 1356.97C558.777 1358.15 557.889 1359.15 556.781 1359.84C555.674 1360.54 554.392 1360.91 553.083 1360.91Z"
        fill="black"
      />
      <path
        d="M546.215 1375.3C545.687 1375.3 545.161 1375.24 544.646 1375.13L510.639 1367.3C509.716 1367.09 508.845 1366.69 508.081 1366.13C507.317 1365.57 506.676 1364.86 506.197 1364.04C505.719 1363.23 505.412 1362.32 505.297 1361.38C505.182 1360.44 505.26 1359.49 505.528 1358.58L508.708 1347.74H486.236C484.5 1347.74 482.819 1347.08 481.535 1345.91C471.785 1336.94 470.285 1327.53 470.75 1321.22C472.229 1300.9 494.771 1284.96 497.333 1283.21C498.854 1282.22 500.704 1281.86 502.485 1282.21C504.266 1282.57 505.837 1283.61 506.862 1285.11C507.886 1286.61 508.283 1288.45 507.966 1290.24C507.649 1292.02 506.644 1293.62 505.167 1294.67C499.729 1298.41 485.424 1310.65 484.597 1322.26C484.306 1326.37 485.778 1330.17 489.111 1333.85H518C519.078 1333.86 520.14 1334.11 521.104 1334.59C522.067 1335.08 522.905 1335.78 523.552 1336.64C524.198 1337.5 524.635 1338.5 524.829 1339.56C525.022 1340.62 524.967 1341.71 524.667 1342.75L520.944 1355.42L547.771 1361.59C549.429 1361.98 550.888 1362.96 551.87 1364.35C552.852 1365.74 553.289 1367.44 553.098 1369.13C552.907 1370.83 552.101 1372.39 550.834 1373.52C549.566 1374.66 547.918 1375.29 546.215 1375.3Z"
        fill="black"
      />
      <path
        d="M561.076 1392.09H734.618L782.882 1278.54H609.34L561.076 1392.09ZM561.076 1392.09H484.896"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M608.479 1164.8C608.477 1168.48 607.012 1172.02 604.404 1174.62C601.797 1177.23 598.262 1178.69 594.576 1178.69C590.891 1178.69 587.357 1177.22 584.753 1174.61C582.148 1172.01 580.686 1168.47 580.688 1164.78C580.689 1161.1 582.155 1157.57 584.762 1154.96C587.37 1152.36 590.905 1150.89 594.59 1150.9C598.276 1150.9 601.809 1152.36 604.414 1154.97C607.019 1157.58 608.481 1161.11 608.479 1164.8ZM552.924 1164.8C552.922 1168.48 551.456 1172.02 548.849 1174.62C546.241 1177.23 542.706 1178.69 539.021 1178.69C535.335 1178.69 531.802 1177.22 529.197 1174.61C526.592 1172.01 525.13 1168.47 525.132 1164.78C525.133 1162.96 525.493 1161.15 526.192 1159.47C526.892 1157.78 527.916 1156.25 529.207 1154.96C530.498 1153.67 532.03 1152.65 533.716 1151.95C535.403 1151.25 537.21 1150.9 539.035 1150.9C540.86 1150.9 542.666 1151.26 544.352 1151.96C546.037 1152.66 547.569 1153.68 548.859 1154.97C550.148 1156.26 551.171 1157.79 551.869 1159.48C552.566 1161.17 552.925 1162.97 552.924 1164.8ZM566.813 1234.26C558.813 1234.26 550.813 1232.27 542.875 1228.3C542.052 1227.9 541.317 1227.33 540.712 1226.64C540.108 1225.96 539.645 1225.15 539.352 1224.29C539.058 1223.42 538.94 1222.5 539.003 1221.59C539.066 1220.67 539.309 1219.78 539.719 1218.96C540.128 1218.14 540.696 1217.41 541.39 1216.81C542.083 1216.21 542.888 1215.75 543.759 1215.47C544.629 1215.18 545.548 1215.07 546.462 1215.14C547.376 1215.21 548.267 1215.46 549.083 1215.88C561.014 1221.85 572.611 1221.85 584.542 1215.88C586.19 1215.05 588.098 1214.92 589.846 1215.5C590.711 1215.79 591.511 1216.25 592.2 1216.84C592.889 1217.44 593.453 1218.17 593.861 1218.99C594.269 1219.8 594.512 1220.69 594.576 1221.6C594.64 1222.51 594.525 1223.42 594.236 1224.29C593.947 1225.16 593.49 1225.96 592.892 1226.64C592.294 1227.33 591.566 1227.9 590.75 1228.31C582.806 1232.27 574.813 1234.26 566.813 1234.26Z"
        fill="black"
      />
      <path
        d="M565.958 1279.62C518.715 1279.62 480.278 1232.35 480.278 1174.25C480.278 1169.62 480.521 1164.98 481 1160.47C481.073 1159.55 481.331 1158.65 481.759 1157.82C482.186 1157 482.774 1156.27 483.488 1155.68C484.202 1155.09 485.027 1154.64 485.916 1154.37C486.804 1154.11 487.737 1154.02 488.659 1154.12C489.582 1154.22 490.475 1154.5 491.287 1154.95C492.098 1155.4 492.812 1156 493.384 1156.73C493.957 1157.46 494.378 1158.3 494.622 1159.2C494.867 1160.09 494.929 1161.03 494.806 1161.94C494.382 1165.97 494.167 1170.11 494.167 1174.25C494.167 1224.69 526.368 1265.73 565.965 1265.73C605.549 1265.73 637.757 1224.69 637.757 1174.25C637.757 1170.17 637.549 1166.06 637.118 1162C636.995 1160.2 637.575 1158.43 638.734 1157.05C639.894 1155.67 641.543 1154.8 643.334 1154.61C645.125 1154.43 646.919 1154.94 648.337 1156.05C649.755 1157.16 650.688 1158.78 650.938 1160.56C651.403 1165.09 651.639 1169.69 651.639 1174.24C651.639 1232.35 613.201 1279.62 565.965 1279.62H565.958Z"
        fill="black"
      />
      <path
        d="M644.694 1168.15C643.146 1168.15 641.642 1167.64 640.421 1166.68C639.2 1165.73 638.333 1164.4 637.958 1162.9C633.972 1146.95 626.229 1127.83 620.694 1124.75C619.898 1124.31 619.197 1123.71 618.631 1122.99C618.065 1122.28 617.646 1121.46 617.397 1120.58C617.148 1119.7 617.074 1118.78 617.18 1117.88C617.286 1116.97 617.569 1116.1 618.014 1115.3C618.458 1114.5 619.056 1113.8 619.771 1113.24C620.487 1112.67 621.307 1112.25 622.184 1112C623.062 1111.75 623.98 1111.68 624.886 1111.78C625.792 1111.89 626.669 1112.17 627.465 1112.62C636.25 1117.51 643.035 1133.05 647.167 1145.14C649.444 1133.99 645.326 1117.49 641.431 1104.49C640.902 1102.73 641.096 1100.83 641.97 1099.21C642.844 1097.58 644.326 1096.38 646.09 1095.85C647.855 1095.32 649.757 1095.51 651.378 1096.39C653 1097.26 654.208 1098.74 654.736 1100.51C659.437 1116.18 670.451 1152.89 648.458 1167.05C647.339 1167.77 646.027 1168.15 644.694 1168.15Z"
        fill="black"
      />
      <path
        d="M487.222 1170.15C486.033 1170.15 484.864 1169.84 483.827 1169.26C482.79 1168.68 481.919 1167.84 481.299 1166.83C469.472 1147.55 465.806 1129.35 470.396 1112.74C475.292 1095.05 488.278 1084.33 494.889 1079.85C495.056 1064.62 505.792 1049.78 523.361 1041.12C548.646 1028.64 588.847 1028.62 627.611 1064.49L641.139 1054.04C642.555 1052.95 644.335 1052.45 646.111 1052.64C647.887 1052.83 649.523 1053.69 650.681 1055.05C658.507 1064.27 668.222 1087.27 648.938 1108.3C644.125 1113.49 638.509 1117.88 632.306 1121.28C605.361 1136.23 556.16 1141.99 515.007 1124.73L493.382 1166.4C492.816 1167.49 491.971 1168.41 490.933 1169.07C489.895 1169.72 488.7 1170.09 487.472 1170.14L487.222 1170.15ZM556.764 1047.47C546.417 1047.47 537.125 1049.82 529.514 1053.57C515.59 1060.44 507.514 1071.87 508.938 1082.68C509.125 1084.09 508.873 1085.53 508.215 1086.79C507.558 1088.06 506.528 1089.09 505.264 1089.75C505.125 1089.82 488.597 1098.78 483.75 1116.58C481.132 1126.18 482.292 1136.77 487.188 1148.18L505.708 1112.5C506.542 1110.89 507.972 1109.67 509.692 1109.1C511.413 1108.53 513.288 1108.66 514.917 1109.45C551.583 1127.33 599.979 1123.33 625.569 1109.14C630.469 1106.46 634.903 1103 638.701 1098.9C649.229 1087.43 646.701 1076.19 643.604 1069.69L631.361 1079.13C630.024 1080.16 628.357 1080.67 626.671 1080.56C624.986 1080.46 623.397 1079.74 622.201 1078.55C599.417 1055.76 576.229 1047.47 556.764 1047.47Z"
        fill="black"
      />
    </svg>
  );
};

export default ConstructingIcon;
