import * as S from "./styles";

import { Theme } from "../../../hooks";
import H4 from "../../atoms/Typography/H4";
import Select from "../../molecules/Select";
import AvailableIcons from "../../atoms/AvailableIcons";
import { verticals, eventVerticals } from "../../../constants/calculators";

interface IProps {
  label: string;
  info?: string;
  search?: string;
  bgColor?: string;
  selected: string;
  errors?: string[];
  options?: string[];
  infoIcon?: boolean;
  labelWeight: string;
  placeholder: string;
  useSearch?: boolean;
  labelColor?: string;
  borderColor?: string;
  onClearSearch?: () => void;
  filteredOptions?: string[];
  onSelect: (val: string) => void;
  onSearch?: (value: string) => void;
  selectorType?: "vertical" | "eventVertical";
}

const DropdownMenu: React.FC<IProps> = ({
  info,
  label,
  errors,
  search,
  bgColor,
  onSearch,
  onSelect,
  infoIcon,
  selected,
  useSearch,
  labelColor,
  labelWeight,
  borderColor,
  placeholder,
  selectorType,
  onClearSearch,
  filteredOptions,
  options = [""],
}) => {
  const { textColor, backgroundColor } = Theme.useTheme();

  const optionsList = (() => {
    if (selectorType === "vertical") return verticals;

    if (selectorType === "eventVertical") return eventVerticals;

    return options;
  })();
  const selectedItem = optionsList.findIndex((item) => item === selected);

  const onSelectHandler = (val: number) => {
    if (filteredOptions && filteredOptions.length > 0) {
      onSelect(filteredOptions[val]);

      return;
    }

    onSelect(optionsList[val]);
  };

  return (
    <S.Container>
      <S.LabelBox>
        <H4 color={labelColor || backgroundColor} fontWeight={labelWeight}>
          {label}
        </H4>

        {infoIcon && (
          <S.InfoIcon>
            <AvailableIcons option="infoOne" color={textColor} />

            <S.InfoBox>{info}</S.InfoBox>
          </S.InfoIcon>
        )}
      </S.LabelBox>

      <Select
        search={search}
        bgColor={bgColor}
        label={placeholder}
        useSearch={useSearch}
        options={optionsList}
        borderColor={borderColor}
        selectedIndex={selectedItem}
        filteredOptions={filteredOptions}
        onSelect={(val) => onSelectHandler(val)}
        onSearch={(val) => onSearch && onSearch(val)}
        onClearSearch={() => onClearSearch && onClearSearch()}
      />

      {errors && errors.length && errors[0] !== "" ? (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      ) : null}
    </S.Container>
  );
};

export default DropdownMenu;
