import styled, { css } from "styled-components";

type TextFieldProps = {
  centralize?: boolean;
};

export const TextField = styled.div<TextFieldProps>`
  ${({ centralize }) => css`
    gap: 12px;
    display: flex;
    flex-direction: column;
    text-align: ${centralize && "center"};

    h4 {
      text-align: justify;
    }
  `}
`;
