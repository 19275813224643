import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const TrashCanIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="500"
      height="500"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M192.708 62.5C192.708 58.356 194.354 54.3817 197.285 51.4515C200.215 48.5212 204.189 46.875 208.333 46.875H291.666C295.81 46.875 299.785 48.5212 302.715 51.4515C305.645 54.3817 307.291 58.356 307.291 62.5V78.125H395.833C399.977 78.125 403.951 79.7712 406.882 82.7015C409.812 85.6317 411.458 89.606 411.458 93.75C411.458 97.894 409.812 101.868 406.882 104.799C403.951 107.729 399.977 109.375 395.833 109.375H104.167C100.022 109.375 96.0482 107.729 93.118 104.799C90.1877 101.868 88.5415 97.894 88.5415 93.75C88.5415 89.606 90.1877 85.6317 93.118 82.7015C96.0482 79.7712 100.022 78.125 104.167 78.125H192.708V62.5Z"
        fill={color || primaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130 165.521C130.282 162.972 131.494 160.617 133.405 158.906C135.315 157.196 137.79 156.25 140.354 156.25H359.646C362.21 156.25 364.685 157.196 366.595 158.906C368.505 160.617 369.718 162.972 370 165.521L374.167 203.042C381.687 270.687 381.687 338.958 374.167 406.604L373.75 410.292C372.418 422.265 367.136 433.455 358.739 442.094C350.341 450.732 339.306 456.329 327.375 458C276.042 465.186 223.958 465.186 172.625 458C160.69 456.333 149.65 450.739 141.248 442.099C132.847 433.46 127.562 422.269 126.229 410.292L125.812 406.604C118.298 338.965 118.298 270.702 125.812 203.062L130 165.521ZM223.958 237.5C223.958 233.356 222.312 229.382 219.382 226.451C216.452 223.521 212.477 221.875 208.333 221.875C204.189 221.875 200.215 223.521 197.285 226.451C194.354 229.382 192.708 233.356 192.708 237.5V383.333C192.708 387.477 194.354 391.452 197.285 394.382C200.215 397.312 204.189 398.958 208.333 398.958C212.477 398.958 216.452 397.312 219.382 394.382C222.312 391.452 223.958 387.477 223.958 383.333V237.5ZM307.292 237.5C307.292 233.356 305.645 229.382 302.715 226.451C299.785 223.521 295.811 221.875 291.667 221.875C287.523 221.875 283.548 223.521 280.618 226.451C277.688 229.382 276.042 233.356 276.042 237.5V383.333C276.042 387.477 277.688 391.452 280.618 394.382C283.548 397.312 287.523 398.958 291.667 398.958C295.811 398.958 299.785 397.312 302.715 394.382C305.645 391.452 307.292 387.477 307.292 383.333V237.5Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default TrashCanIcon;
