import styled from "styled-components";

type SmallProps = {
  color: string;
  fontWeight?: string;
};

const Small = styled.small<SmallProps>`
  font-size: 0.8rem;
  letter-spacing: 1.5px;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export default Small;
