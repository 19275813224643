import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as C from "../../../styles";
import {
  LOCAL_STORAGE_CREDITS_KEY,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../../../../../constants";
import {
  emptyProduction,
  emptyCalculatorForm,
} from "../../../../../../utils/calculators";
import {
  transportUnitsObj,
  airConditioningTypes,
  airConditioningTypesObj,
} from "../../../../../../constants/calculators";
import {
  IProduction,
  ICalculatorForm,
  ICalculatorResult,
} from "../../../../../../types/adminCalculators";
import Questions from "./components/Questions";
import { Calculators } from "../../../../../../services";
import Button from "../../../../../../components/atoms/Button";
import H1 from "../../../../../../components/atoms/Typography/H1";
import H2 from "../../../../../../components/atoms/Typography/H2";
import H3 from "../../../../../../components/atoms/Typography/H3";
import H5 from "../../../../../../components/atoms/Typography/H5";
import { Snackbar, Loading, Theme, Auth } from "../../../../../../hooks";
import ExcludeModal from "../../../../../../components/molecules/ExcludeModal";
import ChartAndIndicators from "../../../../../../components/organisms/ChartAndIndicators";
import CalculatorProgressBar from "../../../../../../components/molecules/CalculatorProgressBar";

export interface ProductionErrors {
  product: string | undefined;
  quantity: string | undefined;
  dimension: string | undefined;
  production: string | undefined;
}

const validateProduction = (data?: IProduction[]): ProductionErrors[] => {
  const errors = (() => {
    if (data && data.length > 0) {
      const productionErrors = data.map((item) => {
        const production = !item.production
          ? "Selecione uma produção"
          : undefined;
        const product =
          item.product === "" || !item.product
            ? "Selecione um produto"
            : undefined;
        const dimension =
          item.dimension === "" ? "Insira a dimensão" : undefined;
        const quantity =
          (item.production === "Bebidas" ||
            item.production === "Garrafas térmicas") &&
          item.quantity === ""
            ? "Insira a quantidade"
            : undefined;

        return {
          product: product,
          quantity: quantity,
          dimension: dimension,
          production: production,
        };
      });

      return productionErrors;
    }

    return [
      {
        dimension: "Insira a dimensão",
        product: "Selecione um produto",
        quantity: "Insira a quantidade",
        production: "Selecione uma produção",
      },
    ];
  })();

  return errors;
};

const validateForm = (data: ICalculatorForm): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.companyVertical)
    errors.companyVertical = [
      ...(errors.companyVertical || []),
      "Segmento da empresa é obrigatório",
    ];

  return errors;
};

interface IProps {
  filledForm?: ICalculatorForm;
}

const airConditioningKeys = Object.keys(airConditioningTypesObj);

const Calculator: React.FC<IProps> = ({ filledForm = emptyCalculatorForm }) => {
  const [calculatorForm, setCalculatorForm] =
    useState<ICalculatorForm>(filledForm);
  const [production, setProduction] = useState<boolean>(true);
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [removeProduction, setRemoveProduction] = useState<number>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [excludeProduction, setExcludeProduction] = useState<boolean>(false);
  const [columnOptions, setColumnOptions] = useState(window.innerWidth <= 820);
  const [calculatorResult, setCalculatorResult] = useState<ICalculatorResult>();
  const [airConditioningType, setAirConditioningType] = useState<number>(
    airConditioningKeys.indexOf(filledForm.airConditioningType) || 0
  );
  const [productionErrors, setProductionErrors] =
    useState<ProductionErrors[]>();
  const [productionData, setProductionData] = useState<
    IProduction[] | undefined
  >(filledForm.production || [emptyProduction]);

  const questionsTop = useRef<HTMLDivElement>(null);

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setColumnOptions(window.innerWidth <= 820);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [columnOptions]);

  const onChangeHandler = (
    key: keyof ICalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (
        key === "fuelUnit" ||
        key === "transportationUnit" ||
        key === "publicTransportationUnit"
      ) {
        return {
          ...curr,
          [key]: Object.values(transportUnitsObj)[+value],
        };
      }

      if (key === "cookingGasUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "Kg",
        };
      }

      if (key === "energyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      if (key === "airConditioningUnit") {
        return {
          ...curr,
          [key]: value ? "un." : "Kg",
        };
      }

      if (key === "fireExtinguisherUnit") {
        return {
          ...curr,
          [key]: value ? "un." : "Kg",
        };
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onChangeProductionHandler = (
    key: keyof IProduction,
    index: number,
    value: string | number
  ) => {
    setProductionData((curr) => {
      if (!curr) return curr;

      if (curr) {
        const currProd = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            return {
              ...currProd,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      const currErrors = validateForm(calculatorForm);
      const currProdErrors = production
        ? validateProduction(productionData)
        : [];

      const checkProdError = currProdErrors.reduce((acc, curr) => {
        const textValues = Object.values(curr).reduce((accText, currText) => {
          accText += typeof currText === "string" ? 1 : 0;

          return accText;
        }, 0);

        acc += textValues;

        return acc;
      }, 0);

      if (
        (currErrors && Object.keys(currErrors).length) ||
        checkProdError > 0
      ) {
        if (checkProdError > 0) {
          setProductionErrors(currProdErrors);
          setQuestionNumber(5);
        }

        if (currErrors && Object.keys(currErrors).length) {
          setErrors(currErrors);
          setQuestionNumber(4);
        }

        if (Object.keys(currErrors).length === 0 && Object.keys(errors).length)
          setErrors({});

        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
        );
      }

      setErrors({});
      setProductionErrors(undefined);

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const result = await Calculators.sendInformation(
        {
          ...calculatorForm,
          userId: user.id || "",
          cpf: user.cpf || undefined,
          cnpj: user.cnpj || undefined,
          contactMail: user.mail || "",
          companyName: user.company || "",
          contactName: user.contactName || "",
          companyCity: user.companyCity || "",
          cpfOrCnpj: user.cpfOrCnpj || "cnpj",
          companyState: user.companyState || "",
          contactPhone: user.contactPhone || "",
          fuelUnit:
            calculatorForm.fuelExpenses && !calculatorForm.fuelUnit
              ? "R$"
              : calculatorForm.fuelUnit,
          energyUnit:
            calculatorForm.energyExpenses && !calculatorForm.energyUnit
              ? "KWh"
              : calculatorForm.energyUnit,
          cookingGasUnit:
            calculatorForm.gasExpenses && !calculatorForm.cookingGasUnit
              ? "Kg"
              : calculatorForm.cookingGasUnit,
          airConditioningUnit:
            calculatorForm.airConditioningRefills &&
            !calculatorForm.airConditioningUnit
              ? "Kg"
              : calculatorForm.airConditioningUnit,
          transportationUnit:
            calculatorForm.transportationExpenses &&
            !calculatorForm.transportationUnit
              ? "R$"
              : calculatorForm.transportationUnit,
          fireExtinguisherUnit:
            calculatorForm.fireExtinguisherRefills &&
            !calculatorForm.fireExtinguisherUnit
              ? "Kg"
              : calculatorForm.fireExtinguisherUnit,
          publicTransportationUnit:
            calculatorForm.publicTransportationExpenses &&
            !calculatorForm.publicTransportationUnit
              ? "R$"
              : calculatorForm.publicTransportationUnit,
          airConditioningType: airConditioningTypes[airConditioningType],
          production:
            production && productionData && productionData.length > 0
              ? productionData.map((item) => ({
                  ...item,
                  unit: item.production === "Têxtil" ? "m²" : "mL",
                }))
              : undefined,
        },
        token
      );

      setCalculatorResult(result);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const onBuyCredits = () => {
    localStorage.setItem(
      LOCAL_STORAGE_CREDITS_KEY,
      Math.ceil(calculatorResult?.totalEmissions || 0).toString()
    );

    localStorage.setItem(LOCAL_STORAGE_CALCULATOR_KEY, "admin");

    localStorage.setItem(
      LOCAL_STORAGE_CALCULATION_ID_KEY,
      calculatorResult?.id || ""
    );

    navigate("/comprar");
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setCalculatorForm(emptyCalculatorForm);
    setProductionData([emptyProduction]);
    setProductionErrors(undefined);
    setErrors({});
    setProduction(true);
    setQuestionNumber(1);
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const onAddProduction = () => {
    if (!productionData) {
      setProductionData([emptyProduction]);

      return;
    }

    setProductionData((curr) => [...(curr || []), emptyProduction]);
  };

  const onExcludeProduction = (index: number) => {
    if (productionData && productionData.length === 1) {
      newError(
        "É necessária, ao menos, 1 produção. Se não houver produção, selecione 'Não' acima."
      );

      return;
    }

    setRemoveProduction(index);
    setExcludeProduction(true);
  };

  const onRemoveProduction = (index: number) => {
    setProductionData((curr) => {
      if (!curr) return;

      if (curr && curr.length === 1) {
        newError(
          "É necessária, ao menos, 1 produção. Se não houver produção, selecione 'Não' acima."
        );

        return curr;
      }

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludeProduction(false);
  };

  const airConditioning = Object.values(airConditioningTypesObj);

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  const booleanOptions = ["Sim", "Não"];

  return (
    <C.Container>
      <C.TitleAndProgress ref={questionsTop}>
        <C.TitleAndDescription>
          <H1 color={textColor} fontWeight="bold">
            Calculadora - Administrativa
          </H1>

          {!calculatorResult && (
            <H5 color={textColor}>
              Detalhe sua operação respondendo às perguntas abaixo e descubra
              sua pegada mensal de carbono
            </H5>
          )}
        </C.TitleAndDescription>

        {!calculatorResult && (
          <CalculatorProgressBar
            numberOfQuestions={5}
            currentQuestion={questionNumber}
          />
        )}
      </C.TitleAndProgress>

      <C.Content resultBox={!!calculatorResult}>
        {calculatorResult && (
          <C.ChartResult>
            <H2 color={textColor} fontWeight="600">
              A pegada mensal de carbono de sua empresa é:
            </H2>

            <H2 color={textColor}>
              {calculatorResult.totalEmissions.toFixed(2).replace(".", ",")}{" "}
              toneladas de CO<sub>2</sub>e
            </H2>

            <H3 color={textColor}>
              Clique abaixo para compensar suas emissões
            </H3>

            <Button
              variant="solid"
              fontWeight="bold"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => onBuyCredits()}
            >
              Compre créditos
            </Button>
          </C.ChartResult>
        )}

        <C.CalculatorBox resultBox={!!calculatorResult}>
          {calculatorResult ? (
            <>
              <C.Charts>
                <ChartAndIndicators result={calculatorResult} />
              </C.Charts>

              <C.Buttons>
                <Button
                  variant="solid"
                  fontWeight="bold"
                  textColor={backgroundColor}
                  backgroundColor={primaryColor}
                  onClick={() => onBuyCredits()}
                >
                  Compre créditos
                </Button>

                <Button
                  variant="outline"
                  fontWeight="bold"
                  textColor={primaryColor}
                  borderColor={primaryColor}
                  backgroundColor={backgroundColor}
                  onClick={() => onNewConsultHandler()}
                >
                  Nova consulta
                </Button>
              </C.Buttons>
            </>
          ) : (
            <Questions
              errors={errors}
              production={production}
              question={questionNumber}
              formFields={calculatorForm}
              columnOptions={columnOptions}
              productionData={productionData}
              booleanOptions={booleanOptions}
              productionErrors={productionErrors}
              onAheadClick={() => onAheadClick()}
              airConditioningTypes={airConditioning}
              transportOptions={transportUnitsOptions}
              onAddProduction={() => onAddProduction()}
              airConditioningType={airConditioningType}
              onPreviousClick={() => onPreviousClick()}
              onCalculateClick={() => onCalculateHandler()}
              setProduction={() => setProduction((curr) => !curr)}
              onRemoveProduction={(val) => onExcludeProduction(val)}
              onChangeForm={(key, val) => onChangeHandler(key, val)}
              setAirConditioningType={(val) => setAirConditioningType(val)}
              onChangeProductionForm={(key, index, val) =>
                onChangeProductionHandler(key, index, val)
              }
            />
          )}
        </C.CalculatorBox>
      </C.Content>

      {excludeProduction && (
        <ExcludeModal
          isOpen={excludeProduction}
          onClose={() => setExcludeProduction(false)}
          onExclude={() => onRemoveProduction(removeProduction || 0)}
        />
      )}
    </C.Container>
  );
};

export default Calculator;
