import styled, { css } from "styled-components";

type ContentBoxProps = {
  resultBox: boolean;
};

export const Content = styled.div<ContentBoxProps>`
  ${({ resultBox }) => css`
    gap: 40px;
    width: 100%;
    display: flex;
    margin-top: 52px;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 840px) {
      margin-top: ${resultBox ? "100px" : "52px"};
    }
  `}
`;

export const CropCards = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  max-width: 500px;
  align-items: center;
  flex-direction: column;

  p {
    max-width: 360px;
    text-align: center;
  }
`;

export const CropCardBox = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    padding: 0 40px;
  }
`;
