import styled, { css } from "styled-components";

import P from "../../components/atoms/Typography/P";
import H4 from "../../components/atoms/Typography/H4";

export const Content = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const Statements = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

type TitleCustomProps = {
  width: string;
};

export const TitleCustom = styled(H4)<TitleCustomProps>`
  ${({ width }) => css`
    width: ${width};
    align-items: center;

    sub {
      font-size: 1.2rem;
      font-weight: bold;
    }
  `}
`;

export const TitleCustomDate = styled(H4)<TitleCustomProps>`
  ${({ width }) => css`
    gap: 12px;
    display: flex;
    width: ${width};
    padding-left: 20px;
    align-items: center;

    sub {
      font-size: 1.2rem;
      font-weight: bold;
    }
  `}
`;

type SortIconProps = {
  isAscending: boolean;
};

export const SortIcon = styled.div<SortIconProps>`
  ${({ isAscending }) => css`
    width: 20px;
    height: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transform: ${isAscending ? "rotate(-90deg)" : "rotate(90deg)"};

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`;

type HeaderProps = {
  borderColor: string;
};

export const Header = styled.div<HeaderProps>`
  ${({ borderColor }) => css`
    padding-bottom: 8px;
    display: flex;
    border-bottom: 1px solid ${borderColor};

    @media (max-width: 800px) {
      display: none;
    }
  `}
`;

export const StatementsCards = styled.div`
  display: none;
  @media (max-width: 800px) {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 640px) {
      gap: 24px;
      display: flex;
      flex-direction: column;
    }
  }
`;

type StatementCardProps = {
  backgroundColor: string;
};

export const StatementCard = styled.div<StatementCardProps>`
  ${({ backgroundColor }) => css`
    gap: 20px;
    display: flex;
    cursor: pointer;
    padding: 16px 28px;
    align-items: center;
    border-radius: 40px;
    text-decoration: none;
    background-color: ${backgroundColor};

    @media (max-width: 480px) {
      border-radius: 40px;
    }
  `}
`;

export const StatementCardInfo = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const StatementCardText = styled(P)`
  text-align: justify;

  span {
    font-weight: bold;
  }

  sub {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

export const StatementList = styled.div`
  gap: 24px;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
`;

export const Statement = styled.div`
  gap: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
