import styled, { css } from "styled-components";

import H4 from "../../../../components/atoms/Typography/H4";

export const Content = styled.div`
  gap: 20px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

export const Seals = styled.div`
  display: flex;
  flex-direction: column;
`;

type TitleCustomProps = {
  width: string;
};

export const TitleCustom = styled(H4)<TitleCustomProps>`
  ${({ width }) => css`
    width: ${width};
    padding-left: 20px;

    sub {
      font-size: 1.2rem;
      font-weight: bold;
    }
  `}
`;

type HeaderSealProps = {
  borderColor: string;
};

export const HeaderSeal = styled.div<HeaderSealProps>`
  ${({ borderColor }) => css`
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid ${borderColor};
  `}
`;

export const SealsList = styled.div`
  gap: 24px;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
`;

export const Seal = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
