import axios from "axios";
import { ICertificates } from "../../types/certificates";

const { REACT_APP_CERTIFICATES_API_URL } = process.env;

const getCertificates = async (
  entity: string,
  token: string
): Promise<ICertificates> => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_CERTIFICATES_API_URL}/certificates/${entity}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.certificates;
  } catch (error) {
    throw new Error();
  }
};

export default {
  getCertificates,
};
