import * as S from "./styles";
import { Auth, Theme } from "../../../../hooks";
import { ICertificatesContent } from "../../../../types/certificates";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";

const { REACT_APP_IBIOMA_CERTIFICATES_BUCKET } = process.env;

interface IProps {
  year?: number;
  folders?: number[];
  ascendingOrder: boolean;
  verraCertificates?: number;
  setAscendingOrder: () => void;
  setYear?: (val: number) => void;
  certificates?: ICertificatesContent;
}

const Certificates: React.FC<IProps> = ({
  year,
  folders,
  setYear,
  certificates,
  ascendingOrder,
  setAscendingOrder,
  verraCertificates,
}) => {
  const { user } = Auth.useAuth();
  const { textColor, tertiaryColor } = Theme.useTheme();

  return (
    <S.Certificates>
      {!year &&
        folders &&
        folders.map((item, index) => (
          <S.Folder
            variant="icon"
            fontWeight="bold"
            textColor={textColor}
            key={`${item}#${index}`}
            backgroundColor={tertiaryColor}
            onClick={() => setYear && setYear(item)}
          >
            <S.Icon>
              <AvailableIcons option="folder" color={textColor} />
            </S.Icon>
            {item.toFixed(0)}
          </S.Folder>
        ))}

      {year && certificates && (
        <>
          <S.Content>
            <S.CertificateHeader borderColor={tertiaryColor}>
              <S.TitleCustom fontWeight="bold" color={textColor} width="60%">
                Nome do certificado
              </S.TitleCustom>

              <S.TitleCustom fontWeight="bold" color={textColor} width="40%">
                Mês
                <S.SortIcon
                  isAscending={ascendingOrder}
                  onClick={() => setAscendingOrder()}
                >
                  <AvailableIcons option="arrow" color={textColor} />
                </S.SortIcon>
              </S.TitleCustom>
            </S.CertificateHeader>

            <S.ContentList>
              {verraCertificates && (
                <S.Certificate
                  key="verra"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${REACT_APP_IBIOMA_CERTIFICATES_BUCKET}/${
                    user.entity
                  }/${user.username}/${year.toString()}/Certificados_VERRA.pdf`}
                >
                  <S.CertificateIcon>
                    <AvailableIcons option="seal" color={textColor} />
                  </S.CertificateIcon>

                  <S.TitleCustom width="60%" color={textColor}>
                    Certificado VERRA
                  </S.TitleCustom>

                  <S.TitleCustom width="40%" color={textColor}>
                    -
                  </S.TitleCustom>
                </S.Certificate>
              )}

              {certificates[year].map((item, index) => (
                <S.Certificate
                  href={item.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={`${item.label}#${index}`}
                >
                  <S.CertificateIcon>
                    <AvailableIcons option="seal" color={textColor} />
                  </S.CertificateIcon>

                  <S.TitleCustom width="60%" color={textColor}>
                    {item.label}
                  </S.TitleCustom>

                  <S.TitleCustom width="40%" color={textColor}>
                    {item.month}
                  </S.TitleCustom>
                </S.Certificate>
              ))}
            </S.ContentList>
          </S.Content>
        </>
      )}
    </S.Certificates>
  );
};

export default Certificates;
