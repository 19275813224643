import * as S from "./styles";
import { Theme } from "../../../hooks";
import Option from "../../atoms/Option";
import H4 from "../../atoms/Typography/H4";

interface IProps {
  label: string;
  options: string[];
  labelWeight?: string;
  selectedItem: number;
  direction?: "row" | "column";
  setSelectedItem: (index: number) => void;
}

const OptionsList: React.FC<IProps> = ({
  label,
  options,
  selectedItem,
  setSelectedItem,
  direction = "row",
  labelWeight = "regular",
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.Container>
      <H4 color={textColor} fontWeight={labelWeight}>
        {label}
      </H4>

      <S.Options direction={direction}>
        {options.map((item, index) => (
          <Option
            key={item}
            label={item}
            isSelected={index === selectedItem}
            setSelected={() => setSelectedItem(index)}
          />
        ))}
      </S.Options>
    </S.Container>
  );
};

export default OptionsList;
