import * as S from "./styles";
import { Theme } from "../../../hooks";
import H4 from "../../atoms/Typography/H4";
import { IOrderDetails } from "../../../types/order";
import AvailableIcons from "../../atoms/AvailableIcons";
import SelectProject from "../../molecules/SelectProject";

interface IProps {
  label: string;
  info?: string;
  errors?: string[];
  selected: string[];
  infoIcon?: boolean;
  labelWeight: string;
  placeholder: string;
  labelColor?: string;
  options: IOrderDetails[];
  onSelect: (val: string) => void;
}

const BuyProjectsMenu: React.FC<IProps> = ({
  info,
  label,
  errors,
  options,
  onSelect,
  infoIcon,
  selected,
  labelColor,
  labelWeight,
  placeholder,
}) => {
  const { textColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Container>
      <S.LabelBox>
        <H4 color={labelColor || backgroundColor} fontWeight={labelWeight}>
          {label}
        </H4>

        {infoIcon && (
          <S.InfoIcon>
            <AvailableIcons option="infoOne" color={textColor} />

            <S.InfoBox>{info}</S.InfoBox>
          </S.InfoIcon>
        )}
      </S.LabelBox>

      <SelectProject
        options={options}
        label={placeholder}
        selectedIndex={selected}
        onSelect={(val) => onSelect(val)}
      />

      {errors && errors.length && errors[0] !== "" ? (
        <S.ErrorMessage color="#FF4D4F">
          Erros: {errors.join(", ")}
        </S.ErrorMessage>
      ) : null}
    </S.Container>
  );
};

export default BuyProjectsMenu;
