import styled, { css } from "styled-components";

type ContentProps = {
  isOpen: boolean;
};

export const Content = styled.div<ContentProps>`
  ${({ isOpen }) => css`
    position: relative;
    align-items: center;
    justify-content: center;
    display: ${isOpen ? "flex" : "none"};
  `}
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
  position: fixed;
  background-color: #00000080;
`;

export const Modal = styled.div`
  top: 25vh;
  gap: 32px;
  width: 600px;
  z-index: 200;
  display: flex;
  position: fixed;
  padding: 20px 60px;
  text-align: center;
  border-radius: 12px;
  flex-direction: column;
  background-color: #fafafa;

  @media (max-width: 1160px) {
    align-self: center;
  }

  @media (max-width: 700px) {
    width: 400px;
    padding: 20px 40px;
  }

  @media (max-width: 480px) {
    width: 300px;
    padding: 20px;
  }
`;

export const Buttons = styled.div`
  gap: 100px;
  display: flex;
  padding: 0 40px;
  justify-content: space-between;

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;
