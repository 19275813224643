import { PropsWithChildren, useState } from "react";

import * as S from "./styles";
import Menu from "../Menu";
import { Theme } from "../../../hooks";
import MobileMenu from "../MobileMenu";
import LogoutModal from "../LogoutModal";
import MobileHeader from "../MobileHeader";
import { TSections } from "../../../types";

interface IProps {
  bgColor?: string;
  pageIndex: number;
  mobileSection: TSections;
}

const Page: React.FC<PropsWithChildren<IProps>> = ({
  bgColor,
  children,
  pageIndex,
  mobileSection,
}) => {
  const [logout, setLogout] = useState<boolean>(false);
  const [optionOpen, setOptionOpen] = useState<boolean>(false);
  const [calcSection, setCalcSection] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(pageIndex);
  const [previousMobileOption, setPreviousMobileOption] =
    useState<TSections>(mobileSection);
  const [selectedMobileOption, setSelectedMobileOption] =
    useState<TSections>(mobileSection);

  const { backgroundColor } = Theme.useTheme();

  const onClickBackdrop = () => {
    setOptionOpen(false);
    setCalcSection(false);
    setSelectedMobileOption(previousMobileOption);
  };

  const onSelectMobileMenuOption = (val: TSections) => {
    setPreviousMobileOption(selectedMobileOption);
    setSelectedMobileOption(val);
  };

  return (
    <>
      <S.Page backgroundColor={bgColor || backgroundColor}>
        {(optionOpen || calcSection) && (
          <S.Backdrop onClick={() => onClickBackdrop()} />
        )}

        <Menu
          calcSection={calcSection}
          selectedIndex={selectedOption}
          setLogout={(val) => setLogout(val)}
          setCalcSection={(val) => setCalcSection(val)}
          setSelected={(val) => setSelectedOption(val)}
        />

        <MobileHeader setLogout={(val) => setLogout(val)} />

        <S.Container>{children}</S.Container>

        <MobileMenu
          optionOpen={optionOpen}
          selected={selectedMobileOption}
          setSelected={(val) => onSelectMobileMenuOption(val)}
          setOptionSelected={() => setOptionOpen((curr) => !curr)}
        />
      </S.Page>

      {logout && (
        <LogoutModal isOpen={logout} onClose={() => setLogout(false)} />
      )}
    </>
  );
};

export default Page;
