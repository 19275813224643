import { useState } from "react";

import * as S from "./styles";
import {
  states,
  statesCities,
  statesCitiesCalcs,
} from "../../constants/location";
import { Client } from "../../services";
import { IAddress, IRegister } from "../../types";
import Button from "../../components/atoms/Button";
import { TStates } from "../../types/adminCalculators";
import Title from "../../components/atoms/Typography/Title";
import InputText from "../../components/molecules/InputText";
import AddImages from "../../components/organisms/AddImages";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import { emptyRegister, normalizeUsername } from "../../utils";
import OptionsList from "../../components/molecules/OptionsList";
import RegisterPage from "../../components/molecules/RegisterPage";
import DropdownMenu from "../../components/organisms/DropdownMenu";
import RegisterProgress from "../../components/molecules/RegisterProgress";
import { maskCEP, maskCPFOrCNPJ, maskPhoneNumber } from "../../utils/numbers";

const validateForm = (
  step: number,
  data: IRegister
): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (step === 0) {
    if (!data.mail || data.mail.length === 0)
      errors.mail = [...(errors.mail || []), "E-mail é obrigatório"];

    if (
      data.mail &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.mail)
    )
      errors.mail = [...(errors.mail || []), "Verifique o e-mail"];

    if (!data.password || data.password.length === 0)
      errors.password = [...(errors.password || []), "Senha é obrigatória"];

    if (!data.confirmPassword || data.confirmPassword.length === 0)
      errors.confirmPassword = [
        ...(errors.confirmPassword || []),
        "Confirmação de senha é obrigatória",
      ];

    if (
      data.password &&
      data.password.length > 0 &&
      data.confirmPassword &&
      data.confirmPassword.length > 0 &&
      JSON.stringify(data.password) !== JSON.stringify(data.confirmPassword)
    ) {
      errors.password = [...(errors.password || []), "Senhas não coincidem"];
      errors.confirmPassword = [
        ...(errors.confirmPassword || []),
        "Senhas não coincidem",
      ];
    }
  }

  if (step === 1) {
    if (!data.username || data.username.length === 0)
      errors.username = [...(errors.username || []), "Username é obrigatório"];

    if (data.cpfOrCnpj === "cpf" && !data.cpf)
      errors.cpf = [...(errors.cpf || []), "CPF obrigatório"];

    if (data.cpfOrCnpj === "cpf" && data.cpf && data.cpf.length !== 11)
      errors.cpf = [...(errors.cpf || []), "CPF incompleto"];

    if (data.cpfOrCnpj === "cpf") {
      if (!data.address?.street)
        errors.street = [...(errors.street || []), "Endereço obrigatório"];

      if (!data.address?.number)
        errors.number = [...(errors.number || []), "Número obrigatório"];

      if (!data.address?.postalCode)
        errors.postalCode = [...(errors.postalCode || []), "CEP obrigatório"];

      if (!data.address?.district)
        errors.district = [...(errors.district || []), "Bairro obrigatório"];

      if (!data.address?.city?.name)
        errors.city = [...(errors.city || []), "Cidade obrigatória"];
    }

    if (data.cpfOrCnpj === "cnpj" && !data.cnpj)
      errors.cnpj = [...(errors.cnpj || []), "CNPJ obrigatório"];

    if (data.cpfOrCnpj === "cnpj" && data.cnpj && data.cnpj.length !== 14)
      errors.cnpj = [...(errors.cnpj || []), "CNPJ incompleto"];
  }

  return errors;
};

const Register: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [search, setSearch] = useState<string>();
  const [filtered, setFiltered] = useState<string[]>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [registerForm, setRegisterForm] = useState<IRegister>(emptyRegister);

  const { register, user } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { hideLoading, showLoading } = Loading.useLoading();
  const { textColor, tertiaryColor, primaryColor, backgroundColor } =
    Theme.useTheme();

  const onChangeHandler = (
    key: keyof IRegister,
    value: string | number | boolean
  ) => {
    if (key === "cpfOrCnpj" && value === "cpf") {
      setRegisterForm((curr) => ({
        ...curr,
        cnpj: undefined,
        cpfOrCnpj: value,
      }));

      return;
    }

    if (key === "cpfOrCnpj" && value === "cnpj") {
      setRegisterForm((curr) => ({
        ...curr,
        cpf: undefined,
        cpfOrCnpj: value,
        address: undefined,
      }));

      return;
    }

    setRegisterForm((curr) => ({
      ...curr,
      [key]: value,
    }));
  };

  const onChangeAddressHandler = (key: keyof IAddress, value: string) => {
    setRegisterForm((curr) => {
      const address = { ...(curr.address || {}), [key]: value };

      return {
        ...curr,
        address: address,
      };
    });
  };

  const onChangeCityAddressHandler = (value: string) => {
    const cityCode = statesCities[registerForm.companyState as TStates].find(
      (item) => item.city === value
    );

    setRegisterForm((curr) => {
      const city = {
        name: value,
        code: cityCode?.code,
        state: registerForm.companyState,
      };

      return {
        ...curr,
        address: { ...(curr.address || {}), city: city },
      };
    });
  };

  const onSearch = (val: string, options: string[]) => {
    setSearch(val);

    const filteredArray = options.filter((item) =>
      item
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          val
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );

    setFiltered(filteredArray);
  };

  const cpfCnpj = ["CNPJ", "CPF"];

  const cities = registerForm.companyState
    ? statesCities[registerForm.companyState as TStates].map(
        (item) => item.city
      )
    : [];

  const registerStep = () => {
    switch (step) {
      case 0:
        return (
          <S.FormBox>
            <InputText
              label="E-mail"
              errors={errors.mail}
              labelColor={textColor}
              value={registerForm.mail}
              backgroundColor={tertiaryColor}
              onChange={(val) => onChangeHandler("mail", val)}
            />

            <InputText
              label="Senha"
              type="password"
              labelColor={textColor}
              errors={errors.password}
              value={registerForm.password}
              backgroundColor={tertiaryColor}
              onChange={(val) => onChangeHandler("password", val)}
            />

            <InputText
              type="password"
              labelColor={textColor}
              label="Confirme a senha"
              errors={errors.confirmPassword}
              backgroundColor={tertiaryColor}
              value={registerForm.confirmPassword || ""}
              onChange={(val) => onChangeHandler("confirmPassword", val)}
            />
          </S.FormBox>
        );

      case 1:
        return (
          <S.FormBox>
            <InputText
              labelColor={textColor}
              label="Nome da empresa"
              errors={errors.company}
              value={registerForm.company}
              backgroundColor={tertiaryColor}
              onChange={(val) => onChangeHandler("company", val)}
            />

            <InputText
              label="Username"
              labelColor={textColor}
              errors={errors.username}
              value={registerForm.username}
              backgroundColor={tertiaryColor}
              onChange={(val) =>
                onChangeHandler("username", normalizeUsername(val))
              }
            />

            <OptionsList
              direction="row"
              options={cpfCnpj}
              labelWeight="bold"
              label="CNPJ ou CPF"
              selectedItem={cpfCnpj.indexOf(
                registerForm.cpfOrCnpj.toUpperCase()
              )}
              setSelectedItem={(val) =>
                onChangeHandler("cpfOrCnpj", cpfCnpj[val].toLowerCase())
              }
            />

            {registerForm.cpfOrCnpj === "cnpj" && (
              <InputText
                label="CNPJ"
                charLimit={18}
                errors={errors.cnpj}
                labelColor={textColor}
                backgroundColor={tertiaryColor}
                value={maskCPFOrCNPJ(registerForm.cnpj || "")}
                onChange={(val) =>
                  onChangeHandler("cnpj", val.replace(/[^0-9]/g, ""))
                }
              />
            )}

            {registerForm.cpfOrCnpj === "cpf" && (
              <InputText
                label="CPF"
                charLimit={14}
                errors={errors.cpf}
                labelColor={textColor}
                backgroundColor={tertiaryColor}
                value={maskCPFOrCNPJ(registerForm.cpf || "")}
                onChange={(val) =>
                  onChangeHandler("cpf", val.replace(/[^0-9]/g, ""))
                }
              />
            )}

            <S.LocationBox>
              <DropdownMenu
                options={states}
                labelWeight="bold"
                labelColor={textColor}
                borderColor={textColor}
                bgColor={tertiaryColor}
                label="Estado da empresa"
                errors={errors.companyState}
                placeholder="Selecione o estado"
                selected={registerForm.companyState}
                onSelect={(val) => onChangeHandler("companyState", val)}
              />

              <DropdownMenu
                search={search}
                infoIcon={true}
                useSearch={true}
                labelWeight="bold"
                labelColor={textColor}
                borderColor={textColor}
                bgColor={tertiaryColor}
                label="Cidade da empresa"
                filteredOptions={filtered}
                errors={errors.companyCity}
                placeholder="Selecione a cidade"
                selected={registerForm.companyCity}
                onClearSearch={() => setFiltered(undefined)}
                onSelect={(val) => onChangeHandler("companyCity", val)}
                onSearch={(val) =>
                  onSearch(
                    val,
                    statesCitiesCalcs[registerForm.companyState as TStates]
                  )
                }
                options={
                  statesCitiesCalcs[registerForm.companyState as TStates]
                }
                info="Essa cidade será utilizada nas calculadoras. Caso não encontre sua cidade, selecione aquela mais próxima"
              />
            </S.LocationBox>

            {registerForm.cpfOrCnpj === "cpf" && (
              <S.Address>
                <InputText
                  key="street"
                  label="Endereço"
                  labelWeight="bold"
                  labelColor={textColor}
                  errors={errors.street}
                  backgroundColor={tertiaryColor}
                  value={registerForm.address?.street || ""}
                  onChange={(val) => onChangeAddressHandler("street", val)}
                />

                <S.AddressBox>
                  <InputText
                    label="Número"
                    key="addressNumber"
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.number}
                    backgroundColor={tertiaryColor}
                    value={registerForm.address?.number || ""}
                    onChange={(val) => onChangeAddressHandler("number", val)}
                  />

                  <InputText
                    label="Complemento"
                    labelWeight="bold"
                    labelColor={textColor}
                    key="addressComplement"
                    backgroundColor={tertiaryColor}
                    value={registerForm.address?.additionalInformation || ""}
                    onChange={(val) =>
                      onChangeAddressHandler("additionalInformation", val)
                    }
                  />
                </S.AddressBox>

                <S.AddressBox>
                  <InputText
                    label="Bairro"
                    key="district"
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.district}
                    backgroundColor={tertiaryColor}
                    value={registerForm.address?.district || ""}
                    onChange={(val) => onChangeAddressHandler("district", val)}
                  />

                  <InputText
                    label="CEP"
                    key="postalCode"
                    labelWeight="bold"
                    labelColor={textColor}
                    errors={errors.postalCode}
                    backgroundColor={tertiaryColor}
                    value={maskCEP(registerForm.address?.postalCode || "")}
                    onChange={(val) =>
                      onChangeAddressHandler(
                        "postalCode",
                        val.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </S.AddressBox>

                <S.CityBox>
                  <DropdownMenu
                    label="Cidade"
                    infoIcon={true}
                    search={search}
                    options={cities}
                    useSearch={true}
                    labelWeight="bold"
                    errors={errors.city}
                    labelColor={textColor}
                    borderColor={textColor}
                    bgColor={tertiaryColor}
                    filteredOptions={filtered}
                    placeholder="Selecione a cidade"
                    onSearch={(val) => onSearch(val, cities)}
                    onClearSearch={() => setFiltered(undefined)}
                    selected={registerForm.address?.city?.name || ""}
                    onSelect={(val) => onChangeCityAddressHandler(val)}
                    info="Essa cidade será utilizada nas emissões da notas fiscais"
                  />
                </S.CityBox>
              </S.Address>
            )}

            <AddImages
              label="Logo"
              showPreview={true}
              image={registerForm.logo}
              onChange={(val) => onChangeHandler("logo", val)}
            />
          </S.FormBox>
        );

      case 2:
        return (
          <S.FormBox>
            <InputText
              labelColor={textColor}
              label="Nome do contato"
              errors={errors.contactName}
              value={registerForm.contactName}
              onChange={(val) => onChangeHandler("contactName", val)}
            />

            <InputText
              type="tel"
              charLimit={15}
              pattern="[0-9]*"
              inputMode="numeric"
              labelColor={textColor}
              errors={errors.contactPhone}
              label="Telefone para contato"
              value={maskPhoneNumber(registerForm.contactPhone)}
              onChange={(val) =>
                onChangeHandler(
                  "contactPhone",
                  (val || "").replace(/[^0-9]/g, "")
                )
              }
            />
          </S.FormBox>
        );

      default:
        return <></>;
    }
  };

  const checkUsernameAvailability = async () => {
    const isUsernameRegistered = await Client.checkFieldAvailability(
      "username",
      registerForm.username,
      user.entity
    );

    return isUsernameRegistered;
  };

  const onCheckMail = async () => {
    const isEmailInUse = await Client.checkFieldAvailability(
      "mail",
      registerForm.mail,
      user.entity
    );

    return isEmailInUse;
  };

  const onNextHandler = async () => {
    if (step === 0) {
      try {
        showLoading();

        const isMailRegistered = await onCheckMail();

        if (isMailRegistered) {
          newError(
            "O e-mail informado já está cadastrado. Faça login (entrar) ou utilize um e-mail diferente"
          );

          return;
        }

        const currError = validateForm(0, registerForm);

        if (
          currError &&
          Object.keys(currError).length > 0 &&
          (currError.password || currError.confirmPassword)
        ) {
          newError("As senhas não coincidem. Tente novamente.");
          setErrors(currError);

          return;
        }

        if (currError && Object.keys(currError).length > 0) {
          setErrors(currError);

          return;
        }

        setStep((curr) => curr + 1);
        setErrors({});
      } finally {
        hideLoading();
      }
    }

    if (step === 1) {
      try {
        showLoading();
        const isUsernameRegistered = await checkUsernameAvailability();

        if (isUsernameRegistered) {
          setErrors((curr) => ({
            ...curr,
            username: ["Username inválido"],
          }));

          newError(
            "Esse username já existe. Utilize-o para login ou escolha outra opção."
          );

          return;
        }

        setErrors({});

        const currError = validateForm(1, registerForm);

        if (currError && Object.keys(currError).length > 0) {
          setErrors(currError);

          return;
        }

        setStep((curr) => curr + 1);
      } catch (error) {
        newError("Houve um erro ao verificar o username escolhido");
      } finally {
        hideLoading();
      }
    }
  };

  const onRegisterInfo = async () => {
    register({
      ...registerForm,
      entity: user.entity,
      username: registerForm.username?.trim(),
      mail: registerForm.mail?.toLowerCase().trim(),
    });

    setErrors({});
  };

  return (
    <RegisterPage>
      <S.HeaderAndProgress>
        <Title color={textColor}>Cadastro</Title>

        <RegisterProgress formStep={step} />
      </S.HeaderAndProgress>

      <S.Content>{registerStep()}</S.Content>

      <S.Buttons>
        {step !== 2 && (
          <Button
            width="120px"
            variant="solid"
            fontWeight="500"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
            onClick={() => onNextHandler()}
          >
            Próximo
          </Button>
        )}

        {step === 2 && (
          <Button
            width="120px"
            variant="solid"
            fontWeight="500"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
            onClick={() => onRegisterInfo()}
          >
            Criar conta
          </Button>
        )}

        {step > 0 && (
          <Button
            width="120px"
            variant="solid"
            fontWeight="500"
            textColor={backgroundColor}
            backgroundColor={primaryColor}
            onClick={() => setStep((curr) => curr - 1)}
          >
            Anterior
          </Button>
        )}
      </S.Buttons>
    </RegisterPage>
  );
};

export default Register;
