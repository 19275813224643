import * as S from "./styles";
import {
  ICardsData,
  ICreditCard,
  TRecurrence,
  IOrderDetails,
  TBillingOption,
  ICreditCardHolderInfo,
} from "../../../../types/order";
import {
  irRate,
  pisRate,
  csllRate,
  cofinsRate,
} from "../../../../constants/taxes";
import {
  paymentMethod,
  paymentMethodValues,
} from "../../../../constants/order";
import { Auth, Theme } from "../../../../hooks";
import Button from "../../../../components/atoms/Button";
import P from "../../../../components/atoms/Typography/P";
import H3 from "../../../../components/atoms/Typography/H3";
import H5 from "../../../../components/atoms/Typography/H5";
import Title from "../../../../components/atoms/Typography/Title";
import { volumeSelectedProjects } from "../../../../utils/numbers";
import OptionsList from "../../../../components/molecules/OptionsList";
import AvailableIcons from "../../../../components/atoms/AvailableIcons";
import CreditCardInfo from "../../../../components/organisms/CreditCardInfo";
import CreditCardsCard from "../../../../components/molecules/CreditCardsCard";

interface IProps {
  plan: TRecurrence;
  totalValue: number;
  addNewCard: boolean;
  saveNewCard: boolean;
  selectedCard: number;
  selectedPayment: number;
  newCardInfo: ICardsData;
  onBuyHandler: () => void;
  onAddNewCard: () => void;
  onSaveNewCard: () => void;
  onDiscardNewCard: () => void;
  availableCards?: ICardsData[];
  onBeneficiaryClick: () => void;
  selectedProjects: IOrderDetails[];
  errors: { [key: string]: string[] };
  setSelectedCard: (val: number) => void;
  onSelectPayment: (val: TBillingOption) => void;
  onChangeCardInfo: (key: keyof ICreditCard, val: string) => void;
  onChangeCardHolderInfo: (
    key: keyof ICreditCardHolderInfo,
    val: string
  ) => void;
}

const CompleteBuy: React.FC<IProps> = ({
  plan,
  errors,
  totalValue,
  addNewCard,
  saveNewCard,
  newCardInfo,
  onBuyHandler,
  selectedCard,
  onAddNewCard,
  onSaveNewCard,
  availableCards,
  selectedPayment,
  onSelectPayment,
  setSelectedCard,
  selectedProjects,
  onDiscardNewCard,
  onChangeCardInfo,
  onBeneficiaryClick,
  onChangeCardHolderInfo,
}) => {
  const { user } = Auth.useAuth();
  const { primaryColor, tertiaryColor, backgroundColor, textColor } =
    Theme.useTheme();

  const projectWidth = "40%";
  const tCo2Width = "20%";
  const volumeWidth = "20%";
  const pricingWidth = "20%";

  const volume = volumeSelectedProjects(selectedProjects);

  const paymentOpt =
    plan === "annual" ? ["Cartão de crédito"] : paymentMethodValues;

  const disableButton =
    !selectedProjects ||
    selectedProjects.length === 0 ||
    selectedPayment < 0 ||
    (Object.keys(paymentMethod)[selectedPayment] === "CREDIT_CARD" &&
      !availableCards &&
      !addNewCard) ||
    (Object.keys(paymentMethod)[selectedPayment] === "CREDIT_CARD" &&
      !addNewCard &&
      availableCards &&
      availableCards.length > 0 &&
      selectedCard < 0);

  const billingValue = user.simplesNacional
    ? totalValue
    : totalValue * (1 - (irRate + pisRate + csllRate + cofinsRate) / 100);

  return (
    <S.CompleteBuy>
      <Title color={textColor}>Resumo do Pedido</Title>

      <S.Content>
        <S.HeaderContent>
          <S.TitleCustom
            fontWeight="bold"
            color={textColor}
            width={projectWidth}
          >
            Projeto
          </S.TitleCustom>

          <S.TitleCustom width={tCo2Width} color={textColor} fontWeight="bold">
            tCO<sub>2</sub>
          </S.TitleCustom>

          <S.TitleCustom
            fontWeight="bold"
            color={textColor}
            width={volumeWidth}
          >
            % Volume
          </S.TitleCustom>

          <S.TitleCustom
            color={textColor}
            fontWeight="bold"
            width={pricingWidth}
          >
            Preço
          </S.TitleCustom>
        </S.HeaderContent>

        <S.ContentList>
          {selectedProjects.map((item, index) => (
            <S.Project key={`${item.projectId}#${index}`}>
              <S.TitleCustom width={projectWidth} color={textColor}>
                {item.projectName}
              </S.TitleCustom>

              <S.TitleCustom width={tCo2Width} color={textColor}>
                {item.quantity.toLocaleString("pt-BR", {
                  minimumFractionDigits: 0,
                })}
              </S.TitleCustom>

              <S.TitleCustom width={volumeWidth} color={textColor}>
                {volume[index]}
              </S.TitleCustom>

              <S.TitleCustom width={pricingWidth} color={textColor}>
                R${" "}
                {item.pricing.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}
              </S.TitleCustom>
            </S.Project>
          ))}

          <S.TotalContainer>
            <S.Total>
              <S.TotalValue
                color={textColor}
                fontWeight="bold"
                width={projectWidth + tCo2Width + volumeWidth}
              >
                Total
              </S.TotalValue>

              <S.TotalValue width={pricingWidth} color={textColor}>
                R${" "}
                {totalValue.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}
              </S.TotalValue>
            </S.Total>

            {!user.simplesNacional && (
              <>
                <S.Total>
                  <S.TotalValue
                    color={textColor}
                    width={projectWidth + tCo2Width + volumeWidth}
                  >
                    Impostos retidos na fonte
                  </S.TotalValue>

                  <S.TotalValue width={pricingWidth} color={textColor}>
                    - R${" "}
                    {(totalValue - billingValue).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </S.TotalValue>
                </S.Total>

                <S.Total>
                  <S.TotalValue
                    color={textColor}
                    fontWeight="bold"
                    width={projectWidth + tCo2Width + volumeWidth}
                  >
                    Valor da cobrança
                  </S.TotalValue>

                  <S.TotalValue width={pricingWidth} color={textColor}>
                    R${" "}
                    {billingValue.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </S.TotalValue>
                </S.Total>
              </>
            )}
          </S.TotalContainer>
        </S.ContentList>

        <S.Beneficiary borderColor={tertiaryColor}>
          <H3 color={textColor} fontWeight="bold">
            Beneficiário
          </H3>

          <H5 color={textColor}>
            O beneficiário é a entidade legal que será exibida nos certificados
            de aposentadoria dos créditos de carbono.
          </H5>

          <S.IconAndCTA>
            <S.IconAndLabel>
              <S.Icon>
                <AvailableIcons option="identification" color={textColor} />
              </S.Icon>

              <H5 color={textColor}>{user.company}</H5>
            </S.IconAndLabel>

            <S.CTA>
              <Button
                variant="solid"
                textColor={textColor}
                backgroundColor={tertiaryColor}
                onClick={() => onBeneficiaryClick()}
              >
                Alterar
              </Button>
            </S.CTA>
          </S.IconAndCTA>
        </S.Beneficiary>

        <S.Payment>
          <H3 color={textColor} fontWeight="bold">
            Pagamento
          </H3>

          <OptionsList
            direction="column"
            options={paymentOpt}
            selectedItem={selectedPayment}
            label="Selecione o método de pagamento desejado:"
            setSelectedItem={(val) =>
              onSelectPayment(paymentOpt[val] as TBillingOption)
            }
          />
        </S.Payment>

        {paymentOpt[selectedPayment] === "Cartão de crédito" && addNewCard && (
          <CreditCardInfo
            errors={errors}
            saveCard={saveNewCard}
            onSaveCard={() => onSaveNewCard()}
            onDiscard={() => onDiscardNewCard()}
            cardInfo={newCardInfo["creditCard"]}
            cardHolderInfo={newCardInfo["creditCardHolderInfo"]}
            onChangeCardInfo={(key, val) => onChangeCardInfo(key, val)}
            onChangeCardHolderInfo={(key, val) =>
              onChangeCardHolderInfo(key, val)
            }
          />
        )}

        {paymentOpt[selectedPayment] === "Cartão de crédito" && !addNewCard && (
          <S.Cards>
            {availableCards &&
              availableCards.map((item, index) => (
                <CreditCardsCard
                  key={`${item.id}#${index}`}
                  number={item.creditCard.number}
                  name={item.creditCard.holderName}
                  isSelected={selectedCard === index}
                  onSelect={() => setSelectedCard(index)}
                  brand={item.creditCard.brand || "mastercard"}
                />
              ))}

            <S.AddCardButton>
              <Button
                variant="dashed"
                textColor={primaryColor}
                borderColor={primaryColor}
                onClick={() => onAddNewCard()}
              >
                Adicionar novo cartão de crédito
              </Button>
            </S.AddCardButton>
          </S.Cards>
        )}

        <S.TermsAndConditions textColor={`${textColor}80`}>
          <P color={`${textColor}80`}>
            Ao clicar em Concluir compra você estará de acordo com os{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://ibioma.com.br/termos-e-condicoes"
            >
              termos e condições
            </a>
          </P>
        </S.TermsAndConditions>
      </S.Content>

      <S.BuyButton
        variant="solid"
        fontWeight="bold"
        disabled={disableButton}
        textColor={backgroundColor}
        backgroundColor={primaryColor}
        onClick={() => onBuyHandler()}
      >
        Concluir compra
      </S.BuyButton>
    </S.CompleteBuy>
  );
};

export default CompleteBuy;
