import * as S from "./styles";
import { Theme } from "../../../hooks";
import H4 from "../../atoms/Typography/H4";
import Title from "../../atoms/Typography/Title";

type TitleDescriptionProps = {
  title: string;
  description: string;
  centralize?: boolean;
  textColorCustom?: string;
  titleColorCustom?: string;
};

const TitleDescription: React.FC<TitleDescriptionProps> = ({
  title,
  centralize,
  description,
  textColorCustom,
  titleColorCustom,
}) => {
  const { textColor } = Theme.useTheme();

  return (
    <S.TextField centralize={centralize}>
      <Title color={titleColorCustom || textColor}>{title}</Title>

      <H4 color={textColorCustom || textColor}>{description}</H4>
    </S.TextField>
  );
};

export default TitleDescription;
