import styled, { css } from "styled-components";
import Button from "../../atoms/Button";

type PageProps = {
  backgroundColor: string;
};

export const Page = styled.div<PageProps>`
  width: 100%;
  display: flex;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  padding-bottom: 120px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  overflow-y: auto;
  position: relative;
  margin-left: 340px;
  flex-direction: column;
  padding: 40px 100px 40px 36px;

  @media (max-width: 1050px) {
    margin-left: 0;
    padding: 148px 80px 40px 80px;
  }

  @media (max-width: 720px) {
    padding: 148px 40px 40px 40px;
  }
`;

type MenuProps = {
  backgroundColor: string;
};

export const Menu = styled.div<MenuProps>`
  ${({ backgroundColor }) => css`
    top: 0;
    gap: 40px;
    display: flex;
    padding: 40px;
    height: 100vh;
    position: fixed;
    flex-direction: column;
    background-color: ${backgroundColor};

    @media (max-width: 1050px) {
      height: auto;
      padding: 20px;
      z-index: 1000;
      margin: 20px 40px;
      border-radius: 48px;
      flex-direction: row;
      width: calc(100% - 80px);
      justify-content: space-between;
    }

    @media (max-width: 540px) {
      border-radius: 32px;
    }
  `}
`;

type LogoProps = {
  logo: string;
};

export const Logo = styled.div<LogoProps>`
  ${({ logo }) => css`
    width: 200px;
    height: 60px;
    background-image: url(${logo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 780px) {
      width: 160px;
      height: 48px;
    }
  `}
`;

export const LoginButton = styled(Button)`
  @media (max-width: 1050px) {
    width: 160px;
  }

  @media (max-width: 570px) {
    width: 100px;
  }
`;
