import * as S from "./styles";
import { Theme } from "../../../hooks";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";

interface IProps {
  isOpen: boolean;
  onEdit: () => void;
  onClose: () => void;
}

const EditCropModal: React.FC<IProps> = ({ isOpen, onClose, onEdit }) => {
  const { primaryColor, backgroundColor } = Theme.useTheme();

  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <H4 color="#000000">
          Deseja editar este item? Você irá sobrescrever o formulário atual
        </H4>

        <S.Buttons>
          <Button
            variant="solid"
            onClick={onClose}
            backgroundColor="#ff4040"
            textColor={backgroundColor}
          >
            Cancelar
          </Button>

          <Button
            variant="solid"
            textColor={backgroundColor}
            onClick={() => onEdit()}
            backgroundColor={primaryColor}
          >
            Editar
          </Button>
        </S.Buttons>
      </S.Modal>
    </S.Content>
  );
};

export default EditCropModal;
