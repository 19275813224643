import styled from "styled-components";

type H3Props = {
  color: string;
  fontWeight?: string;
};

const H3 = styled.h3<H3Props>`
  font-size: 1.4rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`;

export default H3;
