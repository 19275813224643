import styled, { css } from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: start;
  position: relative;
  flex-direction: column;
`;

export const NoCalculationBox = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  max-width: 500px;
  margin-top: 80px;
  align-self: center;
  text-align: center;
  align-items: center;
  flex-direction: column;

  button {
    font-size: 1.5rem;
  }

  @media (max-width: 600px) {
    margin-top: 60px;
  }
`;

export const Icon = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 100px;
  }
`;

export const CalculationBox = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  margin-top: 60px;
  flex-direction: column;
`;

export const SummaryBox = styled.div`
  gap: 32px;
  width: 100%;
  display: grid;
  padding: 0 40px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    padding: 0;
  }

  @media (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type CardProps = {
  borderColor: string;
  backgroundColor: string;
};

export const Card = styled.div<CardProps>`
  ${({ borderColor, backgroundColor }) => css`
    gap: 32px;
    width: 100%;
    display: flex;
    padding: 32px 20px;
    text-align: center;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
  `}
`;

export const Calculator = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
`;

export const Buttons = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 0 40px;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 800px) {
    padding: 0;
  }
`;
