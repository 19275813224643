import { ResponsivePie } from "@nivo/pie";

import * as S from "./styles";
import {
  chartConfig,
  colorsBarChart,
  calculatePercentage,
  calculatePercentagePie,
} from "../../../utils/calculators";
import { Theme } from "../../../hooks";
import H2 from "../../atoms/Typography/H2";
import H3 from "../../atoms/Typography/H3";
import H4 from "../../atoms/Typography/H4";
import Small from "../../atoms/Typography/Small";
import NivoChartContainer from "../../atoms/NivoChartContainer";
import { ICalculatorResult } from "../../../types/adminCalculators";
import CalculatorResultIcons from "../../atoms/CalculatorResultIcons";

interface IProps {
  result: ICalculatorResult;
}

const ChartAndIndicators: React.FC<IProps> = ({ result }) => {
  const { textColor } = Theme.useTheme();

  const normScopes = [
    {
      id: "operações diretas",
      label: "directOp",
      value: result.ghgProtcolEmissions.directOperations,
    },
    {
      id: "energia elétrica",
      label: "energy",
      value: result.ghgProtcolEmissions.energy,
    },
    {
      id: "operações indiretas",
      label: "indirectOp",
      value: result.ghgProtcolEmissions.indirectOperations,
    },
  ];

  const percentageValues = calculatePercentagePie(normScopes);

  return (
    <S.Container>
      <S.Chart>
        <H3 color={textColor}>
          Emissões de CO<sub>2</sub> pelos escopos do GHG Protocol
        </H3>

        <NivoChartContainer type="bar">
          <ResponsivePie
            fit={true}
            padAngle={0.7}
            cornerRadius={4}
            innerRadius={0.7}
            data={normScopes}
            valueFormat=" >-.2"
            enableArcLabels={true}
            arcLabelsTextColor="black"
            enableArcLinkLabels={false}
            colors={{ datum: "defs.colors" }}
            arcLinkLabelsColor={{ from: "defs" }}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            defs={[
              {
                id: "directOp",
                type: "linearGradient",
                colors: [
                  { offset: 0, color: colorsBarChart[0] },
                  { offset: 100, color: colorsBarChart[0] },
                ],
              },
              {
                id: "energy",
                type: "linearGradient",
                colors: [
                  { offset: 0, color: colorsBarChart[1] },
                  { offset: 100, color: colorsBarChart[1] },
                ],
              },
              {
                id: "indirectOp",
                type: "linearGradient",
                colors: [
                  { offset: 0, color: colorsBarChart[2] },
                  { offset: 100, color: colorsBarChart[2] },
                ],
              },
            ]}
            fill={[
              { match: (d) => d.data.label === "directOp", id: "directOp" },
              { match: (d) => d.data.label === "energy", id: "energy" },
              { match: (d) => d.data.label === "indirectOp", id: "indirectOp" },
            ]}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: textColor,
                    },
                  },
                ],
              },
            ]}
          />
        </NivoChartContainer>

        <S.PieChartLegend>
          <S.LegendItem>
            <S.Colors>
              <S.Color color={colorsBarChart[0]} />
            </S.Colors>

            <S.Texts>
              <H2 color={textColor}>{percentageValues[0].value}</H2>
              <Small color={textColor}>{percentageValues[0].id}</Small>
            </S.Texts>
          </S.LegendItem>

          <S.LegendItem>
            <S.Colors>
              <S.Color color={colorsBarChart[1]} />
            </S.Colors>

            <S.Texts>
              <H2 color={textColor}>{percentageValues[1].value}</H2>
              <Small color={textColor}>{percentageValues[1].id}</Small>
            </S.Texts>
          </S.LegendItem>

          <S.LegendItem>
            <S.Colors>
              <S.Color color={colorsBarChart[2]} />
            </S.Colors>

            <S.Texts>
              <H2 color={textColor}>{percentageValues[2].value}</H2>
              <Small color={textColor}>{percentageValues[2].id}</Small>
            </S.Texts>
          </S.LegendItem>
        </S.PieChartLegend>
      </S.Chart>

      <S.Indicators>
        {result.detailedEmissions.map((item, index) => (
          <S.Indicator key={`${item.key}#${index}`}>
            <S.Icon>
              <CalculatorResultIcons option={item.key} />
            </S.Icon>

            <S.Data>
              <H4 color={textColor} fontWeight="bold">
                {chartConfig[item.key].label}
              </H4>

              <S.Percentage color={chartConfig[item.key].color}>
                {calculatePercentage(item.value, result.totalEmissions)}
              </S.Percentage>

              <H4 color={textColor}>
                {item.value.toFixed(2).replace(".", ",")} tCO<sub>2</sub>e
              </H4>
            </S.Data>
          </S.Indicator>
        ))}
      </S.Indicators>
    </S.Container>
  );
};

export default ChartAndIndicators;
