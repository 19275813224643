import { Theme } from "../../hooks";

interface IProps {
  color?: string;
  opacity?: number;
  blendMode?: "normal" | "overlay";
}

const CalculatorIcon: React.FC<IProps> = ({ color, opacity, blendMode }) => {
  const { primaryColor } = Theme.useTheme();
  return (
    <svg
      style={{
        opacity: opacity,
        mixBlendMode: blendMode,
      }}
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.28571 0H13.7143C14.3205 0 14.9019 0.210714 15.3305 0.585786C15.7592 0.960859 16 1.46957 16 2V18C16 18.5304 15.7592 19.0391 15.3305 19.4142C14.9019 19.7893 14.3205 20 13.7143 20H2.28571C1.67951 20 1.09812 19.7893 0.66947 19.4142C0.240816 19.0391 0 18.5304 0 18V2C0 1.46957 0.240816 0.960859 0.66947 0.585786C1.09812 0.210714 1.67951 0 2.28571 0ZM2.28571 2V6H13.7143V2H2.28571ZM2.28571 8V10H4.57143V8H2.28571ZM6.85714 8V10H9.14286V8H6.85714ZM11.4286 8V10H13.7143V8H11.4286ZM2.28571 12V14H4.57143V12H2.28571ZM6.85714 12V14H9.14286V12H6.85714ZM11.4286 12V14H13.7143V12H11.4286ZM2.28571 16V18H4.57143V16H2.28571ZM6.85714 16V18H9.14286V16H6.85714ZM11.4286 16V18H13.7143V16H11.4286Z"
        fill={color || primaryColor}
      />
    </svg>
  );
};

export default CalculatorIcon;
