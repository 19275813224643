import * as S from "./styles";
import { Auth } from "../../../hooks";
import Button from "../../atoms/Button";
import H4 from "../../atoms/Typography/H4";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { logout } = Auth.useAuth();

  return (
    <S.Content isOpen={isOpen}>
      <S.Backdrop onClick={onClose} />

      <S.Modal>
        <H4 color="#000000">Você que mesmo sair?</H4>

        <S.Buttons>
          <Button
            variant="solid"
            onClick={onClose}
            textColor="#fafafa"
            backgroundColor="#4A8415"
          >
            Cancelar
          </Button>

          <Button
            variant="solid"
            textColor="#fafafa"
            onClick={() => logout()}
            backgroundColor="#ff0000"
          >
            Sair
          </Button>
        </S.Buttons>
      </S.Modal>
    </S.Content>
  );
};

export default LogoutModal;
