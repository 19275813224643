import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import * as C from "../../../styles";
import {
  LOCAL_STORAGE_CREDITS_KEY,
  LOCAL_STORAGE_CALCULATOR_KEY,
  LOCAL_STORAGE_CALCULATION_ID_KEY,
} from "../../../../../../constants";
import {
  emptyLogistic,
  emptyLogistcCalculatorForm,
} from "../../../../../../utils/calculators";
import {
  vehiclesFuel,
  transportUnitsObj,
  airConditioningTypes,
  airConditioningTypesObj,
} from "../../../../../../constants/calculators";
import {
  ILogistic,
  ILogisticRoutes,
  ILogisticCalculatorForm,
} from "../../../../../../types/logisticCalculator";
import Questions from "./components/Questions";
import { Calculators } from "../../../../../../services";
import Button from "../../../../../../components/atoms/Button";
import H1 from "../../../../../../components/atoms/Typography/H1";
import H2 from "../../../../../../components/atoms/Typography/H2";
import H3 from "../../../../../../components/atoms/Typography/H3";
import H5 from "../../../../../../components/atoms/Typography/H5";
import { Snackbar, Loading, Theme, Auth } from "../../../../../../hooks";
import { ICalculatorResult } from "../../../../../../types/adminCalculators";
import ExcludeModal from "../../../../../../components/molecules/ExcludeModal";
import ChartAndIndicators from "../../../../../../components/organisms/ChartAndIndicators";
import CalculatorProgressBar from "../../../../../../components/molecules/CalculatorProgressBar";

export interface LogisticErrors {
  load?: string | undefined;
  fuelType: string | undefined;
  vehicleType: string | undefined;
  fuelExpenses: string | undefined;
}

const validateLogistic = (data?: ILogistic[]): LogisticErrors[] => {
  const errors = (() => {
    if (data && data.length > 0) {
      const logisticErrors = data.map((item) => {
        const fuel =
          item.fuelExpenses === "0,00" || !item.fuelExpenses
            ? "Valor deve ser maior que zero"
            : undefined;
        // const load =
        //   item.load === "0,00" || !item.load
        //     ? "Valor deve ser maior que zero"
        //     : undefined;
        const vehicleType =
          item.vehicleType === "" ? "Selecione um veículo" : undefined;
        const fuelType =
          item.fuelType === "" ? "Selecione um combustível" : undefined;

        return {
          // load: load,
          fuelType: fuelType,
          fuelExpenses: fuel,
          vehicleType: vehicleType,
        };
      });

      return logisticErrors;
    }

    return [
      {
        fuelType: "Selecione um veículo",
        vehicleType: "Selecione um veículo",
        // load: "Valor deve ser maior que zero",
        fuelExpenses: "Valor deve ser maior que zero",
      },
    ];
  })();

  return errors;
};

interface IProps {
  filledForm?: ILogisticCalculatorForm;
}

const airConditioningKeys = Object.keys(airConditioningTypesObj);

const Calculator: React.FC<IProps> = ({
  filledForm = emptyLogistcCalculatorForm,
}) => {
  const [calculatorForm, setCalculatorForm] =
    useState<ILogisticCalculatorForm>(filledForm);
  const [office, setOffice] = useState<boolean>(true);
  const [pickups, setPickups] = useState<boolean>(true);
  const [warehouse, setWarehouse] = useState<boolean>(true);
  const [removePickup, setRemovePickup] = useState<number>();
  const [documentName, setDocumentName] = useState<string>();
  const [removeDelivery, setRemoveDelivery] = useState<number>();
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [excludePickup, setExcludePickup] = useState<boolean>(false);
  const [excludeDelivery, setExcludeDelivery] = useState<boolean>(false);
  const [pickupsErrors, setPickupsErrors] = useState<LogisticErrors[]>();
  const [columnOptions, setColumnOptions] = useState(window.innerWidth <= 820);
  const [deliveriesErrors, setDeliveriesErrors] = useState<LogisticErrors[]>();
  const [calculatorResult, setCalculatorResult] = useState<ICalculatorResult>();
  const [airConditioningType, setAirConditioningType] = useState<number>(
    airConditioningKeys.indexOf(filledForm.airConditioningType || "none") || 0
  );
  const [columnRoutesOptions, setColumnRoutesOptions] = useState(
    window.innerWidth <= 640
  );
  const [pickupsData, setPickupsData] = useState<ILogistic[] | undefined>(
    filledForm.pickupsData || [emptyLogistic]
  );
  const [deliveriesData, setDeliveriesData] = useState<ILogistic[] | undefined>(
    filledForm.deliveriesData || [emptyLogistic]
  );

  const questionsTop = useRef<HTMLDivElement>(null);

  const { user, token } = Auth.useAuth();
  const { newError } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, textColor, backgroundColor } = Theme.useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setColumnOptions(window.innerWidth <= 820);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [columnOptions]);

  useEffect(() => {
    const handleResize = () => {
      setColumnRoutesOptions(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [columnRoutesOptions]);

  const onChangeHandler = (
    key: keyof ILogisticCalculatorForm,
    value: string | number | boolean
  ) => {
    setCalculatorForm((curr) => {
      if (
        key === "officeTransportationUnit" ||
        key === "warehouseTransportationUnit" ||
        key === "officePublicTransportationUnit" ||
        key === "warehousePublicTransportationUnit"
      ) {
        return {
          ...curr,
          [key]: Object.values(transportUnitsObj)[+value],
        };
      }

      if (key === "officeCookingGasUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "Kg",
        };
      }

      if (key === "officeEnergyUnit" || key === "warehouseEnergyUnit") {
        return {
          ...curr,
          [key]: value ? "R$" : "KWh",
        };
      }

      if (
        key === "airConditioningUnit" ||
        key === "warehouseRefrigeratorUnit"
      ) {
        return {
          ...curr,
          [key]: value ? "un." : "Kg",
        };
      }

      if (key === "routesMethod") {
        if (value === "manually") {
          setDocumentName(undefined);

          return {
            ...curr,
            [key]: value,
            routes: undefined,
          };
        }

        if (value === "upload") {
          setPickups(false);
          setPickupsErrors(undefined);
          setDeliveriesErrors(undefined);
          setPickupsData([emptyLogistic]);
          setDeliveriesData([emptyLogistic]);

          return {
            ...curr,
            [key]: value,
            routes: undefined,
          };
        }
      }

      return {
        ...curr,
        [key]: value,
      };
    });
  };

  const onAddRoutes = (routesData: ILogisticRoutes[], document: string) => {
    setCalculatorForm((curr) => ({
      ...curr,
      routes: routesData,
    }));

    setDocumentName(document);
  };

  const onChangePickupHandler = (
    key: keyof ILogistic,
    index: number,
    value: string | number | boolean
  ) => {
    setPickupsData((curr) => {
      if (!curr) return curr;

      if (curr) {
        const currentPickup = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            if (key === "expensesUnit") {
              return {
                ...currentPickup,
                [key]: Object.values(transportUnitsObj)[+value],
              };
            }

            if (key === "fuelType") {
              return {
                ...currentPickup,
                [key]:
                  Object.keys(vehiclesFuel)[
                    Object.values(vehiclesFuel).indexOf(value.toString())
                  ],
              };
            }

            return {
              ...currentPickup,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onChangeDeliveryHandler = (
    key: keyof ILogistic,
    index: number,
    value: string | number | boolean
  ) => {
    setDeliveriesData((curr) => {
      if (!curr) return curr;

      if (curr) {
        const currentDelivery = curr[index];

        const newData = curr.map((item, i) => {
          if (index === i) {
            if (key === "expensesUnit") {
              return {
                ...currentDelivery,
                [key]: Object.values(transportUnitsObj)[+value],
              };
            }

            if (key === "fuelType") {
              return {
                ...currentDelivery,
                [key]:
                  Object.keys(vehiclesFuel)[
                    Object.values(vehiclesFuel).indexOf(value.toString())
                  ],
              };
            }

            return {
              ...currentDelivery,
              [key]: value,
            };
          }

          return { ...item };
        });

        return newData;
      }
    });
  };

  const onBuyCredits = () => {
    localStorage.setItem(
      LOCAL_STORAGE_CREDITS_KEY,
      Math.ceil(calculatorResult?.totalEmissions || 0).toString()
    );

    localStorage.setItem(LOCAL_STORAGE_CALCULATOR_KEY, "logistic");

    localStorage.setItem(
      LOCAL_STORAGE_CALCULATION_ID_KEY,
      calculatorResult?.id || ""
    );

    navigate("/comprar");
  };

  const onNewConsultHandler = async () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setCalculatorResult(undefined);
    setCalculatorForm(emptyLogistcCalculatorForm);
    setOffice(true);
    setPickups(true);
    setWarehouse(true);
    setQuestionNumber(1);
    setPickupsErrors(undefined);
    setDeliveriesErrors(undefined);
    setPickupsData([emptyLogistic]);
    setDeliveriesData([emptyLogistic]);
  };

  const onAheadClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr + 1);
  };

  const onPreviousClick = () => {
    questionsTop.current?.scrollIntoView({ behavior: "smooth" });

    setQuestionNumber((curr) => curr - 1);
  };

  const onAddPickup = () => {
    if (!pickupsData) {
      setPickupsData([emptyLogistic]);

      return;
    }

    setPickupsData((curr) => [...(curr || []), emptyLogistic]);
  };

  const onExcludePickup = (index: number) => {
    if (pickupsData && pickupsData.length === 1) {
      newError(
        "É necessária, ao menos, 1 coleta. Caso não queira adicionar coletas, selecione 'Não' acima."
      );

      return;
    }

    setRemovePickup(index);
    setExcludePickup(true);
  };

  const onExcludeDelivery = (index: number) => {
    if (deliveriesData && deliveriesData.length === 1) {
      newError("É necessária, ao menos, 1 entrega.");

      return;
    }

    setRemoveDelivery(index);
    setExcludeDelivery(true);
  };

  const onRemovePickup = (index: number) => {
    setPickupsData((curr) => {
      if (!curr) return;

      if (curr && curr.length === 1) {
        newError(
          "É necessária, ao menos, 1 coleta. Caso não queira adicionar coletas, selecione 'Não' acima."
        );

        return curr;
      }

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludePickup(false);
  };

  const onAddDelivery = () => {
    if (!deliveriesData) {
      setDeliveriesData([emptyLogistic]);

      return;
    }

    setDeliveriesData((curr) => [...(curr || []), emptyLogistic]);
  };

  const onRemoveDelivery = (index: number) => {
    setDeliveriesData((curr) => {
      if (!curr) return;

      if (curr && curr.length === 1) {
        newError("É necessária, ao menos, 1 entrega.");

        return curr;
      }

      const newData = [...(curr || [])];

      newData.splice(index, 1);

      return [...newData];
    });

    setExcludeDelivery(false);
  };

  const onCalculateHandler = async () => {
    try {
      showLoading();

      const currDelivErrors =
        calculatorForm.routesMethod === "manually"
          ? validateLogistic(deliveriesData)
          : [];
      const currPickErrors =
        calculatorForm.routesMethod === "manually" && pickups
          ? validateLogistic(pickupsData)
          : [];

      const checkPickError = currPickErrors.reduce((acc, curr) => {
        const textValues = Object.values(curr).reduce((accText, currText) => {
          accText += typeof currText === "string" ? 1 : 0;

          return accText;
        }, 0);

        acc += textValues;

        return acc;
      }, 0);

      const checkDelivError = currDelivErrors.reduce((acc, curr) => {
        const textValues = Object.values(curr).reduce((accText, currText) => {
          accText += typeof currText === "string" ? 1 : 0;

          return accText;
        }, 0);

        acc += textValues;

        return acc;
      }, 0);

      if (checkDelivError > 0 || checkPickError > 0) {
        if (checkDelivError > 0) {
          setDeliveriesErrors(currDelivErrors);
          setQuestionNumber(1);
        }
        if (checkPickError > 0) {
          setPickupsErrors(currPickErrors);
          setQuestionNumber(1);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        return alert(
          "O formulário da calculadora possui erros, por favor verifique os campos para continuar"
        );
      }

      questionsTop.current?.scrollIntoView({ behavior: "smooth" });

      const result = await Calculators.sendLogisticInformation(
        {
          ...calculatorForm,
          userId: user.id || "",
          cpf: user.cpf || undefined,
          cnpj: user.cnpj || undefined,
          contactMail: user.mail || "",
          companyName: user.company || "",
          cpfOrCnpj: user.cpfOrCnpj || "cnpj",
          companyCity: user.companyCity || "",
          contactName: user.contactName || "",
          companyState: user.companyState || "",
          contactPhone: user.contactPhone || "",
          officeEnergyUnit:
            calculatorForm.officeEnergyExpenses &&
            !calculatorForm.officeEnergyUnit
              ? "KWh"
              : calculatorForm.officeEnergyUnit,
          airConditioningUnit:
            calculatorForm.airConditioningRefills &&
            !calculatorForm.airConditioningUnit
              ? "Kg"
              : calculatorForm.airConditioningUnit,
          warehouseEnergyUnit:
            calculatorForm.warehouseEnergyExpenses &&
            !calculatorForm.warehouseEnergyUnit
              ? "KWh"
              : calculatorForm.warehouseEnergyUnit,
          officeCookingGasUnit:
            calculatorForm.officeCookingGasExpenses &&
            !calculatorForm.officeCookingGasUnit
              ? "Kg"
              : calculatorForm.officeCookingGasUnit,
          officeTransportationUnit:
            calculatorForm.officeTransportationExpenses &&
            !calculatorForm.officePublicTransportationUnit
              ? "R$"
              : calculatorForm.officePublicTransportationUnit,
          warehouseRefrigeratorUnit:
            calculatorForm.warehouseRefrigeratorRefills &&
            !calculatorForm.warehouseRefrigeratorUnit
              ? "Kg"
              : calculatorForm.warehouseRefrigeratorUnit,
          warehouseTransportationUnit:
            calculatorForm.warehouseTransportationExpenses &&
            !calculatorForm.warehouseTransportationUnit
              ? "R$"
              : calculatorForm.warehouseTransportationUnit,
          officePublicTransportationUnit:
            calculatorForm.officePublicTransportationExpenses &&
            !calculatorForm.officePublicTransportationUnit
              ? "R$"
              : calculatorForm.officePublicTransportationUnit,
          warehousePublicTransportationUnit:
            calculatorForm.warehousePublicTransportationExpenses &&
            !calculatorForm.warehousePublicTransportationUnit
              ? "R$"
              : calculatorForm.warehousePublicTransportationUnit,
          deliveriesData:
            calculatorForm.routesMethod === "manually"
              ? deliveriesData
              : undefined,
          pickupsData:
            calculatorForm.routesMethod === "manually" && pickups
              ? pickupsData
              : undefined,
          airConditioningType: airConditioningTypes[airConditioningType],
        },
        token
      );

      setDocumentName(undefined);
      setCalculatorResult(result);
    } catch (error) {
      newError("Houve um erro ao calcular suas emissões");
    } finally {
      hideLoading();
    }
  };

  const airConditioning = Object.values(airConditioningTypesObj);

  const transportUnitsOptions = Object.keys(transportUnitsObj);

  const booleanOptions = ["Sim", "Não"];

  return (
    <C.Container>
      <C.TitleAndProgress ref={questionsTop}>
        <C.TitleAndDescription>
          <H1 color={textColor} fontWeight="bold">
            Calculadora - Logística
          </H1>

          {!calculatorResult && (
            <H5 color={textColor}>
              Detalhe sua operação respondendo às perguntas abaixo e descubra
              sua pegada mensal de carbono
            </H5>
          )}
        </C.TitleAndDescription>

        {!calculatorResult && (
          <CalculatorProgressBar
            numberOfQuestions={5}
            currentQuestion={questionNumber}
          />
        )}
      </C.TitleAndProgress>

      <C.Content resultBox={!!calculatorResult}>
        {calculatorResult && (
          <C.ChartResult>
            <H2 color={textColor} fontWeight="600">
              A pegada mensal de carbono de sua empresa é:
            </H2>

            <H2 color={textColor}>
              {calculatorResult.totalEmissions.toFixed(2).replace(".", ",")}{" "}
              toneladas de CO<sub>2</sub>e
            </H2>

            <H3 color={textColor}>
              Clique abaixo para compensar suas emissões
            </H3>

            <Button
              variant="solid"
              fontWeight="bold"
              textColor={backgroundColor}
              backgroundColor={primaryColor}
              onClick={() => onBuyCredits()}
            >
              Compre créditos
            </Button>
          </C.ChartResult>
        )}

        <C.CalculatorBox resultBox={!!calculatorResult}>
          {calculatorResult ? (
            <>
              <C.Charts>
                <C.ChartBox>
                  <ChartAndIndicators result={calculatorResult} />
                </C.ChartBox>
              </C.Charts>

              <C.Buttons>
                <Button
                  variant="solid"
                  fontWeight="bold"
                  textColor={backgroundColor}
                  backgroundColor={primaryColor}
                  onClick={() => onBuyCredits()}
                >
                  Compre créditos
                </Button>

                <Button
                  variant="outline"
                  fontWeight="bold"
                  textColor={primaryColor}
                  borderColor={primaryColor}
                  backgroundColor={backgroundColor}
                  onClick={() => onNewConsultHandler()}
                >
                  Nova consulta
                </Button>
              </C.Buttons>
            </>
          ) : (
            <Questions
              office={office}
              pickups={pickups}
              warehouse={warehouse}
              pickupsData={pickupsData}
              question={questionNumber}
              documentName={documentName}
              formFields={calculatorForm}
              pickupsErrors={pickupsErrors}
              columnOptions={columnOptions}
              deliveriesData={deliveriesData}
              booleanOptions={booleanOptions}
              onAddPickup={() => onAddPickup()}
              deliveriesErrors={deliveriesErrors}
              onAheadClick={() => onAheadClick()}
              onAddDelivery={() => onAddDelivery()}
              airConditioningTypes={airConditioning}
              transportOptions={transportUnitsOptions}
              columnRoutesOptions={columnRoutesOptions}
              airConditioningType={airConditioningType}
              onPreviousClick={() => onPreviousClick()}
              setOffice={() => setOffice((curr) => !curr)}
              onCalculateClick={() => onCalculateHandler()}
              onRemovePickup={(val) => onExcludePickup(val)}
              setPickups={() => setPickups((curr) => !curr)}
              onRemoveDelivery={(val) => onExcludeDelivery(val)}
              setWarehouse={() => setWarehouse((curr) => !curr)}
              onChangeForm={(key, val) => onChangeHandler(key, val)}
              onAddRoutes={(doc, docName) => onAddRoutes(doc, docName)}
              setAirConditioningType={(val) => setAirConditioningType(val)}
              onChangeDeliveryForm={(key, index, val) =>
                onChangeDeliveryHandler(key, index, val)
              }
              onChangePickupForm={(key, index, val) =>
                onChangePickupHandler(key, index, val)
              }
            />
          )}
        </C.CalculatorBox>
      </C.Content>

      {excludePickup && (
        <ExcludeModal
          isOpen={excludePickup}
          onClose={() => setExcludePickup(false)}
          onExclude={() => onRemovePickup(removePickup || 0)}
        />
      )}

      {excludeDelivery && (
        <ExcludeModal
          isOpen={excludeDelivery}
          onClose={() => setExcludeDelivery(false)}
          onExclude={() => onRemoveDelivery(removeDelivery || 0)}
        />
      )}
    </C.Container>
  );
};

export default Calculator;
