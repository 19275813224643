/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import * as S from "./styles";
import {
  ICardsData,
  ICreditCard,
  ICreditCardHolderInfo,
} from "../../types/order";
import { Order } from "../../services";
import Page from "../../components/molecules/Page";
import Button from "../../components/atoms/Button";
import H5 from "../../components/atoms/Typography/H5";
import Skeleton from "../../components/atoms/Skeleton";
import { Auth, Loading, Snackbar, Theme } from "../../hooks";
import { creditCardBrand, emptyCardInfo } from "../../utils/order";
import AvailableIcons from "../../components/atoms/AvailableIcons";
import CreditCardInfo from "../../components/organisms/CreditCardInfo";
import ExcludeCardModal from "../../components/molecules/ExcludeCardModal";
import TitleDescription from "../../components/molecules/TitleDescription";

const validateForm = (data: ICardsData): { [key: string]: string[] } => {
  const errors: { [key: string]: string[] } = {};

  if (!data.creditCard.number)
    errors.number = [
      ...(errors.number || []),
      "Número do cartão é obrigatório",
    ];

  if (!data.creditCard.ccv)
    errors.ccv = [...(errors.ccv || []), "CCV é obrigatório"];

  if (!data.creditCard.holderName)
    errors.holderName = [
      ...(errors.holderName || []),
      "Nome no cartão é obrigatório",
    ];

  if (!data.creditCard.expiryMonth)
    errors.expiryMonth = [...(errors.expiryMonth || []), "Mês"];

  if (!data.creditCard.expiryYear)
    errors.expiryYear = [...(errors.expiryYear || []), "Ano"];

  if (!data.creditCardHolderInfo.addressNumber)
    errors.addressNumber = [
      ...(errors.addressNumber || []),
      "Número do endereço é obrigatório",
    ];

  if (!data.creditCardHolderInfo.address)
    errors.address = [...(errors.address || []), "Endereço é obrigatório"];

  if (!data.creditCardHolderInfo.cpfCnpj)
    errors.cpfCnpj = [...(errors.cpfCnpj || []), "CPF/CNPJ é obrigatório"];

  if (!data.creditCardHolderInfo.email)
    errors.email = [...(errors.email || []), "E-mail do contato é obrigatório"];

  if (!data.creditCardHolderInfo.phone)
    errors.phone = [
      ...(errors.phone || []),
      "Telefone do contato é obrigatório",
    ];

  if (!data.creditCardHolderInfo.name)
    errors.name = [...(errors.name || []), "Nome do contato é obrigatório"];

  if (!data.creditCardHolderInfo.postalCode)
    errors.postalCode = [...(errors.postalCode || []), "CEP é obrigatório"];

  return errors;
};

const PaymentCards: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [newCard, setNewCard] = useState<boolean>(false);
  const [excludeCard, setExcludeCard] = useState<string>();
  const [excludeModal, setExcludeModal] = useState<boolean>(false);
  const [availableCards, setAvailableCards] = useState<ICardsData[]>();
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [newCardData, setNewCardData] = useState<ICardsData>(emptyCardInfo);

  const { user, token, setUserHandler } = Auth.useAuth();
  const { newError, newSuccess } = Snackbar.useSnackbar();
  const { showLoading, hideLoading } = Loading.useLoading();
  const { primaryColor, tertiaryColor, textColor, backgroundColor } =
    Theme.useTheme();

  useEffect(() => {
    const getCreditCards = async () => {
      try {
        const creditCards = await Order.getCreditCards(user.entity, token);

        setAvailableCards(creditCards);
      } catch (error) {
        newError("Houve um eror ao obter os cartões salvos.");
      } finally {
        setLoading(false);
      }
    };

    getCreditCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCardInfo = (key: keyof ICreditCard, val: string) => {
    if (key === "number") {
      const currBrand = creditCardBrand(val);

      setNewCardData((curr) => ({
        ...curr,
        creditCard: {
          ...curr.creditCard,
          [key]: val,
          brand: currBrand,
        },
      }));

      return;
    }

    setNewCardData((curr) => ({
      ...curr,
      creditCard: {
        ...curr.creditCard,
        [key]: val,
      },
    }));
  };

  const onChangeCardHolderInfo = (
    key: keyof ICreditCardHolderInfo,
    val: string
  ) => {
    setNewCardData((curr) => ({
      ...curr,
      creditCardHolderInfo: {
        ...curr.creditCardHolderInfo,
        [key]: val,
      },
    }));
  };

  const onDiscardNewCard = () => {
    setErrors({});
    setNewCard(false);
    setNewCardData(emptyCardInfo);
  };

  const onRemove = (cardId: string) => {
    setExcludeModal(true);
    setExcludeCard(cardId);
  };

  const onRemoveCard = async () => {
    try {
      showLoading();

      if (!excludeCard) return;

      const newCards = await Order.removeCreditCard(
        excludeCard,
        user.entity,
        token
      );

      setAvailableCards(newCards);
      setExcludeCard(undefined);
      setExcludeModal(false);

      newSuccess("Cartão excluído com sucesso.");
    } catch (error) {
      newError("Houver um erro ao excluir o cartão");
    } finally {
      hideLoading();
    }
  };

  const onSaveCard = async () => {
    try {
      showLoading();

      const currErrors = validateForm(newCardData);

      if (currErrors && Object.keys(currErrors).length > 0) {
        setErrors(currErrors);
        newError("Verifique as informações. Cartão não salvo");

        return;
      }

      setErrors({});

      const updatedUser = (async () => {
        if (!user.asaasId) {
          const newUser = await Order.createAsaasClient(
            {
              email: user.mail,
              company: user.company,
              phone: user.contactPhone,
              externalReference: user.id,
              mobilePhone: user.contactPhone,
              address: newCardData.creditCardHolderInfo.address,
              postalCode: newCardData.creditCardHolderInfo.postalCode,
              addressNumber: newCardData.creditCardHolderInfo.addressNumber,
              complement: newCardData.creditCardHolderInfo.addressComplement,
              name: user.contactName || newCardData.creditCardHolderInfo.name,
              cpfCnpj: (
                user.cnpj ||
                user.cpf ||
                newCardData.creditCardHolderInfo.cpfCnpj
              ).replace(/[^0-9]/g, ""),
            },
            user.entity,
            token
          );

          return newUser;
        }

        return user;
      })();

      if (JSON.stringify(user) !== JSON.stringify(updatedUser))
        setUserHandler(await updatedUser);

      const newCards = await Order.saveCreditCard(
        newCardData,
        user.entity,
        token,
        (await updatedUser).asaasId || ""
      );

      setAvailableCards(newCards);
      setNewCard(false);
      setNewCardData(emptyCardInfo);

      newSuccess("Cartão salvo com sucesso.");
    } catch (error) {
      newError("Houve um erro ao salvar o cartão");
    } finally {
      hideLoading();
    }
  };

  return (
    <Page pageIndex={11} mobileSection="profile">
      <S.Content>
        <TitleDescription
          title="Cartões"
          description="Adicione ou exclua seus cartões, para maior praticidade no checkout."
        />

        <S.PaymentCards>
          {loading ? (
            <Skeleton
              direction="column"
              numberSkeletons={3}
              skeletonHeight="60px"
              skeletonWidth="400px"
            />
          ) : (
            <>
              {newCard ? (
                <CreditCardInfo
                  errors={errors}
                  showSaveDisclamer={false}
                  onSaveCard={() => onSaveCard()}
                  cardInfo={newCardData.creditCard}
                  onDiscard={() => onDiscardNewCard()}
                  cardHolderInfo={newCardData.creditCardHolderInfo}
                  onChangeCardInfo={(key, val) => onChangeCardInfo(key, val)}
                  onChangeCardHolderInfo={(key, val) =>
                    onChangeCardHolderInfo(key, val)
                  }
                />
              ) : (
                <S.Cards>
                  {availableCards && availableCards.length > 0 ? (
                    availableCards.map((item, index) => (
                      <S.CardBox key={`${item.id}#${index}`}>
                        <S.CreditCard
                          isSelected={false}
                          onSelect={() => null}
                          number={item.creditCard.number}
                          name={item.creditCard.holderName}
                          brand={item.creditCard.brand || "mastercard"}
                        />

                        <S.RemoveIcon
                          backgroundColor={tertiaryColor}
                          onClick={() => onRemove(item.id || "")}
                        >
                          <AvailableIcons
                            option="trashCan"
                            color={backgroundColor}
                          />
                        </S.RemoveIcon>
                      </S.CardBox>
                    ))
                  ) : (
                    <H5 color={textColor}>
                      Você ainda não possui cartões salvos.
                    </H5>
                  )}

                  <S.AddCardButton>
                    <Button
                      width="400px"
                      variant="dashed"
                      textColor={primaryColor}
                      borderColor={primaryColor}
                      onClick={() => setNewCard(true)}
                    >
                      Adicionar novo cartão de crédito
                    </Button>
                  </S.AddCardButton>
                </S.Cards>
              )}
            </>
          )}
        </S.PaymentCards>

        {excludeModal && (
          <ExcludeCardModal
            isOpen={excludeModal}
            onExclude={() => onRemoveCard()}
            onClose={() => setExcludeModal(false)}
          />
        )}
      </S.Content>
    </Page>
  );
};

export default PaymentCards;
