import React, { createContext, useContext, useEffect, useState } from "react";

import { Auth } from "..";
import { Manager } from "../../services";

interface IThemeContext {
  textColor: string;
  primaryColor: string;
  tertiaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
}

const defaultContext: IThemeContext = {
  textColor: "#000000",
  primaryColor: "#226d30",
  tertiaryColor: "#dee2df",
  secondaryColor: "#add13f",
  backgroundColor: "#fafafa",
};

const Context = createContext(defaultContext);
const useTheme = () => useContext(Context);

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<IThemeContext>(defaultContext);

  const { user, isLogged, setUserHandler } = Auth.useAuth();

  useEffect(() => {
    const run = async () => {
      if (!isLogged) {
        const url = window.location.href;
        const usefulUrl = url.split("://");
        let entity = usefulUrl[1].split(".")[0];

        entity =
          entity.substring(0, 9) === "localhost" ||
          entity.substring(0, 9) === "staging"
            ? "ibioma"
            : entity;

        const managerPref = await Manager.getManagerPreferences(entity);

        const currentTheme = {
          textColor: managerPref.textColor || "#000000",
          primaryColor: managerPref.primaryColor || "#226d30",
          tertiaryColor: managerPref.tertiaryColor || "#dee2df",
          secondaryColor: managerPref.secondaryColor || "#add13f",
          backgroundColor: managerPref.backgroundColor || "#fafafa",
        };

        document.body.style.backgroundColor = currentTheme.backgroundColor;
        setTheme(currentTheme);
        setUserHandler({
          ...user,
          entity: entity,
        });
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) {
      const currentTheme = {
        textColor: user.textColor || "#000000",
        primaryColor: user.primaryColor || "#226d30",
        tertiaryColor: user.tertiaryColor || "#dee2df",
        secondaryColor: user.secondaryColor || "#add13f",
        backgroundColor: user.backgroundColor || "#fafafa",
      };

      document.body.style.backgroundColor = currentTheme.backgroundColor;
      setTheme(currentTheme);
    }
  }, [isLogged, user]);

  const providerValue = {
    ...theme,
    setTheme,
  };

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export default {
  useTheme,
  Provider,
};
