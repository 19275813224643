import styled, { css } from "styled-components";

import P from "../../../../components/atoms/Typography/P";
import H5 from "../../../../components/atoms/Typography/H5";

export const Container = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;

  h5 {
    text-align: justify;
  }
`;

export const Invoice = styled.div`
  display: flex;
  max-width: 360px;
  margin: 12px 0 24px;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  width: fit-content;
  transform: rotate(180deg);
  justify-content: flex-start;
`;

export const Details = styled.div`
  gap: 12px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

export const Detail = styled.div`
  width: 100%;
  display: flex;
  overflow-x: visible;
  padding: 16px 20px 0;
  flex-direction: column;

  @media (max-width: 820px) {
    padding: 16px 0 0;
  }
`;

type WidthProps = {
  width: string;
};

export const TitleCustom = styled(H5)<WidthProps>`
  ${({ width }) => css`
    width: ${width};
    padding-left: 20px;

    sub {
      font-weight: bold;
      font-size: 0.875rem;
    }

    @media (max-width: 620px) {
      padding-left: 0;
      font-size: 0.8rem;

      sub {
        font-size: 0.75rem;
      }
    }
  `}
`;

export const ValueCustom = styled(P)<WidthProps>`
  ${({ width }) => css`
    width: ${width};
    padding-left: 20px;

    @media (max-width: 620px) {
      padding-left: 0;
      line-height: 1.1;
      font-size: 0.7rem;
    }
  `}
`;

export const HeaderDetail = styled.div`
  display: flex;
`;

type BorderProps = {
  borderColor: string;
};

export const ProjectsList = styled.div<BorderProps>`
  ${({ borderColor }) => css`
    gap: 24px;
    display: flex;
    padding: 16px 0;
    margin-top: 16px;
    flex-direction: column;
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
  `}
`;

export const Project = styled.div`
  display: flex;
  align-items: center;
`;

export const Total = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const TotalValue = styled(H5)<WidthProps>`
  ${({ width }) => css`
    width: ${width};
    padding-left: 20px;

    @media (max-width: 620px) {
      padding-left: 0;
      font-size: 0.8rem;
    }
  `}
`;

export const CancelSignatureMessage = styled.div`
  display: flex;
  margin-top: 100px;
  align-items: center;
`;
