import styled, { css } from "styled-components";

export const Container = styled.div`
  gap: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  h3 {
    max-width: 300px;
    text-align: center;

    sub {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }

  @media (max-width: 1160px) {
    flex-direction: row;
  }

  @media (max-width: 1300px) {
    gap: 40px;
    flex-direction: column;
  }
`;

export const Chart = styled.div`
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1750px) {
    width: 360px;
  }
`;

export const PieChartLegend = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LegendItem = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
`;

export const Colors = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ColorProps = {
  color: string;
};

export const Color = styled.div<ColorProps>`
  ${({ color }) => css`
    width: 20px !important;
    height: 20px;
    display: flex;
    border-radius: 50%;
    background-color: ${color};
  `}
`;

export const Texts = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
`;

export const Indicators = styled.div`
  gap: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 440px) {
    gap: 20px;
  }
`;

export const Indicator = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  max-width: 200px;
  align-items: center;

  h4 {
    sub {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 1200px) {
    max-width: 160px;
  }

  @media (max-width: 1020px) {
    max-width: 200px;
  }

  @media (max-width: 645px) {
    max-width: 140px;
  }
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Data = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

type PercentageProps = {
  color: string;
};

export const Percentage = styled.div<PercentageProps>`
  ${({ color }) => css`
    width: 64px;
    padding: 6px;
    display: flex;
    color: #fafafa;
    font-weight: bold;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    background-color: ${color};
  `}
`;
