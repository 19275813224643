import { Theme } from "../../../hooks";
import CheckIcon from "../../icons/CheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import InfoIcon from "../../icons/InfoIcon";
import WarningIcon from "../../icons/WarningIcon";
import Small from "../Typography/Small";

import * as S from "./styles";

interface IProps {
  message: string;
  isVisible: boolean;
  onClick: () => void;
  type: "error" | "info" | "success" | "warning";
}

const Snackbar: React.FC<IProps> = ({ type, message, onClick, isVisible }) => {
  const { primaryColor, backgroundColor, textColor } = Theme.useTheme();

  if (!isVisible) return null;

  const color = {
    error: "#FF4D4F90",
    success: `${primaryColor}90`,
    info: `${textColor}cc`,
    warning: "#eead2d",
  };

  const icon = {
    error: <CloseIcon color={backgroundColor} />,
    success: <CheckIcon color={backgroundColor} />,
    info: <InfoIcon color={textColor} />,
    warning: <WarningIcon color={backgroundColor} />,
  };

  return (
    <S.Snackbar onClick={onClick}>
      <S.Container backgroundColor={color[type]}>
        {icon[type]}
        <Small color={type === "info" ? textColor : backgroundColor}>
          {message}
        </Small>
      </S.Container>
    </S.Snackbar>
  );
};

export default Snackbar;
