import styled, { css } from "styled-components";

type ProgressBarProps = {
  backgroundColor: string;
};

export const ProgressBar = styled.div<ProgressBarProps>`
  ${({ backgroundColor }) => css`
    width: 100%;
    height: 3px;
    max-width: 700px;
    background-color: ${backgroundColor};

    @media (max-width: 1080px) {
      max-width: 500px;
    }

    @media (max-width: 680px) {
      max-width: 300px;
    }
  `}
`;

type ProgressProps = {
  progress: number;
  backgroundColor: string;
};

export const Progress = styled.div<ProgressProps>`
  ${({ progress, backgroundColor }) => css`
    height: 3px;
    max-width: 700px;
    width: ${`${progress}%`};
    background-color: ${backgroundColor};

    @media (max-width: 1080px) {
      max-width: 500px;
    }

    @media (max-width: 680px) {
      max-width: 300px;
    }
  `}
`;
