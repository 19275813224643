import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: fit-content;
  transform: rotate(180deg);
  justify-content: flex-start;
`;
